import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface IMenuItem {
  isActive: boolean;
}

export const SmallCloseMenu = styled.img`
  position: absolute;
  bottom: 2.25rem;
  left: 2rem;
  width: 1rem;
  cursor: pointer;
`;

export const SmallMenuBox = styled.nav`
  min-width: 5.25rem;
  height: 100vh;
  background-color: ${COLORS.darkBlue};

  hr {
    opacity: 0.1;
    border: 0.4px solid ${COLORS.white};
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 3.125rem;
  }
`;

export const ContainerSmallLogo = styled.div`
  height: 2.375rem;
  width: 2.375rem;
  border-radius: 50%;
  background-color: ${COLORS.darkBlue};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.625rem;
  margin-bottom: 3.75rem;
`;

export const ItemSmallMenu = styled.div<IMenuItem>`
  height: 2.375rem;
  width: 2.375rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  cursor: pointer;
  ${props => props.isActive && css`background: rgba(255, 255, 255, 0.1);` }

  & + & {
    margin-top: 1rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ItemSmallMenuHelp = styled.div<IMenuItem>`
  height: 2.375rem;
  width: 2.375rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 5.625rem;
  left: 1.313rem;
  ${props => props.isActive && css`background: rgba(255, 255, 255, 0.1);` }

  & + & {
    margin-top: 1rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
