import React, { useState } from "react";

import SubscriberDashboard from "components/SubscriberDashboard";
import PrimaryButton from "components/PrimaryButton";

import longArrowRight from 'assets/long-arrow-right.svg';
import logoTelemedicine1 from 'assets/logo-telemedicine-1.svg';
import logoTelemedicine2 from 'assets/logo-telemedicine-2.svg';
import logoTelemedicine3 from 'assets/logo-telemedicine-3.svg';
import logoTelemedicine4 from 'assets/logo-telemedicine-4.svg';
import logoTelemedicine5 from 'assets/logo-telemedicine-5.svg';

import {
  Card,
  CardsContainer,
  Container, 
  TelemedicineContainer
} from "./styles";

import api from "service/subscriberApi";
import { toast } from "react-toastify";
import { getUser } from "service/subscriber";

const Telemedicine: React.FC = () => {
  const isPlus = getUser().isPlus;
  const [openTelemedicine , setOpenTelemedicine] = useState(false);

  async function getUrlTelemedicine() {
    if (openTelemedicine) return;

    setOpenTelemedicine(true);
    const response = await api.get(`/users/generate-magic-link-to-telemedicine`);

    if (response?.status === 200 && response?.data?.url) {
      window.open(response.data.url, "_blank");
      setOpenTelemedicine(false);
    } else if (response?.status === 204) { 
      toast.error('Assinatura bloqueada para telemedicina!');
      setOpenTelemedicine(false);
    } else {
      toast.error('Não foi possivel redirecionar para telemedicina, por favor tente mais tarde.');
      setOpenTelemedicine(false);
    }
  }

  function cardConsultation() {
    return(
      <Card>
        <div className="card-content">
          <img src={logoTelemedicine2} alt="LogoTelemedicine2"></img>
          <h4>{ !isPlus ? "Consultar sem sair de casa" : "24 consultas" }</h4>
          <p>{ !isPlus ? 
            "Agende consultas a qualquer momento a qualquer lugar" 
            : "Direito a 2 consultas por mês para a mesma pessoa"}</p>
        </div>
      </Card>
    );
  }

  function cardDoctor() {
    return (
      <Card>
        <div className="card-content">
          <img src={logoTelemedicine3} alt="LogoTelemedicine3"></img> 
          <h4>{ !isPlus ? "Médicos de várias especialidades" : "Cliníco Geral" }</h4>
          <p>{ !isPlus ? 
            "Agende com nutricionistas, psicólogos e outras especialidades" 
            : "Vários médicos prontos para atendimento" }</p>
        </div>
      </Card>
    );
  }

  function cardAttendance() {
    return (
      <Card>
        <div className="card-content">
          <img src={logoTelemedicine4} alt="LogoTelemedicine4"></img>           
          <h4>Atendimento 24h</h4>
          <p>Atendimento a qualquer hora do dia</p>
        </div>
      </Card>  
    );    
  }

  function cardDiscount() {
    return (
      <Card>
        <div className="card-content">
          <img src={logoTelemedicine5} alt="LogoTelemedicine5"></img>           
          <h4>{ !isPlus ? "Baixo custo" : "Custo zero" }</h4>
          <p>{ !isPlus ? "Descontos de até 50% para as consultas online" 
            : "Faça suas consultas sem pagar nada mais" }</p>
        </div>
      </Card>
    );
  }

  return (
    <SubscriberDashboard title="Consulta Online" backPath="/assinantes/home">
      <Container>
        <TelemedicineContainer>
          <div className="header-telemedicine">
            <div className="icon-telemedicine">
              <img src={logoTelemedicine1} alt="Telemedicine"/>
            </div>
            
            <div className="telemedicine-description">
              <h2>Fazer consulta online</h2>
              <p>Realize sua consulta sem sair de casa a qualquer hora do dia.</p>
            </div>
          </div>
          <PrimaryButton
            type="submit"
            height="2.5rem"
            width="12rem"
            iconUrl={longArrowRight}
            onClick={getUrlTelemedicine}
            positionIcon="right">
            {openTelemedicine ? "Redirecionando..." : "Fazer Consulta"}
          </PrimaryButton>
        </TelemedicineContainer>
        <div className="cards-container-description">
          <h2>Com a consulta online você pode:</h2>
        </div>
        <CardsContainer>
          {cardConsultation()}
          {cardDoctor()}
          {cardAttendance()}
          {cardDiscount()}
        </CardsContainer>
      </Container>
    </SubscriberDashboard>
  )
}

export default Telemedicine;
