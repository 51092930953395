import styled from 'styled-components';

export const ContainerEmptyArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;

  div {
    max-width: 20rem;
    text-align: center;
  }

  p {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
