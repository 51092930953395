import React, { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "service/subscriberApi";

import SubscriberDashboard from "components/SubscriberDashboard";
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import SearchInput from 'components/SearchInput';
import Badge from "components/Badge";

import { limitStr, formatDate } from "utils";

import activeVoucherIcon from "assets/icons/active-voucher.svg";
import inactiveVoucherIcon from "assets/icons/inactive-voucher.svg";

import {
  Container,
  Card,
  Cards,
  ButtonsSearchContainer,
  SearchContainer,
} from "./styles";

interface IVoucher {
  code: string;
  title: string;
  expirationDate: string;
  translatedStatus: string;
  description: string;
  showAllDescription?: boolean;
}

const Vouchers: React.FC = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [allVouchers, setAllVouchers] = useState<IVoucher[]>([]);
  const [filteredVouchers, setFilteredVouchers] = useState<IVoucher[]>([]);

  useEffect(() => {
    loadVouchersByUser();
  }, []);

  useEffect(() => {
    searchByCode(null);
  }, [filter]);

  function loadVouchersByUser(): void {
    api.get(`/users/vouchers/`)
    .then(response => {
      const vouchers: IVoucher[] = response.data.vouchers || [];

      setAllVouchers(vouchers.map(formatVoucher));
      setFilteredVouchers(vouchers.map(formatVoucher));
    })
    .catch(_error => {
      toast.error(
        'Serviço indisponível no momento, por favor tente mais tarde.',
      );
    });
  }

  function searchByCode(event: FormEvent | null) {
    event && event.preventDefault();

    const vouchers = allVouchers
      .filter(voucher => voucher.code.toLowerCase().includes(search.toLowerCase()))
      .filter(filterByStatus);

    setFilteredVouchers(vouchers);
  }

  function formatVoucher(voucher: any): IVoucher {   
    return {
      code: voucher.token,
      title: voucher.campaign.title,
      description: voucher.campaign.description,
      expirationDate: formatDate(voucher.expiration_date),
      translatedStatus: voucher.status === 'active' ? 'ATIVO' : 'INATIVO',
    };
  }

  function openMoreDetails(voucher: IVoucher) {
    const newVouchers = allVouchers.map((item) => {
      if (item.code === voucher.code) {
        item.showAllDescription = !item.showAllDescription;
      }

      return item;
    });
    setFilteredVouchers(newVouchers);
  }

  function sortVoucher(firstVoucher: IVoucher, _secondVoucher: IVoucher) {
    return firstVoucher.translatedStatus === 'ATIVO' ? -1 : 1;
  }

  function filterByStatus(voucher: IVoucher) {
    if (filter === 'active') {
      return voucher.translatedStatus === 'ATIVO';
    } else if (filter === 'inactive') {
      return voucher.translatedStatus === 'INATIVO'
    } else {
      return true;
    }
  }

  return (
    <SubscriberDashboard title="Vouchers" backPath="/assinantes/home">
      <Container>
        <h1>Utilize o filtro para encontrar o cupom que procura</h1>
        <SearchContainer onSubmit={searchByCode}>
          <SearchInput
            type="text"
            placeholder="Buscar por código"
            value={search}
            maxLength={50}
            onChange={(event: any) => setSearch(event.target.value)} />
          <PrimaryButton
            type="submit"
            height="2.5rem"
            width="10rem">
            Buscar
          </PrimaryButton>
        </SearchContainer>
        <ButtonsSearchContainer>
          <SecondaryButton
            type="button"
            height="1.875rem"
            width="5.625rem"
            active={filter === 'all'}
            onClick={() => setFilter('all')}>Todos ({allVouchers.length})</SecondaryButton>
          <SecondaryButton
            type="button"
            height="1.875rem"
            width="5.625rem"
            active={filter === 'active'}
            onClick={() => setFilter('active')}>Ativos ({allVouchers.filter(v => v.translatedStatus === 'ATIVO').length})</SecondaryButton>
          <SecondaryButton
            type="button"
            height="1.875rem"
            width="5.625rem"
            active={filter === 'inactive'}
            onClick={() => setFilter('inactive')}>Inativos ({allVouchers.filter(v => v.translatedStatus === 'INATIVO').length})</SecondaryButton>
        </ButtonsSearchContainer>
        <Cards>
          {filteredVouchers.sort(sortVoucher)
            .map((voucher) => (
            <Card active={voucher.translatedStatus === 'ATIVO'} key={voucher.code}>
              <div className="header">
                <div className="voucher-icon">
                  <img src={voucher.translatedStatus === 'ATIVO' ? activeVoucherIcon : inactiveVoucherIcon} alt="Voucher"/>
                  <div>
                    <strong>VOUCHER</strong>
                    <p>Expira em {voucher.expirationDate}</p>
                  </div>
                </div>
                <Badge type={voucher.translatedStatus === 'ATIVO' ? 'success' : 'inactive'}>{voucher.translatedStatus}</Badge>
              </div>
              <div className="body">
                <Badge type={voucher.translatedStatus === 'ATIVO' ? 'light-warning' : 'inactive'} fontSize={14}>{voucher.code}</Badge>
                <div>
                  <p className="voucher-title" title={voucher.title}>{limitStr(voucher.title, 15)}</p>
                  {voucher.showAllDescription
                    ? <p className="voucher-description">{voucher.description}</p>
                    : <p className="voucher-description">{limitStr(voucher.description, 30)}</p>}
                  <p className="show-more" onClick={() => openMoreDetails(voucher)}>VER DETALHES</p>
                </div>
              </div>
            </Card>
          ))}
        </Cards>
        {(!filteredVouchers.length || !allVouchers.length) && <p className="empty-area">Nenhum voucher encontrado.</p>}
      </Container>
    </SubscriberDashboard>
  )
}

export default Vouchers;
