import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerList = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 0rem 3.75rem;
  p {
    margin-top: 1rem;
  }
`;
