import api from './subscriberApi';

export interface ISubscriber {
  id: string;
  name: string;
  email: string;
  imageUrl: string;
  isPlus: boolean;
}

export function isAuthenticated(): boolean {
  return !!localStorage.getItem('@subscriber_token');
}

export function saveToken(token: string): void {
  localStorage.setItem('@subscriber_token', token);
}

export function getToken(): string | null {
  return localStorage.getItem('@subscriber_token');
}

export function saveUser(sub: ISubscriber): ISubscriber {
  localStorage.setItem('@subscriber_user', JSON.stringify(sub));

  return sub;
}

export function getUser(): ISubscriber {
  const user: ISubscriber = JSON.parse(localStorage.getItem('@subscriber_user') || "{}");

  return user;
}

export function logout(): void {
  localStorage.removeItem('@subscriber_user');
  localStorage.removeItem('@subscriber_token');
}

export function syncronizeSubscriber() {
  if (!isAuthenticated()) return;

  api.get(`/users/find-user`)
    .then((response) => {
      const user: ISubscriber = getUser();
      const newUser = response.data.user;
      user.name = newUser.name
      user.email = newUser.email
      user.isPlus = newUser.plus
      saveUser(user);
    });
}
