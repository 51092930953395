import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'service/hrAccountApi';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/HrAccountDashboard';
import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';
import SuccessModal from 'components/SuccessModal';
import BtnLink from 'components/BtnLink';

import {
  Container,
  Form,
  ContainerButton
} from './styles';

import { toast } from 'react-toastify';

const ChangePassword: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [newPassowrd, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmed] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Alterar senha', path: `` },
    ]);
  }, []);

  function validForm() {
    let isValid = true;

    if (!password || !newPassowrd || !passwordConfirmation) {
      setMessage('Preencha todos os campos.');
      isValid = false;
    } else if (newPassowrd !== passwordConfirmation) {
      setMessage('Senhas não coincidem.');
      isValid = false;
    }

    return isValid;
  }

  async function handleForm(e: FormEvent) {
    e.preventDefault();
    setMessage('');

    if (!validForm()) return;

    try {
      const params = {
        password: password,
        new_password: newPassowrd,
        password_confirmation: passwordConfirmation,
      }

      const response = await api.put('/hr-account/reset-password', params);

      if (response.status === 200) {
        setShowSuccessModal(true);
      } else {
        setMessage('Não foi possível salvar a imagem.');
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status === 400) {
        setMessage(ex.response.data.error);
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  return (
    <>
      <Dashboard title="Alterar senha" backPath="/rh/dados-pessoais">
        <Container>
          <Form onSubmit={handleForm}>
            <h3>Digite sua nova senha</h3>

            <TextInput
              type="password"
              placeholder="Senha atual"
              value={password}
              onChange={(value: string) => setPassword(value)} />

            <TextInput
              type="password"
              placeholder="Nova senha"
              value={newPassowrd}
              onChange={(value: string) => setNewPassword(value)} />

            <TextInput
              type="password"
              placeholder="Confirmar senha"
              value={passwordConfirmation}
              onChange={(value: string) => setPasswordConfirmed(value)} />

            <p className="message">{message}</p>

            <ContainerButton>
              <BtnLink onClick={() => history.goBack()}>Cancelar</BtnLink>
              <PrimaryButton
                type="submit"
                width="10rem">
                Salvar senha
              </PrimaryButton>
            </ContainerButton>
          </Form>
        </Container>
      </Dashboard>
      {
        showSuccessModal &&
        <SuccessModal
          message="Senha alterada com sucesso"
          buttonMessage="OK"
          onClose={() => { history.goBack(); }} />
      }
    </>
  )
};

export default ChangePassword;
