import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  margin-top: 4rem;
  max-width: 25rem;
  width: 100%;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 1.875rem;
  }

  div {
    margin-top: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 14px;
      color: ${COLORS.accentColor};
      text-decoration: underline;
      cursor: pointer;
    }

    &.actions {
      margin-top: 3rem;
    }
  }
`;

export const StatusBox = styled.div`
  display: flex;
  align-items: center;
`

export const Separator = styled.hr`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: solid 0.01rem rgba(220, 220, 220, 0.6);
`;
