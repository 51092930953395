import React from 'react';

import { ContainerButton } from './styles';

interface ISecondaryButton{
  onClick?: Function;
  type: "button" | "submit" | "reset" | undefined;
  iconUrl?: string;
  height?: string;
  width?: string;
  altImage?: string;
  active?: boolean;
}

const SecondaryButton: React.FC<ISecondaryButton> = (props) => {
  const { onClick, type, iconUrl, height, width, children, altImage, active } = props;

  return (
    <ContainerButton
      type={type}
      height={height}
      width={width}
      icon={iconUrl}
      text={!!children}
      onClick={(e: any) => !!onClick && onClick(e)}
      active={active}>
      {iconUrl && <img src={iconUrl} alt={altImage} />}
      {children}
    </ContainerButton>
  )
};

export default SecondaryButton;
