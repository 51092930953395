import React from 'react';
import { HashRouter, Route } from 'react-router-dom';

import { PrivateRouterHr, PrivateRouterSubscriber } from './PrivateRouter';

import AdditionalService from 'pages/hrAccount/AdditionalService';
import AssistenceService from 'pages/hrAccount/AssistanceService';
import AuthHrAccount from 'pages/hrAccount/AuthHrAccount';
import ChangePassword from 'pages/hrAccount/ChangePassword';
import ChoiceCompanies from 'pages/hrAccount/ChoiceCompanies';
import ChooseAccess from 'pages/hrAccount/ChooseAccess';
import CompanyBill from 'pages/hrAccount/CompanyBill';
import CompanyBills from 'pages/hrAccount/CompanyBills';
import CompanyProfile from 'pages/hrAccount/CompanyProfile';
import Dependant from 'pages/hrAccount/Dependant';
import Dependants from 'pages/hrAccount/Dependants';
import Employee from 'pages/hrAccount/Employee';
import Employees from 'pages/hrAccount/Employees';
import Faqs from 'pages/hrAccount/Faqs';
import ForgotPassword from 'pages/hrAccount/ForgotPassword';
import ImportEmployees from 'pages/hrAccount/ImportEmployees';
import NewEmployee from 'pages/hrAccount/NewEmployee';
import Notification from 'pages/hrAccount/Notification';
import Notifications from 'pages/hrAccount/Notifications';
import PersonalData from 'pages/hrAccount/PersonalData';
import ResetPassword from 'pages/hrAccount/ResetPassword';
import ShowImport from 'pages/hrAccount/ShowImport';

import AuthSubscriber from 'pages/subscriber/AuthSubscriber';
import SubscriberFirstAccess from 'pages/subscriber/FirstAccess';
import SubscriberForgotPassword from 'pages/subscriber/ForgotPassword';
import SubscriberResetPassword from 'pages/subscriber/ResetPassword';
import Home from 'pages/subscriber/Home';
import SubscriberMyPlan from 'pages/subscriber/MyPlan';
import SubscriberPersonalData from 'pages/subscriber/PersonalData';
import SubscriberPaymentData from 'pages/subscriber/PaymentData';
import SubscriberDependants from 'pages/subscriber/Dependants';
import SubscriberNotifications from 'pages/subscriber/Notifications';
import SubscriberNotification from 'pages/subscriber/Notification';
import SubscriberNewDependant from 'pages/subscriber/NewDependant';
import SubscriberInvoices from 'pages/subscriber/Invoices';
import SubscriberVouchers from 'pages/subscriber/Vouchers';
import SubscriberDependant from 'pages/subscriber/Dependant';
import SubscriberTelemedicine from 'pages/subscriber/Telemedicine'
import SubscriberPartners from 'pages/subscriber/Partners';
import SubscriberTechnicalAssistance from 'pages/subscriber/TechnicalAssistance';
import SubscriberLifeInsurance from 'pages/subscriber/LifeInsurance';
import SubscriberMyHealth from 'pages/subscriber/MyHealth';

const Routes: React.FC = () => (
  <HashRouter>
    <Route exact path="/" component={ChooseAccess} />
    <Route exact path="/escolher-acesso" component={ChooseAccess} />

    {/* HR AREA */}
    <Route exact path="/rh/login" component={AuthHrAccount} />
    <Route exact path="/rh/esqueci-minha-senha" component={ForgotPassword} />
    <Route exact path="/rh/reset-password" component={ResetPassword} />
    <PrivateRouterHr exact path="/rh/escolher-empresa" component={ChoiceCompanies} />
    <PrivateRouterHr exact path="/rh/funcionarios" component={Employees} />
    <PrivateRouterHr exact path="/rh/importar-funcionarios" component={ImportEmployees} />
    <PrivateRouterHr exact path="/rh/resultado-importacao/:notification_id" component={ShowImport} />
    <PrivateRouterHr exact path="/rh/funcionarios/:id/dependentes" component={Dependants} />
    <PrivateRouterHr exact path="/rh/funcionarios/:id/dependentes/:dependantId" component={Dependant} />
    <PrivateRouterHr exact path="/rh/funcionarios/:id" component={Employee} />
    <PrivateRouterHr exact path="/rh/funcionario/novo" component={NewEmployee} />
    <PrivateRouterHr exact path="/rh/notificacoes" component={Notifications} />
    <PrivateRouterHr exact path="/rh/notificacoes/:notification_id" component={Notification} />
    <PrivateRouterHr exact path="/rh/faturas" component={CompanyBills} />
    <PrivateRouterHr exact path="/rh/faturas/:id" component={CompanyBill} />
    <PrivateRouterHr exact path="/rh/perfil-da-empresa" component={CompanyProfile} />
    <PrivateRouterHr exact path="/rh/dados-pessoais" component={PersonalData} />
    <PrivateRouterHr exact path="/rh/alterar-senha" component={ChangePassword} />
    <PrivateRouterHr exact path="/rh/servicos/seguros" component={AdditionalService} />
    <PrivateRouterHr exact path="/rh/servicos/assistencias" component={AssistenceService} />
    <PrivateRouterHr exact path="/rh/faqs" component={Faqs} />

    {/* SUBSCRIPTION AREA */}
    <Route exact path="/assinantes" component={AuthSubscriber} />
    <Route exact path="/assinantes/login" component={AuthSubscriber} />
    <Route exact path="/assinantes/esqueci-minha-senha" component={SubscriberForgotPassword} />
    <Route exact path="/assinantes/alterar-senha" component={SubscriberResetPassword} />
    <Route exact path="/assinantes/primeiro-acesso" component={SubscriberFirstAccess} />
    <PrivateRouterSubscriber exact path="/assinantes/home" component={Home} />
    <PrivateRouterSubscriber exact path="/assinantes/meu-plano" component={SubscriberMyPlan} />
    <PrivateRouterSubscriber exact path="/assinantes/dados-pessoais" component={SubscriberPersonalData} />
    <PrivateRouterSubscriber exact path="/assinantes/dados-de-pagamento" component={SubscriberPaymentData} />
    <PrivateRouterSubscriber exact path="/assinantes/notificacoes" component={SubscriberNotifications} />
    <PrivateRouterSubscriber exact path="/assinantes/notificacoes/:notification_id" component={SubscriberNotification} />
    <PrivateRouterSubscriber exact path="/assinantes/dependente/novo" component={SubscriberNewDependant} />
    <PrivateRouterSubscriber exact path="/assinantes/dependentes" component={SubscriberDependants} />
    <PrivateRouterSubscriber exact path="/assinantes/dependentes/:id" component={SubscriberDependant} />
    <PrivateRouterSubscriber exact path="/assinantes/notas-fiscais" component={SubscriberInvoices} />
    <PrivateRouterSubscriber exact path="/assinantes/vouchers" component={SubscriberVouchers} />
    <PrivateRouterSubscriber exact path="/assinantes/telemedicina" component={SubscriberTelemedicine} />
    <PrivateRouterSubscriber exact path="/assinantes/rede-de-parceiros" component={SubscriberPartners} />
    <PrivateRouterSubscriber exact path="/assinantes/assistencia" component={SubscriberTechnicalAssistance} />
    <PrivateRouterSubscriber exact path="/assinantes/seguro-de-vida" component={SubscriberLifeInsurance} />
    <PrivateRouterSubscriber exact path="/assinantes/minha-saude" component={SubscriberMyHealth} />
  </HashRouter>
);

export default Routes;
