import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BackLink = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.accentColor};
  margin-top: 1rem;
  text-align: end;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;
