import styled, { css } from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

interface IInfoDate {
  withPoint: boolean;
}

interface ITitle {
  status: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(${SIZES.smallerThanTablet}) {
    h1 {
      text-align: center;
    }
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    color: ${COLORS.offblack};
  }
`;

export const ContainerNotifications = styled.div`
  width: 100%;
  max-width: 49rem;

  > p {
    text-align: center;
  }
`;

export const Notification = styled.div`
  display: flex;
  width: 100%;
  -webkit-align-items: initial;
`;

export const Description = styled.div`
  padding: 1rem 1rem 1rem 0rem;
  min-width: 20rem;

  p {
    color: ${COLORS.offblack};
    font-size: 1rem;

    &.date {
      display: flex;
      justify-content: flex-end;
      color: ${COLORS.mediumGrey};
      font-size: 0.85rem;
      margin-top: 0.5rem;
    }
  }

  p + p {
    color: ${COLORS.mediumGrey};
    text-align: end;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`;

export const TitleNotification = styled.div<ITitle>`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }

  a {
    color: ${COLORS.accentColor};
    min-width: 15rem;
    max-width: 15rem;
    padding-right: 1rem;
    font-size: 1rem;
    ${(props) => props.status === 'success' && css`color: ${COLORS.success};`}
    ${(props) => props.status === 'warning' && css`color: ${COLORS.mediumOrange};`}
    ${(props) => props.status === 'error' && css`color: ${COLORS.red};`}
    ${(props) => ['processing', 'waiting'].includes(props.status) && css`color: ${COLORS.mediumGrey};`}
  }

  @media(${SIZES.smallerThanTablet}) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

export const ContentNotification = styled.div`
  display: flex;
  align-items: baseline;
  border-top: 1px solid ${COLORS.borderBase};
  margin-left: 1rem;

  @media(${SIZES.smallerThanTablet}) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;


export const ContainerLine = styled.div`
  min-width: 1.5rem;
`;

export const Point = styled.div`
  display: flex;
  justify-content: center;

  div {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: ${COLORS.borderBase};
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  div {
    width: 0.0625rem;
    background-color: ${COLORS.borderBase};
  }
`;

export const InfoDate = styled.div<IInfoDate>`
  min-width: 10rem;
  padding-top: 0.3125rem;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 15px;
    color: ${COLORS.offblack};
    text-align: end;
    margin-right: 8px;
  }
`;
