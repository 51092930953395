import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Card,
  CardHeader,
  QuickAccessessSection,
  QuickAccessCard,
  VirtualCardSection,
  VirtualCard,
  BoxLogo,
  BoxInfos,
  Separator,
  StatusBox,
  ChipActive,
  ChipCanceled,
  Chip
} from './styles';

import blueExclamationIcon from 'assets/icons/blue-exclamation.svg'
import blueCloseIcon from 'assets/icons/blue-close.svg'
import logo from 'assets/icons/new-logo-alianca-blue-text.svg'
import logoPlus from 'assets/icons/logo-alianca-plus-white-text.svg'
import playIcon from 'assets/icons/telemedicine.svg'
import partnersIcon from 'assets/icons/partners.svg'
import additionalServiceIcon from 'assets/icons/additional-service.svg'
import voucherIcon from 'assets/icons/voucher.svg'
import whiteCheckIcon from 'assets/icons/white-check.svg'
import whiteCloseIcon from 'assets/icons/white-close.svg'

import SubscriberDashboard from 'components/SubscriberDashboard';
import RenewalModal from 'components/RenewalModal';
import Alert from 'components/Alert';

import api from 'service/subscriberApi';
import { formatDate } from 'utils';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';
import { getUser } from 'service/subscriber';

import { toast } from 'react-toastify';

interface IUser {
  id: string;
  name: string;
  cpf: string;
  planType: string;
  status: string;
  expirationDate: string;
  isPlus: boolean;
  legacyUser: string;
}

const Home: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const { checkAllAlerts, showUserAlert } = useItemsMenuSubscriber();
  const [currentUser, setCurrentUser] = useState<IUser>({} as IUser)
  const [expirationCard, setExpirationCard] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [openRenewalModal, setOpenRenewalModal] = useState(false)

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Home', path: `/assinantes/home` }
    ])
    loadData();
    checkAllAlerts();
  }, [openRenewalModal]);

  function loadData() {
    setLoading(true);
    setMessage('Carregando...');
    api.get(`/users/find-user`)
      .then((response) => {
        const { user } = response.data;
        const { subscription } = user;
        const showExpirationCard = (user.close_to_expiration && subscription.legacy_plan_type) ? true  : subscription.status === 'expired';

        setLoading(false);
        setMessage('');
        setCurrentUser(formatUser(user));
        setExpirationCard(showExpirationCard);
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function getChipByStatus(status: string) {
    switch (status) {
      case 'active':
        return (
          <ChipActive>
            <img src={whiteCheckIcon} alt="Ícone check branco" />
            <span>Ativo</span>
          </ChipActive>
        );
      case 'expired':
        return (
          <ChipCanceled>
            <img src={whiteCloseIcon} alt="Ícone para fechar branco" />
            <span>Expirado</span>
          </ChipCanceled>
        );
      case 'canceled':
        return (
          <ChipCanceled>
            <img src={whiteCloseIcon} alt="Ícone para fechar branco" />
            <span>Cancelado</span>
          </ChipCanceled>
        );
      case 'future':
        return (
          <ChipActive>
            <img src={whiteCheckIcon} alt="Ícone check branco" />
            <span>Em renovação</span>
          </ChipActive>
        );
      default:
        return (
          <Chip>
            <img src={whiteCloseIcon} alt="Ícone para fechar branco" />
            <span>Pendente</span>
          </Chip>
        );
    }
  }

  function formatUser(user: any): IUser {
    return {
      id: user.id,
      name: user.name,
      cpf: user.cpf,
      planType: user.plan_type,
      status: user.subscription?.status,
      expirationDate: user.subscription?.expiration_timestamp,
      legacyUser: user.subscription?.legacy_plan_type,
      isPlus: user.plus
    }
  }

  function containerAlert() {
    if (!showUserAlert) return;

    let title = '';

    if (getUser().isPlus)
      title = 'Atualize suas informações pessoais e endereço para ganhar um voucher de desconto';
    else
      title = 'É importante manter atualizadas suas informações pessoais e de endereço';

    return (
      <Alert title="Atualização cadastral" type="info">
        <p>{title}</p>
        <Link to="/assinantes/dados-pessoais?step=personal-data">Para atualizar, clique aqui</Link>
      </Alert>
    )
  }

  function renewalReminder() {
    if (!expirationCard) return;

    return (
      <Card>
        <CardHeader>
          <div>
            <img src={blueExclamationIcon} alt="Ícone azul de exclamação" />
            {currentUser?.status === 'expired' ? <span>Seu plano venceu</span> : <span>Seu plano está para vencer</span>}
          </div>
          <img src={blueCloseIcon} alt="Ícone azul para fechar" onClick={() => { setExpirationCard(!expirationCard) }} />
        </CardHeader>
        <span>
          Olá, {currentUser?.name}, seu plano {currentUser?.status === 'expired' ? 'venceu' : 'vence'} no dia {formatDate(currentUser?.expirationDate)},
          para renovar e não perder as vantagens do Cartão Aliança, <a onClick={() => setOpenRenewalModal(true)}>clique aqui</a>.
        </span>
      </Card>
    )
  }

  return (
    <>
      <SubscriberDashboard title="Home">
        <Container>
          {!loading && !message &&
            <>
              <div>
                {renewalReminder()}
                {containerAlert()}

                <br />
                <span>Acesso rápido</span>

                <QuickAccessessSection>
                  <QuickAccessCard onClick={() => history.push('/assinantes/telemedicina')}>
                    <img src={playIcon} alt="Ícone de telemedicina" />
                    <span>Consultas</span>
                  </QuickAccessCard>
                  <QuickAccessCard onClick={() => history.push('/assinantes/rede-de-parceiros')}>
                    <img src={partnersIcon} alt="Ícone de parcerias" />
                    <span>Parcerias</span>
                  </QuickAccessCard>
                  <QuickAccessCard onClick={() => history.push('/assinantes/vouchers')} >
                    <img src={voucherIcon} alt="Ícone de vouchers" />
                    <span>Vouchers</span>
                  </QuickAccessCard>
                  {currentUser.isPlus && <QuickAccessCard onClick={() => history.push('/assinantes/seguro-de-vida')}>
                    <img src={additionalServiceIcon} alt="Ícone de serviços adicionais" />
                    <span>Serviços Adicionais</span>
                  </QuickAccessCard>}
                </QuickAccessessSection>
              </div >
              <div>
                <VirtualCardSection>
                  <VirtualCard isPlus={currentUser.isPlus}>
                    <BoxLogo>
                      <img src={currentUser.isPlus ? logoPlus : logo} alt="Logo Cartão Aliança." />
                    </BoxLogo>

                    <BoxInfos>
                      <div className="content">
                        <p className="name">{currentUser?.name}</p>
                        <p>{currentUser?.cpf}</p>
                        <p>ALLIAR MÉDICOS À FRENTE</p>
                        <div className="box-info-card">
                          {currentUser?.expirationDate &&
                            <>
                              <div>
                                <p>Validade</p>
                                <p className="value">{formatDate(currentUser?.expirationDate)}</p>
                              </div>
                              <Separator />
                            </>}
                          <div>
                            <p>Plano</p>
                            <p className="value">{currentUser?.planType}</p>
                          </div>
                          <Separator />
                          <div>
                            <p>Número do cartão</p>
                            <p className="value">{currentUser?.cpf?.replace(/[^0-9]+/g, "")}</p>
                          </div>
                        </div>
                      </div>
                    </BoxInfos>
                  </VirtualCard>

                  <br />
                  <StatusBox>
                    <span>Status</span>
                    {getChipByStatus(currentUser?.status)}
                  </StatusBox>
                </VirtualCardSection>
              </div>
            </>}

          {message && <span className="m-auto">{message}</span>}
        </Container>
      </SubscriberDashboard>
      {openRenewalModal && <RenewalModal closeModal={setOpenRenewalModal} />}
    </>
  );
}

export default Home;
