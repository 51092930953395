export interface IHrAccount {
  name: string;
  email: string;
  imageUrl: string;
}

export function isAuthenticated(): boolean {
  return !!localStorage.getItem('@hr_account_token');
}

export function saveUser(user: IHrAccount): IHrAccount {
  localStorage.setItem('@hr_account_user', JSON.stringify(user));

  return user;
}

export function getUser(): IHrAccount {
  const user: IHrAccount = JSON.parse(localStorage.getItem('@hr_account_user') || "{}");

  return user;
}

export function saveToken(token: string): void {
  localStorage.setItem('@hr_account_token', token);
}

export function getToken(): string | null {
  return localStorage.getItem('@hr_account_token');
}

export function logout(): void {
  localStorage.removeItem('@hr_account_user');
  localStorage.removeItem('@hr_account_token');
  localStorage.removeItem('@hr_account_company');
}
