import axios from 'axios';
import Base64 from 'base-64';

const gatewayApi = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_PUBLIC_API
});

gatewayApi.interceptors.request.use(async (config: any) => {
  config.headers = {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${Base64.encode(`${process.env.REACT_APP_GATEWAY_PUBLIC_KEY}:`)}`
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default gatewayApi;

