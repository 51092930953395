import React from 'react';
import { useHistory } from 'react-router-dom';

import { ISideMenu } from '..';
import { IMenuItem } from 'components/SubscriberDashboard';

import logo from 'assets/logo-alianca-write.svg';
import arrowRightDisabled from 'assets/arrow-right-disabled.svg';
import arrowRightEnabled from 'assets/arrow-right-enabled.svg';
import arrowCloseMenu from 'assets/arrow-close-menu.svg';
import exclamation from 'assets/icons/exclamation.svg';

import {
  LargeMenuBox,
  ContainerLogo,
  CloseMenu,
  MenuItemHelp,
  MenuItemBox,
} from './styles';

interface ILargeMenu extends ISideMenu {
  toggleMenu?: Function;
}

const LargeMenu: React.FC<ILargeMenu> = ({ helpItem, menuItems, toggleMenu }) => {
  const history = useHistory();

  return (
    <LargeMenuBox>
      <ContainerLogo>
        <img src={logo} alt="Cartão Aliança" />
      </ContainerLogo>

      <hr />

      {menuItems?.map((menuItem: IMenuItem) => (
        <MenuItemBox
          key={menuItem.id}
          isActive={menuItem.isActive}
          title={menuItem.name}
          onClick={() => history.push(menuItem.path)}
        >
          <div className="content">
            <img src={menuItem.logo} alt="Funcionários" />
            <p>{menuItem.name}</p>
          </div>
          <div className="arrow">
            {menuItem.showAlert && <img src={exclamation} alt="Atenção" className="exclamation" title="Atualize seu cadastro" />}
            {menuItem.isActive && (
              <img src={arrowRightEnabled} alt="Seta para direita" />
            )}
            {!menuItem.isActive && (
              <img src={arrowRightDisabled} alt="Seta para direita" />
            )}
          </div>
        </MenuItemBox>
      ))}

      <MenuItemHelp
        isActive={helpItem.isActive}
        title={helpItem.name}
        onClick={() => history.push(helpItem.path)}
      >
        <div>
          <img src={helpItem.logo} alt={helpItem.name} />
          <p>{helpItem.name}</p>
        </div>
      </MenuItemHelp>

      <CloseMenu
        src={arrowCloseMenu}
        alt="Fechar menu"
        onClick={() => toggleMenu && toggleMenu()}
      />
    </LargeMenuBox>
  )
}

export default LargeMenu;
