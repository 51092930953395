
import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface IMenuItem {
  isActive: boolean;
}

export const LargeMenuBox = styled.nav`
  min-width: 16.875rem;
  height: 100vh;
  background-color: ${COLORS.darkBlue};

  hr {
    opacity: 0.1;
    border: 0.4px solid ${COLORS.white};
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 3.125rem;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  height: 6rem;

  img {
    width: 11rem;
  }
`;

export const CloseMenu = styled.img`
  position: absolute;
  bottom: 2.25rem;
  left: 14rem;
  width: 1rem;
  cursor: pointer;
`;

export const MenuItemHelp = styled.div<IMenuItem>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  padding: 1.625rem;
  cursor: pointer;
  position: absolute;
  bottom: 5rem;
  width: 16.875rem;
  cursor: pointer;
  ${props => props.isActive && css`background: rgba(255, 255, 255, 0.1);` }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  div {
    display: flex;
    align-items: center;
    color: #FFFFFF;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 21px;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.75rem;
    }
  }
`;

export const MenuItemBox = styled.div<IMenuItem>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  padding: 1.625rem;
  cursor: pointer;
  bottom: 5rem;
  width: 16.875rem;
  ${props => props.isActive && css`background: rgba(255, 255, 255, 0.1);` }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .content {
    display: flex;
    align-items: center;
    color: ${COLORS.white};

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 21px;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.75rem;
    }
  }

  .arrow {
    display: flex;
    align-items: center;

    .exclamation {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`;
