import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;

  > img {
    align-self: flex-end;
    width: 1.4rem;
    cursor: pointer;
  }

  > p {
    margin-top: 1.5rem;
    color: ${COLORS.defaultText};
  }
  
  .accent-color {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: ${COLORS.accentColor};
  }
  
  .header-content {
    text-align: center;

    h3, img {
      margin-top: 1.5rem;
    }

    img {
      width: 8rem;
    }
  }

  .icon-and-description {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-left: 0.3rem;
    }
  }
`;