import React, { useState, useEffect } from 'react';

import BranchCardItem from '../BranchCardItem';

import { BranchesArea, BranchesCardsSection } from './styles';

import api from 'service/subscriberApi';

import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner'

interface IWithSearch {
  search: string;
  locationSearch: string;
  classificationSearch: string;
  partnerId: string;
  nearbyOnly: boolean;
  favoriteOnly: boolean;
}

interface IPartnerBranchItem {
  id: string;
  name: string;
  logoImageUrl: string;
  phone: string;
  partnerName: string;
  street: string;
  streetNumber: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  fullStateName: string;
  zipCode: string;
  subCategories: [];
  usersIds: [];
}

const WithSearch: React.FC<IWithSearch> = ({
  search,
  locationSearch,
  classificationSearch,
  partnerId,
  nearbyOnly,
  favoriteOnly
}) => {
  const [loading, setLoading] = useState(true);
  const [partnerBranches, setPartnerBranches] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFilteredCount, setTotalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(12);

  useEffect(() => {
    const timeOutId = setTimeout(() => getPartners(), 500);
    return () => clearTimeout(timeOutId);
  }, [search, locationSearch, classificationSearch, partnerId, currentPage]);

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }

  function getPartners() {
    setLoading(true)
    const params = {
      search: search,
      location_search: locationSearch,
      classification_search: classificationSearch,
      partner_id: partnerId,
      page: currentPage,
      nearby_only: nearbyOnly,
      favorite_only: favoriteOnly
    };

    api
      .get('/users/partner_branches', { params: params })
      .then(response => {
        const { total_count, total_filtered_count, total_pages, per_page } =
          response.data;

        setTotalCount(total_count);
        setTotalFilteredCount(total_filtered_count);
        setTotalPages(total_pages);
        setPerPage(per_page);
        setPartnerBranches(
          response.data.partner_branches.map((branch: any) => {
            return {
              id: branch.id,
              name: branch.name,
              logoImageUrl: branch.logo_image_url,
              phone: branch.phone,
              partnerName: branch.partner.name,
              street: branch.address.street,
              streetNumber: branch.address.number,
              complement: branch.address.complement,
              district: branch.address.district,
              city: branch.address.city,
              state: branch.address.state,
              fullStateName: branch.address.full_state_name,
              zipCode: branch.address.zip_code,
              subCategories: branch.sub_categories,
              usersIds: branch.users_ids
            };
          }),
        );
        setLoading(false);
      })
      .catch(_error => {
        setLoading(false);
      });
  }

  function renderPartners() {
    return partnerBranches.map((branch: IPartnerBranchItem) => {
      return <BranchCardItem branch={branch} />;
    });
  }

  return (
    <BranchesArea>
      {loading && <Spinner size="medium"/>}
      {!loading && partnerBranches.length > 0 && (
        <>
          <BranchesCardsSection>{renderPartners()}</BranchesCardsSection>
          <Pagination
            currentPage={currentPage}
            numOfPages={totalPages}
            totalFilteredCount={totalFilteredCount}
            totalCount={totalCount}
            lengthPage={partnerBranches.length}
            perPage={perPage}
            onSelectPage={handleSelectPage}
          />
        </>
      )}
      {!loading && partnerBranches.length == 0 && <div>Nenhum parceiro encontrado!</div>}
    </BranchesArea>
  );
};

export default WithSearch;
