import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'service/hrAccountApi';
import { saveUser } from 'service/hrAccount';

import { getNameInitials } from 'utils';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/HrAccountDashboard';
import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';
import SuccessModal from 'components/SuccessModal';
import BtnLink from 'components/BtnLink';

import pencilActive from 'assets/icons/pencil-active.svg';
import checked from 'assets/checked-active.svg';
import purpleTrash from 'assets/purple-trash.svg';

import {
  Container,
  CirclePhoto,
  Form,
  TextSelectFile,
  ContainerButton
} from './styles';

interface IValue {
  value: string;
  errorMessage: string;
}

const PersonalData: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const [name, setName] = useState<IValue>({ value: '', errorMessage: '' });
  const [email, setEmail] = useState<IValue>({ value: '', errorMessage: '' });
  const [urlImage, setUrlImage] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState('');
  const [changingPhoto, setChangingPhoto] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    loadUser();
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Dados pessoais', path: `` },
    ]);
  }, []);

  function loadUser() {
    setMessage('');

    api.get('/hr-account')
      .then((response) => {
        const { hr_account: hrAccount } = response.data;

        setName({ value: hrAccount.name, errorMessage: '' });
        setEmail({ value: hrAccount.email, errorMessage: '' });
        setUrlImage(hrAccount.image_url);
      })
      .catch((_error) => {
        setMessage('Não foi possível carregar as informações.');
      });
  }

  async function handleForm(e: FormEvent) {
    e.preventDefault();
    setMessage('');

    try {
      const response = await api.put('/hr-account', { name: name.value, email: email.value });

      if (response.status === 200) {
        const { hr_account: { name, email, image_url } } = response.data;
        saveUser({ name, email, imageUrl: image_url });
        setShowSuccessModal(true);
      } else {
        setMessage('Não foi possível salvar a imagem.');
      }
    } catch (ex: any) {
      const { errors } = ex.response.data;
      if (!!errors.name) {
        setName({ ...name, errorMessage: errors.name[0] })
      }

      if (!!errors.email) {
        setEmail({ ...email, errorMessage: errors.email[0] })
      }
    }
  }

  async function saveImage() {
    try {
      setChangingPhoto(false);
      setMessage('');
      const formData = new FormData();
      formData.append("name", fileName);
      formData.append("file", file);
      const response = await api.put('/hr-account/photos', formData);
      if (response.status === 200) {
        const { hr_account: { name, email, image_url } } = response.data;
        setMessage('');
        saveUser({ name, email, imageUrl: image_url });
        loadUser();
      } else {
        setMessage('Não foi possível salvar a imagem.');
      }
    } catch (ex: any) {
      setMessage(ex.response.data.error);
    }
  }

  function changePhoto() {
    document.getElementById('input-file')?.click();
  }

  function handleFileInput(e: any) {
    const [file] = e.target.files;
    if (file) {
      setChangingPhoto(true);
      setFileName(file.name);
      setFile(file);
      var reader = new FileReader();

      reader.onload = function (e: any) {
        setImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }

  function getImage() {
    if (changingPhoto) {
      return <img src={image} alt="Imagem do usuário" />
    } else if (urlImage) {
      return <img src={urlImage} alt="Imagem do usuário" />
    } else {
      return getNameInitials(name.value);
    }
  }

  function removeSelectPhoto() {
    setChangingPhoto(false);
    setFile(null);
    setFileName('');
    setImage('');
    const fileInput: any = document.getElementById('input-file');
    fileInput.value = '';
  }

  return (
    <>
      <Dashboard title="Dados pessoais" backPath="/rh/funcionarios">
        <Container>
          <Form onSubmit={handleForm}>
            <CirclePhoto>
              {getImage()}
            </CirclePhoto>
            <TextSelectFile>
              {!changingPhoto &&
                <BtnLink onClick={changePhoto}>
                  <img src={pencilActive} alt="Editar" />
                  {urlImage ? 'Trocar foto' : 'Adicionar foto'}
                </BtnLink>}

              {changingPhoto &&
                <p onClick={removeSelectPhoto}>
                  <img src={purpleTrash} alt="Remover imagem" />
                  Remover
                </p>}

              {changingPhoto &&
                <p onClick={saveImage}>
                  <img src={checked} alt="Editar" />
                  Salvar
                </p>}
            </TextSelectFile>
            <input type="file" name="file" id="input-file" onChange={handleFileInput} />

            <h3>Dados de acesso</h3>

            <TextInput
              type="text"
              placeholder="Nome"
              value={name.value}
              maxLength={255}
              onChange={(value: string) => setName({ value, errorMessage: '' })} />
            <p className="error">{name.errorMessage}</p>

            <TextInput
              type="text"
              placeholder="E-mail"
              value={email.value}
              maxLength={255}
              onChange={(value: string) => setEmail({ value, errorMessage: '' })} />
            <p className="error">{email.errorMessage}</p>

            <div className="forgot-password">
              <p>{message}</p>
              <BtnLink onClick={() => history.push('/rh/alterar-senha')}>Trocar senha</BtnLink>
            </div>

            <ContainerButton>
              <BtnLink onClick={() => history.goBack()}>Cancelar</BtnLink>
              <PrimaryButton
                type="submit"
                width="10rem">
                Salvar alteração
              </PrimaryButton>
            </ContainerButton>
          </Form>
        </Container>
      </Dashboard>
      {
        showSuccessModal &&
        <SuccessModal
          message="Perfil de acesso alterado com sucesso"
          buttonMessage="OK"
          onClose={() => { setShowSuccessModal(false) }} />
      }
    </>
  )
};

export default PersonalData;
