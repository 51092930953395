import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import SubscriberDashboard from 'components/SubscriberDashboard';
import Alert from 'components/Alert';

import FormPersonalData from './FormPersonalData';
import FormAddress from './FormAddress';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

import { Container, ContainerMenu, ContainerForm } from './styles';
import { getUser } from 'service/subscriber';

const Dependant: React.FC = (props: any) => {
  const dependantId = props.match.params.id;
  const step: string =
    new URLSearchParams(props.location.search).get('step') || 'personal-data';
  const history = useHistory();
  const { showDependantsAlert } = useItemsMenuSubscriber();
  const [dependantName, setDependantName] = useState('');

  function chooseForm() {
    if (step === 'address') {
      return <FormAddress dependantId={dependantId} />;
    } else {
      return (
        <FormPersonalData
          dependantId={dependantId}
          callbackName={(value: string) => setDependantName(value)}
        />
      );
    }
  }

  function alertContainer() {
    if (!showDependantsAlert) return;

    let title = '';

    if (getUser().isPlus)
      title = 'Atualize suas informações pessoais e endereço para ganhar um voucher de desconto';
    else
      title = 'É importante manter atualizadas suas informações pessoais e de endereço';

    return (
      <Container>
        <Alert className="alert" title={title} type="info"></Alert>
      </Container>
    );
  }

  return (
    <SubscriberDashboard
      title={`Dependente ${dependantName}`}
      backPath="/assinantes/dependentes"
    >
      {alertContainer()}
      <Container>
        <ContainerMenu>
          <ul>
            <li
              className={step === 'personal-data' ? 'active' : ''}
              onClick={() =>
                history.push(
                  `/assinantes/dependentes/${dependantId}?step=personal-data`,
                )
              }
            >
              Informações pessoais
            </li>
            <li
              className={step === 'address' ? 'active' : ''}
              onClick={() =>
                history.push(
                  `/assinantes/dependentes/${dependantId}?step=address`,
                )
              }
            >
              Endereço
            </li>
          </ul>
        </ContainerMenu>
        <ContainerForm>{chooseForm()}</ContainerForm>
      </Container>
    </SubscriberDashboard>
  );
};

export default Dependant;
