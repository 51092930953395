import React, { useState, FormEvent, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import luhn from 'luhn';

import { toast } from 'react-toastify';

import api from 'service/subscriberApi';

import TextInput from 'components/TextInput';

import amex from 'assets/icons/checkout/amex.svg';
import elo from 'assets/icons/checkout/elo.svg';
import hipercard from 'assets/icons/checkout/hipercard.svg';
import mastercard from 'assets/icons/checkout/mastercard.svg';
import visa from 'assets/icons/checkout/visa.svg';

import { Form, BoxLogo, FormGroup, ActionsBox } from './styles';
import PrimaryButton from 'components/PrimaryButton';
import { getCardFlag } from 'utils';

interface IUpdatePaymentMethodForm {
  closeForm: Function;
}

interface IError {
  key: string;
  message: string;
}

const UpdatePaymentMethodForm: React.FC<IUpdatePaymentMethodForm> = ({ closeForm }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [holderName, setHolderName] = useState('');
  const [loadind, setLoading] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [message, setMessage] = useState('');
  const [creditCardFlag, setCreditCardFlag] = useState('');

  useEffect(() => {
    if (!!cardNumber) {
      setCreditCardFlag(getCardFlag(cardNumber));
    }
  }, [cardNumber]);

  async function handleForm(event: FormEvent) {
    try {
      event.preventDefault();
      setMessage('');
      setErrors([]);

      const newErrors = isFormValid();
      if (newErrors.length) {
        setErrors(newErrors);
        return;
      }

      const params = {
        holder_name: holderName,
        card_expiration: cardExpiration,
        card_number: cardNumber.replace(/ /g, ''),
        card_cvv: cardCvv,
        payment_method_code: "credit_card",
        payment_company_code: creditCardFlag,
      }

      const { REACT_APP_PUBLIC_KEY_GATEWAY, REACT_APP_PUBLIC_API_GATEWAY } = process.env;

      const config: AxiosRequestConfig = {
        auth: {
          username: REACT_APP_PUBLIC_KEY_GATEWAY || '',
          password: ''
        }
      }

      setLoading(true);
      const url = REACT_APP_PUBLIC_API_GATEWAY || '';
      const gatewayResponse = await axios.post(url, params, config);
      const { gateway_token: gatewayToken } = gatewayResponse.data.payment_profile;

      if (!gatewayToken) {
        throw new Error('Desculpe, estamos tendo problemas com nosso gateway de pagamento. Por favor, tente novamente mais tarde.');
      }

      const response = await api.put('/users/payment-methods', { gateway_token: gatewayToken });
      setLoading(false);

      const { message } = response.data;
      toast.success(message);
      closeForm();
    } catch (ex: any) {
      setLoading(false);
      if (ex.response) {
        const { error, errors } = ex.response.data;
        const newErrors: IError[] = (errors || []).map((error: any) => ({ key: error.key, message: error.value }));
        setErrors(newErrors);
        setMessage(error);
      } else if (ex.message) {
        toast.error(ex.message);
      }
    }
  }

  function isFormValid(): IError[] {
    const errors: IError[] = [];

    if (!holderName) {
      errors.push({ key: 'holder_name', message: 'Nome deve ser preenchido' });
    }

    if (!cardNumber) {
      errors.push({ key: 'card_number', message: 'Número do cartão deve ser preenchido' });
    }

    if (!luhn.validate(cardNumber)) {
      errors.push({ key: 'card_number', message: 'Cartão inválido' });
    }

    if (!cardExpiration) {
      errors.push({ key: 'card_expiration', message: 'Data de expiração deve ser preenchida' });
    }

    if (!cardCvv) {
      errors.push({ key: 'card_cvv', message: 'CVV deve ser preenchida' });
    }

    return errors;
  }

  function addError(msg: string, index: any) {
    return (
      <React.Fragment key={index}>
        <p>{msg}</p>
      </React.Fragment>
    )
  }

  function getBoxLogo() {
    return (
      <BoxLogo>
        <img
          src={amex}
          alt="Amex"
          className={creditCardFlag === 'american_express' ? 'active' : ''} />
        <img
          src={elo}
          alt="Elo"
          className={creditCardFlag === 'elo' ? 'active' : ''} />
        <img
          src={hipercard}
          alt="Hipercard"
          className={creditCardFlag === 'hipercard' ? 'active' : ''} />
        <img
          src={mastercard}
          alt="Mastercard"
          className={creditCardFlag === 'mastercard' ? 'active' : ''} />
        <img
          src={visa}
          alt="Visa"
          className={creditCardFlag === 'visa' ? 'active' : ''} />
      </BoxLogo>
    )
  }

  return (
    <Form onSubmit={handleForm}>
      {getBoxLogo()}
      <TextInput
        onChange={(value: any) => setCardNumber(value)}
        value={cardNumber}
        type="text"
        placeholder="Número do cartão"
        typeMask="credit_card_number"
        maxLength={19} />
      <div className="box-errors">
        {
          errors
            .filter((error) => error.key === 'card_number')
            .map((error, index) => addError(error.message, index))
        }
      </div>

      <FormGroup>
        <div>
          <TextInput
            onChange={(value: any) => setCardCvv(value)}
            value={cardCvv}
            type="text"
            placeholder="CVV"
            maxLength={4}
            typeMask="only_numbers" />
          <div className="box-errors">
            {
              errors
                .filter((error) => error.key === 'card_cvv')
                .map((error, index) => addError(error.message, index))
            }
          </div>
        </div>

        <div>
          <TextInput
            onChange={(value: any) => setCardExpiration(value)}
            value={cardExpiration}
            type="text"
            placeholder="Validade (mm/aaaa)"
            typeMask="mmyyyy"
            maxLength={7} />
          <div className="box-errors">
            {
              errors
                .filter((error) => error.key === 'card_expiration')
                .map((error, index) => addError(error.message, index))
            }
          </div>
        </div>
      </FormGroup>

      <TextInput
        onChange={(value: any) => setHolderName(value)}
        value={holderName}
        type="text"
        placeholder="Nome do titular"
        maxLength={255} />
      <div className="box-errors">
        {
          errors
            .filter((error) => error.key === 'holder_name')
            .map((error, index) => addError(error.message, index))
        }
      </div>

      {
        message &&
        <div className="box-errors">
          <br />
          <p>{message}</p>
        </div>
      }
      <ActionsBox>
        <p onClick={() => closeForm()}>Cancelar</p>
        <PrimaryButton type="submit" loading={loadind}>
          Salvar alteração
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
};

export default UpdatePaymentMethodForm;
