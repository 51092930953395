import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import step1Activate from 'assets/icons/step-1-activate.svg';
import step2Activate from 'assets/icons/step-2-activate.svg';
import step2Inactivate from 'assets/icons/step-2-inactivate.svg';
import SubscriberDashboard from 'components/SubscriberDashboard';
import FormAddress from './FormAddress';
import FormPersonalData from './FormPersonalData';

import { Container, ContainerMenu, ContainerForm } from './styles';

const NewDependant: React.FC = (props: any) => {
  const history = useHistory();
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const step: string = new URLSearchParams(props.location.search).get("step") || '';
  const userId: string = new URLSearchParams(props.location.search).get("user_id") || '';

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Dependentes', path: `/assinantes/dependentes` },
      { title: 'Novo', path: `/assinantes/dependente/novo?step=personal-data` }
    ]);
  }, []);

  function chooseStepImage() {
    if (step === 'address') {
      return <img src={step2Activate} alt="Step 2" />
    } else {
      return <img src={step2Inactivate} alt="Step 2" />
    }
  }

  function chooseForm() {
    if (step === 'address') {
      return <FormAddress userId={userId} />
    } else {
      return <FormPersonalData userId={userId} />
    }
  }

  function selectPersonalDataStep() {
    let url = '/assinantes/dependente/novo?step=personal-data';
    if (!!userId) {
      url += `&user_id=${userId}`;
    }
    history.push(url);
  }

  function selectAddressStep() {
    if (!userId) return;
    history.push(`/assinantes/dependente/novo?step=address&user_id=${userId}`);
  }

  return (
    <SubscriberDashboard title="Novo dependente" backPath="/assinantes/dependentes">
      <Container>
        <ContainerMenu>
          <ul>
            <li
              className={step === 'personal-data' ? 'active' : ''}
              onClick={selectPersonalDataStep}>
              <img src={step1Activate} alt="Step 1" />
              Informações pessoais
            </li>
            <li
              className={step === 'address' ? 'active' : ''}
              onClick={selectAddressStep}>
              {chooseStepImage()}
              Endereço
            </li>
          </ul>
        </ContainerMenu>
        <ContainerForm>
          {chooseForm()}
        </ContainerForm>
      </Container>
    </SubscriberDashboard>
  )
}

export default NewDependant;
