import styled from 'styled-components';

import { COLORS } from 'styles/constants';

export const ContainerBreadcrumb = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  color: ${COLORS.defaultBg};
  font-style: normal;
  font-weight: normal;
  font-size: 0.85rem;
  line-height: 24px;

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  img {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;
