import styled, { css } from 'styled-components';
import { SIZES } from 'styles/constants';
import { COLORS } from 'styles/constants';

export const ContainerBody = styled.section`
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
`;

export const ContainerLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50%;

  @media(${SIZES.smallerThanTablet}) {
    max-width: initial;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 28.125rem;
  padding: 1rem;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 24px;
    color: ${COLORS.defaultSubtitle};
    margin-bottom: 2.5rem;
  }

  div + div  {
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
  }
`;

export const ChangeAccessText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.accentColor};
  margin-top: 1rem;
  text-align: end;
  cursor: pointer;
  margin-top: 30%;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const BackLink = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.accentColor};
  margin-top: 1rem;
  text-align: end;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;

interface IMessage {
  isError: boolean;
}

export const Message = styled.span<IMessage>`
  margin-top: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.success};
  ${(props) => props.isError && css`color: ${COLORS.red};`}
`;
