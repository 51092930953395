import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';

import errorIcon from 'assets/error-icon.svg';

interface IErrorModal {
  message: string;
  buttonMessage: string;
  onClose: Function;
}

const ErrorModal: React.FC<IErrorModal> = ({ message, onClose, buttonMessage }) => {
  return (
    <GenericModal height="11rem">
      <BodyModal>
        <img src={errorIcon} alt="Erro" />
        <strong>{message}</strong>
        <p onClick={() => onClose()}>{buttonMessage}</p>
      </BodyModal>
    </GenericModal>
  );
};

export default ErrorModal;
