import React, { useState, useEffect } from 'react';

import api from 'service/subscriberApi';

import SubscriberDashboard from 'components/SubscriberDashboard';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import EmptyArea from './EmptyArea';
import ListArea from './ListArea';

import { Loading } from './styles';

export interface IDependant {
  id: number;
  name: string;
  cpf: string;
  rg: string;
  active: string;
}

const Dependants: React.FC = (props: any) => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [message, setMessage] = useState('');
  const [dependants, setDependants] = useState<IDependant[]>([]);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Dependentes', path: `/assinantes/dependentes` }
    ]);
    listDependants();
  }, []);

  function listDependants() {
    setResponseError(false);
    setLoading(true);
    setMessage('');
    api.get('/users/dependants')
      .then((response) => {
        setLoading(false);
        const { dependants } = response.data;
        setDependants((dependants || []).map(formatDependant));
      })
      .catch((_error) => {
        setLoading(false);
        setResponseError(true);
        setMessage('Não foi possível carregar os dependentes, por favor tente novamente!');
      });
  }

  function formatDependant(dep: any): IDependant {
    return {
      id: dep.id,
      cpf: dep.cpf,
      name: dep.name,
      rg: dep.rg,
      active: dep.active,
    };
  }

  function chooseBody() {
    if (responseError) {
      return <Loading>{message}</Loading>
    } else if (loading) {
      return <Loading>Carregando...</Loading>
    } else if (!!dependants.length) {
      return <ListArea dependants={dependants} listDependants={listDependants} />
    } else {
      return <EmptyArea />
    }
  }

  return (
    <SubscriberDashboard title="Meus dependentes">
      {chooseBody()}
    </SubscriberDashboard>
  )
}

export default Dependants;
