import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.8rem;
  position: static;
  width: border-box;
  height: 4rem;
  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-top: 1rem;

  button {
    margin-right: 1rem;
    background-color: white;
    border: none;
  }

  .assistance-icon-description {
    display: flex;
    flex-direction: row;
    
    img {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
    strong {
      color: ${COLORS.accentColor};
    }
  }

  .access-site {
    margin-right: 1rem;
    a {
      color: ${COLORS.accentColor};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const IconAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  
  img {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
  strong {
    color: ${COLORS.accentColor};
  }
`;

export const TitleCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  padding: 2rem 0.5rem 1rem 0rem;

  strong {
    margin-left: 0.8rem;
    font-weight: 600;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardBody = styled.div`
  padding-top: 1rem;

  p {
    color: ${COLORS.mediumGrey};
  }

  span {
    color: ${COLORS.defaultText};
  }
`;