import styled from 'styled-components';
import { COLORS } from 'styles/constants'

export const Select = styled.select`
  width: 100%;
  border: 1px solid ${COLORS.borderBase};
  border-radius: 6px;
  height: 2.5rem;
  padding: 0 0.3rem;
  color: ${COLORS.darkGrey};
  color: ${(props) => props.value ? 'black' : COLORS.darkGrey};
  background-color: ${COLORS.white};
`;
