import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import rightArrow from 'assets/icons/partners/right-arrow.svg';
import categoryHeart from 'assets/icons/partners/category-heart.svg';
import categoryEye from 'assets/icons/partners/category-eye.svg';
import categorySmile from 'assets/icons/partners/category-smile.svg';
import categoryPharmacy from 'assets/icons/partners/category-pharmacy.svg';
import categoryBook from 'assets/icons/partners/category-book.svg';

import api from 'service/subscriberApi';
import { addDefaultLogoSrc, verifyIfHasImage } from '../../../../utils';

import Spinner from 'components/Spinner';

import {
  ClassificationsArea,
  ClassificationsCardsSection,
  ClassificationCard,
  PartnersArea,
  PartnersAreaTitle,
  PartnersCardsSection,
  PartnerCard,
} from './styles';

interface IWithoutSearch {
  onClassificationClick: Function;
  onPartnerClick: Function;
  onNearbyClick: Function;
  onFavoriteClick: Function;
}

interface IClassificationItem {
  id: string;
  title: string;
  subTitle: string;
  searchParam?: string;
  icon: string;
}

interface IPartnerItem {
  id: string;
  name: string;
  logoImageUrl: string;
}

const WithoutSearch: React.FC<IWithoutSearch> = ({
  onClassificationClick,
  onPartnerClick,
  onNearbyClick,
  onFavoriteClick
}) => {
  const [loading, setLoading] = useState(true);
  const [loadingNearbyPartners, setLoadingNearbyPartners] = useState(true);
  const [loadingFavoritePartners, setLoadingFavoritePartners] = useState(true);
  const [nearbyPartners, setNearbyPartners] = useState([]);
  const [favoritePartners, setFavoritePartners] = useState([]);

  const classifications = [
    {
      id: uuidv4(),
      title: 'Cuide de você',
      searchParam: 'Cuide de você',
      subTitle: 'Consultas e exames',
      icon: categoryHeart,
    },
    {
      id: uuidv4(),
      title: 'Veja o mundo melhor',
      searchParam: 'Veja o mundo melhor',
      subTitle: 'Oftalmo e óticas',
      icon: categoryEye,
    },
    {
      id: uuidv4(),
      title: 'Sorria sempre',
      searchParam: 'Sorria sempre',
      subTitle: 'Dentistas',
      icon: categorySmile,
    },
    {
      id: uuidv4(),
      title: 'Medicamentos',
      searchParam: 'Medicamentos',
      subTitle: 'Farmácias',
      icon: categoryPharmacy,
    },
    {
      id: uuidv4(),
      title: 'Um mundo de vantagens',
      searchParam: 'Todos',
      subTitle: 'Todos os parceiros',
      icon: categoryBook,
    },
  ];

  useEffect(() => {
    getNearbyPartners();
    getFavoritePartners();
  }, []);

  useEffect(() => {
    verifyLoadingState();
  }, [loadingNearbyPartners, loadingFavoritePartners]);

  function getFavoritePartners() {
    setLoadingFavoritePartners(true);
    api
      .get('/users/partners?favorite_only=true')
      .then(response => {
        const partners = response.data.partners.map((partner: any) => {
          return {
            id: partner.id,
            name: partner.name,
            logoImageUrl: partner.logo_image_url,
          };
        });
        setFavoritePartners(partners);
        setLoadingFavoritePartners(false);
      })
      .catch(_error => {
        setLoadingFavoritePartners(false);
      });
  }

  function getNearbyPartners() {
    setLoadingNearbyPartners(true);
    api
      .get('/users/partners?nearby_only=true')
      .then(response => {
        const partners = response.data.partners.map((partner: any) => {
          return {
            id: partner.id,
            name: partner.name,
            logoImageUrl: partner.logo_image_url,
          };
        });
        setNearbyPartners(partners);
        setLoadingNearbyPartners(false);
      })
      .catch(_error => {
        setLoadingNearbyPartners(false);
      });
  }

  function verifyLoadingState() {
    if (loadingNearbyPartners || loadingFavoritePartners) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }

  function renderClassificationList() {
    return classifications.map((classification: IClassificationItem) => {
      return (
        <ClassificationCard
          key={classification.id}
          onClick={() => onClassificationClick(classification.searchParam)}
        >
          <img src={classification.icon} />
          <p>{classification.title}</p>
          <p>{classification.subTitle}</p>
          <img src={rightArrow} />
        </ClassificationCard>
      );
    });
  }

  function renderPartners(partners: any) {
    return partners.map((partner: IPartnerItem) => {
      return (
        <PartnerCard
          key={partner.id}
          onClick={() => onPartnerClick(partner.id, partner.name)}
        >
          <img src={verifyIfHasImage(partner.logoImageUrl)} onError={addDefaultLogoSrc} />
          <p>{partner.name}</p>
        </PartnerCard>
      );
    });
  }

  return (
    <>
      {loading && <Spinner size="medium" />}
      {!loading && (
        <>
          <ClassificationsArea>
            <h2>Descontos por categoria</h2>
            <ClassificationsCardsSection>
              {renderClassificationList()}
            </ClassificationsCardsSection>
          </ClassificationsArea>

          <PartnersArea>
            {nearbyPartners.length > 0 && (
              <>
                <PartnersAreaTitle>
                  <h2>Parceiros perto de você</h2>
                  <a onClick={() => onNearbyClick()}>Ver todos</a>
                </PartnersAreaTitle>
                <PartnersCardsSection>
                  {renderPartners(nearbyPartners)}
                </PartnersCardsSection>
              </>
            )}

            {favoritePartners.length > 0 && (
              <>
                <PartnersAreaTitle>
                  <h2>Meus parceiros favoritos</h2>
                  <a onClick={() => onFavoriteClick()}>Ver todos</a>
                </PartnersAreaTitle>
                <PartnersCardsSection>
                  {renderPartners(favoritePartners)}
                </PartnersCardsSection>
              </>
            )}
          </PartnersArea>
        </>
      )}
    </>
  );
};

export default WithoutSearch;
