import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'service/hrAccountApi';
import { formatDate } from 'utils';
import { getCompanyId } from 'service/company';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import { Container, Form, StatusBox, Separator } from './styles';

import Dashboard from 'components/HrAccountDashboard';
import TextInput from 'components/TextInput';
import BtnLink from 'components/BtnLink';
import Badge from 'components/Badge';

import { toast } from 'react-toastify';
interface IEmployee {
  id: number;
  name: string;
}

const Dependant: React.FC = (props: any) => {
  const employeeId = props.match.params.id;
  const dependantId = props.match.params.dependantId;

  const [message, setMessage] = useState('');
  const [employee, setEmployee] = useState<IEmployee>()
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [status, setStatus] = useState<boolean>(false);
  
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const history = useHistory();

  useEffect(() => {
    loadDependant();
    loadEmployee();
  }, []);


  useEffect(() => {
    if (!employee) return

    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: employee.name, path: `/rh/funcionarios/${employeeId}` },
      { title: 'Dependentes', path: `/rh/funcionarios/${employeeId}/dependentes` },
      { title: name, path: `/rh/funcionarios/${employeeId}/dependentes/${dependantId}` }
    ])
  }, [employee, name])

  function loadEmployee() {
    api.get(`/hr-account/companies/${getCompanyId()}/users/${employeeId}`)
      .then((response) => {
        setEmployee(response.data.user)
      }).catch((_error) => {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function loadDependant(): void {
    let url = `/hr-account/companies/${getCompanyId()}/users/${employeeId}/dependants/${dependantId}`;

    api.get(url)
      .then((response: any) => {
        setMessage('');

        if (response.status === 200) {
          const { name, active, cpf, phone, email, birth_date: birthDate } = response.data.dependant;
          setName(name || '');
          setCpf(cpf || '');
          setPhone(phone || '');
          setEmail(email || '');
          setBirthDate(formatDate(birthDate) || '');
          setStatus(active);
        }
      }).catch((_error) => {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  return (
    <Dashboard title={employee?.name} backPath={`/rh/funcionarios/${employeeId}/dependentes`}>
      <Container>
        <Form>
          <StatusBox>
            <span>Status:</span>
            {status ? <Badge type="success">ATIVO</Badge> : <Badge type="error">INATIVO</Badge>}
          </StatusBox>
          <Separator />

          <h3>Dados do dependente</h3>

          <TextInput
            value={name}
            type="text"
            placeholder="Nome *"
            disabled
            maxLength={255} />

          <TextInput
            type="text"
            placeholder="CPF"
            value={cpf}
            disabled
            maxLength={14}
            typeMask="cpf" />

          <TextInput
            value={birthDate}
            type="text"
            placeholder="Data de nascimento *"
            maxLength={10}
            disabled
            typeMask="date" />

          <TextInput
            value={phone}
            type="text"
            placeholder="Telefone"
            maxLength={15}
            disabled
            typeMask="phone" />

          <TextInput
            value={email}
            type="text"
            placeholder="E-mail"
            disabled
            maxLength={100} />

          {
            message && <>
              <br />
              <br />
              <span>{message}</span>
            </>
          }

          <div className="actions">
            <div className="ml-auto"><BtnLink onClick={() => history.push(`/rh/funcionarios/${employeeId}/dependentes`)}>Voltar</BtnLink></div>
          </div>
        </Form>
      </Container>
    </Dashboard>
  )
};

export default Dependant;
