import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BodyModal = styled.div`
  display: grid;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${COLORS.mediumGrey};
    cursor: pointer;
    margin-top: 0.75rem;
  }

  button, strong {
    margin-top: 0.75rem;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    animation: rotation 3s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
`;
