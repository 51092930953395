import React from "react";

import {
  BodyModal,
} from "./styles";

import GenericModal from "components/GenericModal";

import successIcon from "assets/succes-orange.svg";
import hospitalBedIcon from "assets/icons/hospital-bed.svg";
import closeIcon from "assets/icons/close-accent-background.svg";

interface IHospitalBedModal {
  close: Function;
}

const HospitalBedModal: React.FC<IHospitalBedModal> = ({close}) => {  
  return (
    <GenericModal height="auto" width="37rem">
      <BodyModal>
        <img onClick={() => close()} src={closeIcon} alt="Fechar"></img>
        <div className="header-content">
          <img src={hospitalBedIcon} alt="Internação HospitalarInternação Hospitalar"></img>
          <h3>Internação Hospitalar</h3>  
        </div>
        <p>As Diárias de Internação Hospitalar garantem ao Segurado, após o período de carência (12h de internação),
           o valor de R$ 60,00 por diária de internação hospitalar.</p>
        <p className="accent-color">Tipos de internação hospitalar contemplada:</p>
        <div className="icon-and-description">
          <img src={successIcon}></img>
          <p>Por acidente</p>
        </div>
        <div className="icon-and-description">
          <img src={successIcon}></img>
          <p>Por doença</p>
        </div>
      </BodyModal>      
    </GenericModal>
  )
}

export default HospitalBedModal;
