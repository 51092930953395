import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SubscriberDashboard from 'components/SubscriberDashboard';
import Alert from 'components/Alert';
import Tabs, { ITabItems } from 'components/Tabs';

import FormPersonalData from './FormPersonalData';
import FormAddress from './FormAddress';
import FormResetPassword from './FormResetPassword';

import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

import { getSubscriberPersonalDataTabs, SubscriberPersonalDataTabsEnum } from 'utils';

import { getUser } from 'service/subscriber';

import { Container, ContainerMenu, ContainerForm, ContainerAlert } from './styles';

const PersonalData: React.FC = (props: any) => {
  const history = useHistory();
  const step: string = new URLSearchParams(props.location.search).get("step") || '';
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const { showUserAlert } = useItemsMenuSubscriber();
  const [tabItems] = useState<ITabItems[]>(getSubscriberPersonalDataTabs(SubscriberPersonalDataTabsEnum.PERSONAL_DATA));

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Meus dados', path: `/assinantes/dados-pessoais` }
    ]);
  }, []);

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function defineForm() {
    if (step === 'personal-data') {
      return <FormPersonalData />;
    } else if (step === 'address') {
      return <FormAddress />;
    } else if (step === 'reset-password') {
      return <FormResetPassword />;
    } else {
      return <FormPersonalData />;
    }
  }

  function alertContainer() {
    if (!showUserAlert) return;

    let title = '';

    if (getUser().isPlus)
      title = 'Atualize suas informações pessoais e endereço para ganhar um voucher de desconto';
    else
      title = 'É importante manter atualizadas suas informações pessoais e de endereço';

    <ContainerAlert>
      <Alert className="alert" title={title} type="info"></Alert>
    </ContainerAlert>
  }

  return (
    <SubscriberDashboard title="Dados Pessoais">
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      {alertContainer()}
      <Container>
        <ContainerMenu>
          <ul>
            <li
              className={step === 'personal-data' ? 'active' : ''}
              onClick={() => history.push('/assinantes/dados-pessoais?step=personal-data')}>
              Informações pessoais
            </li>
            <li
              className={step === 'address' ? 'active' : ''}
              onClick={() => history.push('/assinantes/dados-pessoais?step=address')}>
              Endereço
            </li>
            <li
              className={step === 'reset-password' ? 'active' : ''}
              onClick={() => history.push('/assinantes/dados-pessoais?step=reset-password')}>
              Alterar senha
            </li>
          </ul>
        </ContainerMenu>
        <ContainerForm>
          {defineForm()}
        </ContainerForm>
      </Container>
    </SubscriberDashboard>
  )
}

export default PersonalData;

