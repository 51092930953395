import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import api from 'service/hrAccountApi';
import { getCompany, getCompanyId, saveCompany } from 'service/company';
import { getNameInitials, formatPhone } from 'utils';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/HrAccountDashboard';
import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';
import SelectInput, { IOption } from 'components/SelectInput';
import SuccessModal from 'components/SuccessModal';
import BtnLink from 'components/BtnLink';

import pencilActive from 'assets/icons/pencil-active.svg';
import addActive from 'assets/icons/add-active.svg';
import checked from 'assets/checked-active.svg';
import purpleTrash from 'assets/purple-trash.svg';
import removeImg from 'assets/remove-active.svg';

import {
  Container,
  CirclePhoto,
  Form,
  TextSelectFile,
  AddMoreContact,
  ContainerButton,
  ContactData
} from './styles';

interface IValue {
  value: string;
  error: string;
}

interface IContact {
  uid: string;
  id: string | null;
  name: IValue;
  phone: IValue;
  email: IValue;
  position: IValue;
}

const CompanyProfile: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const positionOptions: IOption[] = [
    { value: 'CEO', label: 'CEO' },
    { value: 'Financeiro', label: 'Financeiro' },
    { value: 'Gestor', label: 'Gestor' },
  ];

  const [name] = useState(getCompanyName);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [urlImage, setUrlImage] = useState<string>();
  const [image, setImage] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState('');
  const [changingPhoto, setChangingPhoto] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    loadContacts();
    getCompanyLogo();
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Contatos da empresa', path: `` },
    ]);
  }, []);

  function getCompanyLogo() {
    setUrlImage(getCompany().imageUrl);
  }

  function loadContacts() {
    api.get(`/hr-account/companies/${getCompanyId()}/contacts`)
      .then((response) => {
        const { contacts } = response.data;
        setContacts(contacts.map(mapContact));
      })
      .catch((_error) => {
        setMessage('Não foi possível carregar os contatos.');
      });
  }

  function mapContact(contact: any): IContact {
    return {
      id: contact.id,
      uid: uuidv4(),
      email: { value: contact.email, error: '' },
      name: { value: contact.name, error: '' },
      phone: { value: formatPhone(contact.phone), error: '' },
      position: { value: contact.position, error: '' },
    };
  }

  function addNewContacts() {
    setContacts([
      ...contacts,
      {
        id: null,
        uid: uuidv4(),
        email: { value: '', error: '' },
        name: { value: '', error: '' },
        phone: { value: '', error: '' },
        position: { value: '', error: '' },
      }
    ])
  }

  function getCompanyName(): string {
    const company = getCompany();
    return company?.name;
  }

  function setValue(uid: string, value: string, attr: string) {
    const newContacts = contacts.map((contact: any) => {
      if (contact.uid === uid) {
        contact[attr] = { value, error: '' };
      }

      return contact;
    });

    setContacts(newContacts);
  }

  function getImage() {
    if (changingPhoto) {
      return <img src={image} alt="Imagem do usuário" />
    } else if (urlImage) {
      return <img src={urlImage} alt="Imagem do usuário" />
    } else {
      return getNameInitials(name);
    }
  }

  function removeSelectPhoto() {
    setChangingPhoto(false);
    setFile(null);
    setFileName('');
    setImage('');
    const fileInput: any = document.getElementById('input-file');
    fileInput.value = '';
  }

  function changePhoto() {
    document.getElementById('input-file')?.click();
  }

  async function saveImage() {
    try {
      setChangingPhoto(false);
      setMessage('');
      const formData = new FormData();
      formData.append("name", fileName);
      formData.append("file", file);
      const response = await api.put(`/hr-account/companies/${getCompanyId()}/photos`, formData);
      if (response.status === 200) {
        const { company: { image_url } } = response.data;
        setMessage('');
        setUrlImage(image_url);
        const company = getCompany();
        saveCompany({ ...company, imageUrl: image_url });
      } else {
        setMessage('Não foi possível salvar a imagem.');
      }
    } catch (ex: any) {
      setMessage(ex?.response?.data?.error);
    }
  }

  function handleFileInput(e: any) {
    const [file] = e.target.files;
    if (file) {
      setChangingPhoto(true);
      setFileName(file.name);
      setFile(file);
      var reader = new FileReader();

      reader.onload = function (e: any) {
        setImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }

  async function deleteContact(id: string) {
    try {
      const response = await api.delete(`/hr-account/companies/${getCompanyId()}/contacts/${id}`);
      if (response.status === 200) {
        setContacts(contacts.filter(contact => contact.id !== id));
      } else {
        setMessage('Não foi possível excluir o arquivo.');
      }
    } catch (error) {
      setMessage('Não foi possível excluir o arquivo.');
    }
  }

  async function removeContact(contact: IContact) {
    if (!!contact.id) {
      await deleteContact(contact.id)
    } else {
      setContacts(contacts.filter(ct => ct.uid !== contact.uid));
    }
  }

  function setUpdatedContact(contact: IContact) {
    const newContacts = contacts.map((ct: any) => {
      if (ct.uid === contact.uid) {
        return contact;
      } else {
        return ct;
      }
    });

    setContacts(newContacts);
  }

  function setErrorsByContact(uid: string, errors: any) {
    const newContacts = contacts.map((contact: any) => {
      if (contact.uid === uid) {
        Object.keys(errors).forEach(attr => {
          contact[attr] = { value: contact[attr].value, error: errors[attr][0] }
        });
      }

      return contact;
    });

    setContacts(newContacts);
  }

  async function updateContact(uid: string): Promise<boolean> {
    try {
      const ct = contacts.find((contact) => contact.uid === uid);
      const params = {
        name: ct?.name.value,
        phone: ct?.phone.value,
        email: ct?.email.value,
        position: ct?.position.value
      };
      const response = await api.put(`/hr-account/companies/${getCompanyId()}/contacts/${ct?.id}`, params);
      const { contact } = response.data;
      if (response.status === 200) {
        setUpdatedContact({ ...contact, uid });
      }
      return true;
    } catch (error: any) {
      const { errors } = error?.response?.data;
      setErrorsByContact(uid, errors);
      return false;
    }
  }

  async function createContact(uid: string): Promise<boolean> {
    try {
      const ct = contacts.find((contact) => contact.uid === uid);
      const params = {
        name: ct?.name.value,
        phone: ct?.phone.value,
        email: ct?.email.value,
        position: ct?.position.value
      };
      const response = await api.post(`/hr-account/companies/${getCompanyId()}/contacts`, params);
      const { contact } = response.data;
      if (response.status === 201) {
        setUpdatedContact({ ...contact, uid });
      }
      return true;
    } catch (error: any) {
      const { errors } = error?.response?.data;
      setErrorsByContact(uid, errors);
      return false;
    }
  }

  async function saveContacts() {
    let hasError = false;

    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];

      if (!!contact.id) {
        const responseStatus = await updateContact(contact.uid);
        hasError = !responseStatus;
      } else {
        const responseStatus = await createContact(contact.uid);
        hasError = !responseStatus;
      }
    }

    if (!hasError) {
      setShowSuccessModal(true);
    }
  }

  function createContactForm(contact: IContact) {
    return (
      <ContactData key={contact.uid}>
        <BtnLink onClick={() => removeContact(contact)}>
          Remover
          <img src={removeImg} alt="Remover contato" />
        </BtnLink>
        <TextInput
          onChange={(value: string) => setValue(contact.uid, value, 'name')}
          value={contact.name.value}
          type="text"
          placeholder="Nome" />
        <p>{contact.name.error}</p>

        <SelectInput
          onChange={(value: string) => setValue(contact.uid, value, 'position')}
          options={positionOptions}
          value={contact.position.value}
          defaultValue="Selecione um cargo..." />
        <p>{contact.position.error}</p>

        <TextInput
          onChange={(value: string) => setValue(contact.uid, value, 'phone')}
          value={contact.phone.value}
          type="text"
          placeholder="Telefone"
          maxLength={15}
          typeMask="phone" />
        <p>{contact.phone.error}</p>

        <TextInput
          onChange={(value: string) => setValue(contact.uid, value, 'email')}
          value={contact.email.value}
          type="text"
          placeholder="E-mail"
          maxLength={100} />
        <p>{contact.email.error}</p>
      </ContactData>
    );
  }

  return (
    <>
      <Dashboard title="Editar empresa" backPath="/rh/escolher-empresa">
        <Container>
          <Form>
            <h1>{name}</h1>
            <p>Personalize o acesso da sua empresa</p>
            <CirclePhoto>
              {getImage()}
            </CirclePhoto>
            <TextSelectFile>
              {!changingPhoto &&
                <BtnLink onClick={changePhoto}>
                  <img src={pencilActive} alt="Editar" />
                  {urlImage ? 'Trocar foto' : 'Adicionar foto'}
                </BtnLink>}

              {changingPhoto &&
                <p onClick={removeSelectPhoto}>
                  <img src={purpleTrash} alt="Remover imagem" />
                  Remover
                </p>}

              {changingPhoto &&
                <p onClick={saveImage}>
                  <img src={checked} alt="Editar" />
                  Salvar
                </p>}
            </TextSelectFile>
            <input type="file" name="file" id="input-file" onChange={handleFileInput} />

            <h3>Contatos da empresa</h3>

            {contacts.map((contact) => createContactForm(contact))}
            {contacts.length === 0 && <p className="empty-contacts">Nenhum contato adicinado</p>}
            <AddMoreContact onClick={addNewContacts}>
              Adicionar contato
              <img src={addActive} alt="Adicionar mais um contato" />
            </AddMoreContact>

            <p className="message">{message}</p>

            <ContainerButton>
              <BtnLink onClick={() => history.goBack()}>Cancelar</BtnLink>
              <PrimaryButton
                type="button"
                width="10rem"
                onClick={saveContacts}>
                Salvar alteração
              </PrimaryButton>
            </ContainerButton>
          </Form>
        </Container>
      </Dashboard>
      {
        showSuccessModal &&
        <SuccessModal
          message="Contatos salvos com sucesso"
          buttonMessage="OK"
          onClose={() => { setShowSuccessModal(false) }} />
      }
    </>
  )
};

export default CompanyProfile;
