import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Dashboard from 'components/HrAccountDashboard';
import Tabs, { ITabItems } from 'components/Tabs';
import SearchInput from 'components/SearchInput';
import Pagination from 'components/Pagination';

import { useBreadcrumb } from 'hooks/useBreadcrumb';
import api from 'service/hrAccountApi';
import { getCompanyId } from 'service/company';

import documentActiveIcon from 'assets/icons/documentActive.svg';
import documentInactiveIcon from 'assets/icons/documentInactive.svg';
import buildInactiveIcon from 'assets/icons/buildInactive.svg';
import buildActiveIcon from 'assets/icons/buildActive.svg';
import arrowDown from 'assets/arrow-down-black.svg';

import {
  Container,
  ContainerList,
  HeaderActions,
  CustomTable,
  ChipActive,
  Chip,
  ChipCanceled,
  FilterBox
} from './styles';

import { toast } from 'react-toastify';

interface IEmployee {
  id: number;
  name: string;
  cpf: string;
  dependantsCount: number;
  status: string;
  checked: boolean;
  statusTempoAssist: string;
}

const AssistanceService: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const [search, setSearch] = useState(null);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [orderByName, setOrderByName] = useState<string>('');
  const [orderByDate, setOrderByDate] = useState<string>('');
  const [statusFilter] = useState<string>('');
  const [debounceTimer, setDebounceTimer] = useState<any>(0);
  const [showStatusFilterBox, setShowStatusFilterBox] = useState<boolean>(false);
  const [showFilterBox, setShowFilterBox] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFilteredCount, setTotalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(5);

  useEffect(() => {
    setBreadcrumb([...defaultBreadcrumbs,
                  { title: 'Serviços Adicionais', path: '/rh/servicos/seguros' },
                  { title: 'Assistência', path: '/rh/servicos/assistencias' }])
    loadEmployeesByCompany();
  }, [orderByName, orderByDate, statusFilter, currentPage]);

  useEffect(() => {
    if (search !== null) onSearch();
  }, [search]);

  function getTabItems(): ITabItems[] {
    return [
      {
        id: uuidv4(),
        title: 'Seguro',
        path: '/rh/servicos/seguros',
        isActive: false,
        icons: {
          active: documentActiveIcon,
          inactive: documentInactiveIcon
        }
      },
      {
        id: uuidv4(),
        title: 'Assistência',
        path: '/rh/servicos/assistencias',
        isActive: true,
        icons: {
          active: buildActiveIcon,
          inactive: buildInactiveIcon
        }
      }
    ]
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find((tabItem) => tabItem.id === tabId)
    if (!activeTab) return

    history.push(activeTab.path);
  }

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }

  function loadEmployeesByCompany(): void {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${getCompanyId()}/users?`
    url += `search=${search}&`
    url += `page=${currentPage}&`
    url += `per_page=${perPage}&`
    url += `order_by_name=${orderByName}&`
    url += `order_by_date=${orderByDate}&`
    url += `status_temp_assist=${statusFilter}&`;

    api.get(url)
      .then((response) => {
        setLoading(false);
        setMessage('');

        const {
          total_count,
          total_filtered_count,
          total_pages,
          per_page,
          users
        } = response.data;

        setTotalCount(total_count);
        setTotalFilteredCount(total_filtered_count);
        setTotalPages(total_pages);
        setPerPage(per_page);

        const employees: IEmployee[] = users || [];
        setEmployees(employees.map((employee) => formatEmployee(employee)));

        if (!employees.length) {
          setMessage('Nenhum funcionário encontrado.');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatEmployee(employee: any): IEmployee {
    return {
      id: employee.id,
      name: employee.name,
      cpf: employee.cpf,
      dependantsCount: employee.dependants_count,
      status: employee.subscription.status,
      statusTempoAssist: employee.tempo_assist_status,
      checked: false
    }
  }


  function handleOnSearch(event: any) {
    setMessage('Nenhum funcionário encontrado na busca!')
    setSearch(event.target.value)
  }

  function onSearch() {
    clearTimeout(debounceTimer);

    setEmployees([])
    setLoading(true);
    setMessage('Carregando...');

    const timeout = setTimeout(() => {
      setDebounceTimer(null)
      setCurrentPage(1)
      loadEmployeesByCompany()
    }, 1000);

    setDebounceTimer(timeout);
  }

  function getChipByStatus(status: string) {
    switch (status) {
      case 'active':
        return <ChipActive><span>Ativo</span></ChipActive>
      case 'inactive':
        return <ChipCanceled><span>Inativo</span></ChipCanceled>
      default:
        return <Chip><span>Pendente</span></Chip>
    }
  }

  function handleStatusFilterBox() {
    setShowStatusFilterBox(!showStatusFilterBox)
  }

  function handleFilter() {
    setShowFilterBox(!showFilterBox)
  }

  function renderFilterBox(showFilterBox: boolean) {
    return (showFilterBox &&
      <FilterBox onMouseOver={() => setShowFilterBox(true)} onMouseLeave={() => setShowFilterBox(false)}>
        <span onClick={handleOrderByName}>Ordem alfabética</span>
        <span onClick={() => handleOrderByDate('DESC')}>Mais recentes</span>
        <span onClick={() => handleOrderByDate('ASC')}>Mais antigos</span>
      </FilterBox>
    )
  }

  function handleOrderByDate(order: string) {
    setOrderByName('')
    order === 'ASC' ? setOrderByDate('ASC') : setOrderByDate('DESC')
  }

  function handleOrderByName() {
    setOrderByDate('')
    orderByName === 'ASC' ? setOrderByName('DESC') : setOrderByName('ASC' )
  }

  return (
    <>
      <Dashboard title="Serviços Adicionais" backPath="/rh/servicos/seguros">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          <ContainerList>
            <HeaderActions>
              <SearchInput
                type="text"
                placeholder="Buscar por nome ou CPF"
                value={search || ''}
                onChange={(event: any) => handleOnSearch(event)} />
            </HeaderActions>

            <CustomTable>
              <thead>
                <tr>
                  <th scope="col" onClick={handleFilter} className="filter-box">
                    NOME
                    <img src={arrowDown} alt="Seta para baixo" />
                    {renderFilterBox(showFilterBox)}
                  </th>
                  <th scope="col">CPF</th>
                  <th className="th-dependants" scope="col">DEPENDENTES</th>
                  <th scope="col" onClick={handleStatusFilterBox} className="filter-box">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.cpf}>
                    <td>{employee.name}</td>
                    <td>{employee.cpf}</td>
                    <td className="td-dependants">{employee.dependantsCount}</td>
                    <td>{getChipByStatus(employee.statusTempoAssist)} </td>
                  </tr>
                ))}
              </tbody>
            </CustomTable>
            {(loading || !employees.length) && <p>{message}</p>}
            <Pagination
              currentPage={currentPage}
              numOfPages={totalPages}
              totalFilteredCount={totalFilteredCount}
              totalCount={totalCount}
              perPage={perPage}
              lengthPage={employees.length}
              onSelectPage={handleSelectPage} />
          </ContainerList>
        </Container>
      </Dashboard>
    </>
  )
}

export default AssistanceService;
