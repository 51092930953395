import styled from 'styled-components';
import { SIZES } from 'styles/constants';
import { COLORS } from 'styles/constants';

export const ContainerBody = styled.section`
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
`;

export const ContainerLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50%;

  @media(${SIZES.smallerThanTablet}) {
    max-width: initial;
  }
`;

export const FirstAccessText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.defaultSubtitle};
  margin-top: 1rem;

  span {
    color: ${COLORS.accentColor} !important;

    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
`;

export const ForgotPasswordText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.accentColor};
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const ChangeAccessText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${COLORS.accentColor};
  margin-top: 1rem;
  text-align: end;
  cursor: pointer;
  margin-top: 30%;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 29rem;
  padding: 1rem;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 24px;
    color: ${COLORS.defaultSubtitle};
    margin-bottom: 2.5rem;
  }

  div + div  {
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  span {
    margin-top: 1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${COLORS.red};
  }
`;
