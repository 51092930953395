import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

export const BranchesArea = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BranchesAreaTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: ${COLORS.accentColor};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const BranchesCardsSection = styled.section`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media(${SIZES.smallerThanDesktopuhd}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const BranchCard = styled.div`
  background-color: ${COLORS.defaultBg};
  border: 1px solid ${COLORS.borderBase};
  border-radius: 6px;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;

  hr {
    margin: 1rem 0;
    border: 1px solid ${COLORS.borderBase};
  }

  &:hover {
    background-color: ${COLORS.borderBase};
  }

  .gray-text {
    color: ${COLORS.mediumGrey};
  }
`;

export const BranchCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 3.5rem;
  justify-content: space-between;

  .logo-name {
    margin: 0;
    display: flex;
    align-items: flex-end;
    
    .logo {
      max-height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }

    .name{
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .favorite-icon {
    cursor: pointer;
  }
`;

export const BranchCardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .info-section {
    margin-top: 0.5rem;
  }
`;

export const BranchTagArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  .plus-icon {
    max-width: 2rem;
    cursor: pointer;
  }
`;

export const BranchTagSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 9rem));
`;

export const BranchTag = styled.div`
  margin: 0.35rem 0;

  span {
    background-color: ${COLORS.accentColor};
    color: ${COLORS.defaultBg};
    padding: 0.2rem 0.4rem;
    border-radius: 12px;
  }
`;
