import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Loading = styled.p`
  padding-top: 3rem;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;
`;

export const ContainerTable = styled.div``;

export const CustomTable = styled.table`
  width: calc(100% + 3rem);
  overflow-x: auto;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  thead {
    th {
      border-bottom: none;
      border-top: none;
      color: ${COLORS.offblack};
      font-size: 0.9rem;
      font-weight: 400;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: inherit;
      height: 3rem;

      img {
        margin-left: 0.5rem;
        width: 0.7rem;
        cursor: pointer;
      }

      &:first-child {
        display: flex;
        padding-left: 1.75rem;

        img {
          height: 0.8rem;
          width: 0.8rem;
          cursor: pointer;
        }
      }

      &:last-child {
        padding-right: 1.75rem;
      }
    }
  }

  tbody {
    tr {
      td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${COLORS.offblack};
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-top: 1px solid ${COLORS.backgroundBase};
        border-bottom: 1px solid ${COLORS.backgroundBase};

        &:first-child {
          padding-left: 1.75rem;
          border-left: 1px solid ${COLORS.backgroundBase};
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          width: 7rem;
        }

        &:last-child {
          padding-right: 1.75rem;
          border-right: 1px solid ${COLORS.backgroundBase};
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          width: 5rem;
        }

        img + img {
          margin-left: 0.75rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(244, 244, 244, 0.6);
      }
    }
  }
`;
