import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 25rem;
  text-align: center;

  input {
    margin-top: 1rem;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.9375rem;
    color: ${COLORS.offblack};
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: ${COLORS.offblack};
    margin-top: 2rem;
    text-align: start;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    color: ${COLORS.offblack};
  }

  .message {
    text-align: start;
    margin-top: 1rem;
    color: ${COLORS.red};
    font-size: 0.75rem;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;
