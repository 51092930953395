import React from 'react';

import { Select } from './styles';

export interface IOption {
  value: string;
  label: string;
}

interface ISelectInput {
  defaultValue: string;
  value: string;
  onChange: Function;
  options: IOption[];
}

const SelectInput: React.FC<ISelectInput> = ({ onChange, options, defaultValue, value }) => {

  function handleChange(event: any) {
    const value = event.target.value;

    onChange(value)
  }

  return (
    <Select defaultValue={value || defaultValue} onChange={handleChange}>
      <option value=''>{defaultValue}</option>
      {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </Select>
  );
};

export default SelectInput;
