import styled from 'styled-components';
import { COLORS } from 'styles/constants';

interface ILoading {
  size: string;
}

export const Loading = styled.div<ILoading>`
  ${(props) => props.size === 'small' && `border: 2px solid ${COLORS.lightAccentColor};`}
  ${(props) => props.size === 'medium' && `border: 4px solid ${COLORS.lightAccentColor};`}
  ${(props) => props.size === 'large' && `border: 6px solid ${COLORS.lightAccentColor};`}
  border-radius: 50% !important;
  border-top: 2px solid ${COLORS.white};
  ${(props) => props.size === 'small' && `width: 1.5rem !important;`}
  ${(props) => props.size === 'small' && `height: 1.5rem !important;`}
  ${(props) => props.size === 'medium' && `width: 3rem !important;`}
  ${(props) => props.size === 'medium' && `height: 3rem !important;`}
  ${(props) => props.size === 'large' && `width: 4.5rem !important;`}
  ${(props) => props.size === 'large' && `height: 4.5rem !important;`}
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 !important;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;