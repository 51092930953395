import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import downloadInactive from 'assets/icons/download-grey.svg';

import api from 'service/subscriberApi';

import SubscriberDashboard from 'components/SubscriberDashboard';
import Tooltip from 'components/Tooltip';

import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { formatDate } from 'utils';

import { Container, ContainerTable, CustomTable, Loading } from './styles';

export interface IInvoice {
  id: number;
  number: string;
  emissionDate: string;
  rpsNumber: string;
  validationKey: string;
  invoicePdf: string;
}

const Invoices: React.FC = (props: any) => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Notas fiscais', path: `/assinantes/notas-fiscais` }
    ]);
    listInvoices();
  }, []);

  function listInvoices() {
    setLoading(true);
    api.get('/users/invoices')
      .then((response) => {
        setLoading(false);
        const { invoices } = response.data;
        setInvoices((invoices || []).map(formatInvoices));
      })
      .catch((_error) => {
        setLoading(false);
        toast.error('Não foi possível carregar as notas fiscais, por favor tente novamente!');
      });
  }

  function formatInvoices(invoice: any): IInvoice {
    return {
      id: invoice.id,
      number: invoice.number,
      emissionDate: invoice.emission_date,
      rpsNumber: invoice.rps_number,
      validationKey: invoice.validation_key,
      invoicePdf: invoice.invoice_pdf,
    };
  }

  function openPDF(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = 'blank';
    a.click();
  }

  function showTable() {
    return (
      <ContainerTable>
        <CustomTable>
          <thead>
            <tr>
              <th scope="col">NÚMERO</th>
              <th scope="col">DATA DE EMISSÃO</th>
              <th scope="col">NÚMERO RPS</th>
              <th scope="col">CHAVE</th>
              <th scope="col">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {
              invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td>{invoice.number}</td>
                  <td>{formatDate(invoice.emissionDate)}</td>
                  <td>{invoice.rpsNumber}</td>
                  <td>{invoice.validationKey}</td>
                  <td>
                    <div className="d-flex flex-row">
                      <Tooltip position="top center" text="Abrir PDF">
                        <img
                          src={downloadInactive}
                          height="16rem"
                          onClick={() => openPDF(invoice.invoicePdf)}
                          alt="Icone para abrir PDF"
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </CustomTable>
      </ContainerTable>
    )
  }

  return (
    <SubscriberDashboard title="Notas fiscais" backPath="/assinantes/meu-plano">
      <Container>
        {loading && <Loading>Carregando..</Loading>}
        {!loading && showTable()}
      </Container>
    </SubscriberDashboard>
  )
}

export default Invoices;
