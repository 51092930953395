import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { limitStr } from 'utils';
import { saveCompany } from 'service/company';

import { useBreadcrumb } from 'hooks/useBreadcrumb'

import {
  Container,
  ContainerList,
  Companies,
  Company
} from './styles';

import api from 'service/hrAccountApi';

import SearchInput from 'components/SearchInput';
import HeaderMenu from 'components/HrAccountHeaderMenu';

import logoAliancaNoText from 'assets/logo-alianca-no-text.svg';

import { toast } from 'react-toastify';

export interface ICompany {
  id: number;
  cnpj: string;
  name: string;
  tradeName: string;
  imageUrl: string;
  usersCount: number;
  hasActivePlugins: boolean;
  hasMetlife: boolean;
  hasTempoAssist: boolean;
}

const ChoiceCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    setBreadcrumb([...defaultBreadcrumbs, { title: 'Buscar Empresa', path: '/rh/escolher-empresa' }])
    loadCompaniesByHrAccount();
  }, []);

  function loadCompaniesByHrAccount() {
    setLoading(true);
    setMessage('');

    api.get('/hr-account/companies')
      .then((response) => {
        setLoading(false);

        const companies: any = response.data || [];
        setCompanies(companies.map(mapCompany));

        if (!companies.length) {
          setMessage('Nenhuma empresa encontrada.');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function mapCompany(company: any): ICompany {
    return {
      id: company.id,
      name: company.name,
      cnpj: company.cnpj,
      imageUrl: company.image_url,
      tradeName: company.trade_name,
      usersCount: company.users_count,
      hasActivePlugins: company["has_active_plugins?"],
      hasMetlife: company["has_metlife?"],
      hasTempoAssist: company["has_tempo_assist?"]
    }
  }

  function selectCompany(company: ICompany) {
    saveCompany(company);
    history.push('/rh/funcionarios');
  }

  return (
    <>
      <HeaderMenu title="Buscar Empresa"/>
      <Container>
        <ContainerList>
          <SearchInput
            type="text"
            placeholder="Buscar por nome"
            value={search}
            onChange={(event: any) => setSearch(event.target.value)} />

          <h3>Todas as empresas</h3>

          {message && !companies.length && <p>{message}</p>}
          {loading && <p>Carregando...</p>}

          <Companies>
            {
              companies.filter(company => company.tradeName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0)
                .map((company) => (
                  <Company key={company.cnpj} onClick={() => selectCompany(company)} title={company.tradeName}>
                    <div>
                      <img src={company.imageUrl || logoAliancaNoText} alt={company.tradeName} />
                    </div>
                    <strong>{limitStr(company.tradeName, 25)}</strong>
                    <p>{company.usersCount} {company.usersCount > 1 ? 'funcionários' : 'funcionário'}</p>
                  </Company>
                ))
            }
          </Companies>
        </ContainerList>
      </Container>
    </>
  )
};

export default ChoiceCompanies;
