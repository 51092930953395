import React, { useState, useEffect } from 'react';
import { Container, ContainerMenu, ContainerForm } from './styles';
import { useBreadcrumb } from 'hooks/useBreadcrumb'
import Dashboard from 'components/HrAccountDashboard';
import SuccessModal from './SuccessModal';
import step1Activate from 'assets/icons/step-1-activate.svg';
import step2Activate from 'assets/icons/step-2-activate.svg';
import step2Inactivate from 'assets/icons/step-2-inactivate.svg';
import FormPersonalData from './FormPersonalData';
import FormAddress from './FormAddress';
import { getCompanyId } from 'service/company';
import { toast } from 'react-toastify';
import api from 'service/hrAccountApi';

export interface IForm {
  name: string,
  email?: string,
  cpf: string,
  phone: string,
  birth_date: string
}

const NewEmployee: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [employeeId, setEmployeeId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [step, setStep] = useState("personal-data")
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState()


  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: 'Novo Funcionário', path: `/rh/funcionario/novo` }
    ])
  }, [])

  function chooseStepImage() {
    if (step === 'address') {
      return <img src={step2Activate} alt="Step 2" />
    } else {
      return <img src={step2Inactivate} alt="Step 2" />
    }
  }

  function chooseForm() {
    if (step === 'address') {
      return <FormAddress userId={employeeId} _setShowConfirmModal={() => setShowConfirmModal(true)} />
    } else {
      return (
        <FormPersonalData
          handleSubmitForm={(values: IForm) => createEmployee(values)}
          _setLoading={setLoading}
          loading={loading}
          formErrors={errors}
        />
      )
    }
  }

  async function createEmployee(values: IForm) {
    try {
      const response = await api.post(`/hr-account/companies/${getCompanyId()}/users`, values);
      setLoading(false);

      if (response.status === 201) {
        setEmployeeId(response.data.user.id)
        setStep('address')
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    } catch (ex: any) {
      setLoading(false);

      if (ex.response && ex.response.data && ex.response.status === 422) {
        const { errors } = ex.response.data;
        setErrors(errors)
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  return (
    <>
      <Dashboard title="Novo funcionário" backPath={`/rh/funcionarios`}>
        <Container>
          <ContainerMenu>
            <ul>
              <li className={step === 'personal-data' ? 'active' : ''}>
                <img src={step1Activate} alt="Step 1" />
                Informações pessoais
              </li>
              <li className={step === 'address' ? 'active' : ''}>
                {chooseStepImage()}
                Endereço
              </li>
            </ul>
          </ContainerMenu>
          <ContainerForm>
            {chooseForm()}
          </ContainerForm>
        </Container>
      </Dashboard>
      {showConfirmModal && <SuccessModal employeeId={employeeId} />}
    </>
  )
};

export default NewEmployee;
