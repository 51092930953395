import React, { createContext, useState, useContext } from 'react';

import Modal from 'components/Modal';

import redTrash from 'assets/red-trash.svg';

const ConfirmationModalContext = createContext<any>([]);

interface IParamsModal {
  description: string;
  textBtnConfirm: string;
  onConfirm: Function;
}

export const ConfirmationModalProvider: React.FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [paramsModal, setParamsModal] = useState<IParamsModal>({ description: '', onConfirm: () => { }, textBtnConfirm: '' });

  function confirmDelete({ description, onConfirm, textBtnConfirm }: IParamsModal): void {
    setShowModal(true);
    setParamsModal({ description, onConfirm, textBtnConfirm });
  }

  function onCancel() {
    setShowModal(false);
  }

  function confirm() {
    setShowModal(false);
    if (!!paramsModal?.onConfirm) {
      paramsModal.onConfirm();
    }
  }

  return (
    <ConfirmationModalContext.Provider value={{ confirmDelete }}>
      {children}
      <Modal
        type="confirmation"
        icon={redTrash}
        showModal={showModal}
        onCancel={onCancel}
        onConfirm={confirm}
        textBtnConfirm={paramsModal?.textBtnConfirm}
        message={paramsModal?.description}
      />
    </ConfirmationModalContext.Provider>
  )
};

export function useConfirmationModal() {
  const context = useContext(ConfirmationModalContext);

  return context;
}
