import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import SubscriberDashboard from "components/SubscriberDashboard";
import Accordion from 'components/Accordion';
import Tabs, { ITabItems } from 'components/Tabs';

import {
  AccordionBody,
  Accordions,
  Card,
  CardsContainer,
  Container,
  SmallCard,
  SmallCardsContainer,
  Title,
} from "./styles";

import buildActiveIcon from "assets/icons/buildActive.svg";
import buildInactiveIcon from "assets/icons/buildInactive.svg";
import shieldActiveIcon from "assets/icons/shieldActive.svg";
import shieldInactiveIcon from "assets/icons/shieldInactive.svg";

import accidentalDeathIcon from "assets/icons/accidental-death.svg";
import hospitalBedIcon from "assets/icons/hospital-bed.svg";
import tombIcon from "assets/icons/tomb.svg";
import coinIcon from "assets/icons/coin.svg";

import attendanceOrangeIcon from "assets/icons/attendance-orange.svg";
import attendanceGreenIcon from "assets/icons/attendance-green.svg";
import attendanceNotebookIcon from "assets/icons/attendance-notebook.svg";

import AccidentalDeathModal from "./AccidentalDeathModal";
import HospitalBedModal from "./HospitalBedModal";
import TombModal from "./TombModal";
import CoinModal from "./CoinModal";


const LifeInsurance: React.FC = () => {
  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const [showAccidentalDeathModal, setShowAccidentalDeathModal] = useState(false);
  const [showHospitalBedModal, setShowHospitalBedModal] = useState(false);
  const [showTombModal, setShowTombModal] = useState(false);
  const [showCoinModal, setShowCoinModal] = useState(false);
  
  const history = useHistory();

  function getTabItems() {
    return [
      {
        id: uuidv4(),
        title: 'Seguro de Vida',
        path: '/assinantes/seguro-de-vida',
        isActive: true,
        icons: {
          active: shieldActiveIcon,
          inactive: shieldInactiveIcon
        }
      },
      {
        id: uuidv4(),
        title: 'Assistência',
        path: '/assinantes/assistencia',
        isActive: false,
        icons: {
          active: buildActiveIcon,
          inactive: buildInactiveIcon,
        },
      }      
    ];
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function accidentalDeathCard() {
    return (
      <Card>
        <div className="card-content">
          <img src={accidentalDeathIcon} alt="Morte acidental"></img>
          <h4>Morte Acidental</h4>
          <p>Cobertura de R$15.000,00</p>
          <a onClick={() => setShowAccidentalDeathModal(true)}><p>Saiba mais</p></a>
        </div>
      </Card>
    );
  }

  function hospitalBedCard() {
    return (
    <Card>
      <div className="card-content">
        <img src={hospitalBedIcon} alt="Internação Hospitalar"></img>
        <h4>Internação Hospitalar</h4>
        <p>Até 6 diárias de R$100,00 cada</p>
        <a onClick={() => setShowHospitalBedModal(true)}><p>Saiba mais</p></a>
      </div>
    </Card>
    );
  }

  function tombCard() {
    return (
    <Card>
      <div className="card-content">
        <img src={tombIcon} alt="Assistência Funeral"></img>
        <h4>Assistência Funeral</h4>
        <p>Cobertura de R$5.000,00</p>
        <a onClick={() => setShowTombModal(true)}><p>Saiba mais</p></a>
      </div>
    </Card>
    );
  }

  function coinCard() {
    return (
    <Card>
      <div className="card-content">
        <img src={coinIcon} alt="Sorteio Mensal"></img>
        <h4>Sorteio Mensal</h4>
        <p>Prêmio de R$5.000,00</p>
        <a onClick={() => setShowCoinModal(true)}><p>Saiba mais</p></a>
      </div>
    </Card>
    );
  }

  function smallCardOrange() {
    return (
      <SmallCard color="orange">
        <div className="body">
          <div>
            <p className="info">Ligue</p>
            <p className="phone-number">3003 5433</p>
            <p className="phone-description">(Capitais e grandes centros)</p>
          </div>
          <img src={attendanceOrangeIcon} alt="Atendimento Telefone"></img>
        </div>
      </SmallCard>
    );
  }

  function smallCardGreen() {
    return (
      <SmallCard color="green">
        <div className="body">
          <div>
            <p className="info">Ligue</p>
            <p className="phone-number">0800 638 5433</p>
            <p className="phone-description">(Demais localidades)</p>
          </div>
          <img src={attendanceGreenIcon} alt="Atendimento Telefone"></img>
        </div>
      </SmallCard>
    );
  }

  function smallCardAccent() {
    return (
      <SmallCard color="accent">
        <div className="body">
          <div>
            <p className="info">Horário de Atendimento</p>
            <p className="phone-number">9h às 18h</p>
            <p className="phone-description">(Para todo Brasil)</p>
          </div>
          <img src={attendanceNotebookIcon} alt="Horário de Atendimento"></img>
        </div>
      </SmallCard>
    );
  }  

  function insuranceFeaturesCard() {
    return(
      <AccordionBody>
        <p>Franquia</p>
        <p>✓ internação por período mínimo a 12 horas;</p>

        <p className="title-paragraph">Vigência</p>
        <p>✓ A partir das 24 horas da data de contratação;</p>
        <p>✓ Vigência de 1 ano, com renovação automática;</p>
        <p>✓ Quantidade máxima de diárias: conforme cada plano;</p>

        <p className="title-paragraph">Carência</p>
        <p>✓ 90 dias para todas as doenças, exceto pré-existentes que não terão cobertura;</p>
        <p> ✓ Para acidentes não há carência;</p>

        <p className="title-paragraph">Reajustes</p>
        <p>✓ Reajuste anual pelo IPCA;</p>
        <p>✓ Reenquadramento por idade ao cumprir idade limite do plano;</p>
        <p>✓ Seguro Acidentes Pessoais a partir de 60 anos, 10% ao ano;</p>
      </AccordionBody>
    );
  }

  function proceedAccidentCard() {
    return(
      <AccordionBody>
        <p>Você, sendo segurado ou beneficiário, deverá  providenciar todos os documentos necessários (cópia simples) e 
          preencher os formulários específicos para o andamento do processo de indenização. 
          Esse kit de documentos varia conforme a natureza do sinistro.</p>
        <p className="title-paragraph">
          <a href="https://www.metlife.com.br/content/dam/metlifecom/br/PDFs/forms-library/sinistro/preechimento/Lista-de-documentos-basicos-de-sinistro.pdf" 
          target="_blank">Clique aqui</a> para acessar a lista de documentos básicos para cada sinistro!
        </p>
        <p className="title-paragraph">Avisos Importantes:</p>
        <p className="title-paragraph">✓ O kit de abertura de sinistro deverá ser enviado para a MetLife;</p>
        <p className="title-paragraph">
          ✓ É importante lembrar que a análise e resposta do seu pedido dependem do envio de toda a documentação básica informada. 
          Sem a documentação completa não será possível finalizar do processo de sinistro;</p>
      </AccordionBody>
    );
  }

  function communicatePolicyCard() {
    return(
      <AccordionBody>
        <p>
          Caso necessite realizar alguma alteração em seu seguro de vida, 
          preencha os formulários de acordo com cada solicitação e envie para um de nossos escritórios.
        </p>
        <p className="title-paragraph">
          <a href="https://www.metlife.com.br/suporte-e-servicos/vida/como-comunicar-alteracoes-na-apolice/"
            target="_blank">Procure o escritório mais próximo de você</a>
        </p>
      </AccordionBody>
    );
  }
  
  return (
    <>
      <SubscriberDashboard title="Serviços Adicionais" backPath="/assinantes/home">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          <Title>
            <h3><strong>Minhas Coberturas</strong></h3>
          </Title>
          <CardsContainer>
            {accidentalDeathCard()}
            {hospitalBedCard()}
            {tombCard()}
            {coinCard()}
          </CardsContainer>
          <Title>
            <h3><strong>Precisa de Ajuda?</strong></h3>
          </Title>
          <SmallCardsContainer>
            {smallCardOrange()}
            {smallCardGreen()}
            {smallCardAccent()}
          </SmallCardsContainer>
          <Title>
            <h3><strong>Informações do Seguro</strong></h3>
          </Title>
          <Accordions>
            <Accordion title="Características do seguro" titleColor="default">
              {insuranceFeaturesCard()}
            </Accordion>
          </Accordions>        
          <Title>
            <h3><strong>Como acionar o Seguro</strong></h3>
          </Title>
          <Accordions>
            <Accordion title="Como proceder em caso de sinistro?" titleColor="default">
              {proceedAccidentCard()}
            </Accordion>
            <Accordion title="Como comunicar alteração na apólice?" titleColor="default">
              {communicatePolicyCard()}
            </Accordion>
          </Accordions>
        </Container>
      </SubscriberDashboard>

      {showAccidentalDeathModal && <AccidentalDeathModal close={() => setShowAccidentalDeathModal(false)}/>}
      {showHospitalBedModal && <HospitalBedModal close={() => setShowHospitalBedModal(false)}/>}
      {showTombModal && <TombModal close={() => setShowTombModal(false)}/>}
      {showCoinModal && <CoinModal close={() => setShowCoinModal(false)}/>}
    </>
  )
}

export default LifeInsurance;
