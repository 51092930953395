import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  ContainerBox,
  ContainerList,
  CustomTable,
  ChipActive,
  ChipCanceled,
  Chip,
  StatusFilterBox,
  FilterBox
} from './styles';

import api from 'service/hrAccountApi';
import { getCompanyId } from 'service/company';
import { formatDate, formatValueToCurrency } from 'utils'

import { useBreadcrumb } from 'hooks/useBreadcrumb'

import Dashboard from 'components/HrAccountDashboard'
import Tabs, { ITabItems } from 'components/Tabs';
import Pagination from 'components/Pagination';
import Tooltip from 'components/Tooltip';

import arrowDown from 'assets/arrow-down-black.svg';
import billIcon from 'assets/icons/text-snippet.svg';
import downloadIcon from 'assets/icons/download-grey.svg';

import { toast } from 'react-toastify';

interface ICompanyBill {
  id: number;
  billCode: string;
  createdAt: string;
  totalValue: string;
  status: string;
  paymentSlipUrl?: string;
  companyInvoiceUrl?: string;
}

const CompanyBills: React.FC = () => {
  const [companyBills, setCompanyBills] = useState<ICompanyBill[]>([]);
  const [orderByName, setOrderByName] = useState<string>('');
  const [orderByDate, setOrderByDate] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFilteredCount, setTotalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(5);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showStatusFilterBox, setShowStatusFilterBox] = useState<boolean>(false);
  const [showDateFilterBox, setShowDateFilterBox] = useState<boolean>(false);
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [tabItems] = useState<ITabItems[]>([{
    id: uuidv4(),
    title: 'Minhas faturas',
    path: '/rh/faturas',
    isActive: true,
    icons: {
      active: billIcon,
      inactive: billIcon
    }
  }]);

  const history = useHistory();

  useEffect(() => {
    setBreadcrumb([...defaultBreadcrumbs, { title: 'Faturas', path: '/rh/faturas' }])
    loadCompanyBills();
  }, [orderByName, orderByDate, statusFilter, currentPage]);

  function loadCompanyBills(): void {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${getCompanyId()}/bills?`
    url += `order_by_date=${orderByDate}&`
    url += `page=${currentPage}&`
    url += `per_page=${perPage}&`
    url += `status=${statusFilter}`;

    api.get(url)
      .then((response) => {
        setLoading(false);
        setMessage('');

        const {
          total_count,
          total_filtered_count,
          total_pages,
          per_page,
          company_bills
        } = response.data;

        setTotalCount(total_count);
        setTotalFilteredCount(total_filtered_count);
        setTotalPages(total_pages);
        setPerPage(per_page);

        const companyBills: ICompanyBill[] = company_bills || [];
        setCompanyBills(companyBills.map((bill) => formatBill(bill)));

        if (!companyBills.length) {
          setMessage('Nenhuma fatura encontrada.');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatBill(bill: any): ICompanyBill {
    return {
      id: bill.id,
      billCode: bill.id_on_protheus,
      createdAt: bill.created_at,
      totalValue: bill.value_with_taxes,
      status: bill.status,
      paymentSlipUrl: bill.payment_slip_url,
      companyInvoiceUrl: bill.company_invoice_url
    }
  }

  function getChipByStatus(status: string) {
    switch (status) {
      case 'paid':
        return <ChipActive><span>PAGA</span></ChipActive>
      case 'canceled':
        return <ChipCanceled><span>CANCELADA</span></ChipCanceled>
      default:
        return <Chip><span>PENDENTE</span></Chip>
    }
  }

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }


  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find((tabItem) => tabItem.id === tabId)
    if (!activeTab) return

    history.push(activeTab.path);
  }

  function handleBillDetails(billId: number) {
    history.push(`/rh/faturas/${billId}`);
  }

  function handleStatusFilterBox() {
    setShowStatusFilterBox(!showStatusFilterBox)
  }

  function handleDateFilterBox() {
    setShowDateFilterBox(!showDateFilterBox)
  }

  function handleOrderByDate(order: string) {
    setOrderByName('')

    if (order === 'ASC') {
      setOrderByDate('ASC')
    } else if (order === 'DESC') {
      setOrderByDate('DESC')
    }
  }

  function handleStatusFilter(status: string) {
    if (status === 'paid') return setStatusFilter('paid')
    if (status === 'pending') return setStatusFilter('pending')
    if (status === 'canceled') return setStatusFilter('canceled')

    return setStatusFilter('')
  }

  function renderStatusFilterBox(showStatusFilterBox: boolean) {
    return (showStatusFilterBox &&
      <StatusFilterBox onMouseOver={() => setShowStatusFilterBox(true)} onMouseLeave={() => setShowStatusFilterBox(false)}>
        <span onClick={() => handleStatusFilter('')}>Todas</span>
        <span onClick={() => handleStatusFilter('paid')}>Pagas</span>
        <span onClick={() => handleStatusFilter('pending')}>Pendentes</span>
        <span onClick={() => handleStatusFilter('canceled')}>Canceladas</span>
      </StatusFilterBox>
    )
  }

  function renderDateFilterBox(showDateFilterBox: boolean) {
    return (showDateFilterBox &&
      <FilterBox onMouseOver={() => setShowDateFilterBox(true)} onMouseLeave={() => setShowDateFilterBox(false)}>
        <span onClick={() => handleOrderByDate('')}>Todas</span>
        <span onClick={() => handleOrderByDate('DESC')}>Mais recentes</span>
        <span onClick={() => handleOrderByDate('ASC')}>Mais antigas</span>
      </FilterBox>
    )
  }

  return (
    <>
      <Dashboard title="Financeiro" backPath="/rh/funcionarios">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          {!companyBills.length && !statusFilter && !loading ?
            <ContainerBox>
              <p>Nenhuma fatura encontrada.</p>
            </ContainerBox>
            :
            <ContainerList>
              <CustomTable>
                <thead>
                  <tr>
                    <th scope="col">CÓDIGO DA FATURA</th>
                    <th scope="col" onClick={handleDateFilterBox} className="filter-box">
                      DATA DE EMISSÃO
                      <img src={arrowDown} alt="Seta para baixo" />
                      {renderDateFilterBox(showDateFilterBox)}
                    </th>
                    <th scope="col">VALOR TOTAL</th>
                    <th scope="col" onClick={handleStatusFilterBox} className="filter-box">
                      STATUS
                      <img src={arrowDown} alt="Seta para baixo" />
                      {renderStatusFilterBox(showStatusFilterBox)}
                    </th>
                    <th scope="col">AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {companyBills.map((bill) => (
                    <tr key={bill.id}>
                      <td onClick={() => handleBillDetails(bill.id)}>{bill.billCode}</td>
                      <td onClick={() => handleBillDetails(bill.id)}>{formatDate(bill.createdAt)}</td>
                      <td onClick={() => handleBillDetails(bill.id)}>{formatValueToCurrency(bill.totalValue)}</td>
                      <td onClick={() => handleBillDetails(bill.id)}>{getChipByStatus(bill.status)} </td>
                      <td>
                        {bill.status === 'pending' && bill.paymentSlipUrl ?
                          <Tooltip position="top center" text="baixar boleto">
                            <a
                              href={bill.paymentSlipUrl}
                              target="_blank">
                              <img src={downloadIcon} alt="baixar boleto em PDF." />
                            </a>
                          </Tooltip>
                          :
                          <span> - </span>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustomTable>
              {(!companyBills.length) && <p>{message}</p>}
              <Pagination
                currentPage={currentPage}
                numOfPages={totalPages}
                totalFilteredCount={totalFilteredCount}
                totalCount={totalCount}
                perPage={perPage}
                lengthPage={companyBills.length}
                onSelectPage={handleSelectPage} />
            </ContainerList>
          }
        </Container>
      </Dashboard>
    </>
  )
};

export default CompanyBills;
