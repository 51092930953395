import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import { selectUrl, TypeURL } from 'utils';

import { HeaderMenu } from './styles';

import logoCartaoAlianca from 'assets/logo-cartao-alianca.svg';

const SiteHeaderMenu: React.FC = () => {
  const history = useHistory();

  return (
    <HeaderMenu>
      <div className="links-site">
        <img src={logoCartaoAlianca} alt="Logo Cartão Aliança" onClick={() => history.push('/')} />
        <ul>
          <li>
            <a href={selectUrl(TypeURL.HOME)}>Início</a>
          </li>
          <li>
            <a href={selectUrl(TypeURL.PARTNERS)}>Rede de parceiros</a>
          </li>
          <li>
            <a href={selectUrl(TypeURL.CONTACT_US)}>Fale conosco</a>
          </li>
          <li>
            <a href={selectUrl(TypeURL.TYPE_FORM)}>Quero ser parceiro</a>
          </li>
          <li>
            <a href={selectUrl(TypeURL.OUR_PRODUCTS)}>Nossos Produtos</a>
          </li>
        </ul>
      </div>
      <div className="links-login">
        <span></span>
        <Link to="/escolher-acesso" className="login">Entrar</Link>
        <a href={selectUrl(TypeURL.CHOOSE_PLAN)} className="card">Quero meu cartão</a>
      </div>
    </HeaderMenu>
  );
}

export default SiteHeaderMenu;
