import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';

import { formatDate, formatPhone } from 'utils';

import subscriberApi from 'service/subscriberApi';

import { BackLink } from './styles';

export interface IUser {
  id: number;
  name: string;
  cpf: string;
  email: string;
  bithdate: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
}

interface IError {
  key: string;
  message: string;
}

interface ISecondStep {
  back: Function;
  user: IUser;
}

const SecondStep: React.FC<ISecondStep> = ({ back, user: userFound }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>(() => userFound);
  const [errors, setErrors] = useState<IError[]>([]);
  const history = useHistory();

  useEffect(() => {
    setUser({
      ...user,
      phone: formatPhone(user?.phone),
      bithdate: formatDate(user?.bithdate)
    })
  }, [userFound]);

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();
      setLoading(true);
      setMessages([]);
      const params = {
        name: user.name,
        cpf: user.cpf,
        email: user.email,
        birth_date: user.bithdate,
        phone: user.phone,
        password: user.password,
        password_confirmation: user.passwordConfirmation,
      }

      await subscriberApi.put(`/users/${user.id}`, params);
      setLoading(false);
      history.push('/assinantes/login');
    } catch (ex: any) {
      handleResponseErrors(ex);
    }
  }

  function handleResponseErrors(ex: any) {
    setLoading(false);
    const response = ex.response;
    if (response && response.data) {
      const error: any = response.data.error;
      const newErrors: any = response.data.errors;

      if (error) {
        setMessages([...messages, response.data.error]);
      } else if (!!newErrors) {
        const newFieldErrors: IError[] = [];
        Object
          .keys(newErrors)
          .forEach((attr: any) => {
            const values: any = newErrors[attr];
            values.forEach((value: any) => {
              newFieldErrors.push({ key: attr, message: value });
            });
          })

        setErrors(newFieldErrors);
      }
    } else {
      setMessages([...messages, 'Serviço indisponível no momento, por favor tente mais tarde.']);
    }
  }

  function addError(msg: string) {
    return (
      <>
        <span>{msg}</span>
        <br />
      </>
    )
  }

  return (
    <>
      <TextInput
        onChange={(value: string) => setUser({ ...user, name: value })}
        value={user.name}
        type="text"
        placeholder="Nome *"
        maxLength={255} />
      {
        errors
          .filter((error) => error.key === 'name')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, cpf: value })}
        value={user.cpf}
        type="text"
        placeholder="CPF *"
        typeMask="cpf"
        maxLength={255}
        disabled />
      {
        errors
          .filter((error) => error.key === 'cpf')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, email: value })}
        value={user.email}
        type="text"
        placeholder="E-mail *"
        maxLength={255} />
      {
        errors
          .filter((error) => error.key === 'email')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, bithdate: value })}
        value={user.bithdate}
        type="text"
        placeholder="Data de nascimento *"
        maxLength={10}
        typeMask="date" />
      {
        errors
          .filter((error) => error.key === 'birth_date')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, phone: value })}
        value={user.phone}
        type="text"
        placeholder="Telefone *"
        typeMask="phone"
        maxLength={15} />
      {
        errors
          .filter((error) => error.key === 'phone')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, password: value })}
        value={user.password}
        type="password"
        placeholder="Senha *"
        maxLength={255} />
      {
        errors
          .filter((error) => error.key === 'password')
          .map(error => addError(error.message))
      }

      <TextInput
        onChange={(value: string) => setUser({ ...user, passwordConfirmation: value })}
        value={user.passwordConfirmation}
        type="password"
        placeholder="Confirmar senha *"
        maxLength={255} />
      {
        errors
          .filter((error) => error.key === 'password_confirmation')
          .map(error => addError(error.message))
      }

      {messages.map(error => addError(error))}

      <PrimaryButton type="submit" onClick={submitForm} loading={loading}>
        Confirmar
      </PrimaryButton>
      <BackLink onClick={() => back()}>Voltar</BackLink>
    </>
  )
}

export default SecondStep;
