import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Link = styled.a`
  color: ${COLORS.accentColor} !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 0.875rem;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;
