import React from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import HeaderMenu from 'components/HrAccountHeaderMenu';

import {
  Container,
  ContainerHeader,
  ContainerBody,
} from './styles';

import social from 'assets/social.svg';
import additionalServices from 'assets/additional-services.svg';
import financial from 'assets/financial.svg';
import help from 'assets/help.svg';

import { getCompany } from 'service/company';

import SideMenu from 'components/SideMenu';

interface IDashboard {
  title?: string;
  backPath?: string;
}

export interface IMenuItem {
  id: string;
  isActive: boolean;
  name: string;
  path: string;
  logo: string;
}

const Dashboard: React.FC<IDashboard> = ({ children, title, backPath }) => {
  const location = useLocation();

  function getMenuItems(): IMenuItem[] {
    const menus: IMenuItem[] = [];

    menus.push({
      id: uuidv4(),
      isActive: checkIfActive(['rhFuncionarios', 'rhImportarFuncionarios']),
      name: 'Funcionários',
      path: '/rh/funcionarios',
      logo: social,
    });

    menus.push({
      id: uuidv4(),
      isActive: checkIfActive(['rhFaturas']),
      name: 'Financeiro',
      path: '/rh/faturas',
      logo: financial,
    });

    if (getCompany()?.hasActivePlugins) {
      menus.push({
        id: uuidv4(),
        isActive: checkIfActive(['rhServicosSeguros']),
        name: 'Serviços adicionais',
        path: '/rh/servicos/seguros',
        logo: additionalServices,
      });
    }

    return menus;
  }

  function getFaqMenuItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['rhFaqs']),
      name: 'FAQs',
      path: '/rh/faqs',
      logo: help
    }
  }

  function checkIfActive(paths: string[]): boolean {
    const pathname = location.pathname.replace(/\//g, '').replace(/-/g, '');

    return paths?.map(path => path.toLowerCase())?.includes(pathname);
  }

  return (
    <Container>
      <SideMenu menuItems={getMenuItems()} helpItem={getFaqMenuItem()} />
      <ContainerHeader>
        <HeaderMenu title={title} backPath={backPath} />
        <ContainerBody>{children}</ContainerBody>
      </ContainerHeader>
    </Container>
  );
};

export default Dashboard;
