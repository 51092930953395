import React from 'react';

import {
  CheckboxContainer,
  HiddenCheckbox,
} from './styles';

interface ICheckbox {
  onChange: Function;
  checked: boolean;
}

const Checkbox: React.FC<ICheckbox> = ({ checked, onChange }) => {
  return (
    <CheckboxContainer
      checked={checked}
      onChange={(e: any) => onChange(e)}>
      <HiddenCheckbox/>
    </CheckboxContainer>
  );
}

export default Checkbox;
