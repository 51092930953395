import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';

import refreshing from 'assets/refreshing.svg';

const ProcessingModal: React.FC = () => {
  return (
    <GenericModal height="13rem">
      <BodyModal>
        <img src={refreshing} alt="Refreshing" />
        <strong>Gerando relátório...</strong>
        <p>Estamos gerando o relatório e logo ele ficará disponível para download.</p>
      </BodyModal>
    </GenericModal>
  );
};

export default ProcessingModal;
