import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const ContainerNav = styled.nav`
  height: 7.5rem;
  width: 100%;
  padding: 1rem 3.75rem 1rem 3.75rem;
  background: ${COLORS.accentColor};
`;

export const ContainerBreadcrumb = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  color: ${COLORS.defaultBg};
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5rem;

  img {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50%;
  align-items: center;

  div {
    align-items: center;
    display: flex;
    flex-direction: row;

    img {
      margin-right: 0.7rem;
      cursor: pointer;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.938rem;
    color: ${COLORS.defaultBg};
  }
`;

export const ContainerHrAccount = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  p {
    color: ${COLORS.defaultBg};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const CirclePhoto = styled.div`
  height: 3rem;
  width: 3rem;
  background-color: ${COLORS.defaultBg};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;

  img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin: 0 !important;
  }
`;

export const SideMenu = styled.nav`
  display: flex;
  position: absolute;
  width: 14rem;
  background-color: ${COLORS.defaultBg};
  top: 2.5rem;
  right: -0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0 rgb(0 0 0 / 10%);

  ul {
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0.6rem;
    border-radius: 0.375rem;

    &:hover {
      background-color: rgba(244, 244, 244, 0.6);
    }

    p {
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 0.938rem;
      color: ${COLORS.offblack};
      background-color: transparent;
      border: 0;
    }

    img {
      width: 1rem;
    }
  }

  li + li {
    border-top: 0.063rem solid ${COLORS.backgroundBase};
  }
`;

export const NotificationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;

  img {
    width: 2rem;
  }
`;

export const Alert = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: white;
  border-radius: 50%;
  font-size: 0.5625rem;
  border: 2px solid ${COLORS.accentColor};
  color: ${COLORS.accentColor};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -0.5rem;
`;

export const Arrow = styled.div`
  position: absolute;
  height: 1rem;
  width: 56%;
  top: -0.6881rem;
  background-color: transparent;

  span {
    position: absolute;
    right: 3.125rem;
    bottom: -0.5rem;
    height: 1rem;
    width: 1rem;
    background-color: ${COLORS.defaultBg};
    transform: rotate(45deg);
  }
`;


