import React, { FormEvent, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

import { getUser } from "service/subscriber";
import api from "service/subscriberApi";

import {
  ActionsBox,
  Container, 
  ContainerForm, 
  ContainerMenu, 
  Form
} from "./styles";

import step1Activate from 'assets/icons/step-1-activate.svg';
import step2Activate from 'assets/icons/step-2-activate.svg';
import step2Inactivate from 'assets/icons/step-2-inactivate.svg';
import step3Activate from 'assets/icons/step-3-activate.svg';
import step3Inactivate from 'assets/icons/step-3-inactivate.svg';
import step4Activate from 'assets/icons/step-4-activate.svg';
import step4Inactivate from 'assets/icons/step-4-inactivate.svg';

import SelectInput from "components/SelectInput";
import TextInput from "components/TextInput";
import PrimaryButton from "components/PrimaryButton";

import { 
  chronicDiseasesOptions, 
  drinkAlcoholOptions, 
  familyChronicDiseasesOptions, 
  foodQualityOptions, 
  genderOptions, 
  healthQualityOptions, 
  lastAppointmentOptions, 
  mentalHealthQualityOptions, 
  physicalActivityOptions, 
  sleepQualityOptions, 
  cigarettesAmountOptions, 
  useMedicationOptions 
} from "../QuizOptions";

import { IMyHealthQuizData } from "..";

interface IMyHealthQuiz {
  myHealthData: IMyHealthQuizData;
  close: Function;
}

const MyHealthQuiz: React.FC<IMyHealthQuiz> = ({ myHealthData, close }) => { 
  const history = useHistory();
  const [myHealthSteps, setMyHealthSteps] = useState(1);
  const [advanceButton, setAdvanceButton] = useState(false);

  const [weight, setWeight] = useState(myHealthData['weight'] || '');
  const [height, setHeight] = useState(myHealthData['height'] || '');
  const [gender, setGender] = useState(myHealthData['gender'] || '');

  const [chronicDiseases, setChronicDiseases] = useState(myHealthData['chronicDiseases'] || '');
  const [othersChronicDiseases, setOthersChronicDiseases] = useState(myHealthData['chronicDiseasesDescription'] || '');
  const [enableOthersChronicDiseases, setEnableOthersChronicDiseases] = useState(false);
  const [familiyChronicDiseases, setFamiliyChronicDiseases] = useState(myHealthData['familyChronicDiseases'] || '');
  const [othersFamilyChronicDiseases, setOthersFamilyChronicDiseases] = useState(myHealthData['familyChronicDiseasesDescription'] || '');
  const [enableOthersFamilyChronicDiseases, setEnableOthersFamilyChronicDiseases] = useState(false);
  const [useMedication, setUseMedication] = useState(myHealthData['useMedication'] || '');
  const [whichMedication, setWhichMedication] = useState(myHealthData['medicationDescription'] || '');
  const [enableWhichMedication, setEnableWhichMedication] = useState(false);

  const [cigarettesAmount, setCigarettesAmount] = useState(myHealthData['cigarettesAmount'] || '');
  const [drinkAlcohol, setDrinkAlcohol] = useState(myHealthData['drinkAlcohol'] || '');
  const [physicalActivity, setPhysicalActivity] = useState(myHealthData['physicalActivity'] || '');

  const [sleepQuality, setSleepQuality] = useState(myHealthData['sleepQuality'] || '');
  const [foodQuality, setFoodQuality] = useState(myHealthData['foodQuality'] || '');
  const [healthQuality, setHealthQuality] = useState(myHealthData['healthQuality'] || '');
  const [mentalHealthQuality, setMentalHealthQuality] = useState(myHealthData['mentalHealthQuality'] || '');
  const [lastAppointment, setLastAppointment] = useState(myHealthData['lastAppointment'] || '');

  useEffect(() => {
    verifyAdvanceButton();
  }, [weight, height, gender])

  useEffect(() => {
    enableFields();
  }, [chronicDiseases, familiyChronicDiseases, useMedication])
  

  function generalDataForm() {
    return(
      <>
        <h4>Peso (ex: 65.2)</h4>
        <TextInput
          onChange={(value: any) => handleFieldsChange('weight', value)}
          value={weight}
          type="text"
          placeholder="Quilos"
          maxLength={6} 
          typeMask="weight" />
        <h4>Altura (ex: 1.70)</h4>
        <TextInput
          onChange={(value: any) => handleFieldsChange('height', value)}
          value={height}
          type="text"
          placeholder="Metros"
          maxLength={4} 
          typeMask="height" />
        <p>Sabemos que ao se cadastrar você marcou seu gênero de acordo com sua identidade de gênero. Mas, nesse momento, precisamos saber qual é o seu sexo biológico 
          (o do seu nascimento). Essa informação é importante para uma análise mais completa da sua saúde.</p>
        <SelectInput
          onChange={(value: string) => handleFieldsChange('gender', value)}
          options={genderOptions}
          value={gender}
          defaultValue="Sexo" />
        <ActionsBox>
          <p onClick={() => close()}>Cancelar</p>
          <PrimaryButton type="submit" active={advanceButton} onClick={() => {setMyHealthSteps(2)}}>
            Avançar
          </PrimaryButton>
        </ActionsBox>
      </>
    );
  }

  function healthHistoryForm() {
    return(
      <>
        <h4>Você tem alguma doença crônica?</h4>
        <SelectInput
          onChange={(value: string) => setChronicDiseases(value)}
          options={chronicDiseasesOptions}
          value={chronicDiseases}
          defaultValue="Selecionar" />

        {enableOthersChronicDiseases && <div>
          <h4>Qual doença crônica?</h4>
          <TextInput
            onChange={(value: any) => setOthersChronicDiseases(value)}
            value={othersChronicDiseases}
            type="text"
            placeholder="Inserir doença"
            maxLength={55}/>
        </div>}

        <h4>Alguém na sua família tem ou teve alguma doença crônica?</h4>
        <SelectInput
          onChange={(value: string) => setFamiliyChronicDiseases(value)}
          options={familyChronicDiseasesOptions}
          value={familiyChronicDiseases}
          defaultValue="Selecionar" />

        {enableOthersFamilyChronicDiseases && <div>
          <h4>Qual doença crônica?</h4>
          <TextInput
            onChange={(value: any) => setOthersFamilyChronicDiseases(value)}
            value={othersFamilyChronicDiseases}
            type="text"
            placeholder="Inserir doença"
            maxLength={55} />   
        </div>}

        <h4>Você utiliza algum medicamento de uso contínuo?</h4>
        <SelectInput
          onChange={(value: string) => setUseMedication(value)}
          options={useMedicationOptions}
          value={useMedication}
          defaultValue="Selecionar" />

        {enableWhichMedication && <div>
          <h4>Qual medicamento?</h4>
          <TextInput
            onChange={(value: any) => setWhichMedication(value)}
            value={whichMedication}
            type="text"
            placeholder="Inserir medicamento(s)"
            maxLength={80} />
        </div>}

        <ActionsBox>
          <p onClick={() => close()}>Cancelar</p>
          <PrimaryButton type="submit" onClick={() => {setMyHealthSteps(3)}}>
            Avançar
          </PrimaryButton>
        </ActionsBox>
      </>
    );
  }

  function habitsForm() {
    return(
      <>
        <h4>Você fuma? Se sim, quantos cigarros por dia?</h4>
        <SelectInput
          onChange={(value: string) => setCigarettesAmount(value)}
          options={cigarettesAmountOptions}
          value={cigarettesAmount}
          defaultValue="Selecionar" />

        <h4>Você consome bebidas alcoólicas? Se sim, quantos dias por semana?</h4>
        <SelectInput
          onChange={(value: string) => setDrinkAlcohol(value)}
          options={drinkAlcoholOptions}
          value={drinkAlcohol}
          defaultValue="Selecionar" />

        <h4>Você pratica alguma atividade física? Se sim, quantos dias por semana?</h4>
        <SelectInput
          onChange={(value: string) => setPhysicalActivity(value)}
          options={physicalActivityOptions}
          value={physicalActivity}
          defaultValue="Selecionar" />

        <ActionsBox>
          <p onClick={() => close()}>Cancelar</p>
          <PrimaryButton type="submit" onClick={() => {setMyHealthSteps(4)}}>
            Avançar
          </PrimaryButton>
        </ActionsBox>
      </>
    );
  }

  function welfareForm() {
    return(
      <>
        <h4>Como tem sido a qualidade do seu sono?</h4>
        <SelectInput
          onChange={(value: string) => setSleepQuality(value)}
          options={sleepQualityOptions}
          value={sleepQuality}
          defaultValue="Selecionar" />

        <h4>Como você avalia a sua alimentação?</h4>
        <SelectInput
          onChange={(value: string) => setFoodQuality(value)}
          options={foodQualityOptions}
          value={foodQuality}
          defaultValue="Selecionar" />

        <h4>Como você avalia o seu estado de saúde e qualidade de vida atual?</h4>
        <SelectInput
          onChange={(value: string) => setHealthQuality(value)}
          options={healthQualityOptions}
          value={healthQuality}
          defaultValue="Selecionar" />

        <h4>Como avalia seu estado de saúde mental?</h4>
        <SelectInput
          onChange={(value: string) => setMentalHealthQuality(value)}
          options={mentalHealthQualityOptions}
          value={mentalHealthQuality}
          defaultValue="Selecionar" />

        <h4>Quando foi a última vez que você se consultou?</h4>
        <SelectInput
          onChange={(value: string) => setLastAppointment(value)}
          options={lastAppointmentOptions}
          value={lastAppointment}
          defaultValue="Selecionar" />

        <ActionsBox>
          <p onClick={() => close()}>Cancelar</p>
          <PrimaryButton type="submit">
            Finalizar
          </PrimaryButton>
        </ActionsBox>
      </>
    );
  }

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();

      const params = {
        weight: weight,
        height: height,
        gender: gender,
        chronic_diseases: chronicDiseases,
        family_chronic_diseases: familiyChronicDiseases,
        chronic_diseases_description: othersChronicDiseases,
        family_chronic_diseases_description: othersFamilyChronicDiseases,
        use_medication: useMedication,
        medication_description: whichMedication,
        cigarettes_amount: cigarettesAmount,
        drink_alcohol: drinkAlcohol,
        physical_activity: physicalActivity,
        sleep_quality: sleepQuality,
        food_quality: foodQuality,
        health_quality: healthQuality,
        mental_health_quality: mentalHealthQuality,
        last_appointment: lastAppointment,
      }

      await api.post(`/users/${getUser().id}/create-user-health-info`, params);
      history.push(`/assinantes/home`);
      toast.success('Dados salvos com sucesso!');
    } catch (ex: any) {
      toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
    }
  }

  function enableFields() {
    if (chronicDiseases === 'others') {
      setEnableOthersChronicDiseases(true);
    } else {
      setEnableOthersChronicDiseases(false);
    }

    if (familiyChronicDiseases === 'others_family') {
      setEnableOthersFamilyChronicDiseases(true);
    } else {
      setEnableOthersFamilyChronicDiseases(false);
    }

    if (useMedication === 'true') {
      setEnableWhichMedication(true);
    } else {
      setEnableWhichMedication(false);
    }
  }
  
  function handleFieldsChange(field: string, value: string) {
    switch (field) {
      case 'weight':
        return setWeightAndAdvanceButton(value)
      case 'height':
        return setHeightAndAdvanceButton(value)
      case 'gender':
        return setGenderAndAdvanceButton(value)
      default:
        return ''
    }    
  }

  function setWeightAndAdvanceButton(value: string) {
    setWeight(value);
    verifyAdvanceButton();
  }

  function setHeightAndAdvanceButton(value: string) {
    setHeight(value);
    verifyAdvanceButton();
  }

  function setGenderAndAdvanceButton(value: string) {
    setGender(value);
    verifyAdvanceButton();
  }

  function verifyAdvanceButton() {    
    if (weight && height && gender) {
      setAdvanceButton(true);
    }
    else {
      setAdvanceButton(false);
    }
  }
  
  return (
    <Container>
      <ContainerMenu>
        <ul>
          <li
            className='active'>
            <img src={step1Activate} alt="Step 1" />
            Dados Gerais
          </li>
          <li
            className={myHealthSteps >= 2 ? 'active' : 'inactive'}>
            <img src={myHealthSteps >= 2 ? step2Activate : step2Inactivate} alt="Step 2" />
            Histórico de Saúde
          </li>
          <li
            className={myHealthSteps >= 3 ? 'active' : 'inactive'}>
            <img src={myHealthSteps >= 3 ? step3Activate : step3Inactivate} alt="Step 3" />
            Hábitos
          </li>
          <li
            className={myHealthSteps >= 4 ? 'active' : 'inactive'}>
            <img src={myHealthSteps >= 4 ? step4Activate : step4Inactivate} alt="Step 4" />
            Seu bem-estar
          </li>
        </ul>
      </ContainerMenu>
      <ContainerForm>
        <Form onSubmit={submitForm}>
          {myHealthSteps === 1 && generalDataForm()}
          {myHealthSteps === 2 && healthHistoryForm()}
          {myHealthSteps === 3 && habitsForm()}
          {myHealthSteps === 4 && welfareForm()}
        </Form>
      </ContainerForm>
    </Container>      
  )
}

export default MyHealthQuiz;
