import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';
import SelectInput, { IOption } from 'components/SelectInput';

import { formatPhone, formatDate, validBirthDate, capitalizeFirstLetter } from 'utils';

import api from 'service/subscriberApi';

import { Form, ActionsBox, ContainerLoading, DocumentInputs } from './styles';

interface IError {
  key: string;
  message: string;
}
interface IFormPersonalData {
  userId: string;
}

const FormPersonalData: React.FC<IFormPersonalData> = ({ userId }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [messages, setMessages] = useState<string[]>([]);

  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [rg, setRg] = useState('');
  const [issuingAgency, setIssuingAgency] = useState('');
  const [issuingAgencies, setIssuingAgencies] = useState<IOption[]>([]);

  const [invalidBirthDate, setInvalidBirthDate] = useState(true);

  const genderOptions: IOption[] = [
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
  ];

  useEffect(() => {
    getIssuingAgencies();     
  },[])

  useEffect(() => {
    verifyBirthDate();     
  },[birthDate])

  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    api.get(`/users/dependants/${userId}`)
      .then((response) => {
        setLoading(false);
        setMessages([]);

        const { dependant } = response.data;
        setName(dependant.name);
        setCpf(dependant.cpf);
        setEmail(dependant.email);
        setPhone(formatPhone(dependant.phone));
        setBirthDate(formatDate(dependant.birth_date));
        setGender(dependant.gender || '');
      }).catch((_error) => {
        setLoading(false);
      });
  }, [userId]);

  async function getIssuingAgencies() {
    try {
      const response = await api.get('/issuing_agencies');
      let issuingAgencies  = response.data.issuing_agencies;
      issuingAgencies = issuingAgencies.map((issuingAgency: any) => formatIssuingAgency(issuingAgency));
      setIssuingAgencies(issuingAgencies);
    } catch (ex: any) {
      console.log(ex);
    }
  }

  function formatIssuingAgency(issuingAgency: any) {
    return { 
      value: `${issuingAgency.sigla} - ${issuingAgency.extenso}`, 
      label: `${issuingAgency.sigla} - ${issuingAgency.extenso}` 
    }
  }

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();
      setSaving(true);
      setMessages([]);
      setErrors([]);

      const params = {
        name: name,
        document,
        cpf: cpf,
        rg: rg,
        issuing_agency: issuingAgency,
        email: email,
        birth_date: birthDate,
        phone: phone,
        gender: gender
      }

      let response = null;

      if (!!userId) {
        response = await api.put(`/users/dependants/${userId}`, params);
      } else {
        response = await api.post(`/users/dependants`, params);
      }

      const { dependant } = response.data;
      history.push(`/assinantes/dependente/novo?step=address&user_id=${dependant.id}`);
      toast.success('Dados salvos com sucesso!');
    } catch (ex: any) {
      handleResponseErrors(ex);
    }
  }

  function handleResponseErrors(ex: any) {
    setSaving(false);
    toast.error('Não foi possível atualizar o cadastro!');
    const response = ex.response;
    if (response && response.data) {
      const error: any = response.data.error;
      const newErrors: any = response.data.errors;

      if (error) {
        setMessages([...messages, response.data.error]);
      } else if (!!newErrors) {
        const newFieldErrors: IError[] = [];
        Object
          .keys(newErrors)
          .forEach((attr: any) => {
            const values: any = newErrors[attr];
            values.forEach((value: any) => {
              newFieldErrors.push({ key: attr, message: capitalizeFirstLetter(value) });
            });
          })

        setErrors(newFieldErrors);
      }
    } else {
      setMessages([...messages, 'Serviço indisponível no momento, por favor tente mais tarde.']);
    }
  }

  function formatMessage({ message }: IError): string {
    return message?.trim().replace('.', '');
  }

  function choseDocument(document: string) {
    if (document === 'rg') {
      setCpf('')
    } else {
      setRg('')
      setIssuingAgency('')
    }

    return setDocument(document);
  }

  function verifyBirthDate() {
    !validBirthDate(birthDate) ? setInvalidBirthDate(true) : setInvalidBirthDate(false)
  }

  if (loading) return <ContainerLoading>Carregando...</ContainerLoading>;

  return (
    <Form onSubmit={submitForm}>
      <TextInput
        onChange={(value: any) => setName(value)}
        value={name}
        type="text"
        placeholder="Nome *"
        maxLength={255} />
      <span>
        {errors.filter((error) => error.key === 'name').map(formatMessage).join(', ')}
      </span>

      <SelectInput
        onChange={(value: string) => choseDocument(value)}
        options={[{ value: 'cpf', label: 'CPF (dependente)' }, { value: 'rg', label: 'RG (dependente)' }]}
        value={document}
        defaultValue="Selecione um documento..." />
      <span>
        {errors.filter((error) => error.key === 'document').map(formatMessage).join(', ')}
      </span>

      {document === 'cpf' &&
        <>
          <TextInput
            onChange={(value: any) => setCpf(value)}
            value={cpf}
            type="text"
            placeholder="CPF"
            maxLength={14}
            typeMask="cpf" />
          <span>
            {errors.filter((error) => error.key === 'cpf').map(formatMessage).join(', ')}
          </span>
        </>
      }

      {document === 'rg' &&
        <>
          <DocumentInputs>
            <div className='rg-input'>
              <TextInput
                onChange={(value: any) => setRg(value)}
                value={rg}
                type="text"
                placeholder="RG"
                maxLength={14} />
              <span>
                {errors.filter((error) => error.key === 'rg').map(formatMessage).join(', ')}
              </span>
            </div>

            <div className='issuing-agency-select'>
            
            <SelectInput
              onChange={(value: string) => setIssuingAgency(value)}
              options={issuingAgencies}
              value={issuingAgency}
              defaultValue="Selecione um Órgão Emissor..." />
            <span>
              {errors.filter((error) => error.key === 'issuing_agency').map(formatMessage).join(', ')}
            </span>
            </div>
          </DocumentInputs>
          <span></span>
       </>
      }
     
      <TextInput
        onChange={(value: any) => setBirthDate(value)}
        value={birthDate}
        type="text"
        placeholder="Data de nascimento *"
        typeMask="date" />
      <span>
        {(invalidBirthDate && birthDate.length) ? 'Data inválida' : 
            errors.filter((error) => error.key === 'birth_date').map(formatMessage).join(', ') }
      </span>

      <SelectInput
        onChange={(value: string) => setGender(value)}
        options={genderOptions}
        value={gender}
        defaultValue="Selecione um gênero..." />
      <span></span>

      <TextInput
        onChange={(value: any) => setPhone(value)}
        value={phone}
        type="text"
        placeholder="Telefone"
        typeMask="phone"
        maxLength={15} />
      <span>
        {
          errors
            .filter((error) => error.key === 'phone')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <TextInput
        onChange={(value: any) => setEmail(value)}
        value={email}
        type="text"
        placeholder="E-mail"
        maxLength={255} />
      <span>
        {
          errors
            .filter((error) => error.key === 'email')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      {messages.map(msg => formatMessage({ key: '', message: msg }))
        .join(', ')}
      <ActionsBox>
        <p onClick={() => history.push('/assinantes/dependentes')}>Cancelar</p>
        <PrimaryButton type="submit" loading={saving} active={!invalidBirthDate}>
          Avançar
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
}

export default FormPersonalData;
