import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { IBreadcrumb, useBreadcrumb } from 'hooks/useBreadcrumb'

import { ContainerBreadcrumb } from './styles';
import arrowRight from 'assets/arrow-right.svg';

const Breadcrumb: React.FC = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumb[]>([])
  const { breadcrumb } = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    setBreadcrumbItems(breadcrumb)
  }, [breadcrumb])

  return (
      <ContainerBreadcrumb >
        {breadcrumbItems?.map((item, index)  => (
          <React.Fragment key={index}>
            <div onClick={() => item.path && history.push(item.path)}>
              <p>{item.title}</p>
              {(index < breadcrumbItems.length - 1) && <img src={arrowRight} alt="Seta para direita"/>}
            </div>
          </React.Fragment>
        ))}
      </ContainerBreadcrumb >
  )
};

export default Breadcrumb;
