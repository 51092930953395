import React from 'react';

import { BreadcrumbProvider } from './useBreadcrumb';
import { ConfirmationModalProvider } from './useConfirmationModal';
import { ItemsMenuProvider } from './useItemsMenuSubscriber';

export const HooksProvider: React.FC = ({ children }) => {
  return (
    <ConfirmationModalProvider>
      <ItemsMenuProvider>
        <BreadcrumbProvider>
          {children}
        </BreadcrumbProvider>
      </ItemsMenuProvider>
    </ConfirmationModalProvider>
  )
};

export default HooksProvider
