import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;
`;

export const ContainerMenu = styled.div`
  width: 100%;
  max-width: 18.75rem;

  ul {
    list-style-type: none;
    max-width: 13rem;
  }

  li {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 21px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: default;
    display: flex;

    img {
      margin-right: 0.5rem;
    }
  }

  li + li {
    border-top: 1px solid ${COLORS.borderBase};
  }

  .active {
    color: ${COLORS.accentColor};
    line-height: 21px;
    font-weight: 600;
  }

  .inactive {
    color: ${COLORS.lightGrey};
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  min-height: 45rem;

  p, h4 {
    margin: 0.8rem 0rem 0.8rem 0.5rem;
    font-weight: 600;
  }

  .inactive {
    color: ${COLORS.lightGrey};
  }
`;

export const ActionsBox = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rem;

  p {
    cursor: pointer;
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }

  button {
    max-width: 10rem;
  }
`;