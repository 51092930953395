import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 28.125rem;
  min-height: 45rem;
  padding: 2rem;

  div + div {
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-decoration-line: underline;
    color: ${COLORS.accentColor};
    margin-top: 1rem;
    cursor: pointer;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;

    &.error-message {
      display: block;
      color: ${COLORS.red};
      margin-bottom: 0.9rem;
    }
  }
`;

export const ActionsBox = styled.section`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 10rem;
  }
`;
