import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import SubscriberDashboard from 'components/SubscriberDashboard';
import Tabs, { ITabItems } from 'components/Tabs';
import Badge from 'components/Badge';
import Alert from 'components/Alert';
import RenewalModal from 'components/RenewalModal';

import { formatDate, getSubscriberPersonalDataTabs, SubscriberPersonalDataTabsEnum, translateStatus } from 'utils';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

import api from 'service/subscriberApi';
import { getUser } from 'service/subscriber';

import benefit1 from 'assets/logo-benefit-1.svg';
import benefit2 from 'assets/logo-benefit-2.svg';
import benefit3 from 'assets/logo-benefit-3.svg';
import benefit4 from 'assets/logo-benefit-4.svg';
import benefit5 from 'assets/logo-benefit-5.svg';
import clipboard from 'assets/icons/clipboard.svg';
import invoices from 'assets/icons/invoices.svg';

import { Container, Loading } from './styles';

import { toast } from 'react-toastify';

interface ISubscription {
  status: string;
  initialDate: string;
  planType: string;
  subsctiptionType: string;
  isBusiness: boolean;
  isPlus: boolean;
}

const MyPlan: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const { showUserAlert } = useItemsMenuSubscriber();
  const [tabItems] = useState<ITabItems[]>(getSubscriberPersonalDataTabs(SubscriberPersonalDataTabsEnum.MY_PLAN));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [subscription, setSubscription] = useState<ISubscription>();
  const [showRenewalModal, setShowRenewalModal] = useState<boolean>(true);
  const [openRenewalModal, setOpenRenewalModal] = useState<boolean>(false);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Meu plano', path: `/assinantes/meu-plano` }
    ]);

    loadUser();
  }, [openRenewalModal]);

  function loadUser() {
    setLoading(true);
    setMessage('Carregando...');

    api.get(`/users/find-user`)
      .then((response) => {
        const user = response.data.user;
        const { subscription } = user;
        const showExpirationCard = (user.close_to_expiration && subscription.legacy_plan_type) ? true : subscription.status === 'expired';

        setLoading(false);
        setMessage('');
        setSubscription(formatSubscription(user));
        setShowRenewalModal(showExpirationCard);
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatSubscription(user: any): ISubscription {
    const status = user.subscription.status;
    const purchaseTimestamp = user.subscription.purchase_timestamp;
    const initialDate = formatDate(purchaseTimestamp);
    const isBusiness = !!user.company
    const planType = user.plan_type;
    const isPlus = user.plus;
    const subsctiptionType = isPlus ? 'Cartão Aliança Saúde +' : 'Cartão Aliança Saúde';

    return { status, initialDate, subsctiptionType, planType, isBusiness, isPlus };
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function showContract() {
    const url = subscription?.isPlus ? '/users/download-contract?plus=true' : '/users/download-contract';

    api.get(url)
      .then((response) => {
        const a = document.createElement('a');
        a.href = response.data.url;
        a.target = 'blank';
        a.click();
      });
  }

  function sectionAlert() {
    if (!showUserAlert) return;

    let title = '';

    if (getUser().isPlus)
      title = 'Atualize suas informações pessoais e endereço para ganhar um voucher de desconto';
    else
      title = 'É importante manter atualizadas suas informações pessoais e de endereço';

    return (
      <section>
        <Alert title="Atualização cadastral" type="info">
          <p>{title}</p>
          <Link to="/assinantes/dados-pessoais?step=personal-data">Para atualizar, clique aqui</Link>
        </Alert>
      </section>
    )
  }

  function planBadgeByStatus(status:any) {
    if (status === 'active' || status === 'future') {
      return 'success'
    } else {
      return 'error'
    }
  }

  function containerBenefits() {
    return (
      <section>
        <h1>Benefícios</h1>
        <div className="cards">
          <div className="benefit-card">
            <img src={benefit1} alt="Carência" />
            <br />
            <strong>O cartão aliança não tem carência</strong>
          </div>

          <div className="benefit-card">
            <img src={benefit2} alt="Descontos" />
            <br />
            <strong>Exames com até 50% de Desconto</strong>
          </div>

          <div className="benefit-card">
            <img src={benefit3} alt="Consultas" />
            <br />
            <strong>Consultas de até R$120,00</strong>
          </div>

          <div className="benefit-card">
            <img src={benefit4} alt="Medicamentos" />
            <br />
            <strong>Medicamentos com até 75% de Desconto</strong>
          </div>

          <div className="benefit-card">
            <img src={benefit5} alt="Consultas online" />
            <br />
            <strong>Consultas presenciais ou online</strong>
          </div>
        </div>
      </section>
    );
  }

  function containerInfoPlan() {
    return (
      <section>
        <h1>Informações do plano</h1>
        <div className="card">
          <div className="d-flex">
            <p><span>Tipo de assinatura:</span> {subscription?.subsctiptionType}</p>
            {showRenewalModal &&
              <p className="link ml-auto" onClick={() => setOpenRenewalModal(true)}>renovar plano</p>}
          </div>
          <p><span>Tipo de plano:</span> {subscription?.planType}</p>
          <p><span>Início do contrato:</span> {subscription?.initialDate}</p>
          <p>
            <span>Status:</span>{' '}
            <Badge type={planBadgeByStatus(subscription?.status)}>
              {translateStatus(subscription?.status)}
            </Badge>
          </p>
        </div>
      </section>
    )
  }

  function containerTerm() {
    if (subscription?.isBusiness) return;

    return (
      <section>
        <h1>Termo de uso</h1>
        <div className="card contract-box">
          <div className="contract-title">
            <img src={clipboard} alt="Carência" />
            <p>Contrato Cartão Aliança Saúde</p>
          </div>
          <p className="link" onClick={showContract}>ver contrato</p>
        </div>
      </section>
    );
  }

  function containerInvoices() {
    return (
      <section>
        <h1>Notas fiscais</h1>
        <div className="card contract-box">
          <div className="contract-title">
            <img src={invoices} alt="Carência" />
            <p>Encontre suas notas fiscais</p>
          </div>
          <p className="link" onClick={() => history.push('/assinantes/notas-fiscais')}>ver notas</p>
        </div>
      </section>
    );
  }

  return (
    <>
      <SubscriberDashboard title="Dados Pessoais">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          {message && <Loading>{message}</Loading>}
          {
            !loading &&
            <>
              {sectionAlert()}
              {containerInfoPlan()}
              {containerTerm()}
              {containerInvoices()}
              {containerBenefits()}
            </>
          }
        </Container>
      </SubscriberDashboard>
      {openRenewalModal && <RenewalModal closeModal={setOpenRenewalModal} /> }
    </>
  )
}

export default MyPlan;
