import styled from 'styled-components';
import { COLORS } from 'styles/constants';
import { Popup } from "semantic-ui-react";

export const TooltipContainer = styled(Popup)`
  color: white !important;
  background: ${COLORS.darkBlue} !important;

  &:before {
    background: ${COLORS.darkBlue} !important;
  }
`;
