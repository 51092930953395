import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContainerList = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 0rem 3.75rem;

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
    text-align: center;
  }

  @media(${SIZES.smallerThanTablet}) {
    padding: 2rem 2rem 0rem 2rem;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  width: 100%;

  input {
    flex: 1;
    min-width: 30rem;
  }

  button {
    margin-left:1rem;
  }

  @media(${SIZES.smallerThanTablet}) {
    flex-wrap: wrap;

    input {
      width: 100%;
    }

    button {
      margin-top: 1rem;
      margin-left: 1rem;
      width: 70%;
    }

    button + button {
      width: 20%;
    }
  }
`;

export const CustomTable = styled.table`
  overflow-x: auto;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  thead {
    th {
      border-bottom: none;
      border-top: none;
      color: ${COLORS.offblack};
      font-size: 0.9rem;
      font-weight: 400;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: inherit;
      height: 3rem;

      img {
        margin-left: 0.5rem;
        width: 0.7rem;
        cursor: pointer;
      }

      &.filter-box {
        cursor: pointer;
      }

      &:first-child {
        display: flex;
        padding-left: 1.75rem;

        img {
          height: 0.8rem;
          width: 0.8rem;
          cursor: pointer;
        }
      }

      &:last-child {
        padding-right: 1.75rem;
      }
    }

    @media(${SIZES.smallerThanTablet}) {
      .th-dependants {
        display: none;
      }
    }
  }

  tbody {
    tr {
      td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${COLORS.offblack};
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-top: 1px solid ${COLORS.backgroundBase};
        border-bottom: 1px solid ${COLORS.backgroundBase};

        &:first-child {
          padding-left: 1.75rem;
          border-left: 1px solid ${COLORS.backgroundBase};
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }

        &:last-child {
          padding-right: 1.75rem;
          border-right: 1px solid ${COLORS.backgroundBase};
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          width: 6rem;
        }

        img + img {
          margin-left: 0.75rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(244, 244, 244, 0.6);
      }


      @media(${SIZES.smallerThanTablet}) {
        .td-dependants {
          display: none;
        }
      }
    }
  }
`;

export const Chip = styled.div`
  display: flex;
  place-content: center;
  background-color: ${COLORS.mediumGrey};
  width: fit-content;
  height: 1.2rem;
  padding: 0.4rem;
  border-radius: 2rem;
  align-items: center;

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipActive = styled(Chip)`
  background-color: ${COLORS.success};

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipCanceled = styled(Chip)`
  background-color:  ${COLORS.red};

  span {
    color: white;
    text-align: center;
  }
`;

export const FilterBox = styled.div`
  background-color: ${COLORS.white};
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 6rem;
  margin-top: 0.5rem;
  padding: 1rem;
  position: absolute;
  place-content: center;
  width: 10rem;

  span {
    color: ${COLORS.mediumGrey};
    margin: 0.2rem 0;
  }
`;

export const StatusFilterBox = styled(FilterBox)`
  height: 8rem;
  width: 7rem;
  z-index: 100;
`;
