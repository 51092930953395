import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

import creditCardActive from 'assets/icons/credit-card-active.svg';
import creditCard from 'assets/icons/credit-card.svg';
import personActive from 'assets/person-active.svg';
import person from 'assets/person-inactive.svg';
import dolarActive from 'assets/icons/dolar-active.svg';
import dolar from 'assets/icons/dolar.svg';
import americanExpress from 'assets/icons/checkout/amex.svg';
import elo from 'assets/icons/checkout/elo.svg';
import hipercard from 'assets/icons/checkout/hipercard.svg';
import mastercard from 'assets/icons/checkout/mastercard.svg';
import visa from 'assets/icons/checkout/visa.svg';
import smallLogo from 'assets/small-logo.svg';

export enum TypeURL {
  HOME,
  PARTNERS,
  CONTACT_US,
  CONEXA,
  TYPE_FORM,
  CHOOSE_PLAN,
  TEMPOASSIST,
  OUR_PRODUCTS
}

export interface IStates {
  initials: string;
  name: string;
}

export function getRootPath(): string {
  const { REACT_APP_ENV } = process.env;
  if (REACT_APP_ENV === 'production') {
    return 'https://cartaoalianca.com.br';
  } else if (REACT_APP_ENV === 'staging') {
    return 'https://staging.cartaoalianca.com.br';
  } else {
    return 'http://localhost:3000';
  }
}

export interface ICheckMetlifeStatus {
  metlifeInsuredAt: any;
  metlifeErrorResponse: any;
  metlifeCanceledAt: any;
}

export function validCpf(strCPF: string): boolean {
  if (!strCPF) return false;

  strCPF = strCPF.replace('.', '').replace('.', '').replace('-', '');

  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function validEmail(email: string) {
  const emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
  return emailPattern.test(email);
}

export function validBirthDate(strDate: string): boolean {
  if (!strDate || strDate.length < 10){
    return false
  } else {
    return true
  }
}

export function formatPhone(value: string): string {
  const digits = 11;
  if (!value) return value

  value = value.replace(/[^0-9]+/g, "");
  let ddd = `(${value.substr(0, 2)})`
  let part1 = ''
  let part2 = ''

  if (value.length === digits) {
    part1 = value.substr(2, 5)
    part2 = value.substr(7, 9)
  } else {
    part1 = value.substr(2, 4)
    part2 = value.substr(6, 8)
  }

  return value = `${ddd} ${part1}-${part2}`;
}

export function formatDate(dateStr: string): string {
  if (!dateStr) return dateStr;

  const [part1] = dateStr.split("T");
  const date = part1.split("-").reverse().join("/");

  return `${date}`;
}

export function formatDateTime(dateStr: string) {
  if (!dateStr) return dateStr;

  const [part1, part2] = dateStr.split("T");
  const date = part1.split("-").reverse().join("/");
  const time = part2.substring(0, 5);

  return `${date} ${time}`;
}

export function formatTime(dateStr: string) {
  if (!dateStr) return dateStr;

  const [, part2] = dateStr.split("T");
  const time = part2.substring(0, 5);

  return `${time}`;
}

export function limitStr(str: string, length: number, addEllipsis = true): string {
  if (!str) return str;

  let reducedStr = str.substring(0, length);

  if (str.length > length && addEllipsis) {
    reducedStr += "...";
  }

  return reducedStr;
}

export function selectUrl(typeURL: TypeURL): string {
  let url = '';

  switch (typeURL) {
    case TypeURL.HOME:
      url = getRootPath();
      break;
    case TypeURL.PARTNERS:
      url = `${getRootPath()}/rede-de-parceiros`;
      break;
    case TypeURL.CONTACT_US:
      url = `${getRootPath()}/fale-conosco`;
      break;
    case TypeURL.CONEXA:
      url = 'https://paciente.conexasaude.com.br/login/cartao_alianca';
      break;
    case TypeURL.TYPE_FORM:
      url = 'https://parceiro-alianca.typeform.com/to/pIvSEU';
      break;
    case TypeURL.CHOOSE_PLAN:
      url = `${getRootPath()}#planInfo`;
      break;
    case TypeURL.TEMPOASSIST:
      url = 'https://www.tempoassist.com.br/';
      break;
    case TypeURL.OUR_PRODUCTS:
      url = `${getRootPath()}/nossos-produtos`;
      break;
  }

  return url;
}

export function getMetlifeStatus(checkMetlifeStatus: ICheckMetlifeStatus) {
  if (checkMetlifeStatus.metlifeInsuredAt && !checkMetlifeStatus.metlifeErrorResponse) return 'Aceito'
  if (checkMetlifeStatus.metlifeErrorResponse && !checkMetlifeStatus.metlifeInsuredAt) return 'Recusado'
  if (checkMetlifeStatus.metlifeCanceledAt) return 'Cancelado'

  return 'Pendente'
}

export function formatValueToCurrency(value: string): string {
  if (!value) return ''

  return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function pluralizeText(size: number, pluralText: string, singularText: string): string {
  if (size !== 1) {
    return pluralText
  }

  return singularText
}

export function getNameInitials(name: string): string {
  if (!name) return '';

  const parts = name.trim().toUpperCase().split(' ');
  if (parts.length > 1) {
    const [firstPart, secondPart] = parts;
    return `${firstPart[0]}${secondPart[0]}`;
  } else {
    const [firstName] = parts;
    return `${firstName[0]}`;
  }
}

export function setToogleMenu(isOpen: boolean): void {
  localStorage.setItem('@toggle_menu', isOpen ? 'open' : 'close');
}

export function getToogleMenu(): boolean {
  const toggleMenu = localStorage.getItem('@toggle_menu');

  return toggleMenu === 'open';
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<any>();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function getDocumentTitle(location: any) {
  if (location?.pathname?.includes('assinante')) {
    document.title = 'Cartão Aliança - Área do Assinante'
  } else if (location?.pathname?.includes('rh')) {
    document.title = 'Cartão Aliança - Área do RH'
  } else {
    document.title = 'Cartão Aliança Saúde'
  }
}

export function changeStateSideMenu(width: number): boolean {
  return width < 1050 ? false : true
}

export enum SubscriberPersonalDataTabsEnum {
  MY_PLAN,
  PERSONAL_DATA,
  PAYMENT_DATA,
}

export function getSubscriberPersonalDataTabs(tab: SubscriberPersonalDataTabsEnum) {
  return [
    {
      id: uuidv4(),
      title: 'Meu plano',
      path: '/assinantes/meu-plano',
      isActive: SubscriberPersonalDataTabsEnum.MY_PLAN === tab,
      icons: {
        active: creditCardActive,
        inactive: creditCard,
      },
    },
    {
      id: uuidv4(),
      title: 'Meus dados',
      path: '/assinantes/dados-pessoais?step=personal-data',
      isActive: SubscriberPersonalDataTabsEnum.PERSONAL_DATA === tab,
      icons: {
        active: personActive,
        inactive: person,
      },
    },
    {
      id: uuidv4(),
      title: 'Meio de pagamento',
      path: '/assinantes/dados-de-pagamento',
      isActive: SubscriberPersonalDataTabsEnum.PAYMENT_DATA === tab,
      icons: {
        active: dolarActive,
        inactive: dolar,
      },
    },
  ]
}

export function getCardFlag(cardnumber: string): string {
  cardnumber = cardnumber.replace(/[^0-9]+/g, '');

  const cards: any = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
    diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    american_express: /^3[47][0-9]{13}/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/
  };

  for (let flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return '';
}

export function validUserData(user: any): boolean {
  let isValid = true;
  if (!user.name) {
    isValid = false;
  } else if (!user.phone) {
    isValid = false;
  } else if (!user.birth_date) {
    isValid = false;
  } else if (!user.cpf) {
    isValid = false;
  } else if (!user.email) {
    isValid = false;
  } else if (!user.gender) {
    isValid = false;
  }
  return isValid;
}

export function validDependantsData(dependants: any[]): boolean {
  return !dependants.filter((dep: any) => !validUserData(dep)).length;
}

export function translateStatus(status: any): string {
  switch (status) {
    case 'pending_checkout':
      return 'Pendente'
    case 'active':
      return 'Ativo'
    case 'future':
      return 'Em renovação'
    case 'canceled':
      return 'Cancelado'
    case 'expired':
      return 'Expirado'
    case 'pending_on_protheus':
      return 'Integração pendente com Protheus'
    case 'on_canceling':
      return 'Em cancelamento'
    default:
      return ''
  }
}

export function chooseFlagImg(flagName: string | undefined) {
  switch (flagName) {
    case 'mastercard':
      return <img src={ mastercard } alt = "Logo da bandeira do cartão" />

      case 'visa':
      return <img src={ visa } alt = "Logo da bandeira do cartão" />

      case 'american_express':
      return <img src={ americanExpress } alt = "Logo da bandeira do cartão" />

      case 'hipercard':
      return <img src={ hipercard } alt = "Logo da bandeira do cartão" />

      case 'elo':
      return <img src={ elo } alt = "Logo da bandeira do cartão" />

      default:
      return '';
  }
}

export function addDefaultLogoSrc(ev: any){
  ev.target.src = smallLogo
}

export function verifyIfHasImage(img: string) {
  if (img === null) {
    return smallLogo;
  } else {
    return img;
  }
}

export function capitalizeFirstLetter(str: String) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
