import styled from 'styled-components';

import { COLORS } from 'styles/constants';

interface IContainerButton {
  height?: string;
  width?: string;
  icon?: string;
  text?: boolean;
  active?: boolean;
}

export const ContainerButton = styled.button<IContainerButton>`
  align-items: center;
  background: ${COLORS.defaultBg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.active ? COLORS.accentColor : COLORS.lightGrey};
  color: ${(props) => props.active ? COLORS.accentColor : COLORS.lightGrey};
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  justify-content: center;
  height: ${props => props.height};
  line-height: 1.5rem;
  width: ${props => props.width};

  img {
    margin-right: ${props => props.icon && !props.text ? '' : '0.5rem'};
  }

  &:hover {
    opacity: 0.9;
    background: ${COLORS.backgroundBase};
  }
`;

ContainerButton.defaultProps = {
  height: '3rem',
  width: '100%',
}
