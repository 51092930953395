import React from 'react';

import {
  ContainerTabs,
  ContainerCenter,
  ContainerTab
} from './styles';

interface IIcon {
  active: string;
  inactive: string;
}

export interface ITabItems {
  id: string;
  title: string;
  icons: IIcon;
  isActive: boolean;
  path: string;
  key?: any;
}

interface ITab {
  tabItems: ITabItems[];
  onSelectTab: Function;
}

const Tabs: React.FC<ITab> = ({ tabItems, onSelectTab }) => {
  function handleChangeTab(tabId: string) {
    tabItems.map((item) => {
      if (item.id === tabId) return { ...item, isActive: true }

      return item
    })
  }

  return (
    <ContainerTabs>
      <ContainerCenter>
        {tabItems.map((item => (
          <ContainerTab key={item.id} active={item.isActive} onClick={() => onSelectTab(item.id)}>
            <img
              src={item.isActive ? item.icons.active : item.icons.inactive}
              onClick={() => handleChangeTab(item.id)}
              alt={item.title} />
            {item.title}
          </ContainerTab>
        )))}
      </ContainerCenter>
    </ContainerTabs>
  )
};

export default Tabs;
