import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 2rem;
  }
`;

export const ContainerBox = styled.div`
  border-radius: 6px;
  width: 30rem;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  button {
    align-self: flex-end;
    margin-top: 2.5rem;
    width: 8.5rem;
  }
`;

export const StatusSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const DetailsBox = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.borderBase};
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  span + span {
    margin-top: 0.7rem;
  }
`;

export const InvoiceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.8rem;

  a {
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }
`;

export const Switch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    align-items: center;
    margin-top: 0;
  }

  span {
    font-size: 1rem;
    color: ${COLORS.offblack};
  }

  img {
    margin-left: 0.5rem;
  }
`;

export const Separator = styled.hr`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  border: solid 0.01rem ${COLORS.borderBase};
`;

export const Chip = styled.div`
  display: flex;
  place-content: center;
  background-color: ${COLORS.mediumGrey};
  width: fit-content;
  height: 1.2rem;
  padding: 0.4rem;
  border-radius: 2rem;
  align-items: center;

  span {
    color: white;
    text-align: center;
    font-size: 0.7rem;
  }
`;

export const ChipCanceled = styled(Chip)`
  background-color:  ${COLORS.red};

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipActive = styled(Chip)`
  background-color: ${COLORS.success};

  span {
    color: white;
    text-align: center;
  }
`;
