import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  padding: 2rem 6rem;
  justify-content: center;

  h2 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  @media (${SIZES.largerThanDesktopuhd}) {
    padding: 2rem 10%;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 0;
  justify-content: space-between;

  div {
    flex: 1;
  }
  
  button {
    min-width: 5rem;
    max-width: 5rem;
    max-height: 2.5rem;
    margin-left: 1rem;
  }

  div + div {
    margin-left: 1rem;
    max-width: 30rem;
  }
`;

export const SearchTagsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SearchTag = styled.div`
  margin-right: 0.5rem;

  span {
    display: flex;
    background-color: ${COLORS.accentColor};
    color: ${COLORS.defaultBg};
    padding: 0.3rem 0.8rem;
    border-radius: 2rem;

    img {
      margin-left: 0.5rem;

      &:hover{
        cursor: pointer;
      }
    }
  }
`;
