import React from 'react';

import { Container } from './styles';

interface IBadge {
  type: 'success' | 'error' | 'warning' | 'light-warning' | 'inactive';
  fontSize?: number;
}

const Badge: React.FC<IBadge> = ({ children, type, fontSize }) => <Container type={type} fontSize={fontSize}>{children}</Container>;

export default Badge;
