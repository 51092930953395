import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import {
  Container,
  ContainerList,
  BoxInfo,
  BoxInfoHeader,
  BoxInfoBody,
  Step,
  BoxInstructions,
  BoxDragDrop
} from './styles';

import api from 'service/hrAccountApi';
import { getCompanyId } from 'service/company';

import { useBreadcrumb } from 'hooks/useBreadcrumb'

import Dashboard from 'components/HrAccountDashboard'
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import ProcessingModal from './ProcessingModal';
import SuccessModal from 'components/SuccessModal';
import NoticeModal from 'components/NoticeModal';
import ErrorModal from 'components/ErrorModal';
import Tabs, { ITabItems } from 'components/Tabs';

import iconProfileActive from 'assets/icon-profile-active.svg';
import iconProfileInactive from 'assets/icon-profile-inactive.svg';
import downloadActive from 'assets/icons/download-active.svg';
import uploadActive from 'assets/upload-active.svg';
import uploadInactive from 'assets/upload.svg';
import exclamation from 'assets/exclamation.svg';
import arrowUpPrimary from 'assets/arrow-up-primary.svg';
import arrowDownPrimary from 'assets/arrow-down-primary.svg';
import firstStep from 'assets/first-step.svg';
import secondStep from 'assets/second-step.svg';
import uploadIcon from 'assets/upload.svg';

interface INotification {
  id: number;
  numberOfEmployeesToImport: number;
  currentEmployeesNumber: number;
  status: string;
}

const ImportEmployees: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [arrowOpen, setArrowOpen] = useState(false);
  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<any>(null);
  const [messageProcess, setMessageProcess] = useState('');
  const [notification, setNotification] = useState<INotification>();
  const [message, setMessage] = useState('');
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileMessageError, setFileMessageError] = useState('');
  let intervalState: any = null;

  const history = useHistory();

  useEffect(() => {
    if (!notification) return;

    intervalState = setInterval(startCheckingProcess, 3000);
  }, [notification]);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: 'Importar Funcionários', path: `/rh/importar-funcionarios` }
    ]);

    loadLastProcessingNotification();
  }, []);

  function getTabItems() {
    return [
      {
        id: uuidv4(),
        title: 'Meus Funcionários',
        path: '/rh/funcionarios',
        isActive: false,
        icons: {
          active: iconProfileActive,
          inactive: iconProfileInactive
        }
      },
      {
        id: uuidv4(),
        title: 'Importar Base',
        path: '/rh/importar-funcionarios',
        isActive: true,
        icons: {
          active: uploadActive,
          inactive: uploadInactive
        }
      }
    ];
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find((tabItem) => tabItem.id === tabId)
    if (!activeTab) return

    history.push(activeTab.path);
  }

  function loadLastProcessingNotification() {
    api.get(`/hr-account/companies/${getCompanyId()}/last-import-notification`)
      .then((response) => {
        if (response.data && response.data.notification) {
          const status = response.data.notification.status;

          if (['waiting', 'processing'].includes(status)) {
            const notification: INotification = {
              id: response.data.notification.id,
              currentEmployeesNumber: response.data.notification.current_employees_number,
              numberOfEmployeesToImport: response.data.notification.number_of_employees_to_import,
              status: response.data.notification.status
            };
            setNotification(notification);
            setMessageProcess('Carregando...');
            setShowProcessModal(true);
          }
        }
      });
  }

  function downloadFile() {
    api.get('/hr-account/companies/download-layout-file')
      .then((response) => {
        const base64File = response.data.file;
        const fileName = 'layout_funcionarios.xlsx';
        const a = document.createElement('a');
        a.href = `data:image/png;base64,${base64File}`;
        a.download = fileName;
        a.click();
      })
      .catch(_err => {
        setMessage('Não foi possível baixar o arquivo.');
        setShowErrorMessageModal(true);
      });
  }

  function selectFile() {
    document.getElementById('input-file')?.click();
  }

  function handleFileInput(e: any) {
    setFileMessageError('');
    if (e.target && e.target.files.length) {
      const [file] = e.target.files;
      if (file.name.includes('.xlsx')) {
        setFileName(file?.name);
        setFile(file);
      } else {
        setFileMessageError('Arquivo com extensão inválida!');
      }
    }
  }

  function importFile() {
    const formData = new FormData();
    formData.append("name", fileName);
    formData.append("file", file);

    api
      .post(`/hr-account/companies/${getCompanyId()}/import-users`, formData)
      .then((res) => {
        setShowProcessModal(!showProcessModal);
        const notification: INotification = {
          id: res.data.notification.id,
          currentEmployeesNumber: res.data.notification.current_employees_number,
          numberOfEmployeesToImport: res.data.notification.number_of_employees_to_import,
          status: res.data.notification.status
        };
        setNotification(notification);
        setMessageProcess('Carregando...');
      })
      .catch((_err) => {
        setMessage('Não foi possível importar o arquivo.');

      });
  }

  function startCheckingProcess() {
    const userCountPromisse = api.get(`/hr-account/companies/${getCompanyId()}/users/count`);
    const notificationPromisse = api.get(`/hr-account/companies/${getCompanyId()}/notifications/${notification?.id}`);

    axios.all([userCountPromisse, notificationPromisse])
      .then(([userDataRes, notificationRes]) => {
        const status = notificationRes.data.notification.status;

        if (status === 'waiting') {
          setMessageProcess('Aguardando processar o arquivo...');
        } else if (status === 'success') {
          setMessageProcess('Arquivo processado com sucesso!');
          clearTimeout(intervalState);
          setShowProcessModal(false);
          setShowSuccessModal(true);
        } else if (['notice', 'warning'].includes(status)) {
          setMessageProcess('Arquivo processado com erros!');
          clearTimeout(intervalState);
          setShowProcessModal(false);
          setShowNoticeModal(true)
        } else if (status === 'error') {
          setMessageProcess('Arquivo não processado!');
          clearTimeout(intervalState);
          setShowProcessModal(false);
          setShowErrorModal(true);
        } else if (status === 'processing') {
          const numberOfEmployeesToImport = notificationRes.data.notification.number_of_employees_to_import;
          const totalUsersNow = userDataRes.data.count;
          const currentEmployeesNumber = notificationRes.data.notification.current_employees_number;
          const totalUsersImported = totalUsersNow - currentEmployeesNumber;
          const message = `Importando ${totalUsersImported} de ${numberOfEmployeesToImport} funcionários...`;
          setMessageProcess(message);
        }
      });
  }

  function renderModalByResult() {
    if (showProcessModal) {
      return <ProcessingModal messageProcess={messageProcess} />
    } else if (showNoticeModal) {
      return (
        <NoticeModal
          message="Importação concluída, porém, ocorreram alguns erros!"
          buttonMessage="ver detalhes"
          onClose={() => history.push(`/rh/resultado-importacao/${notification?.id}`)}
        />
      )
    }

    if (showSuccessModal) {
      return (
        <SuccessModal
          message="Importação concluída com sucesso!"
          buttonMessage="ver detalhes"
          onClose={() => history.push(`/rh/resultado-importacao/${notification?.id}`)}
        />
      )
    }

    if (showErrorModal) {
      return (
        <ErrorModal
          message="Erro na importação!"
          buttonMessage="ver detalhes"
          onClose={() => history.push(`/rh/resultado-importacao/${notification?.id}`)}
        />
      )
    }

    if (showErrorMessageModal) {
      return (
        <ErrorModal
          message={message}
          buttonMessage="OK"
          onClose={() => setShowErrorMessageModal(false)}
        />
      )
    }
  }

  function handleDrop(e: any) {
    e.preventDefault();
    setIsDragOver(false);
    setFileMessageError('');

    if (e.dataTransfer && e.dataTransfer.files) {
      const [file] = [...e.dataTransfer.files];
      if (file.name.includes('.xlsx')) {
        setFileName(file?.name);
        setFile(file);
      } else {
        setFileMessageError('Arquivo com extensão inválida!');
      }
    }
  }

  function handleDragOver(e: any) {
    e.preventDefault();
    setIsDragOver(true);
  }

  function handleDragEnter(e: any) {
    e.preventDefault();
    setIsDragOver(false);
  }

  function handleDragLeave(e: any) {
    e.preventDefault();
    setIsDragOver(false);
  }

  return (
    <>
      <Dashboard title="Importar Funcionários" backPath="/rh/funcionarios">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          <ContainerList>
            <BoxInfo>
              <BoxInfoHeader onClick={() => setArrowOpen(!arrowOpen)}>
                <div>
                  <img src={exclamation} alt="Atenção para instruções no arquivo." />
                  <strong>Instruções para a importação do arquivo</strong>
                </div>
                {!arrowOpen && <img src={arrowDownPrimary} alt="Seta para baixo." />}
                {arrowOpen && <img src={arrowUpPrimary} alt="Seta para cima." />}
              </BoxInfoHeader>
              {
                arrowOpen &&
                <BoxInfoBody>
                  <Step>
                    <img src={firstStep} alt="Primeiro step." />
                    <p>Baixe o arquivo de exemplo</p>
                  </Step>
                  <SecondaryButton
                    type="button"
                    height="2.5rem"
                    width="13rem"
                    iconUrl={downloadActive}
                    onClick={downloadFile}>
                    Baixar arquivo layout
                  </SecondaryButton>

                  <br />

                  <Step>
                    <img src={secondStep} alt="Segundo step." />
                    <p>Siga as Instruções abaixo</p>
                  </Step>

                  <BoxInstructions>
                    <ul>
                      <li>
                        <img src={exclamation} alt="Atenção para instruções no arquivo." />
                        <p>O arquivo deve estar no formato ".xlsx".</p>
                      </li>
                      <li>
                        <img src={exclamation} alt="Atenção para instruções no arquivo." />
                        <p>Não altere os cabeçalhos da planilha.</p>
                      </li>
                      <li>
                        <img src={exclamation} alt="Atenção para instruções no arquivo." />
                        <p>Não remova nem adicione novas colunas a planilha.</p>
                      </li>
                      <li>
                        <img src={exclamation} alt="Atenção para instruções no arquivo." />
                        <p>Para incluir um funcionário preencha "inclusão" na primeira coluna.</p>
                      </li>
                      <li>
                        <img src={exclamation} alt="Atenção para instruções no arquivo." />
                        <p>Para excluir um funcionário preencha "exclusão" na primeira coluna.</p>
                      </li>
                    </ul>
                  </BoxInstructions>
                </BoxInfoBody>
              }
            </BoxInfo>

            <h4>Importe o arquivo layout</h4>
            <BoxDragDrop
              onDrop={(e: any) => handleDrop(e)}
              onDragOver={(e: any) => handleDragOver(e)}
              onDragEnter={(e: any) => handleDragEnter(e)}
              onDragLeave={(e: any) => handleDragLeave(e)}
              isDragOver={isDragOver}
            >
              <div>
                <strong>{fileName}</strong>
                <PrimaryButton
                  type="button"
                  height="2.5rem"
                  width="11rem"
                  iconUrl={uploadIcon}
                  onClick={selectFile}>
                  Escolher arquivo
                </PrimaryButton>
                <p>ou arraste aqui</p>
                <input type="file" name="file" id="input-file" onChange={handleFileInput} />
                <span>{fileMessageError}</span>
              </div>
            </BoxDragDrop>
            <PrimaryButton
              type="button"
              height="2.5rem"
              active={!!file}
              width="11rem"
              onClick={importFile}>
              Importar base
            </PrimaryButton>
          </ContainerList>
        </Container>
      </Dashboard>
      {renderModalByResult()}
    </>
  )
};

export default ImportEmployees;
