import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { formatDate, formatTime } from 'utils';

import PrimaryButton from 'components/PrimaryButton';

import SuccessNotificationIcon from 'assets/icons/success-notification.svg';
import WarnigNotificationIcon from 'assets/icons/warning-notification.svg';
import ErrorNotificationIcon from 'assets/icons/error-notification.svg';

import api from 'service/subscriberApi';

import {
  Menu,
  Arrow,
  NotificationTitle,
  NotificationsList,
  EmptyMessage,
  Li,
  Title
} from './styles';

interface INotification {
  id: string;
  title: string;
  description: string;
  status: string;
  date: string;
  time: string;
  href: string;
  event_type: string;
  seen: boolean;
}

const NotificationMenu: React.FC = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    api.get(`/users/notifications`)
      .then(response => {
        setLoading(false);
        setNotifications(response.data.notifications.map(notificationsMapper));
      })
      .catch(_error => {
        setLoading(false);
      });
  }, []);

  function notificationsMapper(notification: any): INotification {
    let updatedAt = notification.updated_at;
    let status = notification.status;
    let title = notification.title;
    let description = notification.description;
    let eventType = notification.event_type;
    let seen = notification.seen;
    let href = `/assinantes/notificacoes/${notification.id}`;

    return {
      id: notification.id,
      title,
      description,
      href,
      status,
      seen,
      event_type: eventType,
      time: formatTime(updatedAt),
      date: formatDate(updatedAt),
    };
  }

  function getMessage() {
    if (loading) {
      return (
        <EmptyMessage>
          <p>Carregando...</p>
        </EmptyMessage>
      );
    } else if (!notifications.length) {
      return (
        <EmptyMessage>
          <p>Nenhuma notificação</p>
        </EmptyMessage>
      );
    }
  }

  function showMore() {
    if (!!notifications.length) {
      return (
        <>
          <br />
          <PrimaryButton
            type="button"
            height="2.25rem"
            onClick={() => history.push('/assinantes/notificacoes')}
          >
            Ver todas
          </PrimaryButton>
        </>
      );
    }
  }

  function getIconByStatus(status: string) {
    if (status === 'success') {
      return <img src={SuccessNotificationIcon} alt="Icone de sucesso." />;
    } else if (status === 'error') {
      return <img src={ErrorNotificationIcon} alt="Icone de erro." />;
    } else {
      return <img src={WarnigNotificationIcon} alt="Icone de aviso." />
    }
  }

  return (
    <Menu>
      <Arrow>
        <span></span>
      </Arrow>

      <NotificationTitle>
        Notificações
      </NotificationTitle>
      <NotificationsList>
        <>
          {notifications.map(notification => (
            <>
              <Li
                key={notification.id}
                onClick={() => history.push(notification.href)}
                seen={notification.seen}
              >
                <Title status={notification.status}>
                  {getIconByStatus(notification.status)}
                  <strong>{notification.title}</strong>
                </Title>
                <p className="date">
                  {notification.date} {notification.time}
                </p>
              </Li>
              <hr />
            </>
          ))}
        </>
        {getMessage()}
        {showMore()}
      </NotificationsList>
    </Menu>
  );
};

export default NotificationMenu;
