import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;

  .alert {
    width: 100%;
  }
`;

export const ContainerMenu = styled.div`
  width: 100%;
  max-width: 18.75rem;

  ul {
    list-style-type: none;
    max-width: 13rem;
  }

  li {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 21px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }

  li + li {
    border-top: 1px solid ${COLORS.borderBase};
  }

  .active {
    color: ${COLORS.accentColor};
    line-height: 21px;
    font-weight: 600;
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
