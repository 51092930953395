import React, { useEffect, useState } from "react";

import {
  Container,
  SuccessBox,
  ErrorBox
} from './styles';

import api from 'service/hrAccountApi';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import { getCompanyId } from "service/company";

import Dashboard from 'components/HrAccountDashboard'

import successIcon from 'assets/small-success-icon.svg';
import errorIcon from 'assets/small-error-icon.svg';

import { toast } from "react-toastify";

interface IEmployee {
  id: string;
  name: string;
  dependants: Array<any>;
  errors: Array<any>;
  showAllErrors: boolean;
}

interface INotification {
  id: number;
  title: string;
  status: string;
  content: object;
  event_type: string;
  description: string;
}

const Notification: React.FC = (props: any) => {
  const notificationId = props.match.params.notification_id;
  const [companyId] = useState<number>(getCompanyId());
  const [notification, setNotification] = useState<INotification>();
  const [employeesReportUrl, setEmployeesReportUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Notificações', path: `/rh/notificacoes` },
      { title: `Notificação ${notificationId}`, path: `/rh/notificacoes/${notificationId}` }
    ]);

    loadNotification()
  }, [notificationId])

  function loadNotification() {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${companyId}/notifications/${notificationId}?`

    api.get(url)
      .then((response) => {
        if (response.data.notification) {
          setLoading(false);
          setMessage('');
          setNotification(response.data.notification);
        } else {
          setLoading(false);
          setMessage('Nenhuma notificação encontrada!');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function handleEmployeeReports() {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${companyId}/notifications/${notificationId}`;

    api.get(url)
      .then((response) => {
        setLoading(false);
        setMessage('');
        setEmployeesReportUrl(response.data.notification?.content?.file_url)
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function getNotificationBoxByStatus(notification: INotification) {
    if (notification.status === 'error') {
      return (
        <ErrorBox>
          <div>
            <img src={errorIcon} alt="icone de erro." />
            <span>{notification.description}</span>
          </div>
        </ErrorBox>
      )
    }
    else if (notification.status === 'success') {
      return (
        <SuccessBox>
          <div>
            <img src={successIcon} alt="icone de sucesso." />
            <span>O relatório de funcionários foi gerado com sucesso!
              Você pode fazer o download <a onClick={handleEmployeeReports}> clicando aqui</a>.
            </span>
          </div>
        </SuccessBox>
      )
    }
  }

  return (
    <>
      <Dashboard title={`Notificação ${notificationId}`} backPath={`/rh/notificacoes`}>
        <Container>
          {notification && getNotificationBoxByStatus(notification)}
          {employeesReportUrl && <iframe style={{ display: 'none' }} src={employeesReportUrl}></iframe>}
          {!loading && <p>{message}</p>}
        </Container>
      </Dashboard>
    </>
  )
};

export default Notification;
