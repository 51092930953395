import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useBreadcrumb } from 'hooks/useBreadcrumb'

import Dashboard from 'components/HrAccountDashboard'
import Tabs, { ITabItems } from 'components/Tabs';

import FormAddress from './FormAddress';
import FormPersonalData from './FormPersonalData';

import iconProfileActive from 'assets/icon-profile-active.svg';
import iconProfileInactive from 'assets/icon-profile-inactive.svg';
import iconPersonActive from 'assets/person-active.svg';
import iconPersonInactive from 'assets/person-inactive.svg';
import step1Activate from 'assets/icons/step-1-activate.svg';
import step2Activate from 'assets/icons/step-2-activate.svg';
import step2Inactivate from 'assets/icons/step-2-inactivate.svg';

import api from 'service/hrAccountApi';
import { getCompanyId } from "service/company";

import { Container, ContainerMenu, ContainerForm } from './styles';
export interface IEmployee {
  id: number;
  name: string;
  cpf: string;
  phone: string;
  email: string;
  status: boolean;
  birthDate: string;
  dependantsCount: number;
  checked: boolean;
}
export interface IEmployeeAddress {
  id: number;
  street: string,
  number: number,
  complement?: string,
  district: string,
  zip_code: string,
  city: string,
  state: string,
  addressable_type: string,
  addressable_id: number,
}

const Employee: React.FC = (props: any) => {
  const employeeId = props.match.params.id;
  const [employee, setEmployee] = useState<IEmployee>()
  const [employeeAddress, setEmployeeAddress] = useState<IEmployeeAddress>()
  const [loading, setLoading] = useState(false);
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [step, setStep] = useState("personal-data");
  const [tabItems] = useState<ITabItems[]>([{
    id: uuidv4(),
    title: 'Dados pessoais',
    path: `/rh/funcionarios/${employeeId}`,
    isActive: true,
    icons: {
      active: iconPersonActive,
      inactive: iconPersonInactive
    }
  },
  {
    id: uuidv4(),
    title: 'Dependentes',
    path: `/rh/funcionarios/${employeeId}/dependentes`,
    isActive: false,
    icons: {
      active: iconProfileActive,
      inactive: iconProfileInactive
    }
  }])

  const history = useHistory();

  useEffect(() => {
    loadEmployee()
  }, []);

  function loadEmployee() {
    setLoading(true);
    api.get(`/hr-account/companies/${getCompanyId()}/users/${employeeId}`)
      .then((response) => {
        setLoading(false);
        setEmployee(formatEmployee(response.data.user))
        if(response.data?.user?.address)
          setEmployeeAddress(formatAddress(response.data.user.address))
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatEmployee(employee: any): IEmployee {
    return {
      id: employee.id,
      name: employee.name,
      cpf: employee.cpf,
      phone: employee.phone,
      email: employee.email,
      birthDate: employee.birth_date,
      dependantsCount: employee.dependants_count,
      status: employee.subscription.status === 'active',
      checked: false
    }
  }

  function formatAddress(employee: any): IEmployeeAddress {
    return {
      id: employee.id,
      street: employee.street,
      number: employee.number,
      complement: employee.complement,
      district: employee.district,
      zip_code: employee.zip_code,
      city: employee.city,
      state: employee.state,
      addressable_type: employee.addressable_type,
      addressable_id: employee.addressable_id,
    }
  }

  useEffect(() => {
    if (!employee) return
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: employee.name, path: `/rh/funcionarios/${employeeId}` }
    ])

  }, [employee])

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find((tabItem) => tabItem.id === tabId)
    if (!activeTab) return

    history.push(activeTab.path);
  }

  function chooseStepImage() {
    if (step === 'address') {
      return <img src={step2Activate} alt="Step 2" />
    } else {
      return <img src={step2Inactivate} alt="Step 2" />
    }
  }

  function chooseForm() {
    if (step === 'address') {
      return <FormAddress employeeId={employeeId} employeeAddress={employeeAddress} loading={loading}/>
    } else if (employee) {
      return (
        <FormPersonalData
          _setLoading={setLoading}
          loading={loading}
          employee={employee}
        />
      )
    }
  }

  return (
    <Dashboard title={employee?.name} backPath="/rh/funcionarios">
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      <Container>
        <ContainerMenu>
          <ul>
            <li onClick={() => setStep('personal-data')} className={step === 'personal-data' ? 'active' : ''}>
              <img src={step1Activate} alt="Step 1" />
              Informações pessoais
            </li>
            <li onClick={() => setStep('address')} className={step === 'address' ? 'active' : ''}>
              {chooseStepImage()}
              Endereço
            </li>
          </ul>
        </ContainerMenu>


        <ContainerForm>
          {!loading && (
            chooseForm()
          )}
        </ContainerForm>
      </Container>
    </Dashboard>
  )
};

export default Employee;
