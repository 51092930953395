export const COLORS = {
  accentColor: '#933C85',
  lightAccentColor: '#a06196',
  backgroundBase: '#FBFBFB',
  borderBase: '#F6F6F6',
  secondaryBg: '#F9F9F9',
  defaultBg: 'white',
  lightGrey: '#E7EAED',
  mediumGrey: '#999999',
  darkGrey: '#686868',
  offblack: '#2F2F2F',
  white: '#FFFFFF',
  red: '#f34747',
  defaultSubtitle: '#9F9F9F',
  success: '#07C585',
  darkBlue: '#292E60',
  mediumOrange: '#F0AB3B',
  yellow: '#ffc107',
  defaultText: '#3C3C3C',
  primaryGreen: '#7AB742',
}

export const SIZES = {
  largeThanMobile: "min-width: 400px",
  largerThanPhablet: "min-width: 550px",
  largerThanTablet: "min-width: 900px",
  largerThanDesktop: "min-width: 1024px",
  largerThanDesktophd: "min-width: 1200px",
  largerThanDesktopuhd: "min-width: 1900px",
  smallerThanMobile: "max-width: 449px",
  smallerThanPhablet: "max-width: 549px",
  smallerThanPhablethd: "max-width: 649px",
  smallerThanTablet: "max-width: 899px",
  smallerThanDesktop: "max-width: 1023px",
  smallerThanDesktophd: "max-width: 1199px",
  smallerThanDesktopuhd: "max-width: 1899px",
  maxWidthDesktophd: '1199px'
}

