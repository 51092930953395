import React from 'react';

import { ContainerButton, Loading } from './styles';

interface IPrimaryButton {
  onClick?: Function;
  type: "button" | "submit" | "reset" | undefined;
  iconUrl?: string;
  height?: string;
  width?: string;
  active?: boolean;
  loading?: boolean;
  altImage?: string;
  positionIcon?: "left" | "right";
}

const PrimaryButton: React.FC<IPrimaryButton> = (props) => {
  const { onClick, type, iconUrl, active = true, height, width, children, loading, altImage, positionIcon = 'left' } = props;

  function getContent() {
    if (loading && active) {
      return <Loading></Loading>
    } else if (iconUrl) {
      return positionIcon === 'left' 
        ? ( <><img src={iconUrl} alt={altImage} />{children}</> ) 
        : ( <>{children}<img src={iconUrl} alt={altImage} /></> );
    } else {
      return children;
    }
  }

  function handleClick(e: any) {
    if (!loading && onClick) {
      onClick(e);
    }
  }

  return (
    <ContainerButton
      type={type}
      height={height}
      width={width}
      active={active}
      icon={iconUrl}
      text={!!children}
      onClick={(e: any) => handleClick(e)}
      positionIcon={positionIcon}>
      {getContent()}
    </ContainerButton>
  )
};

export default PrimaryButton;
