import React, { createContext, useState, useContext, useEffect } from 'react';
import { isAuthenticated } from 'service/subscriber';

import api from 'service/subscriberApi';

import { validUserData, validDependantsData } from 'utils';

interface IItemsMenuContext {
  showUserAlert: boolean;
  setShowUserAlert: Function;
  showDependantsAlert: boolean;
  setShowDependantsAlert: Function;
  checkAllAlerts: Function;
}

const ItemsMenuContext = createContext<IItemsMenuContext>({} as IItemsMenuContext);

export const ItemsMenuProvider: React.FC = ({ children }) => {
  const [showUserAlert, setShowUserAlert] = useState(false);
  const [showDependantsAlert, setShowDependantsAlert] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      checkAllAlerts();
    }
  }, []);

  function checkAllAlerts() {
    checkUserAlerts();
    checkDependantsAlerts();
  }

  function checkUserAlerts() {
    api.get('/users/find-user')
      .then((response) => {
        setShowUserAlert(!validUserData(response.data.user))
      });
  }

  function checkDependantsAlerts() {
    api.get('/users/dependants')
      .then((response) => {
        setShowDependantsAlert(!validDependantsData(response.data.dependants))
      });
  }

  return (
    <ItemsMenuContext.Provider value={{
      showUserAlert,
      setShowUserAlert,
      showDependantsAlert,
      setShowDependantsAlert,
      checkAllAlerts
    }}>
      {children}
    </ItemsMenuContext.Provider>
  )
};

export function useItemsMenuSubscriber() {
  return useContext(ItemsMenuContext);
}
