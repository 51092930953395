import React, { useState, useEffect, FormEvent } from 'react';
import luhn from 'luhn';

import {
  BaseModal,
  ContentModal,
  Title,
  SubTitle,
  ContentBody,
  Step,
  StepCard,
  HeaderStepCard,
  BodyExpandedCard,
  ProgressBar,
  StepLine,
  StepCircle,
  PlanBox,
  PlanBoxes,
  Form,
  BoxLogo,
  ContractTermsBox,
  AcceptTermsRow,
  BoxInput,
  ErrorMessage,
  PaymentCard
} from './styles';

import CloseIcon from 'assets/icons/purple-close.svg';
import ArrowDownIcon from 'assets/arrow-down-black.svg';
import cardIllustration from 'assets/credit-card.svg';
import arrowRightActive from 'assets/icons/arrow-right-active.svg';

import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox';

import amex from 'assets/icons/checkout/amex.svg';
import elo from 'assets/icons/checkout/elo.svg';
import hipercard from 'assets/icons/checkout/hipercard.svg';
import mastercard from 'assets/icons/checkout/mastercard.svg';
import visa from 'assets/icons/checkout/visa.svg';

import { getCardFlag, formatValueToCurrency, chooseFlagImg } from 'utils';
import { toast } from 'react-toastify';
import api from 'service/subscriberApi';
import gatewayApi from 'service/gatewayApi';

import PlusContract from './PlusContract'
import StandardContract from './StandardContract'
import Benefits from './Benefits';

interface IRenewalModal {
  closeModal: Function;
}

interface IError {
  key: string;
  message: string;
}

interface IPlan {
  id: number,
  code: string,
  description: string,
  planValue: string,
  name: string,
  status: string,
  planType: string,
  intervalCount: number
}

interface IPaymentMethod {
  flag: string;
  flagName: string;
  lastFourNumbers: string;
}

const RenewalModal: React.FC<IRenewalModal> = ({ closeModal }) => {
  const [plans, setPlans] = useState<IPlan[]>([])
  const [openedPlanChoice, setOpenedPlanChoice] = useState(false)
  const [openedPaymentMethodChoice, setOpenedPaymentMethodChoice] = useState(false)
  const [openedTermsOfUse, setOpenedTermsOfUse] = useState(false)
  const [gatewayToken, setGatewayToken] = useState('');
  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false);
  const [planType, setPlanType] = useState('')
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null)
  const [cardNumber, setCardNumber] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [holderName, setHolderName] = useState('');
  const [creditCardFlag, setCreditCardFlag] = useState('');
  const [loadind, setLoading] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [updatingCard, setUpdatingCard] = useState(true);
  const [planChoiceDone, setPlanChoiceDone] = useState(false)
  const [paymentMethodDone, setPaymentMethodDone] = useState(false)
  const [, setTermsOfUseDone] = useState(false)

  useEffect(() => {
    loadPlans()
    getPaymentMethod()
  }, []);

  useEffect(() => {
    if (gatewayToken) handlePaymentMethodDone()
  }, [gatewayToken]);

  useEffect(() => {
    if (!!cardNumber) {
      setCreditCardFlag(getCardFlag(cardNumber));
    }
  }, [cardNumber]);

  async function handlePaymentMethodDone() {
    try {
      const params = { gateway_token: gatewayToken }
      const response = await api.post(`/users/payment-profiles`, params);
      const { payment_profile, message } = response.data;

      if (payment_profile) {
        setUpdatingCard(false)
        setPaymentMethod({
          flag: payment_profile.payment_company.code,
          flagName: payment_profile.payment_company.name,
          lastFourNumbers: payment_profile.card_number_last_four
        });
      }

      setLoading(false)
      clearPlaymentFields()
      setErrors([])
      toast.success(message);
    } catch (ex: any) {
      setLoading(false);

      if (ex.response && ex.response.data && ex.response.status === 400) {
        toast.error(ex.response.data.message)
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  async function loadPlans() {
    setLoading(true);

    try {
      const response = await api.get(`/users/list-plans`);
      const { plans } = response.data;

      setLoading(false);
      setPlanType(plans[0]?.code)
      setPlans(plans.map((plan: IPlan) => formatPlan(plan)))
    } catch (ex: any) {
      setLoading(false);

      if (ex.response && ex.response.data && ex.response.status === 400) {
        toast.error(ex.response.data.message)
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  function formatPlan(plan: any) {
    const planValue = getMonthlyValue(plan)
    const formatedPlanValue = formatValueToCurrency(planValue)

    return {
      id: plan.id,
      code: plan.code,
      name: plan.name,
      description: plan.description,
      status: plan.status,
      planType: plan.code,
      planValue: formatedPlanValue,
      intervalCount: plan.interval_count
    }
  }

  function clearPlaymentFields() {
    setCardExpiration('')
    setGatewayToken('')
    setCardNumber('')
    setHolderName('')
    setCardCvv('')
  }

  function handleOpenPlanChoiceCard() {
    setOpenedPlanChoice(!openedPlanChoice)
    setOpenedPaymentMethodChoice(false)
    setOpenedTermsOfUse(false)
  }

  function handleOpenPaymentMethodCard() {
    setOpenedPlanChoice(false)
    setOpenedPaymentMethodChoice(!openedPaymentMethodChoice)
    setOpenedTermsOfUse(false)
  }

  function handleOpenTermsOfUseCard() {
    setPaymentMethodDone(true)
    setTermsOfUseDone(false)
    setOpenedPlanChoice(false)
    setOpenedPaymentMethodChoice(false)
    setOpenedTermsOfUse(!openedTermsOfUse)
  }

  async function handlePlanChoiceDone() {
    setLoading(true);

    try {
      await api.post(`/users/create-customer-on-gateway`);
      setLoading(false);
      setPlanChoiceDone(true)
      setPaymentMethodDone(false)
      setOpenedPlanChoice(false)
      setOpenedPaymentMethodChoice(true)
      toast.success('Plano selecionado com sucesso!');
    } catch (ex: any) {
      setLoading(false);

      if (ex.response && ex.response.data && ex.response.status === 400) {
        toast.error(ex.response.data.message)
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  async function handleTermsOfUseDone() {
    setLoading(true);

    if (!acceptedTermsOfUse) {
      if (!errors.find(error => error.key == 'accepted_terms')) setErrors([...errors, { key: 'accepted_terms', message: 'Campo obrigatório' }])
      return
    }

    const planId = plans.find(plan => plan.code == planType)?.id;
    const params = { accepted_terms: acceptedTermsOfUse, plan_id: planId }

    try {
      await api.post(`/users/create-subscription-on-gateway`, params);
      setLoading(false);
      closeModal()
      setOpenedTermsOfUse(true)
      setOpenedPlanChoice(false)
      setOpenedPaymentMethodChoice(false)
      toast.success('Assinatura renovada com sucesso com sucesso!');
    } catch (ex: any) {
      setLoading(false);

      toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
    }
  }

  function getPaymentMethodBody() {
    return {
      holder_name: holderName,
      card_expiration: cardExpiration,
      card_number: cardNumber.replace(/ /g, ''),
      card_cvv: cardCvv,
      payment_method_code: "credit_card",
      payment_company_code: creditCardFlag,
    }
  }

  async function handleForm(event: FormEvent) {
    try {
      event.preventDefault();

      const newErrors = isFormValid();
      if (!newErrors.length) {
        setLoading(true);

        const gatewayPublicApi = process.env.REACT_APP_GATEWAY_PUBLIC_API || ''
        const params = getPaymentMethodBody()

        const response = await gatewayApi.post(`${gatewayPublicApi}/payment_profiles`, params);
        const { payment_profile } = response.data

        if (payment_profile) {
          setGatewayToken(payment_profile.gateway_token)
        }
      } else {
        setErrors(newErrors);
      }
    } catch (ex: any) {
      setLoading(false);
      toast.error('Erro ao salvar dados do cartão!');

      if (ex.response) {
        const { errors } = ex.response.data;
        const newErrors: IError[] = (errors || []).map((error: any) => ({ key: error.parameter, message: error.message }));
        setErrors(newErrors);
      }
    }
  }

  function isFormValid(): IError[] {
    const errors: IError[] = [];
    const errorMessage = 'não pode ficar em branco'

    if (!holderName) {
      errors.push({ key: 'holder_name', message: errorMessage });
    }

    if (!cardNumber) {
      errors.push({ key: 'card_number', message: errorMessage });
    }

    if (!luhn.validate(cardNumber)) {
      errors.push({ key: 'card_number', message: 'Número do cartão inválido' });
    }

    if (!cardExpiration) {
      errors.push({ key: 'card_expiration', message: errorMessage });
    }

    if (!cardCvv) {
      errors.push({ key: 'card_cvv', message: errorMessage });
    }

    return errors;
  }

  function addError(msg: string, index: any) {
    return (
      <React.Fragment key={index}>
        <ErrorMessage>{msg}</ErrorMessage>
      </React.Fragment>
    )
  }

  function getContractByPlan(planType: string) {
    return planType === 'cartao-mais-anual' ? <PlusContract /> : <StandardContract />
  }

  function getMonthlyValue(plan: any): string {
    const parsedValue = parseFloat(plan["plan_items"][0]["pricing_schema"]["price"]) / plan["interval_count"]

    return parsedValue.toString()
  }

  function getPaymentMethod() {
    setLoading(true);

    api.get(`/users/payment-methods`)
      .then((response) => {
        setLoading(false);
        const paymentMethod = response.data.payment_method;
        if (paymentMethod) {
          setUpdatingCard(false)
          setPaymentMethod({
            flag: paymentMethod.payment_company.code,
            flagName: paymentMethod.payment_company.name,
            lastFourNumbers: paymentMethod.card_number_last_four
          });
        } else {
          setPaymentMethod(null);
        }
      }).catch((_error) => {
        setLoading(false);
      });
  }

  return (
    <BaseModal>
      <ContentModal>
        <img src={CloseIcon} onClick={() => closeModal()} alt="Icone para fechar" className="close-botton" />

        <Title>Renovação do plano</Title>
        <SubTitle>Continue cuidando da sua família com o Cartão Aliança, renove seu plano.</SubTitle>

        <ContentBody>
          <Step active={true} >
            <ProgressBar>
              <StepLine active={planChoiceDone} />
              <StepCircle active={true}>1</StepCircle>
            </ProgressBar>
            <StepCard>
              <HeaderStepCard onClick={handleOpenPlanChoiceCard}>
                <span>Escolher Plano</span>
                <img src={ArrowDownIcon} alt="Icone para expandir" />
              </HeaderStepCard>

            {openedPlanChoice &&
              <BodyExpandedCard>
                <Benefits planType={planType} />

                <PlanBoxes>
                  {plans?.map(plan => {
                    return (
                      <PlanBox onClick={() => setPlanType(plan.code)}>
                        <input type="radio" value={plan.code} checked={planType === plan.code} name={plan.code} />
                        <div>
                          <span>{ plan.name }</span>
                          <span><strong>{ plan.planValue }</strong> / por mês</span>
                        </div>
                      </PlanBox>
                    )
                  })}
                  <PrimaryButton
                    type="button"
                    width="5rem"
                    height="2.5rem"
                    onClick={handlePlanChoiceDone}
                    loading={loadind}
                  >
                    Avançar
                  </PrimaryButton>
                </PlanBoxes>
              </BodyExpandedCard>}
            </StepCard>
          </Step>
          <Step active={planChoiceDone} >
            <ProgressBar>
              <StepLine active={paymentMethodDone} />
              <StepCircle active={planChoiceDone}>2</StepCircle>
            </ProgressBar>
            <StepCard>
              <HeaderStepCard onClick={handleOpenPaymentMethodCard} >
                <span>Escolher meio de pagamento</span>
                <img src={ArrowDownIcon} alt="Icone para expandir" />
              </HeaderStepCard>

              {openedPaymentMethodChoice &&
                <BodyExpandedCard>
                  <div className="payment-area">
                    {updatingCard ?
                      <>
                        <div className="box-image">
                          <img src={cardIllustration} alt="Ilustração de cartão de crédito" />
                        </div>
                        <Form onSubmit={handleForm}>
                          <BoxLogo>
                            <img
                              src={amex}
                              alt="Amex"
                              className={creditCardFlag === 'american_express' ? 'active' : ''} />
                            <img
                              src={elo}
                              alt="Elo"
                              className={creditCardFlag === 'elo' ? 'active' : ''} />
                            <img
                              src={hipercard}
                              alt="Hipercard"
                              className={creditCardFlag === 'hipercard' ? 'active' : ''} />
                            <img
                              src={mastercard}
                              alt="Mastercard"
                              className={creditCardFlag === 'mastercard' ? 'active' : ''} />
                            <img
                              src={visa}
                              alt="Visa"
                              className={creditCardFlag === 'visa' ? 'active' : ''} />
                          </BoxLogo>
                          <BoxInput>
                            <TextInput
                              onChange={(value: any) => setCardNumber(value)}
                              value={cardNumber}
                              type="text"
                              placeholder="Número do cartão"
                              typeMask="credit_card_number"
                              maxLength={19} />
                              {errors.filter((error) => error.key === 'card_number')
                                .map((error, index) => addError(error.message, index))}
                          </BoxInput>

                          <div className="inputs-inline">
                            <BoxInput>
                              <TextInput
                                onChange={(value: any) => setCardCvv(value)}
                                value={cardCvv}
                                type="text"
                                placeholder="CVV"
                                maxLength={4}
                                typeMask="only_numbers" />
                              {errors.filter((error) => error.key === 'card_cvv')
                                .map((error, index) => addError(error.message, index))}
                            </BoxInput>
                            <BoxInput>
                              <TextInput
                                onChange={(value: any) => setCardExpiration(value)}
                                value={cardExpiration}
                                type="text"
                                placeholder="Validade (mm/aaaa)"
                                typeMask="mmyyyy"
                                maxLength={7} />
                              {errors.filter((error) => error.key === 'card_expiration').reverse()
                                .map((error, index) => addError(error.message, index))[0]}
                            </BoxInput>
                          </div>

                          <BoxInput>
                            <TextInput
                              onChange={(value: any) => setHolderName(value)}
                              value={holderName}
                              type="text"
                              placeholder="Nome do titular"
                              maxLength={255} />
                            {errors.filter((error) => error.key === 'holder_name')
                              .map((error, index) => addError(error.message, index))}
                          </BoxInput>

                          <PrimaryButton type="submit" width="6rem" height="2.5rem" loading={loadind}>
                            Cadastrar
                          </PrimaryButton>
                        </Form>
                      </>
                    :
                      <>
                        <PaymentCard>
                          <div className="selected-card-data">
                            {chooseFlagImg(paymentMethod?.flag || '')}
                            <p>{paymentMethod?.flagName} ...{paymentMethod?.lastFourNumbers}</p>
                          </div>
                          <div className="change-card" onClick={() => setUpdatingCard(true)}>
                            <p>Trocar cartão</p>
                            <img src={arrowRightActive} alt="Trocar cartão" />
                          </div>
                        </PaymentCard>
                        <PrimaryButton
                          type="button"
                          width="5rem"
                          height="2.5rem"
                          loading={loadind}
                          onClick={() => handleOpenTermsOfUseCard()}
                        >
                          Avançar
                        </PrimaryButton>
                      </>
                    }
                  </div>
                </BodyExpandedCard>
              }
            </StepCard>
          </Step>
          <Step active={paymentMethodDone}>
            <ProgressBar>
              <StepLine className="last" active={paymentMethodDone} />
              <StepCircle active={paymentMethodDone}>3</StepCircle>
            </ProgressBar>

            <StepCard>
              <HeaderStepCard onClick={handleOpenTermsOfUseCard}>
                <span>Aceitar termos de uso</span>
                <img src={ArrowDownIcon} alt="Icone para expandir" />
              </HeaderStepCard>

              {openedTermsOfUse &&
                <BodyExpandedCard>
                  <div className="d-flex flex-column">
                    <ContractTermsBox>
                      {getContractByPlan(planType)}
                    </ContractTermsBox>

                    <AcceptTermsRow>
                      <Checkbox
                        checked={acceptedTermsOfUse}
                        onChange={() => setAcceptedTermsOfUse(!acceptedTermsOfUse)}
                      />
                      <div className="ml-1">
                        <span className="check-box-label">
                          Li e aceito os termos e condições do contrato de Serviço do
                          {planType === 'cartao-mais-anual' ? ' Cartão Aliança Mais' : ' Cartão Aliança Saúde'}.
                        </span>
                        {errors.filter((error) => error.key === 'accepted_terms')
                          .map((error, index) => addError(error.message, index))}
                      </div>
                    </AcceptTermsRow>
                  </div>
                </BodyExpandedCard>}
              </StepCard>
          </Step>
        </ContentBody>
        <PrimaryButton
          type="button"
          width="10rem"
          onClick={handleTermsOfUseDone}
          active={acceptedTermsOfUse}
          loading={loadind}
        >
          Renovar plano
        </PrimaryButton>
      </ContentModal>
    </BaseModal>
  )
}

export default RenewalModal;
