import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import BtnLink from 'components/BtnLink';
import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';

import api from 'service/hrAccountApi';
import { getCompanyId } from "service/company";

import activeSwitch from 'assets/active-switch.svg';
import inactiveSwitch from 'assets/inactive-switch.svg';

import { validCpf, validEmail, formatDate, formatPhone, validBirthDate } from 'utils';
import { IEmployee } from '..';
import { useForm } from 'hooks/useForm';

import { Form, Separator, Switch } from './styles';

interface IFormPersonalData {
  _setLoading: (isLoading: boolean) => void
  loading: boolean
  employee: IEmployee
}

interface User {
  name: string;
  cpf: string;
  email: string;
  'birth_date': string;
  phone: string;
  status: boolean
}

const FormPersonalData: React.FC<IFormPersonalData> = ({
  _setLoading,
  loading,
  employee
}) => {
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(employee.status)
  }, [employee])

  const { handleSubmit, handleChange, handleSubmitErrors, data: user, errors} = useForm<User>({
    validations: {
      name: {
        required: {
          value: true,
          message: 'Nome deve ser preenchido.'
        }
      },
      cpf: {
        required: {
          value: true,
          message: 'CPF deve ser preenchido.'
        },
        custom: {
          isValid: (value) => validCpf(value),
          message: 'CPF inválido.',
        },
      },
      email: {
        custom: {
          isValid: (value) => value ? validEmail(value) : true,
          message: 'E-mail inválido.',
        },
      },
      'birth_date': {
        required: {
          value: true,
          message: 'Data deve ser preenchida.'
        },
        custom: {
          isValid: (value) => validBirthDate(value),
          message: 'Data deve ser preenchida ou está inválida.',
        },
      },
      phone: {
        required: {
          value: true,
          message: 'Telefone deve ser preenchido ou está inválido'
        },
        custom: {
          isValid: (value) => [10, 11].includes(value.replace(/[^0-9]+/g, "").length),
          message: 'Telefone deve ser preenchido ou está inválido',
        },
      },
    },
    onSubmit: () => handleForm(),
    initialValues: getInitialValues()
  });

  function getInitialValues(){
    if (!employee) return

    return {
      name: employee.name,
      cpf: employee.cpf,
      phone: formatPhone(employee.phone),
      birth_date: formatDate(employee.birthDate),
      email: employee.email,
    }
  }

  async function updateEmployee() {
    if(!employee) return
    const params = {
      name: user.name,
      email: user.email,
      cpf: user.cpf,
      phone: user.phone.replace(/\D/g, ''),
      status: status,
      birth_date: user.birth_date
    };

    try {
      const url = `/hr-account/companies/${getCompanyId()}/users/${employee.id}`
      const response = await api.put(url, params);
      _setLoading(false);

      if (response.status === 200) {
        toast.success('Dados salvos com sucesso!');
        history.push('/rh/funcionarios')
      } else {
        _setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    } catch (ex: any) {
      _setLoading(false);

      if (ex.response && ex.response.data && ex.response.status === 422) {
        handleSubmitErrors(ex.response.data.errors)
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  function handleForm() {
    if (loading) return;
    setMessage('');
    _setLoading(true);
    updateEmployee();
  }

  function formIsValid(){
    return !!user.name && !!user.cpf && !!user.birth_date && !!user.phone
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Switch>
        <span>Status</span>
        {status &&
          <div>
            <span>Ativo: </span>
            <img src={activeSwitch} onClick={() => setStatus(!status)} alt="interruptor ativo." />
          </div>}
        {!status &&
          <div>
            <span>Inativo: </span>
            <img src={inactiveSwitch} onClick={() => setStatus(!status)} alt="interruptor inativo." />
          </div>}
      </Switch>
      <Separator />
      <h3><strong>Dados Pessoais:</strong></h3>

      <TextInput
        onChange={handleChange('name')}
        value={user.name}
        type="text"
        placeholder="Nome *"
        maxLength={255} />
      {errors.name && <span className="error">{errors.name}</span>}

      <TextInput
        onChange={handleChange('cpf')}
        type="text"
        placeholder="CPF *"
        value={user.cpf}
        maxLength={14}
        typeMask="cpf" />
      {errors.cpf && <span className="error">{errors.cpf}</span>}

      <TextInput
        onChange={handleChange('birth_date')}
        value={user.birth_date}
        type="text"
        placeholder="Data de nascimento *"
        maxLength={10}
        typeMask="date" />
      {errors.birth_date && <span className="error">{errors.birth_date}</span>}

      <TextInput
        onChange={handleChange('phone')}
        value={user.phone}
        type="text"
        placeholder="Telefone *"
        maxLength={15}
        typeMask="phone" />
      {errors.phone && <span className="error">{errors.phone}</span>}

      <TextInput
        onChange={handleChange('email')}
        value={user.email}
        type="text"
        placeholder="E-mail"
        maxLength={100} />
      {errors.email && <span className="error">{errors.email}</span>}

      {
        message && <>
          <br />
          <br />
          <span>{message}</span>
        </>
      }

      <div className="actions">
        <BtnLink onClick={() => history.push('/rh/funcionarios')}>Cancelar</BtnLink>
        <PrimaryButton type="submit" active={formIsValid()} width="200px">Salvar Alterações</PrimaryButton>
      </div>
    </Form>
  );
}

export default FormPersonalData;
