import styled, { css } from 'styled-components';

interface IAlertContainer {
  type: 'warning' | 'info' | 'danger';
  hidden: boolean;
}

export const AlertContainer = styled.div<IAlertContainer>`
  min-height: 2.5rem;
  border-radius: 6px;
  ${(props) => props.type === 'info' && css`background: rgba(0, 172, 236, 0.1);`}
  ${(props) => props.type === 'danger' && css`background: rgba(216, 75, 75, 0.1);`}
  ${(props) => props.type === 'warning' && css`background: rgba(229, 161, 75, 0.1);`}
  ${(props) => props.hidden && css`display: none;`}
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  > img {
   cursor: pointer;
  }

  div {
    display: flex;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 14px;
      margin-left: 0.5rem;
    }
  }
`;

export const Body = styled.div`
  padding: 0rem 1rem 1rem 1rem;
`;
