import React, { createContext, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getCompany } from 'service/company';

export interface IBreadcrumb {
  title: string;
  path?: string;
}

const BreadcrumbContext = createContext<any>([])

export const BreadcrumbProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState<IBreadcrumb[]>(getDefaultBradcrumbs())

  function getDefaultBradcrumbs(): IBreadcrumb[] {
    const defaultsBreadcrumbs: IBreadcrumb[] = [{ title: 'Site' }]
    const companyName = getCompany()?.name;

    const hash = location.hash;
    const pathname = location.pathname;
    if (pathname.includes('/assinantes/') || hash.includes('/assinantes/')) {
      defaultsBreadcrumbs.push({ title: 'Área do assinante', path: '/assinantes/home' })
    } else if (pathname.includes('/rh/') || hash.includes('/rh/')) {
      defaultsBreadcrumbs.push({ title: 'Portal do RH', path: '/rh/escolher-empresa' })
      if (companyName) defaultsBreadcrumbs.push({ title: companyName, path: '/rh/funcionarios' })
    }

    return defaultsBreadcrumbs
  }

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
        defaultBreadcrumbs: getDefaultBradcrumbs()
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
};

export function useBreadcrumb() {
  return useContext(BreadcrumbContext);
}
