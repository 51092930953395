import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface INavigationPage {
  active: boolean;
  show: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${COLORS.offblack};
    opacity: 0.3;
    margin: 0;
  }
`;

export const ContainerNavigation = styled.div`
  display: flex;
`;

export const NavigationButton = styled.div`
  background: ${COLORS.secondaryBg};
  border-radius: 0.5rem;
  height: 2rem;
  width: 2rem;
  border: 0;
  margin: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const NavigationPage = styled.div<INavigationPage>`
  background: ${COLORS.white};
  border-radius: 0.5rem;
  height: 2rem;
  width: 2rem;
  border: 0;
  margin: 0.4rem;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.1rem;
  color: ${COLORS.offblack};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => props.active && css`background: ${COLORS.accentColor};`}
  ${(props) => props.active && css`color: ${COLORS.white};`}
  ${(props) => !props.show && css`display: none;`}
`;
