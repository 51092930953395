import React from 'react';

import PurpleSmallCheckIcon from 'assets/icons/purple-small-check.svg';
import RedSmallCheckIcon from 'assets/icons/red-small-check.svg';

import {
  Line,
  Separator,
} from '../styles';

interface IBenefits {
  planType: string;
}

const Benefits: React.FC<IBenefits> = ({ planType }) => {
  function getPlusBenefits() {
    return (
      <div className="box-benefits">
        <Line>
          <span>Tudo que o Cartão Aliança Saúde oferece</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Consultas presenciais a partir de R$34,90</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Até 24 Consultas online no ano</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Seguro de acidentes pessoais</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Assistência</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Pacote de exames</span>
          <img src={RedSmallCheckIcon} alt="Icone verificado" />
        </Line>
      </div>
    )
  }

  function getStandardBenefits() {
    return (
      <div className="box-benefits">
        <Line>
          <span>Você e + 4 Dependentes</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Sem carência</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Exames laboratoriais e de imagem</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Descontos em parceiros</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Consulta Presencial</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
        <Separator />
        <Line>
          <span>Consulta Online</span>
          <img src={PurpleSmallCheckIcon} alt="Icone verificado" />
        </Line>
      </div>
    )
  }

  return planType === 'cartao-mais-anual' ? getPlusBenefits() : getStandardBenefits()
}

export default Benefits;
