import React, { FormEvent, useContext, useEffect, useState } from 'react';

import api from 'service/subscriberApi';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';

import { toast } from 'react-toastify';

import { Form, ActionsBox } from './styles';

interface IValueInput {
  value: string;
  isValid: boolean;
  errorMessage?: string;
}

const FormResetPassword: React.FC = () => {
  const FIELDNAMES = ['newPassword', 'password', 'passwordConfirmation']

  const [password, setPassword] = useState<IValueInput>({ value: '', isValid: true });
  const [newPassword, setNewPassword] = useState<IValueInput>({ value: '', isValid: true });
  const [passwordConfirmation, setPasswordConfirmation] = useState<IValueInput>({ value: '', isValid: true });
  const [invalidFields, setInvalidFields] = useState<string[]>(FIELDNAMES);
  const [message, setMessage] = useState('');
  const [validForm, setValidForm] = useState(false);

  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setValidForm(!invalidFields.length)
  }, [invalidFields])

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Dados Pessoais', path: `/assinantes/alterar-senha-logado` }
    ])
  }, []);

  async function submitForm(event: FormEvent) {
    event.preventDefault();

    try {
      setMessage('');

      const params = {
        password: password.value,
        new_password: newPassword.value,
        password_confirmation: passwordConfirmation.value,
      }

      await api.put('/users/auth/reset-password', params);

      setPassword({ isValid: true, value: '' });
      setNewPassword({ isValid: true, value: '' });
      setPasswordConfirmation({ isValid: true, value: '' });
      toast.success('Senha alterada com sucesso!');
    } catch (ex: any) {
      toast.error('Verifique os dados informados!')
      if (ex.response && ex.response.status === 400) {
        const { errors, error } = ex.response.data;
        if (!!error) {
          setMessage(error);
        } else {
          mountFormErrors(errors)
        }
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  function handleFieldsChange(field: string, value: string) {
    switch (field) {
      case 'password':
        return validPassword(field, value)
      case 'newPassword':
        return validCurrentPassword(field, value)
      case 'passwordConfirmation':
        return validPasswordConfirmation(field, value)
      default:
        return ''
    }
  }

  function validCurrentPassword(field: string, value: string) {
    if (!value) {
      setNewPassword({ value, isValid: false });
      setInvalidFields([...invalidFields, field])
    } else {
      setNewPassword({ value, isValid: true })
      setInvalidFields(invalidFields.filter(item => item !== field))
    }
  }

  function validPassword(field: string, value: string) {
    if (!value) {
      setPassword({ value, isValid: false });
      setInvalidFields([...invalidFields, field])
    } else {
      setPassword({ value, isValid: true })
      setInvalidFields(invalidFields.filter(item => item !== field))
    }
  }

  function validPasswordConfirmation(field: string, value: string) {
    if (!value) {
      setPasswordConfirmation({ value, isValid: false });
      setInvalidFields([...invalidFields, field])
    } else {
      setPasswordConfirmation({ value, isValid: true })
      setInvalidFields(invalidFields.filter(item => item !== field))
    }
  }

  function mountFormErrors(errors: any) {
    if (!!errors['current_password']) {
      const message = errors['current_password'][0];
      setPassword({ ...password, isValid: false, errorMessage: message });
    }

    if (!!errors['password']) {
      const message = errors['password'][0];
      setNewPassword({ ...newPassword, isValid: false, errorMessage: message });
    }

    if (!!errors['password_confirmation']) {
      const message = errors['password_confirmation'][0];
      setPasswordConfirmation({ ...passwordConfirmation, isValid: false, errorMessage: message });
    }
  }

  return (
    <Form onSubmit={submitForm}>
      <TextInput
        onChange={(value: string) => handleFieldsChange('password', value)}
        value={password.value}
        type="password"
        placeholder="Senha atual" />
      {!password.isValid &&
        <span className="error-message">{password.value ? password.errorMessage : 'Senha atual deve ser preenchida'}</span>}

      <TextInput
        onChange={(value: string) => handleFieldsChange('newPassword', value)}
        value={newPassword.value}
        type="password"
        placeholder="Nova senha" />
      {!newPassword.isValid &&
        <span className="error-message">{newPassword.value ? newPassword.errorMessage : 'Senha deve ser preenchida'}</span>}

      <TextInput
        onChange={(value: string) => handleFieldsChange('passwordConfirmation', value)}
        value={passwordConfirmation.value}
        type="password"
        placeholder="Confirmar a senha" />
      {!passwordConfirmation.isValid &&
        <span className="error-message">{passwordConfirmation.value ? passwordConfirmation.errorMessage : 'Confirmação de senha deve ser preenchida'}</span>}

      <span className="error-message">{message}</span>
      <ActionsBox>
        <PrimaryButton type="submit" active={validForm}>
          Salvar alteração
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
}

export default FormResetPassword;
