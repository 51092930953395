import React, { useState } from 'react';

import exclamationBlue from 'assets/icons/exclamation-blue.svg';
import exclamationWarning from 'assets/icons/exclamation-warning.svg';
import exclamationDanger from 'assets/icons/exclamation-danger.svg';

import closeBlue from 'assets/icons/close-blue.svg'
import closeWarning from 'assets/icons/close-warning.svg'
import closeDanger from 'assets/icons/close-danger.svg'

import { AlertContainer, Head, Body } from './styles';

interface IAlert {
  title: string;
  type: 'warning' | 'info' | 'danger';
  className?: string;
  onClose?: Function;
}

const Alert: React.FC<IAlert> = ({ title, onClose, type, children, className }) => {
  const [hidden, setHidden] = useState(false);

  function chooseCloseImage(): string {
    if (type === 'danger') {
      return closeDanger;
    } else if (type === 'warning') {
      return closeWarning;
    } else {
      return closeBlue;
    }
  }

  function chooseAlertImage(): string {
    if (type === 'danger') {
      return exclamationDanger;
    } else if (type === 'warning') {
      return exclamationWarning;
    } else {
      return exclamationBlue;
    }
  }

  function closeAlert() {
    if (!!onClose) {
      onClose();
    }
    setHidden(!hidden);
  }

  return (
    <AlertContainer type={type} hidden={hidden} className={className}>
      <Head>
        <div>
          <img src={chooseAlertImage()} alt="Atenção" />
          <p>{title}</p>
        </div>
        <img
          src={chooseCloseImage()}
          alt="Fechar"
          onClick={closeAlert} />
      </Head>
      {children && <Body>{children}</Body>}
    </AlertContainer>
  )
}

export default Alert;
