import styled, { css } from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

import backgroundVirtualCard from 'assets/icons/background-card-menu.svg'
import backgroundVirtualCardBlack from 'assets/icons/background-card-menu-black.svg'

export const Container = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  justify-content: center;
  max-width: 85rem;
  margin: auto;
  padding: 2rem 6rem;

  @media(${SIZES.smallerThanTablet}) {
    display: flex;
    grid-template-columns: 1fr;
    flex-direction: column-reverse;
  }

  @media(max-width: 1270px) {
    padding: 2rem 2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  border-radius: 6px;
  background: rgba(0, 172, 236, 0.1);
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  div {
    display: flex;

    span {
      font-size: 1.125rem;
      font-weight: 700;
      margin-left: 0.5rem;
    }
  }
`;

export const QuickAccessessSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  @media(${SIZES.smallerThanDesktophd}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const QuickAccessCard = styled.div`
  height: 9rem;
  background-color: ${COLORS.defaultBg};
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  min-width: 9rem;
  cursor: pointer;

  span {
    font-size: 0.875rem;
  }

  &:hover {
    background-color: ${COLORS.borderBase};
  }
`;

export const VirtualCardSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  min-width: 18.5rem;

  @media(${SIZES.smallerThanTablet}) {
    margin: auto;
    max-width: 27rem;
  }
`;

interface IPlanType {
  isPlus: boolean;
}

export const VirtualCard = styled.div<IPlanType>`
  display: grid;
  background-color: ${COLORS.backgroundBase};
  border-radius: 5px;
  min-height: 10.4375rem;
  ${(props) => props.isPlus ? css`background-image: url(${backgroundVirtualCardBlack});` 
                            : css`background-image: url(${backgroundVirtualCard});`}
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-out;
  cursor: zoom-out;

  &:hover {
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
`;

export const BoxLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.8rem;
  padding-top: 0.8rem;

  img {
    max-width: 6rem;
  }
`;

export const BoxInfos = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0.8rem;

  .content {
    width: 100%;

    p {
      color: ${COLORS.backgroundBase};
      margin: 0;
      border: 0;
      font-style: normal;
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 1.125rem;

      &.name {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .box-info-card {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }
`;

export const Separator = styled.div`
  height: 0.875rem;
  width: 0.063rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

export const StatusBox = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-size: 0.875rem;
  }
`;

export const Chip = styled.div`
  display: flex;
  place-content: center;
  background-color: ${COLORS.mediumGrey};
  width: fit-content;
  height: 1.2rem;
  padding: 0.4rem;
  border-radius: 2rem;
  align-items: center;

  img {
    width: 0.6rem;
    margin-right: 0.2rem;
  }

  span {
    color: white;
    text-align: center;
    font-size: 0.75rem;
  }
`;

export const ChipActive = styled(Chip)`
  background-color: ${COLORS.success};

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipCanceled = styled(Chip)`
  background-color:  ${COLORS.red};

  span {
    color: white;
    text-align: center;
  }
`;
