import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

interface IBoxErrors {
  showAllErrors?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 4rem;

  span {
    margin: 1rem 0;
    color: ${COLORS.offblack}
  }

  p {
    align-self: center;
  }

  a {
    color: ${COLORS.accentColor};
    text-decoration: none;
    cursor: pointer;
  }

  @media(${SIZES.smallerThanDesktop}) {
    padding: 1rem 2rem;
  }
`;

export const NotificationBox = styled.div`
  border-radius: 6px;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  div {
    display: flex;
  }

  span {
    margin-left: 0.5rem;
  }

  @media(${SIZES.smallerThanDesktop}) {
    height: 4rem;
  }
`;

export const SuccessBox = styled(NotificationBox)`
  background: rgba(7, 197, 133, 0.1);
`;

export const WarningBox = styled(NotificationBox)`
  background: rgba(240, 171, 59, 0.1);
`;

export const ErrorBox = styled(NotificationBox)`
  background: rgba(255, 0, 0, 0.1);
`;

export const DownloadErrorsReportBox = styled.div`
  background: ${COLORS.white};
  border-radius: 6px;
  border: 1px solid ${COLORS.backgroundBase};
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;

  @media(${SIZES.smallerThanDesktop}) {
    flex-direction: column;
    height: 10rem;
    padding: 1rem;

    .title {
      display: flex;
      flex-direction: row;
      min-width: 2rem;
      max-height: 2.5rem;

      img {
        max-width: 1rem;
        max-height: 1rem;
      }

      span {
        margin-left: 0.5rem;
      }
    }
  }

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .title {
    display: flex;
    flex-direction: row;
    span {
      margin-left: 0.5rem;
    }
  }
  span {
    margin: 0;
  }
`;

export const ContainerList = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.backgroundBase};
  height: 36rem;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CustomTable = styled.table<IBoxErrors>`
  width: calc(100% + 3rem);
  overflow-x: auto;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  thead {
    th {
      border-bottom: none;
      border-top: none;
      color: ${COLORS.offblack};
      font-size: 0.9rem;
      font-weight: 400;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: inherit;
      height: 3rem;

      img {
        margin-left: 0.5rem;
        width: 0.7rem;
        cursor: pointer;
      }

      &.ordering {
        cursor: pointer;
      }

      &:first-child {
        display: flex;
        padding-left: 1.75rem;

        img {
          height: 0.8rem;
          width: 0.8rem;
          cursor: pointer;
        }
      }

      &:last-child {
        padding-right: 1.75rem;
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: rgba(241, 123, 123, 0.1);
        height: 5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${COLORS.offblack};
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid ${COLORS.backgroundBase};
        border-bottom: 1px solid ${COLORS.backgroundBase};
        margin: 0 1rem;

        &:first-child {
          padding-left: 1.75rem;
          border-left: 1px solid ${COLORS.backgroundBase};
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }

        &:last-child {
          padding-right: 1.75rem;
          border-right: 1px solid ${COLORS.backgroundBase};
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          width: 6rem;

          div {
            display: flex;
            align-items: center;

            img {
              margin-right: 0.5rem;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(244, 244, 244, 0.6);
      }
    }
  }
`;

export const BoxErrors = styled.div<IBoxErrors>`
  .errors-box  {
    display: flex;
    height: ${props => props.showAllErrors ? '5.5rem' : '2.5rem'};
    flex-direction: column;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;

    div {
      flex-direction: row !important;
      align-items: center;

      span {
        margin: 0;
        margin-right: 1rem;
      }

      img {
        height: 0.7rem;
        margin-right: 0.3rem;
        width: 1rem;
      }
    }
  }
`;

export const BottomActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;
`;
