import styled, { css } from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

interface INavigationButton {
  type: "NEXT" | "BACK";
}

interface ISlide {
  active: boolean;
}

interface IBannersContainer {
  imageUrl: string | undefined;
}

export const BannersContainer = styled.div<IBannersContainer>`
  flex: 1;
  background: url(${(props) => props.imageUrl}) no-repeat center;
  background-size: cover;
  width: 100%;
  max-width: 50%;
  position: relative;
  -webkit-transition: background 1s ease-in-out;
  -moz-transition: background 1s ease-in-out;
  -o-transition: background 1s ease-in-out;
  transition: background 1s ease-in-out;

  @media(${SIZES.smallerThanTablet}) {
    display: none;
  }
`;

export const Slides = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3rem;
  right: 50%;
`;

export const Slide = styled.div<ISlide>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  ${(props) => props.active && css`background-color: ${COLORS.white};`}

  & + & {
    margin-left: 0.5rem;
  }
`;

export const NavigationButton = styled.div<INavigationButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  ${(props) => props.type === 'BACK' && css`left: 3rem;`}
  ${(props) => props.type === 'NEXT' && css`right: 3rem;`}

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;


