import React, { useState, useEffect } from 'react';

import SubscriberDashboard from 'components/SubscriberDashboard';
import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';

import WithoutSearch from './WithoutSearch';
import WithSearch from './WithSearch';

import close from 'assets/icons/partners/close.svg';
import searchIcon from 'assets/icons/partners/search.svg';
import locationIcon from 'assets/icons/partners/location.svg';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import { Container, SearchArea, SearchTagsArea, SearchTag } from './styles';

const Partners: React.FC = () => {
  const [search, setSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [classificationSearch, setClassificationSearch] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [partnerSearch, setPartnerSearch] = useState('');
  const [nearbyOnly, setNearbyOnly] = useState(false);
  const [favoriteOnly, setFavoriteOnly] = useState(false);
  const [hasAnySearch, setHasAnySearch] = useState(false);
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Rede de Parceiros', path: `/assinantes/rede-de-parceiros` },
    ]);
  }, []);

  useEffect(() => {
    verifySearchState();
  }, [
    search,
    locationSearch,
    classificationSearch,
    partnerSearch,
    partnerId,
    nearbyOnly,
    favoriteOnly,
  ]);

  function handleClassificationClick(classification: string) {
    setClassificationSearch(classification);
  }

  function handlePartnerClick(partnerId: string, partnerSearch: string) {
    setPartnerId(partnerId);
    setPartnerSearch(partnerSearch);
  }

  function clearPartnerSearch() {
    setPartnerSearch('');
    setPartnerId('');
  }

  function verifySearchState() {
    if (
      search ||
      locationSearch ||
      classificationSearch ||
      partnerSearch ||
      nearbyOnly ||
      favoriteOnly
    ) {
      setHasAnySearch(true);
    } else {
      setHasAnySearch(false);
    }
  }

  function renderSearchArea() {
    return (
      <>
        <SearchArea>
          <TextInput
            onChange={(value: string) => setSearch(value)}
            value={search}
            type="text"
            icon={searchIcon}
            iconAlt="Ícone de busca"
            iconPosition="left"
            placeholder="Buscar por especialidade ou parceiro"
          />
          <TextInput
            onChange={(value: string) => setLocationSearch(value)}
            value={locationSearch}
            type="text"
            icon={locationIcon}
            iconAlt="Ícone de busca"
            iconPosition="left"
            placeholder="Buscar por cidade, estado ou CEP"
          />
          <PrimaryButton type="button">Buscar</PrimaryButton>
        </SearchArea>
        <SearchTagsArea>
          {classificationSearch && (
            <SearchTag>
              <span>
                {classificationSearch}
                <img src={close} onClick={() => setClassificationSearch('')} />
              </span>
            </SearchTag>
          )}
          {partnerSearch && (
            <SearchTag>
              <span>
                {partnerSearch}
                <img src={close} onClick={clearPartnerSearch} />
              </span>
            </SearchTag>
          )}
          {nearbyOnly && (
            <SearchTag>
              <span>
                Perto de mim
                <img src={close} onClick={() => setNearbyOnly(false)} />
              </span>
            </SearchTag>
          )}
          {favoriteOnly && (
            <SearchTag>
              <span>
                Meus favoritos
                <img src={close} onClick={() => setFavoriteOnly(false)} />
              </span>
            </SearchTag>
          )}
          {search && (
            <SearchTag>
              <span>
                {search}
                <img src={close} onClick={() => setSearch('')} />
              </span>
            </SearchTag>
          )}
          {locationSearch && (
            <SearchTag>
              <span>
                {locationSearch}
                <img src={close} onClick={() => setLocationSearch('')} />
              </span>
            </SearchTag>
          )}
        </SearchTagsArea>
      </>
    );
  }

  return (
    <SubscriberDashboard title="Rede de Parceiros">
      <Container>
        <h2>Utilize o filtro para encontrar um parceiro</h2>
        {renderSearchArea()}
        {!hasAnySearch && (
          <WithoutSearch
            onClassificationClick={handleClassificationClick}
            onPartnerClick={handlePartnerClick}
            onNearbyClick={() => setNearbyOnly(true)}
            onFavoriteClick={() => setFavoriteOnly(true)}
          />
        )}
        {hasAnySearch && (
          <WithSearch
            search={search}
            locationSearch={locationSearch}
            classificationSearch={classificationSearch}
            partnerId={partnerId}
            nearbyOnly={nearbyOnly}
            favoriteOnly={favoriteOnly}
          />
        )}
      </Container>
    </SubscriberDashboard>
  );
};

export default Partners;
