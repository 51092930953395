import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';

import warningIcon from 'assets/warning-icon.svg';

interface INoticeModal {
  message: string;
  buttonMessage: string;
  onClose: Function;
}

const NoticeModal: React.FC<INoticeModal> = ({ message, onClose, buttonMessage }) => {
  return (
    <GenericModal height="11rem">
      <BodyModal>
        <img src={warningIcon} alt="Aviso." />
        <strong>{message}</strong>
        <p onClick={() => onClose()}>{buttonMessage}</p>
      </BodyModal>
    </GenericModal>
  );
};

export default NoticeModal;
