import React from 'react';
import { useHistory } from 'react-router-dom';

import { BodyModal, ButtonLabel } from './styles';

import GenericModal from 'components/GenericModal';
import SecondaryButton from 'components/SecondaryButton';

import successIcon from 'assets/success-icon.svg';
import iconPersonActive from 'assets/person-active.svg';
interface ISuccessModal {
  employeeId: number | undefined;
}

const SuccessModal: React.FC<ISuccessModal> = ({ employeeId }) => {
  const history = useHistory();

  return (
    <GenericModal>
      <BodyModal>
        <img src={successIcon} alt="Sucesso" />
        <strong>Funcionário cadastrado com sucesso!</strong>
        <SecondaryButton
          type="button"
          iconUrl={iconPersonActive}
          onClick={() => history.push(`/rh/funcionarios/${employeeId}/dependente/novo`)}
        >
          <ButtonLabel>
            Cadastrar Dependentes
          </ButtonLabel>
        </SecondaryButton>
        <p onClick={() => history.push('/rh/funcionarios')}>Cadastrar depois</p>
      </BodyModal>
    </GenericModal>
  );
};

export default SuccessModal;
