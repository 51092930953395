import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BtnLink from 'components/BtnLink';
import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';

import { validBirthDate, validCpf, validEmail } from 'utils';
import { IForm } from '..';

import { Form } from './styles';
import { useForm } from 'hooks/useForm';

interface IFormPersonalData {
  handleSubmitForm: (form: IForm) => void
  _setLoading: (isLoading: boolean) => void
  loading: boolean
  formErrors: any
}
interface User {
  name: string;
  cpf: string;
  email: string;
  'birth_date': string;
  phone: string;
}

const FormPersonalData: React.FC<IFormPersonalData> = ({
  handleSubmitForm,
  _setLoading,
  loading,
  formErrors
}) => {
  const history = useHistory();
  const [message, setMessage] = useState('');

  const { handleSubmit, handleChange, handleSubmitErrors, data: user, errors} = useForm<User>({
    validations: {
      name: {
        required: {
          value: true,
          message: 'Nome deve ser preenchido.'
        }
      },
      cpf: {
        required: {
          value: true,
          message: 'CPF deve ser preenchido.'
        },
        custom: {
          isValid: (value) => validCpf(value),
          message: 'CPF inválido.',
        },
      },
      email: {
        custom: {
          isValid: (value) => value ? validEmail(value) : true,
          message: 'E-mail inválido.',
        },
      },
      'birth_date': {
        required: {
          value: true,
          message: 'Data deve ser preenchida.'
        },
        custom: {
          isValid: (value) => validBirthDate(value),
          message: 'Data deve ser preenchida ou está inválida.',
        },
      },
      phone: {
        required: {
          value: true,
          message: 'Telefone deve ser preenchido ou está inválido'
        },
        custom: {
          isValid: (value) => [10, 11].includes(value.replace(/[^0-9]+/g, "").length),
          message: 'Telefone deve ser preenchido ou está inválido',
        },
      },
    },
    onSubmit: () => handleForm(),
  });

  useEffect(() => {
    handleSubmitErrors(formErrors)
  }, [formErrors])

  async function handleForm() {
    setMessage('');
    _setLoading(true);
    handleSubmitForm({
      name: user.name,
      email: user.email,
      cpf: user.cpf,
      phone: user.phone.replace(/[^0-9]+/g, ""),
      birth_date: user.birth_date,
    })
  }

  function formIsValid(){
    return !!user.name && !!user.cpf && !!user.birth_date && !!user.phone
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h3>Insira os dados pessoais do seu funcionário no formulário abaixo:</h3>

      <TextInput
        onChange={handleChange('name')}
        value={user.name}
        type="text"
        placeholder="Nome *"
        maxLength={255} />
      {errors.name && <span className="error">{errors.name}</span>}

      <TextInput
        onChange={handleChange('cpf')}
        type="text"
        placeholder="CPF *"
        value={user.cpf}
        maxLength={14}
        typeMask="cpf" />
      {errors.cpf && <span className="error">{errors.cpf}</span>}

      <TextInput
        onChange={handleChange('birth_date')}
        value={user.birth_date}
        type="text"
        placeholder="Data de nascimento *"
        maxLength={10}
        typeMask="date" />
      {errors.birth_date && <span className="error">{errors.birth_date}</span>}

      <TextInput
        onChange={handleChange('phone')}
        value={user.phone}
        type="text"
        placeholder="Telefone *"
        maxLength={15}
        typeMask="phone"/>
      {errors.phone && <span className="error">{errors.phone}</span>}

      <TextInput
        onChange={handleChange('email')}
        value={user.email}
        type="text"
        placeholder="E-mail"
        maxLength={100} />
      {errors.email && <span className="error">{errors.email}</span>}

      {message && <><br/><br/><span>{message}</span></>}
      <div className="actions">
        <BtnLink onClick={() => history.push('/rh/funcionarios')}>Cancelar</BtnLink>
        <PrimaryButton
          type="submit"
          active={formIsValid()}
          width="200px"
          loading={loading}>
          Avançar
        </PrimaryButton>
      </div>
    </Form>
  );
}

export default FormPersonalData;
