import React from 'react';

import { Input } from './styles';

interface ISearchInput {
  type: string;
  placeholder: string;
  value: string;
  onChange: Function;
  maxLength?: number;
}

const SearchInput: React.FC<ISearchInput> = ({ type, placeholder, value, onChange, maxLength }) => {
  if (!maxLength) {
    maxLength = 255;
  }

  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      maxLength={maxLength} />
  );
}

export default SearchInput;
