import styled from 'styled-components';

import search from 'assets/search.svg';

export const Input = styled.input`
  width: 100%;
  min-width: 15rem;
  border: 1px solid rgba(220, 220, 220, 0.6);
  border-radius: 6px;
  height: 2.5rem;
  padding: 10px 10px 10px 34px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 1rem;
`;
