import React, { FormEvent, useState } from 'react';
import { useHistory } from "react-router-dom";

import Banners from 'components/Banners';
import SiteHeaderMenu from 'components/SiteHeaderMenu';
import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';

import api from 'service/hrAccountApi';
import { saveToken, saveUser, getToken } from 'service/hrAccount';
import { setToogleMenu, useWindowSize, changeStateSideMenu } from 'utils';

import {
  ContainerBody,
  ContainerLogin,
  ChangeAccessText,
  ForgotPasswordText,
  Form,
} from './styles';

import { toast } from 'react-toastify';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const size = useWindowSize();

  const history = useHistory();
  const token = getToken();

  if (token) {
    history.push('/rh/escolher-empresa');
  }

  async function submitForm(event: FormEvent) {
    event.preventDefault();
    setMessage('');

    if (!email || !password) {
      setMessage('Por favor, preencha o e-mail e senha para continuar.');
      return;
    }

    try {
      const params = {
        email: email.trim(),
        password
      }

      const response = await api.post('/hr-account/auth', params);

      if (response.status === 200) {
        const { token, username, email, image_url } = response.data;
        saveToken(token);
        saveUser({ name: username, email, imageUrl: image_url });

        setToogleMenu(changeStateSideMenu(size?.width))
        history.push('/rh/escolher-empresa');
      } else {
        setMessage('Usuário ou senha incorretos. Por favor tente novamente.');
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status === 401) {
        setMessage(ex.response.data.error);
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  return (
    <>
      <SiteHeaderMenu />
      <ContainerBody>
        <Banners />
        <ContainerLogin>
          <Form onSubmit={submitForm}>
            <h1>Entrar</h1>
            <h5>Insira seu e-mail e senha para entrar na área logada de RH.</h5>

            <TextInput
              onChange={(value: string) => setEmail(value)}
              value={email}
              type="text"
              placeholder="E-mail" />
            <TextInput
              onChange={(value: string) => setPassword(value)}
              value={password}
              type="password"
              placeholder="Senha" />

            <span>{message}</span>

            <PrimaryButton type="submit">Entrar</PrimaryButton>

            <ForgotPasswordText onClick={() => history.push('/rh/esqueci-minha-senha')}>Esqueceu sua senha?</ForgotPasswordText>
            <ChangeAccessText onClick={() => history.push('/escolher-acesso')}>Mudar perfil de acesso</ChangeAccessText>
          </Form>
        </ContainerLogin>
      </ContainerBody>
    </>
  );
}

export default Login;
