import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface IBodyModal {
  height?: string;
  width?: string;
}

export const ContainerModal = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BodyModal = styled.div<IBodyModal>`
  height: 13.75rem;
  width: 20.625rem;
  background-color: ${COLORS.white};
  border-radius: 6px;
  padding: 2rem;
  z-index: 2;

  ${props => !!props.height && css`height: ${props.height};`}
  ${props => !!props.width && css`width: ${props.width};`}
`;
