import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { Container, ContainerList } from './styles';

import Accordion from 'components/Accordion';
import helpActive from 'assets/help-active.svg';

import api from 'service/hrAccountApi';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/HrAccountDashboard';
import Tabs, { ITabItems } from 'components/Tabs';

interface IFaq {
  id: number;
  question: string;
  answer: string;
}

const Faqs: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [faqs, setFaqs] = useState<IFaq[]>([]);
  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Buscando FAQs...');
  const history = useHistory();

  useEffect(() => {
    setBreadcrumb([...defaultBreadcrumbs, { title: 'FAQs', path: `/rh/faqs` }]);
    loadFaqs();
  }, []);

  function loadFaqs() {
    setLoading(true);
    setMessage('');

    api.get('/hr-account/faqs').then(response => {
      setLoading(false);
      if (response.data.faqs.length) {
        setFaqs(response.data.faqs);
      } else {
        setMessage('Nenhum FAQ encontrado!')
      }
    }).catch(() => {
      setLoading(false);
      setMessage('Erro ao buscar FAQs, tente novamente.')
    });
  };

  function getTabItems() {
    return [
      {
        id: uuidv4(),
        title: 'FAQs',
        path: '/rh/faqs',
        isActive: true,
        icons: {
          active: helpActive,
          inactive: helpActive,
        },
      },
    ];
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  return (
    <Dashboard title="FAQs" backPath="/rh/funcionarios">
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      <Container>
        <ContainerList>
          <>
            {!loading && faqs.map(faq => (
              <Accordion key={faq.id} title={faq.question}>
                <p>{faq.answer}</p>
              </Accordion>
            ))}
          </>
          {(loading || !faqs.length) && <p>{message}</p>}
        </ContainerList>
      </Container>
    </Dashboard>
  );
};

export default Faqs;
