import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';


export const Container = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;

  .cards-container-description {
    margin-top: 2rem;
  }
`;

export const TelemedicineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
  width: 100%;
  max-width: 78rem;
  left: 19.5rem;
  top: 10rem;
  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;
  
  @media(${SIZES.smallerThanDesktophd}) {
    display: grid;
    
    button {
      margin-top: 1rem;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.7rem;
  }

  p {
    width: 28.125rem;
    height: 1.3125rem;
    left: 0rem;
    top: 2.1875rem;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.3125rem;
    margin: 0.7rem 0rem;
  }

  .header-telemedicine {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .telemedicine-description {
    margin-left: 3rem;
  }
`;

export const CardsContainer = styled.section`
  max-width: 78rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 1rem;

  @media(${SIZES.smallerThanDesktophd}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 18rem;
  margin-top: 2rem;
  margin-right: 2rem;
  height: 17rem;
  width: 18rem;
  padding: 0.2rem 0.6rem 0.6rem 0.6rem;
  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;

  .card-content {
    margin-top: 2rem;
  }
  
  h4 {
    margin-top: 1.2rem;
  }

  p {
    margin-top: 0.8rem;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.3125rem;
  }
`;


