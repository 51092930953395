import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getUser } from 'service/subscriber';

import HeaderMenu from 'components/SubscriberHeaderMenu';
import SideMenu from 'components/SideMenu';

import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

import {
  Container,
  ContainerHeader,
  ContainerBody,
} from './styles';

import home from 'assets/icons/home.svg';
import creditCard from 'assets/icons/credit-card.svg';
import dependants from 'assets/icons/dependants.svg';
import like from 'assets/icons/like.svg';
import heart from 'assets/icons/heart.svg';
import additionalServices from 'assets/additional-services.svg';
import help from 'assets/help.svg';

interface ISubscriberDashboard {
  title?: string;
  backPath?: string;
  children: React.ReactNode;
}

export interface IMenuItem {
  id: string;
  isActive: boolean;
  name: string;
  path: string;
  logo: string;
  showAlert?: boolean;
}

const SubscriberDashboard: React.FC<ISubscriberDashboard> = (props) => {
  const location = useLocation();
  const [itemsMenu, setItemsMenu] = useState<IMenuItem[]>([]);
  const { showUserAlert, showDependantsAlert } = useItemsMenuSubscriber();

  useEffect(() => {
    setItemsMenu(getMenuItems());
  }, [showUserAlert, showDependantsAlert]);

  function getMenuItems(): IMenuItem[] {
    const menus: IMenuItem[] = [];
    menus.push(getHomeItem());
    menus.push(getMyPlanItem());
    menus.push(getMyDependantsItem());
    menus.push(getPartnersItem());
    menus.push(getMyHealthItem());
    if (getUser().isPlus) menus.push(getAdditionalServicesItem());

    return menus;
  }

  function getMyPlanItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive([
        'assinantesmeuplano',
        'assinantesdadospessoais',
        'assinantesdadosdepagamento',
        'assinantesnotasfiscais'
      ]),
      name: 'Meu plano',
      path: '/assinantes/meu-plano',
      logo: creditCard,
      showAlert: showUserAlert,
    }
  }

  function getHomeItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['assinanteshome']),
      name: 'Home',
      path: '/assinantes/home',
      logo: home,
    }
  }

  function getMyDependantsItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['assinantesdependentes']),
      name: 'Meus dependentes',
      path: '/assinantes/dependentes',
      logo: dependants,
      showAlert: showDependantsAlert,
    }
  }

  function getPartnersItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['']),
      name: 'Parceiros',
      path: '/assinantes/rede-de-parceiros',
      logo: like,
    }
  }

  function getMyHealthItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['']),
      name: 'Minha saúde',
      path: '/assinantes/minha-saude',
      logo: heart,
    }
  }

  function getAdditionalServicesItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['assinantessegurodevida']),
      name: 'Serviços adicionais',
      path: '/assinantes/seguro-de-vida',
      logo: additionalServices,
    }
  }

  function getFaqMenuItem(): IMenuItem {
    return {
      id: uuidv4(),
      isActive: checkIfActive(['assinantesfaqs']),
      name: 'FAQs',
      path: '/assinantes/home',
      logo: help
    }
  }

  function checkIfActive(paths: string[]): boolean {
    const pathname = location.pathname.replace(/\//g, '').replace(/-/g, '');

    return paths?.map(path => path.toLowerCase())?.includes(pathname);
  }

  return (
    <Container>
      <SideMenu menuItems={itemsMenu} helpItem={getFaqMenuItem()} />
      <ContainerHeader>
        <HeaderMenu title={props.title} backPath={props.backPath} />
        <ContainerBody>{props.children}</ContainerBody>
      </ContainerHeader>
    </Container>
  );
};

export default SubscriberDashboard;
