import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Form = styled.form`
  max-width: 31rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 28.125rem;
  min-height: 45rem;
  padding: 2rem;

  span {
    color: ${COLORS.red};
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  a {
    text-align: end;
    color: ${COLORS.accentColor};
    text-decoration: underline;
    margin-bottom: 1rem;
  }
`;

export const ActionsBox = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  p {
    cursor: pointer;
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }

  button {
    max-width: 10rem;
  }
`;

export const ContainerLoading = styled.p`
  text-align: center;
`;
