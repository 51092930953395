import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants'

interface IInput {
  iconPosition?: string;
  disabled: true | false
}

export const InputBox = styled.div<IInput>`
  display: flex;
  position: relative;

  img {
    margin-left: ${props => 
      props.iconPosition === 'right' && '-2rem !important'
    };
    position: ${props => 
      props.iconPosition === 'left' && 'absolute' ||
      props.iconPosition === 'right' && 'relative'
    };
    left: ${props => 
      props.iconPosition === 'left' && '0.6rem'
    };
    top: ${props => 
      props.iconPosition === 'left' && '50%'
    };
    transform: ${props => 
      props.iconPosition === 'left' && 'translateY(-50%)'
    };
    cursor: pointer;
    width: 1.3rem;
  }

  label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 13px);
    left: 0.625rem;
    transition: all .1s linear;
    background-color: white;
    ${(props) => props.disabled ? css`background-color: ${COLORS.secondaryBg};` : ''}
    padding: 0.313rem;
    box-sizing: border-box;
    font-size: 1rem;
    color: ${COLORS.mediumGrey};
  }
`;

export const Input = styled.input<IInput>`
  width: 100%;
  border: 1px solid ${COLORS.borderBase};
  border-radius: 0.375rem;
  height: 2.5rem;
  padding: ${props => 
    props.iconPosition === 'left' && '0.625rem 0.625rem 0.625rem 2.2rem' || '0.625rem'
  };
  color: ${COLORS.offblack};
  transition: all .2s linear;
  margin: 0 !important;
  position: relative;

  & + label {
    padding-left: ${props => 
      props.iconPosition === 'left' && '1.7rem'
    };
  }

  &::placeholder {
    color:transparent;
  }

  &:focus {
    border: 1px solid ${COLORS.accentColor};
    box-shadow: inset 1px 2px 4px rgba(147, 60, 133, 0.1), 0px 0px 8px rgba(147, 60, 133, 0.2);
  }

  &:focus + label, &:not(:placeholder-shown) + label {
    font-size: 0.75rem;
    color: ${COLORS.offblack};
    top: -0.55rem;
    padding:  0 0.3rem;
  }
`;
