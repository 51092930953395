import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { selectUrl, TypeURL } from "utils";

import SubscriberDashboard from "components/SubscriberDashboard";
import Accordion from 'components/Accordion';
import Tabs, { ITabItems } from 'components/Tabs';

import {
  Card,
  CardBody,
  Cards,
  Container, 
  IconAndDescription, 
  TitleCards,
} from "./styles";

import clockIcon from "assets/icons/clock.svg";
import residenceIcon from "assets/icons/residence.svg";
import carIcon from "assets/icons/car.svg";
import buildActiveIcon from "assets/icons/buildActive.svg";
import buildInactiveIcon from "assets/icons/buildInactive.svg";
import shieldActiveIcon from "assets/icons/shieldActive.svg";
import shieldInactiveIcon from "assets/icons/shieldInactive.svg";

import keyIcon from "assets/icons/technical-assistance/key.svg";
import toolsIcon from "assets/icons/technical-assistance/tools.svg";
import lampIcon from "assets/icons/technical-assistance/lamp.svg";
import curtainIcon from "assets/icons/technical-assistance/curtain.svg";

import brokenCarIcon from "assets/icons/technical-assistance/broken-car.svg";
import towCarIcon from "assets/icons/technical-assistance/tow-car.svg";
import tiresIcon from "assets/icons/technical-assistance/tires.svg";
import keyCarIcon from "assets/icons/technical-assistance/car-key.svg";
import gasolineIcon from "assets/icons/technical-assistance/gasoline.svg";

import { 
  brokenCarCard, 
  curtainCard, 
  gasolineCard, 
  keyCarCard, 
  keyCard, 
  lampCard, 
  tiresCard, 
  toolsCard, 
  towCarCard
} from "./TechnicalAssistanceCards";

const TechnicalAssistance: React.FC = () => {
  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const history = useHistory();

  function getTabItems() {
    return [
      {
        id: uuidv4(),
        title: 'Seguro de Vida',
        path: '/assinantes/seguro-de-vida',
        isActive: false,
        icons: {
          active: shieldActiveIcon,
          inactive: shieldInactiveIcon
        }
      },
      {
        id: uuidv4(),
        title: 'Assistência',
        path: '/assinantes/assistencia',
        isActive: true,
        icons: {
          active: buildActiveIcon,
          inactive: buildInactiveIcon,
        },
      }      
    ];
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }
  
  return (
    <SubscriberDashboard title="Serviços Adicionais" backPath="/assinantes/home">
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      <Container>
        <Card>
          <IconAndDescription>
            <img src={clockIcon} alt="Assistencia Tecnica"/>
            <p>Atendimento assistência 24h pelo <strong>0800 725 0960</strong></p>           
          </IconAndDescription>
            <div className="access-site">
              <a href={selectUrl(TypeURL.TEMPOASSIST)} target="_blank"><p>Visitar site</p></a>
            </div>
        </Card>
        <TitleCards>
          <img src={residenceIcon} alt="Residêncial"/>
          <strong>Residêncial</strong>
        </TitleCards>
        <Cards>
          <Accordion title="Chaveiro" titleColor="default" logo={keyIcon}>
            <CardBody>
              {keyCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Mão de Obra Hidraulica" titleColor="default" logo={toolsIcon}>
            <CardBody>
              {toolsCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Mão de Obra Elétrica" titleColor="default" logo={lampIcon}>
            <CardBody>
              {lampCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Vidraceiro" titleColor="default" logo={curtainIcon}>
            <CardBody>
              {curtainCard()}
            </CardBody>
          </Accordion>
        </Cards>
        <TitleCards>
          <img src={carIcon} alt="Auto/Moto"/>
          <strong>Auto/Moto</strong>
        </TitleCards>
        <Cards>
          <Accordion title="Auto Socorro após pane" titleColor="default" logo={brokenCarIcon}>
            <CardBody>
              {brokenCarCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Reboque ou recolha após pane ou evento previsto" titleColor="default" logo={towCarIcon}>
            <CardBody>
              {towCarCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Troca de pneus" titleColor="default" logo={tiresIcon}>
            <CardBody>
              {tiresCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Envio de chaveiro" titleColor="default" logo={keyCarIcon}>
            <CardBody>
              {keyCarCard()}
            </CardBody>
          </Accordion>
          <Accordion title="Pane seca" titleColor="default" logo={gasolineIcon}>
            <CardBody>
              {gasolineCard()}
            </CardBody>
          </Accordion>
        </Cards>
      </Container>
    </SubscriberDashboard>
  )
}

export default TechnicalAssistance;
