import styled from 'styled-components';

import { COLORS } from 'styles/constants';
import Icon from 'assets/checked.svg';

interface ICheckbox {
  checked: boolean;
}

export const CheckboxContainer = styled.div<ICheckbox>`
  border-radius: 5px;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${props => props.checked ? COLORS.accentColor : COLORS.borderBase};
  background-image: ${props => props.checked ? `url(${Icon})` : ''};
  background-size: 0.85rem;
  background-position: center;
  background-repeat: no-repeat;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
`;
