export function keyCard() {
  return(
    <>
      <p>Envio de chaveiro para conserto definitivo ou provisório da porta de acesso a residência ou confecção de chave, conforme evento ocorrido.</p>
      <br/>
      <p><span>Limite:</span> R$ 150 para evento previsto e para problema emergencial</p>
      <p><span>Evento:</span> Arrombamento, roubo ou furto. Perda, quebra de chaves na fechadura, roubo ou furto de chaves</p>
    </>
  );
}

export function toolsCard() {
  return(
    <>
      <p>Envio de encanador para resolver problemas de vazamentos em tubulações aparentes ou solucionar problemas que possam acarretar risco de alagamento na residência, conforme evento ocorrido.</p>
      <br/>
      <p><span>Limite:</span> R$ 150 para evento previsto e para problema emergencial</p>
      <p><span>Evento:</span> Alagamento. Vazamento em tubulações (aparentes) de 1 a 4 polegadas ou em dispositivos hidráulicos como: torneiras, sifões, chuveiros, válvulas de descarga, registro; entupimento de ramais internos em pias, ralos, vaos sanitários e tanques.</p>
    </>
  );
}

export function lampCard() {
  return(
    <>
      <p>Envio de eletricista para realizar reparos necessários para o restabelecimento da energia elétrica ou para solucionar problemas elétricos, conforme evento ocorrido.</p>
      <br/>
      <p><span>Limite:</span> R$ 150 para evento previsto e para problema emergencial</p>
      <p><span>Evento:</span> Raio, dano elétrico - caracterizado pela sobrecarga de energia. Problemas elétricos: tomadas queimadas. interruptores defeituosos, lâmpadas ou reatores queimados, disjuntores e fusíveis danificados, chaves facas, troca de chuveiros ou resistências de chuveiros ou torneiras elétricas (não blindados).</p>
    </>
  );
}

export function curtainCard() {
  return(
    <>
      <p>Envio de profissional para consertar portas ou janelas externas contendo a situação de risco, ou colocação de tapume, caso não seja possível a execução do serviço.</p>
      <br/>
      <p><span>Limite:</span> R$ 150</p>
      <p><span>Evento:</span> Quebra de vidros</p>
    </>
  );
}

export function brokenCarCard() {
  return(
    <>
      <p>Envio de mecânico para conserto no local do evento, desde que tecnicamente possível.</p>
      <br/>
      <p><span>Limite:</span> Até R$ 100</p>
      <p><span>Evento:</span> Pane</p>
    </>
  );
}

export function towCarCard() {
  return(
    <>
      <p>Remoção do veículo ou motocicleta até oficina, ou concessionária mais próxima, no raio máximo contratado, indicada pelo usuário. Caso exceda o limite de quilometragem, o usuário será responsável pelo pagamento do excedente de ida e volta do reboque.</p>
      <br/>
      <p><span>Limite:</span> Até 100 km no total para ida e volta</p>
      <p><span>Evento:</span> Acidente, roubo ou furto incendio ou pane.</p>
    </>
  );
}

export function tiresCard() {
  return(
    <>
      <p>Mão de obra para troca do pneu ou reboque até o borracheiro mais próximo. Despesas para conserto do pneu, câmara, aro, entre outras, por conta do usuário. Em caso de motocicleta será enviado automaticamente um reboque.</p>
      <br/>
      <p><span>Limite:</span> Somente mão de obra para troca de pneus</p>
      <p><span>Evento:</span> Pneu avariado.</p>
    </>
  );
}

export function keyCarCard() {
  return(
    <>
      <p>Mão de obra para abertura do veículo e confecção de uma chave, desde que tecnicamente possível. Caso não seja possível solucionar o problema, o veículo será removido, conforme limite deste serviço. Em caso de motocicleta será enviado automaticamente um reboque.</p>
      <br/>
      <p><span>Limite:</span> Até R$ 300,00 - mão de obra para abertura do veículo e/ou confecção de uma chave, se tecnicamente possivel.</p>
      <p><span>Evento:</span> Perda, roubo, furto, esquecimento das chaves no interior do vaículo ou quebra de ignição, fechadura ou na tranca de direção.</p>
    </>
  );
}

export function gasolineCard() {
  return(
    <>
      <p>Reboque até o posto de abastecimento mais próximo as despesas com combustível por conta do usuário.</p>
      <br/>
      <p><span>Limite:</span> Envio de reboque até o posto de abastecimento mais próximo.</p>
      <p><span>Evento:</span> Pane seca</p>
    </>
  );
}
