import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';

import successIcon from 'assets/success-icon.svg';

interface ISuccessModal {
  message: string;
  buttonMessage: string;
  onClose: Function;
}

const SuccessModal: React.FC<ISuccessModal> = ({ message, onClose, buttonMessage }) => {
  return (
    <GenericModal height="11rem">
      <BodyModal>
        <img src={successIcon} alt="Sucesso" />
        <strong>{message}</strong>
        <p onClick={() => onClose()}>{buttonMessage}</p>
      </BodyModal>
    </GenericModal>
  );
};

export default SuccessModal;
