import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface IContainerTab {
  active: boolean;
}

export const ContainerTabs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLORS.accentColor};
`;

export const ContainerCenter = styled.div`
  display: flex;
  width: 100%;
  padding: 0rem 3.75rem 0rem 3.75rem;
`;

export const ContainerTab = styled.div<IContainerTab>`
  background-color: ${COLORS.white};
  height: 2.5rem;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.offblack};
  cursor: pointer;

  ${(props) => !props.active && css`background-color: rgba(158, 79, 145, 1);`}
  ${(props) => !props.active && css`color: ${COLORS.white};`}

  img {
    margin-right: 0.5rem;
  }

  & + & {
    margin-left: 0.5rem;
  }
`;
