import { IOption } from "components/SelectInput";

export const genderOptions: IOption[] = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' }
];

export const chronicDiseasesOptions: IOption[] = [
  { value: 'dont_have', label: 'Não tenho' },
  { value: 'hypertension', label: 'Hipertensão' },
  { value: 'respiratory_diseases', label: 'Doenças Respiratórias (asma, bronquite, dpoc)' },
  { value: 'cancer', label: 'Câncer' },
  { value: 'mental_disorders', label: 'Transtornos mentais (depressão, ansiedade, esquizofrenia, etc)' },
  { value: 'chronic_pain', label: 'Dor crônica (enxaqueca, dor na lombar, etc)' },
  { value: 'others', label: 'Outras' }
];

export const familyChronicDiseasesOptions: IOption[] = [
  { value: 'nobody_has', label: 'Ninguém tem' },
  { value: 'dont_know_to_tell', label: 'Não sei dizer se alguém tem' },
  { value: 'hypertension_family', label: 'Hipertensão' },
  { value: 'respiratory_diseases_family', label: 'Doenças Respiratórias (asma, bronquite, dpoc)' },
  { value: 'cancer_family', label: 'Câncer' },
  { value: 'mental_disorders_family', label: 'Transtornos mentais (depressão, ansiedade, esquizofrenia, etc)' },
  { value: 'chronic_pain_family', label: 'Dor crônica (enxaqueca, dor na lombar, etc)' },
  { value: 'others_family', label: 'Outras' }
];

export const useMedicationOptions: IOption[] = [
  { value: 'false', label: 'Não' },
  { value: 'true', label: 'Sim' },
];

export const cigarettesAmountOptions: IOption[] = [
  { value: 'dont_smoke', label: 'Não fumo' },
  { value: 'between_1_to_5', label: 'Entre 1 a 5 por dia' },
  { value: 'between_6_to_10', label: 'Entre 6 a 10 por dia' },
  { value: 'more_than_10', label: 'Mais de 10 por dia' },
];

export const drinkAlcoholOptions: IOption[] = [
  { value: 'dont_drink', label: 'Não bebo' },
  { value: 'between_1_to_3_days', label: 'Entre 1 a 3 dias por semana' },
  { value: 'between_4_to_6_days', label: 'Entre 4 a 6 dias por semana' },
  { value: 'the_7_days_a_week', label: '7 dias por semana' },
];

export const physicalActivityOptions: IOption[] = [
  { value: 'dont_practice', label: 'Não pratico' },
  { value: 'between_1_to_2_days', label: 'Entre 1 a 2 dias por semana' },
  { value: 'between_3_to_4_days', label: 'Entre 3 a 4 dias por semana' },
  { value: 'more_than_4_days', label: 'Mais de 4 dias por semana' },
];

export const sleepQualityOptions: IOption[] = [
  { value: 'good_sleep_night', label: 'Tenho uma noite de sono traquila' },
  { value: 'wake_up_sometimes', label: 'Acordo algumas vezes durante a noite' },
  { value: 'difficulty_sleeping', label: 'Tenho dificuldade para dormir' },
  { value: 'have_insomnia', label: 'Tenho insônia' },
];

export const foodQualityOptions: IOption[] = [
  { value: 'bad_food_quality', label: 'Minha alimentação não é equilibrada e não tenho uma rotina alimentar' },
  { value: 'sometimes_good_food_quality', label: 'Mantenho uma alimentação equilibrada na maior parte do tempo, mas tenho fases sem rotina' },
  { value: 'good_food_quality', label: 'Minha alimentação é bem equilibrada e raramente saio dessa rotina' },
  { value: 'very_good_food_quality', label: 'Sigo uma dieta restritiva' },
];

export const healthQualityOptions: IOption[] = [
  { value: 'bad', label: 'Ruim' },
  { value: 'normal', label: 'Regular' },
  { value: 'good', label: 'Bom' },
  { value: 'excellent', label: 'Ótimo' },
];

export const mentalHealthQualityOptions: IOption[] = [
  { value: 'diagnosed_condition_and_medication', label: 'Tenho quadro diagnosticado e tomando medicação' },
  { value: 'diagnosed_condition_no_medication', label: 'Recebi diagnóstico, mas não estou tomando medicação' },
  { value: 'no_diagnosed_condition', label: 'Não recebi diagnóstico, mas não me sinto mentalmente bem, no momento' },
  { value: 'good_at_the_moment', label: 'Estou bem, no momento' },
];

export const lastAppointmentOptions: IOption[] = [
  { value: 'last_three_months', label: 'Nos últimos 3 meses' },
  { value: 'last_six_months', label: 'Nos últimos 6 meses' },
  { value: 'last_twelve_months', label: 'Nos últimos 12 meses' },
  { value: 'dont_remember', label: 'Não me lembro' },
];