import styled from 'styled-components';

import { COLORS, SIZES } from 'styles/constants';

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  background: ${COLORS.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 1rem;

  .links-site {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 7.5rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    ul {
      display: flex;
      list-style-type: none;

      @media(${SIZES.smallerThanTablet}) {
        display: none;
      }

      li {
        margin-left: 1rem;

        a {
          text-decoration: none;
          cursor: pointer;
          font-style: normal;
          font-weight: 300;
          font-size: 1rem;
          line-height: 24px;
          color: ${COLORS.offblack};
        }
      }
    }
  }

  .links-login {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(${SIZES.smallerThanTablet}) {
      span {
        display: none;
      }

      .login {
        display: none;
      }
    }

    span {
      background-color: rgba(153, 153, 153, 0.4);
      width: 0.063rem;
      height: 25px;
      margin-right: 1rem;
    }

    .login {
      color: ${COLORS.accentColor};
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 24px;

      &:hover {
        opacity: 0.9;
      }
    }

    .card {
      background: ${COLORS.accentColor};
      border-radius: 6px;
      height: 3rem;
      width: 11.25rem;
      color: ${COLORS.white};
      border: 0;
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 24px;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
