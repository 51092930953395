import styled from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

interface IStep {
  active: boolean;
}

export const BaseModal = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentModal = styled.div`
  width: 65rem;
  background-color: ${COLORS.white};
  border-radius: 0.5rem;
  text-align: center;
  padding: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-botton {
    margin-left: auto;
  }

  button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
`;

export const Title = styled.span`
  font-size: 1.313rem;
  font-weight: 700;
  line-height: 1.938rem;
  margin-right: auto;
`;

export const SubTitle = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: auto;
  margin-bottom: 2rem;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    margin-bottom: 0.5rem;
  }
`;

export const StepCard = styled.div`
  width: 100%;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1rem;
`;

export const Step = styled.div<IStep>`
  display: flex;
  opacity: ${(props) => props.active ? 'none' : 0.5 };
  pointer-events: ${(props) => props.active ? 'all' : 'none' };
`;

export const ProgressBar = styled.div`
  width: 6.25rem;
  display: flex;
  justify-content: center;
`;

export const StepCircle  = styled.div<IStep>`
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 3.125rem;
  background-color: ${(props) => props.active ? COLORS.accentColor : COLORS.mediumGrey };
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1.5rem;
  position: absolute;
`;

export const StepLine = styled.div<IStep>`
  width: 0.063rem;
  height: auto;
  background-color: ${ (props) => props.active ? COLORS.accentColor : COLORS.mediumGrey };
  margin-bottom: -1.5rem;
  margin-top: 1.5rem;

  &.last {
    margin-top: 0rem;
    height: 1.5rem;
  }
`;

export const HeaderStepCard = styled.div`
  display: flex;
  height: 2rem;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;

  span {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  img {
    margin-left: auto;
  }
`;

export const BodyExpandedCard = styled.div`
  display: flex;
  padding: 0 1.5rem 1.5rem;

  .box-benefits {
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
  }

  .payment-area {
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 0.5rem;
    width: 100%;
    justify-content: center;

    .box-image {
      display: flex;
      place-content: center;

      img {
        display: flex;
        align-self: center;
        width: 10rem;
        height: 10rem;
      }
    }
  }

  .contract-terms {
    p {
      text-align: left;
    }

    .red {
      color: ${COLORS.red};
    }

    .centered {
      text-align: center;
    }

    .disclaimer {
      border: solid 0.05rem ${COLORS.darkGrey};
    }

    b {
      color: ${COLORS.offblack};
      font-weight: 500;
    }

    .modal-dialog {
      max-width: 70vw;
      max-height: 80vh;
      height: 80vh !important;

      @media screen and(${SIZES.smallerThanTablet}) {
        max-width: 100vw;
        max-height: 90vh;
      }
    }

    .modal-content {
      border-radius: 1rem;

      @media screen and(${SIZES.smallerThanTablet}) {
        height: 90vh;
        max-height: 90vh;
      }

      .modal-header {
        border-bottom: none;

        .close {
          opacity: 1.0;
        }
      }

      .modal-body {
        padding: 0 2rem 1.5rem 2rem;

        @media screen and(${SIZES.smallerThanTablet}) {
          padding: 1rem;
          margin-top: 0;
        }

        h2 {
          font-size: 1.5rem;
          padding-bottom: 0.5rem;
        }

        .pop-up-image {
          padding: 2rem;
          max-width: 100%;
        }

        .custom-control-label {
          font-weight: 300;
        }

        .purple-text {
          color: ${COLORS.accentColor};
          font-weight: 500;
        }
      }
    }

    .contract-text-area {
      width: 100%;
      height: 50vh;
      max-height: 50vh;
      padding: 1rem;
      border: 1px solid ${COLORS.borderBase};
      box-sizing: border-box;
      border-radius: 6px;
      color: grey;
      font-size: 12px;
      line-height: 18px;
      overflow-y: auto;

      @media screen and(${SIZES.smallerThanTablet}) {
        max-width: 100vw;
        width: 100%;
        max-height: 30vh;
      }
    }
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorMessage = styled.p`
  align-items: flex-start;
  color: ${COLORS.red};
  display: flex;
  font-size: 12px;
`

export const ContractTermsBox = styled.div`
  width: 100%;
  height: 40vh;
  max-height: 30vh;
  padding: 1rem;
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
  color: grey;
  font-size: 12px;
  line-height: 18px;
  overflow-y: auto;

  @media screen and(${SIZES.smallerThanTablet}) {
    max-width: 100vw;
    width: 100%;
    max-height: 30vh;
  }

  p {
    text-align: left;
  }

  b {
    color: ${COLORS.offblack};
    font-weight: 500;
  }

  .red {
    color: ${COLORS.red};
  }

  .centered {
    text-align: center;
  }

  .disclaimer {
    border: solid 0.05rem ${COLORS.darkGrey};
  }
`;

export const AcceptTermsRow = styled.div`
  background-color: ${COLORS.defaultBg};
  border-radius: 10px;
  padding: 1rem;
  font-weight: 200;
  color: ${COLORS.darkGrey};
  display: flex;
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1rem;

  .custom-control-label, .check-box-label {
    display: flex;
    font-size: 13px;
    margin-top: 0.1rem;
    padding-left: 0.5rem;
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 20rem;

  span {
    font-size: 0.875rem;
  }

  img {
    width: 1rem;
  }
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: solid 0.01rem ${COLORS.borderBase};
`;

export const PlanBoxes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;

  button {
    margin-left: auto;
    margin-top: auto;
  }
`;

export const PlanBox = styled.div`
  display: flex;
  padding: 0 1.5rem 1.5rem;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;

  input {
    margin-right: 1rem;
  }


  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    span {
      font-size: 1rem;
      line-height: 1.5rem;

      strong {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }

  &:hover {
    background-color: ${COLORS.borderBase};
  }
`;

export const Form = styled.form`
  max-width: 30rem;
  width: 100%;

  div + div {
    margin-top: 0;
  }

  .inputs-inline {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;

    input {
      width: 14.5rem;
    }
  }
`;

export const BoxLogo = styled.div`
  display: flex;
  margin-bottom: 1rem !important;

  .active {
    opacity: initial;
  }

  img {
    opacity: 0.4;
  }

  img + img {
    margin-left: 0.5rem;
  }
`;

export const PaymentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125%;
  border: 1px solid ${COLORS.borderBase};
  padding: 1rem;
  border-radius: 6px;

  .selected-card-data {
    display: flex;
    align-items: center;
    margin: 0;

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: ${COLORS.mediumGrey};
      margin-left: 0.5rem;
    }
  }

  .change-card {
    display: flex;
    margin: 0;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: ${COLORS.accentColor};
      text-decoration: underline;
      margin-right: 0.5rem;
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }
`;
