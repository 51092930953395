import styled, { css } from 'styled-components';

import { COLORS } from 'styles/constants';

interface IContainerButton {
  height?: string;
  width?: string;
  active?: boolean;
  icon?: string;
  text?: boolean;
  positionIcon?: string;
}

export const Loading = styled.div`
  border: 2px solid ${COLORS.lightAccentColor};
  border-radius: 50% !important;
  border-top: 2px solid ${COLORS.white};
  width: 1.375rem !important;
  height: 1.375rem !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 !important;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ContainerButton = styled.button<IContainerButton>`
  background: ${props => props.active ? COLORS.accentColor : COLORS.borderBase};
  border-radius: 6px;
  height: ${props => props.height};
  width: ${props => props.width};
  color: ${props => props.active ? COLORS.white : COLORS.mediumGrey};
  border: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${props => props.active ? 'all' : 'none'};

  img {
    ${props => props.icon && props.text && props.positionIcon === 'right' ? 
    css`margin-left: 0.8rem;` : '' };

    margin-right: ${props => props.icon && !props.text ? '' : '0.5rem'};
  }

  &:hover {
    opacity: 0.9;
  }
`;

ContainerButton.defaultProps = {
  height: '3rem',
  width: '100%'
}
