import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  ContainerBox,
  ContainerList,
  CustomTable
} from './styles';

import api from 'service/hrAccountApi';
import { getCompanyId } from 'service/company';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/HrAccountDashboard';
import Tabs, { ITabItems } from 'components/Tabs';
import Badge from 'components/Badge';

import iconProfileActive from 'assets/icon-profile-active.svg';
import iconProfileInactive from 'assets/icon-profile-inactive.svg';
import iconPersonActive from 'assets/person-active.svg';
import iconPersonInactive from 'assets/person-inactive.svg';

import { formatDate } from 'utils';

interface IEmployee {
  id: number;
  name: string;
}

interface IDependant {
  id: number;
  name: string;
  cpf: string;
  birthDate: string,
  active: boolean;
  checked: boolean;
}

const Dependants: React.FC = (props: any) => {
  const history = useHistory();
  const employeeId = props.match.params.id;
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  const [tabItems] = useState<ITabItems[]>(getTabItems);
  const [dependants, setDependants] = useState<IDependant[]>([]);
  const [employee, setEmployee] = useState<IEmployee>();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadEmployeeWithDependants();
  }, []);

  useEffect(() => {
    if (!employee) return;

    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: employee.name, path: `/rh/funcionarios/${employeeId}` },
      { title: 'Dependentes', path: `/rh/funcionarios/${employeeId}/dependentes` },
    ]);
  }, [employee]);

  function getTabItems() {
    return [
      {
        id: uuidv4(),
        title: 'Dados pessoais',
        path: `/rh/funcionarios/${employeeId}`,
        isActive: false,
        icons: {
          active: iconPersonActive,
          inactive: iconPersonInactive
        }
      },
      {
        id: uuidv4(),
        title: 'Dependentes',
        path: `/rh/funcionarios/${employeeId}/dependentes`,
        isActive: true,
        icons: {
          active: iconProfileActive,
          inactive: iconProfileInactive
        }
      }
    ];
  }

  function loadEmployeeWithDependants() {
    setLoading(true);
    setMessage('');

    api
      .get(`/hr-account/companies/${getCompanyId()}/users/${employeeId}`)
      .then(response => {
        setEmployee(response.data.user);
        const dependants: IDependant[] = response.data.dependants || [];
        setDependants(dependants.map(dependant => formatDependant(dependant)));

        if (!dependants.length) {
          setMessage('Nenhum dependente encontrado.');
        }
      })
      .catch(_error => {
        setLoading(false);
        setMessage(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }

  function formatDependant(dependant: any): IDependant {
    return {
      id: dependant.id,
      name: dependant.name,
      cpf: dependant.cpf,
      birthDate: dependant.birth_date,
      active: dependant.active,
      checked: false,
    };
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function handleDependantDetails(dependantId: number) {
    history.push(`/rh/funcionarios/${employeeId}/dependentes/${dependantId}`);
  }

  return (
    <Dashboard
      title={employee?.name}
      backPath={`/rh/funcionarios/${employeeId}`}
    >
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      <Container>
        {!dependants.length && loading ? (
          <ContainerBox>
            <p>Este funcionário não possui dependentes.</p>
          </ContainerBox>
        ) : (
          <ContainerList>
            <CustomTable>
              <thead>
                <tr>
                <th scope="col"></th>
                  <th scope="col">NOME</th>
                  <th scope="col">CPF</th>
                  <th scope="col">DATA DE NASCIMENTO</th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {dependants.map(dependant => (
                  <tr key={dependant.id}>
                    <td></td>
                    <td onClick={() => handleDependantDetails(dependant.id)}>
                      {dependant.name}
                    </td>
                    <td onClick={() => handleDependantDetails(dependant.id)}>
                      {dependant.cpf}
                    </td>
                    <td onClick={() => handleDependantDetails(dependant.id)}>
                      { formatDate(dependant.birthDate)}
                    </td>
                    <td onClick={() => handleDependantDetails(dependant.id)}>
                      {dependant.active && <Badge type="success">ATIVO</Badge>}
                      {!dependant.active && <Badge type="error">INATIVO</Badge>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </CustomTable>
            {loading && <p>{message}</p>}
          </ContainerList>
        )}
      </Container>
    </Dashboard>
  );
};

export default Dependants;
