import styled from 'styled-components';
import { COLORS } from 'styles/constants';


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.75rem 1rem 3.75rem;
`;

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  align-items: center; 
  align-self: center;
  margin-top: 2rem;

  > img {
    align-self: flex-end;
    width: 1.4rem;
    cursor: pointer;
  }

  p {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: ${COLORS.defaultText};
  }
  
  .header-content {
    text-align: center;

    h3, img {
      margin-top: 1.5rem;
    }

    img {
      width: 10rem;
    }
  }
`;