import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';
import SecondaryButton from 'components/SecondaryButton';

import successIcon from 'assets/success-icon.svg';
import iconDownload from 'assets/icons/download-active.svg';

interface ISuccessModal {
  onClose: Function;
}

const SuccessModal: React.FC<ISuccessModal> = ({ onClose }) => {
  return (
    <GenericModal>
      <BodyModal>
        <img src={successIcon} alt="Sucesso" />
        <strong>Relatório gerado com sucesso!</strong>
        <SecondaryButton
          type="button"
          iconUrl={iconDownload}
          width="80%"
          onClick={() => onClose()}
        >
          Fazer Download
        </SecondaryButton>
      </BodyModal>
    </GenericModal>
  );
};

export default SuccessModal;
