import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { selectUrl, TypeURL } from 'utils';

import SiteHeaderMenu from 'components/SiteHeaderMenu';

import iconProfile from 'assets/icon-profile.svg';
import iconChooseAccess from 'assets/choose-access.svg';

import {
  ContainerBody,
  ContainerLinks,
  BannersContainer,
  OptionAccess,
  BoxOptionAccess,
  Info,
} from './styles';

interface IAccess {
  id: string;
  title: string;
  description: string;
  icon: string;
  path: string;
}

const ChooseAccess: React.FC = () => {
  const [accesses] = useState<IAccess[]>(getAccesses);
  const history = useHistory();

  function getAccesses(): IAccess[] {
    return [
      {
        id: uuidv4(),
        title: 'Assinante',
        description: 'Titular do plano',
        icon: iconProfile,
        path: process.env.REACT_APP_SUBSCRIBER_AREA || ''
      },
      {
        id: uuidv4(),
        title: 'Dependente',
        description: 'Dependente do plano',
        icon: iconProfile,
        path: process.env.REACT_APP_DEPENDANT_AREA || ''
      },
      {
        id: uuidv4(),
        title: 'Portal do RH',
        description: 'Perfil empresarial',
        icon: iconProfile,
        path: `/rh/login`
      },
    ];
  }

  function redirect(path: string) {
    if (path.includes('http')) {
      window.location.href = path;
    } else {
      history.push(path);
    }
  }

  return (
    <>
      <SiteHeaderMenu />
      <ContainerBody>
        <BannersContainer href={selectUrl(TypeURL.CHOOSE_PLAN)} />
        <ContainerLinks>
          <BoxOptionAccess>
            <h1>Escolha o seu tipo de acesso</h1>
            <br />
            {
              accesses.map((access) => (
                <OptionAccess key={access.id} onClick={() => redirect(access.path)}>
                  <Info>
                    <span>
                      <img src={access.icon} alt="Assinante" />
                    </span>
                    <div>
                      <h2>{access.title}</h2>
                      <p>{access.description}</p>
                    </div>
                  </Info>
                  <img src={iconChooseAccess} alt="Seta para direita" />
                </OptionAccess>
              ))
            }
          </BoxOptionAccess>
        </ContainerLinks>
      </ContainerBody>
    </>
  )
}

export default ChooseAccess;
