import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  SuccessBox,
  WarningBox,
  ErrorBox,
  DownloadErrorsReportBox,
  ContainerList,
  CustomTable,
  BoxErrors,
  BottomActions
} from './styles';

import { getCompanyId } from "service/company";
import api from 'service/hrAccountApi';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import { pluralizeText } from "utils";

import Dashboard from 'components/HrAccountDashboard'
import SecondaryButton from 'components/SecondaryButton';
import PrimaryButton from 'components/PrimaryButton';
import Pagination from 'components/Pagination';

import redCloseIcon from 'assets/small-close-red-icon.svg';
import successIcon from 'assets/small-success-icon.svg';
import warningIcon from 'assets/small-warning-icon.svg';
import errorIcon from 'assets/small-error-icon.svg';
import downloadIcon from 'assets/download-icon.svg';
import arrowDown from 'assets/arrow-down-black.svg';
import arrowUp from 'assets/arrow-up.svg';

import { toast } from "react-toastify";

interface IEmployee {
  id: string;
  name: string;
  dependants: Array<any>;
  errors: Array<any>;
  showAllErrors: boolean;
}

interface INotification {
  id: number;
  title: string;
  status: string;
  content: object;
}

const ShowImport: React.FC = (props: any) => {
  const notificationId = props.match.params.notification_id;

  const [notification, setNotification] = useState<INotification>();
  const [persistedEmployees, setPersistedEmployees] = useState<IEmployee[]>([]);
  const [updatedEmployees, setUpdatedEmployees] = useState<IEmployee[]>([]);
  const [employeesWithErrors, setEmployeesWithErrors] = useState<IEmployee[]>([]);
  const [fileError, setFileError] = useState();
  const [errorsReportUrl, setErrorsReportUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(4);
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: `/rh/funcionarios` },
      { title: 'Importar Funcionários', path: `/rh/importar-funcionarios` },
      { title: 'Resultado da Importação', path: `/rh/resultado-importacao/${notificationId}` }
    ]);

    loadNotification()
  }, [currentPage, notificationId])

  function loadNotification() {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${getCompanyId()}/import-status/${notificationId}?`
    url += `page=${currentPage}&`;
    url += `per_page=${perPage}&`;

    api.get(url)
      .then((response) => {
        if (response.data.notification) {
          setLoading(false);
          setMessage('');
          setNotification(response.data.notification)
          setPersistedEmployees(response.data.notification.content.persisted_users)
          setUpdatedEmployees(response.data.notification.content.updated_users)
          setTotalCount(response.data.total_count);
          setTotalPages(response.data.total_pages);
          setPerPage(response.data.per_page);
          setFileError(response.data.notification.content.file_error)

          const employees: IEmployee[] = response.data.users_with_errors || [];
          setEmployeesWithErrors(employees.map((employee) => formatEmployee(employee)));
        } else {
          setLoading(false);
          setMessage('Nenhuma notificação encontrada!');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatEmployee(employee: any): IEmployee {
    return {
      id: uuidv4(),
      name: employee.name,
      errors: employee.errors,
      dependants: employee.dependants,
      showAllErrors: false
    }
  }

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }

  function handleErrors(employee: IEmployee) {
    var errors = []

    if (employee?.errors?.length) {
      errors.push(returnEmployeeErrors(employee))
    }
    if (employee?.dependants?.length || employee?.errors?.hasOwnProperty('Inclusão & Exclusão')) {
      errors.push(returnDependantsErrors(employee))
    }

    return errors
  }

  function returnEmployeeErrors(employee: IEmployee) {
    return employee.errors.map((error) => { return error })
  }

  function returnDependantsErrors(employee: IEmployee) {
    return Object.entries(employee.errors).map(([key, value]) => {
      if (!Array.isArray(value) || !value?.length) return []

      return `${key}: ${value.join(', ')}`
    })
  }

  function handleBoxErrors(id: string) {
    const employees: IEmployee[] = employeesWithErrors.map((employee) => {
      if (employee.id === id) {
        return { ...employee, showAllErrors: !employee.showAllErrors }
      }

      return employee
    })

    setEmployeesWithErrors(employees)
  }

  function handleErrorsReportDownload() {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${getCompanyId()}/notification/${notificationId}/errors-report`;

    api.get(url)
      .then((response) => {
        setLoading(false);
        setMessage('');
        setErrorsReportUrl(response.data.file_url)
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function renderSmallBoxErrors(employee: IEmployee) {
    return (
      <BoxErrors showAllErrors={employee.showAllErrors} >
        <span>{employee.name}</span>
        <div className="errors-box">
          {handleErrors(employee).map((errors) => (
            errors.slice(0, 2)?.map((error, index) => (
              <React.Fragment key={index}>
                {error.length > 0  &&
                  <div>
                    <img src={errorIcon} alt="icone de erro." />
                    <span>{error}</span>
                  </div>}
              </React.Fragment>
            ))
          ))}
        </div>
        {handleErrors(employee)[0]?.length > 2 &&
          < span onClick={() => handleBoxErrors(employee.id)}>
            Mostrar mais <img src={arrowDown} alt="Seta para baixo." />
          </span>}
      </BoxErrors>
    )
  }

  function renderLargeBoxErrors(employee: IEmployee) {
    return (
      <BoxErrors showAllErrors={employee.showAllErrors}>
        <span>{employee.name}</span>
        <div className="errors-box">
          {handleErrors(employee).map((errors) => (
            errors.map((error, index) => (
              <React.Fragment key={index}>
                {error?.length > 0 &&
                  <div>
                    <img src={errorIcon} alt="icone de erro." />
                    <span>{error}</span>
                  </div>}
              </React.Fragment>
            ))
          ))}
        </div>
        <span onClick={() => handleBoxErrors(employee.id)}>
          Mostrar menos <img src={arrowUp} alt="seta para cima." />
        </span>
      </BoxErrors>
    )
  }

  function getMessage() {
    if (!updatedEmployees?.length && persistedEmployees?.length && !employeesWithErrors?.length) {
      const count = persistedEmployees.length

      return (
        <span>
          <strong>{count}</strong> {pluralizeText(count, 'funcionários ', 'funcionário ')}
          {pluralizeText(count, 'foram', 'foi')} {pluralizeText(count, 'importados', 'importado')} com sucesso!
        </span>
      )
    }

    if (!persistedEmployees?.length && updatedEmployees?.length && !employeesWithErrors?.length) {
      const count = updatedEmployees.length

      return (
        <span>
          <strong>{count}</strong> {pluralizeText(count, 'funcionários ', 'funcionário ')}
          {pluralizeText(count, 'foram', 'foi')} {pluralizeText(count, 'atualizados', 'atualizado')} com sucesso!
        </span>
      )
    }

    if (!persistedEmployees?.length && !updatedEmployees?.length && employeesWithErrors?.length) {
      const count = employeesWithErrors.length

      return (
        <span>
          <strong>{count}</strong> {pluralizeText(count, 'funcionários ', 'funcionário ')}
          {pluralizeText(count, 'estão', 'está')} com {pluralizeText(employeesWithErrors.length, 'erros', 'erro')}!
        </span>
      )
    }

    const count = (persistedEmployees?.length || updatedEmployees.length)

    if (count && employeesWithErrors?.length) {
      return (
        <span>
          <strong>{count}</strong> {pluralizeText(count, 'funcionários', 'funcionário')} foram
          {pluralizeText(count, ' importados', ' importado')} ou {pluralizeText(count, 'atualizados ', 'atualizado ')}
          com sucesso e <strong>{employeesWithErrors.length}</strong> {pluralizeText(employeesWithErrors.length, 'estão ', 'está ')}
          com {pluralizeText(employeesWithErrors.length, 'erros', 'erro')}!
        </span>
      )
    }

    return (
      <span>
        <strong>{persistedEmployees.length}</strong>
        {pluralizeText(persistedEmployees.length, 'funcionários', 'funcionário')} {pluralizeText(persistedEmployees.length, 'foram ', 'foi ')}
        {pluralizeText(persistedEmployees.length, 'importados ', 'importado ')} com sucesso e <strong>{updatedEmployees.length}</strong>
        {pluralizeText(updatedEmployees.length, ' foram', ' foi')} {pluralizeText(updatedEmployees.length, 'atualizados', 'atualizado')}!
      </span>
    )
  }

  function getNotificationBoxByStatus(notification: INotification) {
    if (notification.status === 'error') {
      return (
        <ErrorBox>
          <div>
            <img src={errorIcon} alt="icone de erro." />
            <span>{fileError}</span>
          </div>
        </ErrorBox>
      )
    }
    else if (notification.status === 'success') {
      return (
        <SuccessBox>
          <div>
            <img src={successIcon} alt="icone de sucesso." />
            {getMessage()}
          </div>
        </SuccessBox>
      )
    } else if (notification.status === 'notice') {
      return (
        <WarningBox>
          <div>
            <img src={warningIcon} alt="icone de aviso." />
            {getMessage()}
          </div>
        </WarningBox>
      )
    }
  }

  return (
    <>
      <Dashboard title="Resultado da Importação" backPath={`/rh/notificacoes`}>
        <Container>
          {notification && !loading ?
            <>
              {getNotificationBoxByStatus(notification)}
              {employeesWithErrors && notification.status !== 'success' && !fileError &&
                <>
                  <span>Relatório de erros</span>
                  <DownloadErrorsReportBox>
                    <div>
                      <div className="title">
                        <img src={errorIcon} alt="icone de erro." />
                        <span><strong>Planilha de excel com os erros de importação!</strong></span>
                      </div>
                      <span className="description">
                        Faça os ajustes necessários e realize o processo novamente.
                      </span>
                      {errorsReportUrl && <iframe style={{ display: 'none' }} src={errorsReportUrl}></iframe>}
                    </div>
                    <SecondaryButton
                      type="button"
                      height="2.5rem"
                      width="15rem"
                      iconUrl={downloadIcon}
                      onClick={handleErrorsReportDownload}>
                      Exportar excel com erros
                    </SecondaryButton>
                  </DownloadErrorsReportBox>
                  <ContainerList>
                    <CustomTable>
                      <thead>
                        <tr>
                          <th scope="col">NOME DO FUNCIONÁRIO</th>
                          <th scope="col">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeesWithErrors.map((employee, idx) => (
                          <tr key={idx}>
                            <td>
                              {employee.showAllErrors ? renderLargeBoxErrors(employee) : renderSmallBoxErrors(employee)}
                            </td>
                            <td>
                              <div>
                                <img src={redCloseIcon} alt="icone close vermelho." />
                                <span>Erro</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </CustomTable>
                    <Pagination
                      currentPage={currentPage}
                      numOfPages={totalPages}
                      totalFilteredCount={totalFilteredCount}
                      totalCount={totalCount}
                      perPage={perPage}
                      lengthPage={employeesWithErrors.length}
                      onSelectPage={handleSelectPage} />
                  </ContainerList>
                  <BottomActions>
                    <PrimaryButton
                      type="button"
                      height="2.5rem"
                      width="11rem"
                      onClick={() => history.push(`/rh/funcionarios`)}>
                      Concluir
                    </PrimaryButton>
                  </BottomActions>
                </>
              }
            </>
            :
            <p>{message}</p>
          }
        </Container>
      </Dashboard>
    </>
  )
};

export default ShowImport;
