import React, { useEffect, useState, FormEvent } from 'react';

import { toast } from 'react-toastify';

import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';
import SelectInput, { IOption } from 'components/SelectInput';

import { formatPhone, formatDate } from 'utils';

import api from 'service/subscriberApi';

import { Form, ActionsBox, ContainerLoading } from './styles';

import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

interface IError {
  key: string;
  message: string;
}

const FormPersonalData: React.FC = () => {
  const { checkAllAlerts } = useItemsMenuSubscriber();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');

  const genderOptions: IOption[] = [
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
  ];

  useEffect(() => {
    setLoading(true);
    api.get(`/users/find-user`)
      .then((response) => {
        setLoading(false);
        setMessages([]);
        const user = response.data.user;
        setName(user.name);
        setCpf(user.cpf);
        setEmail(user.email);
        setPhone(formatPhone(user.phone));
        setBirthDate(formatDate(user.birth_date));
        setGender(user.gender);
      }).catch((_error) => {
        setLoading(false);
        setMessages(['Serviço indisponível no momento, por favor tente mais tarde.']);
      });
  }, []);

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();
      setSaving(true);
      setMessages([]);
      setErrors([]);

      const params = {
        name: name,
        cpf: cpf,
        email: email,
        birth_date: birthDate,
        phone: phone,
        gender: gender,
      }

      await api.put(`/users/update-data`, params);
      setSaving(false);
      toast.success('Dados salvos com sucesso!');
      checkAllAlerts();
    } catch (ex: any) {
      handleResponseErrors(ex);
    }
  }

  function handleResponseErrors(ex: any) {
    setSaving(false);
    toast.error('Não foi possível atualizar o cadastro!');
    const response = ex.response;
    if (response && response.data) {
      const error: any = response.data.error;
      const newErrors: any = response.data.errors;

      if (error) {
        setMessages([...messages, response.data.error]);
      } else if (!!newErrors) {
        const newFieldErrors: IError[] = [];
        Object
          .keys(newErrors)
          .forEach((attr: any) => {
            const values: any = newErrors[attr];
            values.forEach((value: any) => {
              newFieldErrors.push({ key: attr, message: value });
            });
          })

        setErrors(newFieldErrors);
      }
    } else {
      setMessages([...messages, 'Serviço indisponível no momento, por favor tente mais tarde.']);
    }
  }

  function formatMessage({ message }: IError): string {
    return message?.trim().replace('.', '');
  }

  if (loading) return <ContainerLoading>Carregando...</ContainerLoading>;

  return (
    <Form onSubmit={submitForm}>
      <TextInput
        onChange={(value: any) => setName(value)}
        value={name}
        type="text"
        placeholder="Nome"
        maxLength={255} />
      <span>
        {
          errors
            .filter((error) => error.key === 'name')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <TextInput
        onChange={(value: any) => setCpf(value)}
        value={cpf}
        type="text"
        placeholder="CPF"
        maxLength={14}
        typeMask="cpf" 
        disabled={true}/>
      <span>
        {
          errors
            .filter((error) => error.key === 'cpf')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <TextInput
        onChange={(value: any) => setBirthDate(value)}
        value={birthDate}
        type="text"
        placeholder="Data de nascimento"
        typeMask="date" 
        disabled={true}/>
      <span>
        {
          errors
            .filter((error) => error.key === 'birth_date')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <SelectInput
        onChange={(value: string) => setGender(value)}
        options={genderOptions}
        value={gender}
        defaultValue="Selecione um gênero..." />

      <TextInput
        onChange={(value: any) => setPhone(value)}
        value={phone}
        type="text"
        placeholder="Telefone"
        typeMask="phone"
        maxLength={15} />
      <span>
        {
          errors
            .filter((error) => error.key === 'phone')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <TextInput
        onChange={(value: any) => setEmail(value)}
        value={email}
        type="text"
        placeholder="E-mail"
        disabled={true}
        maxLength={255} />
      <span>
        {
          errors
            .filter((error) => error.key === 'email')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      {messages.map(msg => formatMessage({ key: '', message: msg }))
        .join(', ')}
      <ActionsBox>
        <PrimaryButton type="submit" loading={saving}>
          Salvar alteração
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
}

export default FormPersonalData;
