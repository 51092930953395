import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3125rem;
    margin-bottom: 1rem;
  }

  .box-left {
    display: flex;

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: ${COLORS.mediumGrey};
      margin-left: 0.5rem;
    }
  }

  .box-right {
    display: flex;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: ${COLORS.accentColor};
      text-decoration: underline;
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }
`;

export const PaymentBox = styled.div`
  width: 100%;
  max-width: 27rem;
  text-align: center;
`;
