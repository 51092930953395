import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getNameInitials, limitStr } from 'utils';

import { getUser, logout, isAuthenticated } from 'service/subscriber';

import api from 'service/subscriberApi';

import {
  ContainerNav,
  ContainerTitle,
  ContainerHrAccount,
  CirclePhoto,
  SideMenu,
  NotificationBox,
  Alert
} from './styles';

import NotificationMenu from './NotificationMenu';

import arrowLeft from 'assets/arrow-left.svg';
import arrowDown from 'assets/arrow-down.svg';
import exit from 'assets/exit.svg';
import purplePerson from 'assets/purple-person.svg';
import notification from 'assets/notification.svg';

import Breadcrumb from 'components/Breadcrumb';

interface IHeaderMenu {
  title?: string;
  backPath?: string;
}

let intervalState: any = null;

const HeaderMenu: React.FC<IHeaderMenu> = ({ title, backPath }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [notificationsNotSeenCount, setNotificationsNotSeenCount] = useState(0);
  const [currentUser] = useState(formatCurrentUser);

  const history = useHistory();

  useEffect(() => {
    clearInterval(intervalState);

    intervalState = setInterval(() => {
      if (isAuthenticated()) {
        api.get(`/users/notifications`)
          .then((response) => {
            const { notifications_not_seen } = response.data;
            setNotificationsNotSeenCount(notifications_not_seen || 0);
          });
      }
    }, 60000);
  }, []);

  function formatCurrentUser() {
    const { name: fullName, email, imageUrl } = getUser();

    return {
      fullName,
      initials: getNameInitials(fullName),
      reducedName: limitStr(fullName, 12, false),
      email,
      imageUrl
    };
  }

  function handleLogout() {
    logout()
    history.push('/assinantes/login');
  }

  function openNotification() {
    setShowNotificationMenu(!showNotificationMenu);
    setNotificationsNotSeenCount(0);
  }

  return (
    <ContainerNav>
      <Breadcrumb />
      <ContainerTitle>
        <div>
          {backPath && <img src={arrowLeft} onClick={() => history.push(backPath)} alt="Seta para esquerda" />}
          <h2>{title}</h2>
        </div>

        <ContainerHrAccount>
          <div onClick={() => openNotification()}>
            {notificationsNotSeenCount > 0 && <Alert>{notificationsNotSeenCount}</Alert>}
            <NotificationBox >
              <img src={notification} alt="Notificações" />
              {showNotificationMenu && <NotificationMenu />}
            </NotificationBox>
          </div>

          <div onClick={() => setShowMenu(!showMenu)} onMouseLeave={() => setShowMenu(false)}>
            <CirclePhoto>
              {!!currentUser.imageUrl && <img src={currentUser.imageUrl} alt="Imagem de perfil" />}
              {!currentUser.imageUrl && currentUser.initials.toUpperCase()}
            </CirclePhoto>

            <p>Olá, {currentUser.reducedName}</p>
            <img src={arrowDown} alt="Seta para baixo" />

            {showMenu &&
              <SideMenu>
                <ul>
                  <li onClick={handleLogout}>
                    <img src={exit} alt="Ícone de sair" />
                    <p>Sair</p>
                  </li>
                </ul>
              </SideMenu>}
          </div>
        </ContainerHrAccount>
      </ContainerTitle>
    </ContainerNav>
  )
};

export default HeaderMenu;
