import React, { useState } from 'react';
import { addDefaultLogoSrc, verifyIfHasImage } from '../../../../utils';
import { toast } from 'react-toastify';

import starOutline from 'assets/icons/partners/star-outline.svg';
import starFull from 'assets/icons/partners/star-full.svg';
import plus from 'assets/icons/partners/plus-purple.svg';


import {
  BranchCard,
  BranchTag,
  BranchCardHeader,
  BranchCardBody,
  BranchTagArea,
  BranchTagSection,
} from './styles';

import api from 'service/subscriberApi';
import { getUser } from 'service/subscriber';

interface IBranchCardItem {
  branch: IPartnerBranchItem;
}

interface IPartnerBranchItem {
  id: string;
  name: string;
  logoImageUrl: string;
  phone: string;
  partnerName: string;
  street: string;
  streetNumber: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  fullStateName: string;
  zipCode: string;
  subCategories: [];
  usersIds: string[];
}

const BranchCardItem: React.FC<IBranchCardItem> = ({ branch }) => {
  const enableToggle = branch.subCategories.length > 3
  const favoritedByUser = branch.usersIds?.includes(getUser().id)
  const [showAllSubCategories, setShowAllSubCategories] = useState(false)
  const [isFavorite, setIsFavorite] = useState(favoritedByUser)
  const [subCategoriesToShow, setSubcategoriesToShow] = useState(branch.subCategories.slice(0, 3))

  function toggleSubCategories() {
    if (!enableToggle) return

    setShowAllSubCategories(!showAllSubCategories)
    if (showAllSubCategories) {
      setSubcategoriesToShow(branch.subCategories)
    } else {
      setSubcategoriesToShow(branch.subCategories.slice(0, 3))
    }
  }

  function addOrDeleteFavorite() {
    if (isFavorite) {
      api.delete(`/users/partner_branches/remove_favorite/${branch.id}`)
        .then(response => {
          if (response.status === 200) {
            toast.info('Removido dos favoritos')
          } else {
            toast.error('Erro ao remover favorito')
          }
        }).catch(_error => {
          toast.error('Erro ao remover favorito')
        })
    } else {
      api.post(`/users/partner_branches/add_favorite/${branch.id}`)
        .then(response => {
          if (response.status === 201) {
            toast.success('Adicionado aos favoritos')
          } else {
            toast.error('Erro ao adicionar favorito')
          }
        }).catch(_error => {
          toast.error('Erro ao adicionar favorito')
        })
    }
    setIsFavorite(!isFavorite)
  }

  function renderTags() {
    if (subCategoriesToShow.length > 0) {
      return (
        <>
          <p className="gray-text">TAGS</p>
          <BranchTagArea>
            <BranchTagSection>
              {subCategoriesToShow.map((sc: any) => {
                return (
                  <BranchTag key={sc.id}>
                    <span>
                      {sc.name}
                    </span>
                  </BranchTag>
                );
              })}
            </BranchTagSection>
            {enableToggle && <img src={plus} className="plus-icon" onClick={toggleSubCategories} />}
          </BranchTagArea>
        </>
      );
    }
  }

  function renderBranchPhone() {
    return (
      <div className="info-section">
        <p className="gray-text">TELEFONE</p>
        <p>{branch.phone}</p>
      </div>
    );
  }

  function renderBranchAddress() {
    return (
      <div className="info-section">
        <p className="gray-text">ENDEREÇO</p>
        <p>
          {branch.street} {branch.streetNumber} {branch.complement} {branch.city} -{' '}
          {branch.fullStateName} {branch.zipCode}
        </p>
      </div>
    );
  }

  return (
    <BranchCard key={branch.id}>
      <BranchCardHeader>
        <div className="logo-name">
          <img
            className="logo"
            src={verifyIfHasImage(branch.logoImageUrl)}
            onError={addDefaultLogoSrc}
          />
          <div className="name">
            <p className="gray-text">{branch.name}</p>
            <p>{branch.partnerName}</p>
          </div>
        </div>
        <img src={isFavorite ? starFull : starOutline} className="favorite-icon" onClick={addOrDeleteFavorite} />
      </BranchCardHeader>
      <hr />
      <BranchCardBody>
        {renderTags()}
        {branch.phone && renderBranchPhone()}
        {branch.street && renderBranchAddress()}
      </BranchCardBody>
    </BranchCard>
  );
};

export default BranchCardItem;
