import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useQuery } from 'react-query';

import GlobalStyle from './styles/global';
import Routes from './routes';
import { HooksProvider } from './hooks/HooksProvider'
import { syncronizeSubscriber } from 'service/subscriber';

import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID || '' };
if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  useQuery('syncronizeSubscriber', syncronizeSubscriber, {
    refetchInterval: 1000 * 600,
    refetchOnWindowFocus: true,
  });

  return (
    <>
      <BrowserRouter>
        <HooksProvider>
          <Routes />
          <ToastContainer autoClose={3000} />
        </HooksProvider>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
}

export default App;
