import styled from 'styled-components';
import { SIZES, COLORS } from 'styles/constants';

import banner from 'assets/family-group.png';

export const ContainerBody = styled.section`
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
`;

export const ContainerLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50%;
  background-color: ${COLORS.backgroundBase};

  @media(${SIZES.smallerThanTablet}) {
    max-width: initial;
  }
`;

export const OptionAccess = styled.div`
  height: 70px;
  width: 100%;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    opacity: 0.8;
  }
`;


export const Info = styled.div`
  display: flex;

  span {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 6px;
    border: 1px solid ${COLORS.borderBase};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 1rem;
    }
  }

  div {
    margin-left: 0.5rem;

    h2 {
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
    }

    p {
      font-weight: 300;
      font-size: 0.75rem;
      color: ${COLORS.mediumGrey};
    }
  }
`;

export const BoxOptionAccess = styled.div`
  display: initial;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 18.75rem;

  h1 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
  }
`;

export const BannersContainer = styled.a`
  flex: 1;
  background: url(${banner}) no-repeat center;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-width: 50%;
  position: relative;
  -webkit-transition: background 1s ease-in-out;
  -moz-transition: background 1s ease-in-out;
  -o-transition: background 1s ease-in-out;
  transition: background 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media(${SIZES.smallerThanTablet}) {
    display: none;
  }
`;
