import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';
import { SIZES } from 'styles/constants';

interface IBoxDragDrop {
  isDragOver: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContainerList = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 0rem 3.75rem;

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.3125rem;
    color: ${COLORS.offblack};
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
    text-align: center;
  }

  > button {
    margin-left: auto;
    margin-top: 1rem;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  width: 100%;

  input {
    flex: 1;
    min-width: 30rem;
  }

  button {
    margin-left:1rem;
  }

  @media(${SIZES.smallerThanTablet}) {
    flex-wrap: wrap;

    input {
      width: 100%;
    }

    button {
      margin-top: 1rem;
      margin-left: 1rem;
      width: 70%;
    }

    button + button {
      width: 20%;
    }
  }
`;

export const CustomTable = styled.table`
  width: calc(100% + 3rem);
  overflow-x: auto;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  thead {
    th {
      border-bottom: none;
      border-top: none;
      color: ${COLORS.offblack};
      font-size: 0.9rem;
      font-weight: 400;
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: inherit;
      height: 3rem;

      img {
        margin-left: 0.5rem;
        width: 0.7rem;
        cursor: pointer;
      }

      &.ordering {
        cursor: pointer;
      }

      &:first-child {
        display: flex;
        padding-left: 1.75rem;

        img {
          height: 0.8rem;
          width: 0.8rem;
          cursor: pointer;
        }
      }

      &:last-child {
        padding-right: 1.75rem;
      }
    }
  }

  tbody {
    tr {
      td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${COLORS.offblack};
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-top: 1px solid ${COLORS.backgroundBase};
        border-bottom: 1px solid ${COLORS.backgroundBase};

        &:first-child {
          padding-left: 1.75rem;
          border-left: 1px solid ${COLORS.backgroundBase};
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          width: 7rem;
        }

        &:last-child {
          padding-right: 1.75rem;
          border-right: 1px solid ${COLORS.backgroundBase};
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          width: 5rem;
        }

        img + img {
          margin-left: 0.75rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(244, 244, 244, 0.6);
      }
    }
  }
`;

export const Chip = styled.div`
  display: flex;
  place-content: center;
  background-color: ${COLORS.mediumGrey};
  width: fit-content;
  height: 1.2rem;
  padding: 0.4rem;
  border-radius: 2rem;
  align-items: center;

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipCanceled = styled(Chip)`
  background-color:  ${COLORS.red};

  span {
    color: white;
    text-align: center;
  }
`;

export const ChipActive = styled(Chip)`
  background-color: ${COLORS.success};

  span {
    color: white;
    text-align: center;
  }
`;

export const BoxInfo = styled.div`
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
`;

export const BoxInfoHeader = styled.div`
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
`;

export const BoxInfoBody = styled.div`
  padding: 1rem;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;

  p {
    margin: 0;
    margin-left: 0.5rem;
  }
`;

export const BoxInstructions = styled.div`
  border: 1px solid ${COLORS.backgroundBase};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem;

  ul {
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 0.625rem;
      line-height: 1.125rem;
      display: flex;
      align-items: center;
      color: ${COLORS.offblack};
      margin-top: 0.5rem;

      p {
        margin: 0;
        margin-left: 0.5rem;
        text-align: inherit;
      }
    }
  }
`;

export const BoxDragDrop = styled.div<IBoxDragDrop>`
  height: 8.75rem;
  border: 1px dashed ${COLORS.accentColor};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isDragOver && css`border: 1px solid ${COLORS.accentColor};`}

  button {
    margin-left: auto;
    margin-right: auto;
  }

  p, strong {
    margin: 0;
    margin-top: 0.375rem;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    color: ${COLORS.accentColor};
  }

  strong {
    text-decoration: none;
    color: ${COLORS.offblack};
    margin-bottom: 0.5rem;
  }

  input {
    display: none;
  }

  span {
    font-size: 0.75rem;
    color: ${COLORS.red};
  }
`;
