import React from "react";

import {
  BodyModal,
} from "./styles";

import GenericModal from "components/GenericModal";

import accidentalDeathIcon from "assets/icons/accidental-death.svg";
import closeIcon from "assets/icons/close-accent-background.svg";

interface IAccidentalDeathModal {
  close: Function;
}

const AccidentalDeathModal: React.FC<IAccidentalDeathModal> = ({close}) => {  
  return (
    <GenericModal height="auto" width="37rem">
      <BodyModal>
        <img onClick={() => close()} src={closeIcon} alt="Fechar"></img>
        <div className="header-content">
          <img src={accidentalDeathIcon} alt="Morte acidental"></img>
          <h3>Morte Acidental</h3>  
        </div>
        <p>Garante ao(s) beneficiário(s) o pagamento de uma indenização, em caso de Morte do segurado em consequência 
            exclusiva de acidente pessoal coberto.</p>
        <p className="accent-color">É considerado morte acidental:</p>
        <p className="gray-color">evento com data caracterizada, exclusivo e diretamente externo, súbito, involuntário e violento, 
          causador de lesão física que, por si só e independente de toda e qualquer outra causa, tenha como consequência direta a morte.</p>
      </BodyModal>      
    </GenericModal>
  )
}

export default AccidentalDeathModal;
