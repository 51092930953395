import React, { useState } from 'react';

import SmallMenuComponent from './SmallMenu';
import LargeMenuComponent from './LargeMenu';

import { IMenuItem } from 'components/SubscriberDashboard';

import { getToogleMenu, setToogleMenu } from 'utils';
export interface ISideMenu {
  menuItems?: IMenuItem[];
  helpItem: IMenuItem;
}

const SideMenu: React.FC<ISideMenu> = ({ menuItems, helpItem }) => {
  const [showLargeMenu, setShowLargeMenu] = useState(getToogleMenu);

  function toggleMenu() {
    setShowLargeMenu(!showLargeMenu);
    setToogleMenu(!showLargeMenu);
  }

  if (showLargeMenu) {
    return <LargeMenuComponent toggleMenu={toggleMenu} menuItems={menuItems} helpItem={helpItem} />
  } else {
    return <SmallMenuComponent toggleMenu={toggleMenu} menuItems={menuItems} helpItem={helpItem} />
  }
};

export default SideMenu;
