import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatDate, formatTime } from 'utils';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/SubscriberDashboard';
import Pagination from 'components/Pagination';

import SuccessNotificationIcon from 'assets/icons/success-notification.svg';
import WarnigNotificationIcon from 'assets/icons/warning-notification.svg';
import ErrorNotificationIcon from 'assets/icons/error-notification.svg';

import api from 'service/subscriberApi';

import {
  Container,
  ContainerNotifications,
  Notification,
  Description,
  ContainerLine,
  Point,
  Line,
  InfoDate,
  TitleNotification,
  ContentNotification
} from './styles';

interface INotification {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  point: boolean;
  labelDate: string;
  href: string;
  status: string;
  event_type: string;
}

const Notifications: React.FC = () => {
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalFilteredCount, setTotalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Notificações', path: '/assinantes/notificacoes' },
    ]);
  }, []);

  useEffect(() => {
    getNotifications();
  }, [currentPage]);

  function getNotifications() {
    setLoading(true);

    let url = `/users/notifications?`;
    url += `page=${currentPage}&per_page=${perPage}`;

    api.get(url)
      .then(response => {
        setLoading(false);

        const {
          total_count,
          total_filtered_count,
          total_pages,
          per_page,
          notifications
        } = response.data;

        setTotalCount(total_count);
        setTotalFilteredCount(total_filtered_count);
        setTotalPages(total_pages);
        setPerPage(per_page);
        setNotifications(mapNotifications(notifications));
      })
      .catch(_error => {
        setLoading(false);
      });
  }

  function mapNotifications(notifications: any) {
    if (!Array.isArray(notifications) || !notifications.length) return [];

    return notifications.reduce((accNotifications: any, notification: any) => {
      const property = formatDate(notification.updated_at);
      const thereIsNotificationWithTheSameDate = !!accNotifications.filter(
        (not: any) => not.date === property,
      ).length;
      const firstNotification = !thereIsNotificationWithTheSameDate;
      const newNotification = mapNotification(notification, firstNotification);

      accNotifications.push(newNotification);
      return accNotifications;
    }, []);
  }

  function mapNotification(
    notification: any,
    firstNotification: boolean,
  ): INotification {
    let updatedAt = notification.updated_at;
    let status = notification.status;
    let title = notification.title;
    let description = notification.description;
    let eventType = notification.event_type;
    let href = `/assinantes/notificacoes/${notification.id}`;

    return {
      id: notification.id,
      title,
      description,
      href,
      status,
      time: formatTime(updatedAt),
      date: formatDate(updatedAt),
      event_type: eventType,
      labelDate: getLabelDate(formatDate(updatedAt), firstNotification),
      point: firstNotification,
    };
  }

  function getLabelDate(date: string, firstNotification: boolean) {
    if (!firstNotification) return '';

    if (date === getCurrenDate()) {
      return 'Hoje';
    } else if (date === getYesterdayDate()) {
      return 'Ontem';
    } else {
      return date;
    }
  }

  function getIconByStatus(status: string) {
    if (status === 'success') {
      return <img src={SuccessNotificationIcon} alt="Icone de sucesso." />;
    } else if (status === 'error') {
      return <img src={ErrorNotificationIcon} alt="Icone de erro." />;
    } else {
      return <img src={WarnigNotificationIcon} alt="Icone de aviso." />
    }
  }

  function formatCurrentDate(date: Date) {
    let day = `${date.getDate()}`;
    day = day.length === 1 ? `0${day}` : day;

    let month = `${date.getMonth() + 1}`;
    month = month.length === 1 ? `0${month}` : month;

    return day + '/' + month + '/' + date.getFullYear();
  }

  function getYesterdayDate() {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return formatCurrentDate(date);
  }

  function getCurrenDate() {
    let date = new Date();
    return formatCurrentDate(date);
  }

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }

  return (
    <Dashboard title="Notificações" backPath={`/assinantes/home`}>
      <Container>
        <h1>Veja os últimos acontecimentos relacionados ao seu Cartão Aliança.</h1>
        <ContainerNotifications>
          <>
            {notifications.map(notification => (
              <Notification key={notification.id}>
                <InfoDate withPoint={notification.point}>
                  <p>{notification.labelDate}</p>
                </InfoDate>
                <ContainerLine>
                  {notification.point && (
                    <Point>
                      <div></div>
                    </Point>
                  )}
                  <Line>
                    <div></div>
                  </Line>
                </ContainerLine>
                <ContentNotification>
                  <TitleNotification status={notification.status}>
                    {getIconByStatus(notification.status)}
                    <Link to={notification.href}>{notification.title}</Link>{' '}
                  </TitleNotification>
                  <Description>
                    <p>{notification.description}</p>
                    <p className="date">
                      {notification.date} {notification.time}
                    </p>
                  </Description>
                </ContentNotification>
              </Notification>
            ))}
          </>

          {loading && <p>Carregando...</p>}

          <br />
          <br />
          <Pagination
            currentPage={currentPage}
            numOfPages={totalPages}
            totalFilteredCount={totalFilteredCount}
            totalCount={totalCount}
            perPage={perPage}
            lengthPage={notifications.length}
            onSelectPage={handleSelectPage}
          />
          <br />
        </ContainerNotifications>
      </Container>
    </Dashboard>
  );
};

export default Notifications;
