export interface ICompany {
  id: number;
  cnpj: string;
  name: string;
  tradeName: string;
  imageUrl: string;
  usersCount: number;
  hasActivePlugins: boolean;
  hasMetlife: boolean;
  hasTempoAssist: boolean;
}

export function saveCompany(company: ICompany): ICompany {
  localStorage.setItem('@hr_account_company', JSON.stringify(company));

  return company;
}

export function getCompany(): ICompany {
  const company: ICompany = JSON.parse(localStorage.getItem('@hr_account_company') || "null");

  return company;
}

export function getCompanyId(): number {
  const company: ICompany = getCompany();
  return company?.id;
}
