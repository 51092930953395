import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BranchCard = styled.div`
  background-color: ${COLORS.defaultBg};
  border: 1px solid ${COLORS.borderBase};
  border-radius: 6px;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;

  hr {
    margin: 1rem 0;
    border: 1px solid ${COLORS.borderBase};
  }

  &:hover {
    background-color: ${COLORS.borderBase};
  }

  .gray-text {
    color: ${COLORS.mediumGrey};
  }
`;

export const BranchCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 3.5rem;
  justify-content: space-between;

  .logo-name {
    margin: 0;
    display: flex;
    align-items: flex-end;

    .logo {
      max-height: 3rem;
    }

    .name {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .favorite-icon {
    cursor: pointer;
    min-width: 2rem;
  }
`;

export const BranchCardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .info-section {
    margin-top: 0.5rem;
  }
`;

export const BranchTagArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  .plus-icon {
    max-width: 2rem;
    cursor: pointer;
  }
`;

export const BranchTagSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const BranchTag = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
  margin-right: 0.3rem;

  span {
    background-color: ${COLORS.accentColor};
    color: ${COLORS.defaultBg};
    padding: 0.3rem 0.6rem;
    border-radius: 2rem;
  }
`;
