import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  ContainerBox,
  DetailsBox,
  StatusSection,
  Separator,
  InvoiceSection,
  ChipActive,
  ChipCanceled,
  Chip
 } from './styles';

import api from 'service/hrAccountApi';
import { getCompanyId } from "service/company";
import { formatValueToCurrency, formatDate } from 'utils';

import { useBreadcrumb } from 'hooks/useBreadcrumb'

import Dashboard from 'components/HrAccountDashboard'
import PrimaryButton from 'components/PrimaryButton'

import { toast } from "react-toastify";

interface IBill {
  id: number;
  billCode: string;
  issuedAt: string;
  employeesCount: number;
  valuePerEmployee: string;
  totalValue: string;
  status: string;
}

interface IInvoice {
  id: number;
  number: string;
  seriesNumber: number;
  issuedAt: string;
  valueWithoutTaxes: string;
  totalValue: string;
  externalUrl: string;
}

const CompanyBill: React.FC = (props: any) => {
  const billId = props.match.params.id;
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [bill, setBill] = useState<IBill>()
  const [invoice, setInvoice] = useState<IInvoice>()
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  const history = useHistory();

  useEffect(() => {
    loadBillAndInvoice()
  }, [])

  useEffect(() => {
    if (!bill) return

    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Faturas', path: `/rh/faturas` },
      { title: bill?.billCode, path: `/rh/faturas/${billId}` }
    ])
  }, [bill])

  function loadBillAndInvoice() {
    setLoading(true);
    setMessage('Carregando...');

    api.get(`/hr-account/companies/${getCompanyId()}/bills/${billId}`)
      .then((response) => {
        setLoading(false);
        setMessage('');
        setBill(formatBill(response.data.company_bill));
        setInvoice(formatInvoice(response.data.company_invoice));
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function formatBill(bill: any): IBill {
    return {
      id: bill.id,
      billCode: bill.id_on_protheus,
      issuedAt: bill.created_at,
      employeesCount: bill.quantity,
      valuePerEmployee: bill.value_per_user,
      totalValue: bill.value_with_taxes,
      status: bill.status
    }
  }

  function formatInvoice(invoice: any): IInvoice {
    return {
      id: invoice.id,
      number: invoice.number,
      seriesNumber: invoice.series_number,
      issuedAt: invoice.created_at,
      valueWithoutTaxes: invoice.value_without_taxes,
      totalValue: invoice.value_with_taxes,
      externalUrl: invoice.external_url
    }
  }

  function getChipByStatus(status: string) {
    switch (status) {
      case 'paid':
        return <ChipActive><span>PAGA</span></ChipActive>
      case 'canceled':
        return <ChipCanceled><span>CANCELADA</span></ChipCanceled>
      default:
        return <Chip><span>PENDENTE</span></Chip>
    }
  }

  return (
    <>
      <Dashboard title="Detalhes da fatura" backPath="/rh/faturas">
        <Container>
          {loading ?
            <p>{message}</p>
            :
            <ContainerBox>
              <StatusSection>
                <span>Status:</span>
                {getChipByStatus(`${bill?.status}`)}
              </StatusSection>
              <Separator/>
              <DetailsBox>
                <span><strong>Data de emissão: </strong>{formatDate(`${bill?.issuedAt}`)}</span>
                <span><strong>número de funcionários: </strong>{bill?.employeesCount}</span>
                <span><strong>Valor por usuário: </strong>{formatValueToCurrency(`${bill?.valuePerEmployee}`)}</span>
                <span><strong>Valor total: </strong>{formatValueToCurrency(`${bill?.totalValue}`)}</span>
              </DetailsBox>
              {invoice &&
                <>
                  <InvoiceSection>
                    <span>Notas fiscais</span>
                    <a href={invoice?.externalUrl} target="_blank">Visualizar nota</a>
                  </InvoiceSection>
                  <DetailsBox>
                    <span><strong>Nº: </strong>{invoice?.number}</span>
                    <span><strong>Série: </strong>{invoice?.seriesNumber}</span>
                    <span><strong>Data de emissão: </strong>{formatDate(`${invoice?.issuedAt}`)}</span>
                    <span><strong>Valor líquido: </strong>{formatValueToCurrency(`${invoice?.valueWithoutTaxes}`)}</span>
                    <span><strong>Valor total: </strong>{formatValueToCurrency(`${invoice?.totalValue}`)}</span>
                  </DetailsBox>
                </>}
              <PrimaryButton type="button"  onClick={() => history.push(`/rh/faturas`)}>
                Voltar
              </PrimaryButton>
            </ContainerBox>
          }
        </Container>
      </Dashboard>
    </>
  )
};

export default CompanyBill;
