import React from "react";

import arrowRightActive from 'assets/icons/arrow-right-active.svg';

import { IPaymentMethod } from "..";

import { chooseFlagImg } from 'utils'

import { PaymentCardItem } from './styles';

interface IPaymentCard {
  paymentMethod: IPaymentMethod | null;
  setUpdating: Function;
}

const PaymentCard: React.FC<IPaymentCard> = ({ paymentMethod, setUpdating }) => {
  return (
    <>
      <h1>Cartão cadastrado</h1>
      <PaymentCardItem>
        <div className="box-left">
          {chooseFlagImg(paymentMethod?.flag)}
          <p>{paymentMethod?.flagName} ...{paymentMethod?.lastFourNumbers}</p>
        </div>
        <div className="box-right" onClick={() => setUpdating(true)}>
          <p>Trocar cartão</p>
          <img src={arrowRightActive} alt="Trocar cartão" />
        </div>
      </PaymentCardItem>
    </>
  );
}

export default PaymentCard;
