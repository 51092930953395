import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import activeSwitch from 'assets/active-switch.svg';
import inactiveSwitch from 'assets/inactive-switch.svg';

import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';
import SelectInput, { IOption } from 'components/SelectInput';

import { formatPhone, formatDate, validBirthDate, capitalizeFirstLetter } from 'utils';

import api from 'service/subscriberApi';

import { Form, ActionsBox, ContainerLoading, Switch, Separator, DocumentInputs } from './styles';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

interface IError {
  key: string;
  message: string;
}

interface IFormPersonalData {
  dependantId: string;
  callbackName: Function;
}

const FormPersonalData: React.FC<IFormPersonalData> = ({ dependantId, callbackName }) => {
  const { checkAllAlerts } = useItemsMenuSubscriber();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(false);
  const [document, setDocument] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [issuingAgency, setIssuingAgency] = useState('');
  const [temporaryCpf, setTemporaryCpf] = useState('');
  const [temporaryRg, setTemporaryRg] = useState('');
  const [temporaryIssuingAgency, setTemporaryIssuingAgency] = useState('');
  const [hasBothDocuments, setHasBothDocuments] = useState(false);
  const [issuingAgencies, setIssuingAgencies] = useState<IOption[]>([]);


  const [invalidBirthDate, setInvalidBirthDate] = useState(true);

  const genderOptions: IOption[] = [
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
  ];

  useEffect(() => {
    getIssuingAgencies();     
  },[])

  useEffect(() => {
    setLoading(true);
    api.get(`/users/dependants/${dependantId}`)
      .then((response) => {
        setLoading(false);
        setMessages([]);
        const { name, cpf, email, phone, birth_date, gender, active, rg, issuing_agency } = response.data.dependant;
        setName(name);
        setCpf(cpf);
        setEmail(email);
        setPhone(formatPhone(phone));
        setBirthDate(formatDate(birth_date));
        setGender(gender || '');
        setStatus(active);
        setRg(rg);
        setIssuingAgency(issuing_agency);
        setTemporaryCpf(cpf || '');
        setTemporaryRg(rg || '');
        setTemporaryIssuingAgency(issuing_agency || '');
        if (cpf && rg) setHasBothDocuments(true)
        if (cpf) setDocument('CPF (dependente)')
        if (rg) setDocument('RG (dependente)')
      }).catch((_error) => {
        setLoading(false);
        toast.error('Não foi possível carregar os dados de cadastro, por favor tente novamente!');
      });
  }, [dependantId]);

  useEffect(() => {
    callbackName(name);
  }, [name]);

  useEffect(() => {
    verifyBirthDate();     
  },[birthDate])

  async function getIssuingAgencies() {
    try {
      const response = await api.get('/issuing_agencies');
      let issuingAgencies  = response.data.issuing_agencies;
      issuingAgencies = issuingAgencies.map((issuingAgency: any) => formatIssuingAgency(issuingAgency));
      issuingAgencies = [{ value: '', label: "Selecione um Órgão Emissor..." }, ...issuingAgencies]
      setIssuingAgencies(issuingAgencies);
    } catch (ex: any) {
      console.log(ex);
    }
  }

  function formatIssuingAgency(issuingAgency: any) {
    return { 
      value: `${issuingAgency.sigla} - ${issuingAgency.extenso}`, 
      label: `${issuingAgency.sigla} - ${issuingAgency.extenso}` 
    }
  }

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();
      setSaving(true);
      setMessages([]);
      setErrors([]);
      const params = { 
        name, 
        email, 
        phone, 
        gender,
        document,
        cpf: temporaryCpf,
        rg: temporaryRg,
        issuing_agency: temporaryIssuingAgency,
        birth_date: birthDate 
      }

      const response = await api.put(`/users/dependants/${dependantId}`, params);
      const { dependant } = response.data;
      history.push(`/assinantes/dependentes/${dependant.id}?step=address`);
      toast.success('Dados salvos com sucesso!');
      checkAllAlerts();
    } catch (ex: any) {
      handleResponseErrors(ex);
    }
  }

  async function changeDependantStatus(status: boolean) {
    try {
      if (status) {
        await api.post(`users/dependants/${dependantId}/active`);
        toast.success('Dependente ativado com sucesso!');
      } else {
        await api.post(`users/dependants/${dependantId}/inactive`);
        toast.success('Dependente inativado com sucesso!');
      }
      setStatus(status);
    } catch (ex: any) {
      if (ex.response && ex.response.data) {
        toast.error(ex.response.data.error);
      } else {
        toast.error('Não foi possível trocar o status do dependente, por favor tente novamente!');
      }
    }
  }

  function handleResponseErrors(ex: any) {
    setSaving(false);
    toast.error('Não foi possível atualizar o cadastro!');
    const response = ex.response;
    if (response && response.data) {
      const error: any = response.data.error;
      const newErrors: any = response.data.errors;

      if (error) {
        setMessages([...messages, response.data.error]);
      } else if (!!newErrors) {
        const newFieldErrors: IError[] = [];
        Object
          .keys(newErrors)
          .forEach((attr: any) => {
            const values: any = newErrors[attr];
            values.forEach((value: any) => {
              newFieldErrors.push({ key: attr, message: capitalizeFirstLetter(value) });
            });
          })

        setErrors(newFieldErrors);
      }
    } else {
      setMessages([...messages, 'Serviço indisponível no momento, por favor tente mais tarde.']);
    }
  }

  function choseDocument(document: string) {
    if (document === 'RG (dependente)') {
      setTemporaryCpf(cpf || '')
    } else {
      setTemporaryRg(rg || '')
      setTemporaryIssuingAgency(issuingAgency || '')
    }

    return setDocument(document);
  }

  function formatMessage({ message }: IError): string {
    return message?.trim().replace('.', '');
  }

  function verifyBirthDate() {
    !validBirthDate(birthDate) ? setInvalidBirthDate(true) : setInvalidBirthDate(false)
  }
  
  if (loading) return <ContainerLoading>Carregando...</ContainerLoading>;

  return (
    <Form onSubmit={submitForm}>
      <Switch>
        <span>Status</span>
        {status &&
          <div>
            <span>Ativo: </span>
            <img
              src={activeSwitch}
              onClick={() => changeDependantStatus(!status)}
              alt="interruptor ativo."
            />
          </div>}
        {!status &&
          <div>
            <span>Inativo: </span>
            <img
              src={inactiveSwitch}
              onClick={() => changeDependantStatus(!status)}
              alt="interruptor inativo."
            />
          </div>}
      </Switch>
      <Separator />
      <TextInput
        onChange={(value: any) => setName(value)}
        value={name}
        type="text"
        placeholder="Nome *"
        maxLength={255} />
      <span>
        {
          errors
            .filter((error) => error.key === 'name')
            .map(formatMessage)
            .join(', ')
        }
      </span>
      
      {!hasBothDocuments && 
        <>
          <SelectInput
            onChange={(value: string) => choseDocument(value)}
            options={[{ value: 'CPF (dependente)', label: 'CPF (dependente)' }, { value: 'RG (dependente)', label: 'RG (dependente)' }]}
            value={document}
            defaultValue={"Selecione um documento..." } />      
          <span>
            {errors.filter((error) => error.key === 'document').map(formatMessage).join(', ')}
          </span>
        </>}  

      {(document === 'CPF (dependente)' || hasBothDocuments) &&
        <>
          <TextInput
            onChange={(value: any) => setTemporaryCpf(value)}
            value={temporaryCpf}
            type="text"
            placeholder="CPF"
            maxLength={14}
            typeMask="cpf" />
          <span>
            {errors.filter((error) => error.key === 'cpf').map(formatMessage).join(', ')}
          </span>
        </>
      }

      {(document === 'RG (dependente)' || hasBothDocuments) &&
        <>
          <DocumentInputs>
            <div className='rg-input'>
              <TextInput
                onChange={(value: any) => setTemporaryRg(value)}
                value={temporaryRg}
                type="text"
                placeholder="RG"
                maxLength={14} />
              <span>
                {errors.filter((error) => error.key === 'rg').map(formatMessage).join(', ')}
              </span>
            </div>

            <div className='issuing-agency-select'>
              <SelectInput
                onChange={(value: string) => setTemporaryIssuingAgency(value)}
                options={issuingAgencies}
                value={temporaryIssuingAgency}
                defaultValue={ temporaryIssuingAgency || "Selecione um Órgão Emissor..." } />
              <span>
                {errors.filter((error) => error.key === 'issuing_agency').map(formatMessage).join(', ')}
              </span>
            </div>
          </DocumentInputs>
          <span></span>
       </>
      }

      <TextInput
        onChange={(value: any) => setBirthDate(value)}
        value={birthDate}
        type="text"
        placeholder="Data de nascimento *"
        typeMask="date" />
      <span>
        {
          (invalidBirthDate && birthDate.length) ? 'Data inválida' : 
            errors
              .filter((error) => error.key === 'birth_date')
              .map(formatMessage)
              .join(', ')
        }
      </span>

      <SelectInput
        onChange={(value: string) => setGender(value)}
        options={genderOptions}
        value={gender}
        defaultValue="Selecione um gênero..." />
      <span></span>

      <TextInput
        onChange={(value: any) => setPhone(value)}
        value={phone}
        type="text"
        placeholder="Telefone"
        typeMask="phone"
        maxLength={15} />
      <span>
        {
          errors
            .filter((error) => error.key === 'phone')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      <TextInput
        onChange={(value: any) => setEmail(value)}
        value={email}
        type="text"
        placeholder="E-mail"
        maxLength={255} />
      <span>
        {
          errors
            .filter((error) => error.key === 'email')
            .map(formatMessage)
            .join(', ')
        }
      </span>

      {messages.map(msg => formatMessage({ key: '', message: msg }))
        .join(', ')}
      <ActionsBox>
        <p onClick={() => history.push('/assinantes/dependentes')}>Cancelar</p>
        <PrimaryButton type="submit" loading={saving} active={!invalidBirthDate}>
          Salvar alteração
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
}

export default FormPersonalData;
