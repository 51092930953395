import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const ClassificationsArea = styled.div`
  width: 100%;
`;

export const ClassificationsCardsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 17rem));
  gap: 1rem;
  margin-top: 1rem;
`;

export const ClassificationCard = styled.div`
  height: 12rem;
  background-color: ${COLORS.defaultBg};
  border: 1px solid ${COLORS.borderBase};
  border-radius: 6px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.borderBase};
  }

  p {
    margin-top: 1rem;
  }

  p + p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${COLORS.mediumGrey};
  }
`;

export const PartnersArea = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PartnersAreaTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: ${COLORS.accentColor};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const PartnersCardsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 11rem));
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

export const PartnerCard = styled.div`
  min-height: 10rem;
  max-height: 10rem;
  background-color: ${COLORS.defaultBg};
  border: 1px solid ${COLORS.borderBase};
  border-radius: 6px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.borderBase};
  }

  p {
    margin-top: 1rem;
    font-size: 0.8rem;
    text-align: center;
  }

  img {
    height: 4rem;
    width: 4rem;
    border-radius: 50% !important;
  }
`;