import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import api from 'service/hrAccountApi';

import arrowLeft from 'assets/back-slide.svg';
import arrowRight from 'assets/next-slide.svg';
import banner1 from 'assets/banner-1.png';
import banner2 from 'assets/banner-2.png';
import banner3 from 'assets/banner-3.png';

import {
  BannersContainer,
  Slides,
  Slide,
  NavigationButton
} from './styles';

interface IBanner {
  key: string;
  imageUrl: string;
  active: boolean;
}

const Banners: React.FC = () => {
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [canStartSlides, setCanStartSlides] = useState<Boolean>(false);

  useEffect(() => {
    startAutomaticSlides();
  }, [canStartSlides]);

  useEffect(() => {
    getBanners();
  }, []);

  function startAutomaticSlides() {
    if (canStartSlides) {
      setInterval(() => {
        nextSlide();
      }, 10000);
    }
  }

  function defaultBanners(): IBanner[] {
    return [
      { key: uuidv4(), active: true, imageUrl: banner1 },
      { key: uuidv4(), active: false, imageUrl: banner2 },
      { key: uuidv4(), active: false, imageUrl: banner3 },
    ];
  }

  function getBanners() {
    api.get('/custom_images/login_carousel')
      .then(response => {
        const images = response.data.images || [];
        const newBanners = images.map((image: any) => ({
          key: uuidv4(),
          active: true,
          imageUrl: image.url
        }));

        if (!!newBanners.length) {
          newBanners[0].active = true;
          setBanners(newBanners);
        } else {
          setBanners(defaultBanners);
        }

        setCanStartSlides(true);
      })
      .catch((_error) => {
        setBanners(defaultBanners);
        setCanStartSlides(true);
      });
  }

  function selectBanner(key: string) {
    const newBanners = banners.map(banner => {
      banner.active = false;

      if (banner.key === key) {
        banner.active = true;
      }

      return banner;
    });

    setBanners(newBanners);
  }

  function nextSlide() {
    if (banners.length <= 1) return;

    let newBanners = [...banners];
    newBanners = markNextBanner(newBanners);
    setBanners(newBanners);
  }

  function backSlide() {
    if (banners.length <= 1) return;

    let newBanners = [...banners].reverse();
    newBanners = markNextBanner(newBanners);
    setBanners(newBanners.reverse());
  }

  function markNextBanner(newBanners: IBanner[]): IBanner[] {
    if (!newBanners.length) return newBanners;

    for (let i = 0; i < newBanners.length; i++) {
      const currentBanner = newBanners[i];
      const nextBanner = newBanners[i + 1];

      if (currentBanner.active && !!nextBanner) {
        currentBanner.active = false;
        nextBanner.active = true;
        break;
      } else {
        currentBanner.active = false;
      }
    }

    if (!newBanners.filter(banner => banner.active).length) {
      newBanners[0].active = true;
    }

    return newBanners;
  }

  return (
    <BannersContainer
      imageUrl={banners.find(banner => banner.active)?.imageUrl}
    >
      {
        banners.length > 1 &&
        <>
          <NavigationButton type="BACK" onClick={backSlide}>
            <img src={arrowLeft} alt="Voltar imagem" />
          </NavigationButton>
          <NavigationButton type="NEXT" onClick={nextSlide}>
            <img src={arrowRight} alt="Próxima imagem" />
          </NavigationButton>
        </>
      }
      <Slides>
        {banners.map(banner => (
          <Slide
            key={banner.key}
            active={banner.active}
            onClick={() => selectBanner(banner.key)}
          />
        ))}
      </Slides>
    </BannersContainer>
  );
};

export default Banners;
