import { Route, Redirect, useLocation } from 'react-router-dom';

import { isAuthenticated as isAuthenticatedHrAccount } from 'service/hrAccount';
import { isAuthenticated as isAuthenticatedSubscriber } from 'service/subscriber';

import { getDocumentTitle } from 'utils'

export const PrivateRouterHr = ({ component, ...rest }: any) => {
  const Component = component;
  const location = useLocation();
  getDocumentTitle(location)

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticatedHrAccount() ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: "/rh/login", state: { from: props.location } }} />
          )
      }
    />
  )
}

export const PrivateRouterSubscriber = ({ component, ...rest }: any) => {
  const Component = component;
  const location = useLocation();
  getDocumentTitle(location)

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticatedSubscriber() ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: "/assinantes/login", state: { from: props.location } }} />
          )
      }
    />
  )
}
