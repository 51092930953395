import React from 'react';

import { Link } from './styles';

interface IBtnLink {
  onClick: Function;
}

const BtnLink: React.FC<IBtnLink> = ({ children, onClick }) => (
  <Link onClick={() => onClick()}>{children}</Link>
);

export default BtnLink;
