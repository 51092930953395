import styled from 'styled-components';
import { COLORS } from 'styles/constants';

interface ICard {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 2rem 3.75rem 1rem 3.75rem;

  input {
    flex: 1;
    min-width: 30rem;
  }

  h1 {
    font-weight: bold;
    font-size: 1.7rem;
  }

  .active-icon {
    margin-left: auto;
  }

  .empty-area{
    text-align: center;
    margin-top: 2rem;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
`;

export const Card = styled.div<ICard>`
  height: fit-content;
  width: 21.5rem;
  margin: 0.5rem 0.5rem;
  padding: 0.2rem 0.6rem 0.6rem 0.6rem;

  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .voucher-icon {
      display: flex;
      align-items: center;

      img {
        width: 1.5rem;
        margin-right: 0.5rem;
      }

      strong {
        color: ${(props) => props.active ? 'normal' : COLORS.lightGrey};
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.938rem;
        letter-spacing: 0.125rem;
      }

      p {
        font-weight: normal;
        font-size: 0.625rem;
        color: ${(props) => props.active ? COLORS.defaultSubtitle : COLORS.lightGrey};
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1rem;
    margin-top: 0.5rem;
    border-top: 0.0625rem dashed ${COLORS.lightGrey};
  
    .voucher-title {
      font-style: normal;
      font-weight: 550;
      font-size: 1.2rem;
      line-height: 0.625rem;
      letter-spacing: 0.0938rem;
      text-align: right;
      color: ${(props) => props.active ? COLORS.mediumOrange : COLORS.lightGrey};
    }

    .voucher-description {
      font-style: normal;
      font-weight: 300;
      font-size: 0.80rem;
      line-height: 1.125rem;
      color: ${(props) => props.active ? COLORS.mediumGrey : COLORS.lightGrey};
      text-align: right;
      margin-top: 0.6rem;
    }

    .show-more {
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: ${(props) => props.active ? COLORS.mediumOrange : COLORS.lightGrey};
      margin-top: 1rem;
      cursor: pointer;
    }
  }
`;

export const SearchContainer = styled.form`
  display: flex;
  margin-top: 1rem;

  button {
    margin-left: 1rem;
  }
`;

export const ButtonsSearchContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  button + button {
    margin-left: 1rem;
  }
`;

export const EmptyArea = styled.p`
  text-align: center;
  margin-top: 3.75rem;
`;
