import React from 'react';

const PlusContract: React.FC = () => {
  return (
    <div className="contract-text-area">
      <p className="centered"><b>CONTRATO DE ADESÃO - CARTÃO ALIANÇA MAIS</b></p>
      <p className="centered disclaimer">Cartões de descontos <b><u>não são planos de saúde</u></b>, não garantem e não são responsáveis pelos serviços oferecidos e por quaisquer
        pagamentos de despesas. Os descontos podem ser alterados a qualquer momento, por livre iniciativa dos prestadores de serviços,
        sem que a CONTRATADA seja responsável por eles ou pelos serviços, os quais serão pagos diretamente pelo usuário.</p>

      <p><b>ALIANÇA BENEFÍCIOS E SERVIÇOS LTDA.</b>, inscrita no CNPJ/MF sob n. 18.336.018/0001-24, com sede na
        Avenida Bernardo Monteiro, n. 1472, sala 01, Bairro Funcionários, na Cidade de Belo Horizonte, Estado de Minas
        Gerais, CEP 30.150-281, ora denominada CONTRATADA e o ADERENTE denominado no Quadro de Contratação
        anexo, acordam as seguintes condições:</p>

        <p>1. Através da assinatura do presente Contrato de Adesão (“Contrato”), o ADERENTE contrata a prestação do
          serviço de acesso à um cartão de benefícios com empresas prestadoras de serviços nas áreas de saúde
          (consultas médicas, exames clínicos, tratamentos odontológicos, academias e outros), educação e lazer, com
          descontos específicos (“Cartão”), mediante pagamento da condição escolhida no Quadro de Contratação
          descrito ao final do presente contrato.</p>

        <p className="red">1.1. Terão direito de acesso aos benefícios vinculados ao Cartão Aliança Mais, o ADERENTE e até 04 (quatro)
          dependentes, conforme listados no Anexo I – Termo de Inclusão dos Dependentes.</p>

        <p>1.2. O ADERENTE se responsabiliza integralmente por todas as informações inseridas no presente contrato,
          inclusive pela sua veracidade e integridade, não sendo a CONTRATADA responsável por eventuais
          inconsistências decorrentes de informações inverídicas ou incorretas.</p>

        <p>1.3. É inteira responsabilidade do ADERENTE manter a CONTRATADA informada sobre quaisquer alterações
          nos dados indicados no Quadro de Contratação, sob pena de rescisão do presente Contrato.</p>

        <p>1.4. A CONTRATADA se reserva a eventuais direitos de regresso em caso de fraude ou falhas relativas às
          informações prestadas pelo ADERENTE.</p>

        <p>2. O ADERENTE declara ter conhecimento de que poderá ter acesso a rede de parceiros, unidades de
          atendimento e demais informações sobre o Cartão Aliança Mais no site www.cartaoalianca.com.br (“Portal”),
          mediante identificação do beneficiário, o qual poderá ser constantemente atualizado a qualquer momento pela
          CONTRATADA.</p>

        <p>2.1. A CONTRATADA não se responsabiliza pela manutenção das Empresas Parceiras na rede credenciada,
          nem tampouco pelos serviços prestados por estas diretamente ao ADERENTE. </p>

        <p>2.2. A CONTRATADA não se responsabiliza pela qualidade técnica, horários, profissionais ou quaisquer outras
          condições relacionadas aos serviços prestados pelas Empresas Parceiras, assim como, não garante os
          preços por elas aplicados ou os benefícios estabelecidos, uma vez que estas podem sair do rol de
          Empresas Parceiras a qualquer tempo.</p>

        <p>2.3. Todos os serviços das Empresas Parceiras utilizados pelo ADERENTE, não inclusos no rol dos serviços
          oferecidos pelo Cartão Aliança Mais, serão pagos estritamente pelo ADERENTE, de forma direta e sem
          qualquer intermediação da CONTRATADA, na modalidade de pagamento disponibilizada pela Empresa
          Parceira, não havendo qualquer responsabilidade ou gerência da CONTRATADA neste aspecto.</p>

        <p>2.4. É de responsabilidade do ADERENTE confirmar no Portal, a relação de Empresas Parceiras, bem como os
          descontos e benefícios oferecidos, anteriormente à sua utilização, considerando a possibilidade de
          alterações desses dados, de forma constante.</p>

        <p>2.5. O ADERENTE concorda em receber informações promocionais sobre as Empresas Parceiras do Cartão
          Aliança, através de e-mail, ligações ou mensagens, conforme dados informados neste ato de adesão.</p>

        <p>3. O Cartão Aliança Mais inclui além de todos os benefícios oferecidos pelo Cartão Aliança Saúde, os seguintes
          serviços ao beneficiário titular e seus dependentes:</p>

        <p>3.1. Telemedicina (consultas online com clínico geral para situações de baixa complexidade), possibilitando até
          24 consultas anuais, sendo 02 (duas) ao mês não cumulativas, limitada a um dos usuários (titular ou
          dependente), ou seja, somente 01 (um) CPF poderá utilizar o serviço no mês</p>

        <p>3.2. Seguro de vida individual que engloba cobertura de morte acidental no valor de R$15.000,00 (quinze mil
          reais), assistência funeral individual no valor de R$5.000,00 (cinco mil reais) e até 06 (seis) diárias de
          internação hospitalar por acidente ou doença no valor de R$100,00 (cem reais) cada.</p>

        <p>3.3. Assistência residencial que contempla chaveiro, vidraceiro, mão de obra hidráulica e elétrica com uma
          limitação de R$150,00 (cento e cinquenta reais) cada e limitada a 03 (três) intervenções/ano.</p>

        <p>3.4. Assistência veicular que contempla auto socorro após pane (envio de mecânico para conserto no local do
          evento desde que tecnicamente possível) no valor de até R$100,00 (cem reais), reboque/recolha após
          pane ou evento previsto (remoção do veículo ou motocicleta até oficina, ou concessionária mais próxima, no
          raio de até 100km total para ida e volta), troca de pneus (mão de obra ou reboque até o borracheiro mais próximo), envio de chaveiro
          (mão de obra para abertura do veículo e confecção de uma chave) no limite de até R$300,00 (trezentos reais) e pane seca (reboque até o
          posto de abastecimento mais próximo) limitada a 03 (três) intervenções/ano.</p>

        <p>3.5. Voucher para realização de exames de análises clínicas (colesterol total, creatinina com taxa de filtração
          glomerular, HDL colesterol, hemoglobina glicada fração A1C, hemograma completo, LDL colesterol,
          triglicérides -soro) limitado a uma única utilização/ano para o beneficiário titular ou seus dependentes.</p>

        <p>4. O ADERENTE obriga-se a pagar pelo Cartão, a partir da assinatura do presente contrato, para o seu uso próprio
          e o de seus dependentes, devidamente identificados no Quadro de Contratação e Anexo I, na modalidade de
          pagamento que optar no momento da contratação ou de sua renovação, a qual será consignada em anexo a
          este Instrumento.</p>

        <p>4.1. O ADERENTE apenas terá direito aos serviços e vantagens oferecidos pelas Empresas Parceiras após o
          pagamento da primeira mensalidade ou da primeira parcela da anuidade e, para a manutenção dos
          benefícios concedidos através do Cartão, deverá o ADERENTE manter o cumprimento rigorosamente em
          dia das suas obrigações financeiras junto à CONTRATADA.</p>

        <p>4.2. Os valores indicados no Quadro de Contratação serão reajustados anualmente, sempre no mês de
          aniversário do Contrato, em conformidade com o IGP-M (FGV) acumulado do ano anterior, mediante
          valores atualizados disponíveis no Portal.</p>

        <p>5. O Contrato possui vigência constante no Quadro de Contratação, com renovação automática.</p>

        <p>5.1. O presente Contrato será imediatamente suspenso em caso de não pagamento pelo ADERENTE,
          momento em que serão suspensos e tornar-se-ão indisponíveis quaisquer serviços oferecidos pelo Cartão,
          sem prejuízo ao direito da CONTRATADA realizar as cobranças pendentes por quaisquer meios.</p>

        <p>6. O ADERENTE confirma ter lido e entendido todos os termos deste Instrumento, tendo-lhe sido entregue uma via
          completa, declarando-se esclarecido e de acordo com as suas Cláusulas, bem como, ratifica estar ciente de que
          o Cartão Aliança Mais não é plano de saúde, assim como a CONTRATADA não garante e não se
          responsabiliza pelos descontos e benefícios concedidos pelas Empresas Parceiras, assim como, pela
          atualização da rede credenciada. Confirma, ainda, o ADERENTE, possuir conhecimento de que qualquer
          pagamento referente aos serviços utilizados é única e exclusivamente de sua responsabilidade junto às
          Empresas Parceiras.</p>

        <p>6.1. O ADERENTE autoriza, de acordo com a Lei Geral de Proteção de Dados n. 13.709/2018, a divulgação de
          seus dados pessoais e de seus dependentes, para a CONTRATADA e Empresas Parceiras prestadoras de
          serviços, somente para fins deste Contrato, ficando vedado o repasse destas informações a terceiros, com
          finalidade diversa.</p>

        <p>7. O presente Contrato e seus respectivos anexos consubstanciam todas as avenças e entendimentos havidos
          entre as Partes, rescindindo e substituindo, todos os contratos, acordos, promessas, compromissos, cartas,
          comunicações ou obrigações, verbais ou escritas, assumidas anteriormente por qualquer das Partes com
          relação ao Cartão Aliança Mais.</p>

        <p>8. Para resolver quaisquer controvérsias originadas deste Contrato, as Partes elegem o foro da Comarca da
          residência do ADERENTE, conforme informado no Quadro de Contratação, por mais privilegiado que outro
          possa ser.</p>

        <p>Declaro, para todos os fins de direito, que procedi à leitura dos termos deste Contrato, concordando integralmente com todos os termos e elegendo as seguintes condições de contratação do Cartão Aliança Mais.</p>

        <p><b><u>Cancelamento:</u></b></p>

        <p>Em caso de arrependimento o ADERENTE poderá cancelar o Cartão Aliança Mais dentro dos 07 (sete) primeiros dias da
          contratação sem custo, desde que não utilizado. Após este período, caso o ADERENTE não tenha se beneficiado dos
          descontos concedidos pelo Cartão, o Contrato poderá ser cancelado mediante pagamento de multa administrativa de 15%
          (quinze por cento) sobre o valor total do Contrato. Caso o ADERENTE tenha se beneficiado dos descontos concedidos pelo
          Cartão Aliança Mais, a título de rescisão será devida multa no valor relativo aos descontos obtidos nas prestações dos serviços.</p>

        <p><b><u>Privacidade:</u></b></p>

        <p>O ADERENTE autoriza expressamente, de acordo com a Lei Geral de Proteção de Dados n. 13.709/2018, a divulgação dos
          seus dados pessoais e de seus dependentes por este indicado, para a CONTRATADA e Empresas Parceiras, com a finalidade
          expressa de concederem os descontos e benefícios oferecidos pelo “Cartão Aliança Mais”, ficando vedado o repasse dessas
          informações a terceiros para fins diversos que não estejam relacionados ao cumprimento deste Contrato.</p>
    </div>
  );
}

export default PlusContract;
