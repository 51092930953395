import React, { useState, FormEvent } from 'react';
import { useHistory } from "react-router-dom";

import { toast } from 'react-toastify';

import SiteHeaderMenu from 'components/SiteHeaderMenu';
import Banners from 'components/Banners';
import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';

import api from 'service/subscriberApi';

import {
  ContainerBody,
  ContainerLogin,
  Form,
  ChangeAccessText,
  BackLink,
  Message
} from './styles';

interface IMessage {
  description: string;
  isError: boolean;
}

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<IMessage>({ isError: false, description: '' });

  const history = useHistory();

  async function submitForm(event: FormEvent) {
    event.preventDefault();
    setMessage({ description: '', isError: false });

    if (!email) return;

    try {
      const response = await api.post('/users/auth/forgot-password', { email: email.trim().toLowerCase() });
      setEmail('');
      toast.success(response.data.message);
      history.push('/assinantes/login');
    } catch (ex: any) {
      if (ex.response && ex.response.status === 400) {
        setMessage({ isError: true, description: ex.response.data.error });
      } else {
        setMessage({
          isError: true,
          description: 'Serviço indisponível no momento, por favor tente mais tarde.'
        });
      }
    }
  }

  return (
    <>
      <SiteHeaderMenu />
      <ContainerBody>
        <Banners />
        <ContainerLogin>
          <Form onSubmit={submitForm}>
            <h1>Esqueceu sua senha?</h1>
            <h5>Insira seu e-mail abaixo para redefini-la.</h5>

            <TextInput
              onChange={(value: any) => setEmail(value)}
              value={email}
              type="text"
              placeholder="E-mail" />

            <Message isError={message.isError}>{message.description}</Message>

            <PrimaryButton type="submit">Enviar</PrimaryButton>

            <BackLink onClick={() => history.goBack()}>Voltar</BackLink>
            <ChangeAccessText onClick={() => history.push('/escolher-acesso')}>
              Mudar perfil de acesso
          </ChangeAccessText>
          </Form>
        </ContainerLogin>
      </ContainerBody>
    </>
  )
}

export default ForgotPassword;
