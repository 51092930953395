import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  width: 100%;

  section {
    width: 100%;
    padding: 2rem 3.75rem 1rem 3.75rem;

    h1 {
      font-size: 1.125rem;
      line-height: 24px;
      margin-bottom: 1rem;
    }

    .card {
      padding: 1rem;
      border: 1px solid ${COLORS.borderBase};
      border-radius: 6px;

      p span {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 600;
      }

      .link {
        color: ${COLORS.accentColor};
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .benefit-card {
      padding: 1rem;
      border: 1px solid ${COLORS.borderBase};
      border-radius: 6px;
      height: 11rem;
      width: 15rem;
      text-align: center;
      margin: 0.5rem;

      img {
        width: 4.375rem;
        margin-bottom: 1rem;
      }
    }

    .contract-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .contract-title {
      display: flex;
      align-items: center;

      p {
        margin-left: 1rem;
      }
    }
  }
`;

export const Loading = styled.p`
  text-align: center;
  padding: 2rem;
`;
