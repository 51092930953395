import axios from 'axios';

import { getCompany } from './company';
import { getToken, logout } from './hrAccount';

const api = axios.create({
  baseURL: process.env.REACT_APP_WEBAPI
});

const apisToIgnoreToken = [
  '/hr-account/auth',
  '/hr-account/forgot-password',
  '/hr-account/reset-password',
  '/custom_images/login_carousel'
];

const apisToIgnoreWhenWithoutCompany = [
  ...apisToIgnoreToken,
  '/hr-account/companies',
  '/hr-account',
  '/hr-account/photos',
  '/hr-account/reset-password'
];

api.interceptors.request.use(async (config: any) => {
  if (!apisToIgnoreWhenWithoutCompany.includes(config.url)) {
    const company = getCompany();
    if (!company) {
      window.location.href = '#/rh/escolher-empresa';
    }
  }

  if (!apisToIgnoreToken.includes(config.url)) {
    const token = getToken();
    config.headers.Authorization = token;
  }

  const { REACT_APP_API_KEY } = process.env;

  config.headers['X-API-Key'] = REACT_APP_API_KEY

  return config;
}, (error: any) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error: any) => {
  const status = error.response.status;
  const url = error.response.config.url;

  if (status === 401 && !apisToIgnoreToken.includes(url)) {
    logout()
    window.location.href = '#/escolher-acesso';
    const requestConfig = error.config;
    return axios(requestConfig);
  }

  return Promise.reject(error);
});

export default api;
