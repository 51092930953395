import React, { useState } from 'react';

import Banners from 'components/Banners';
import SiteHeaderMenu from 'components/SiteHeaderMenu';
import FirstStep from './FirstStep';
import SecondStep, { IUser } from './SecondStep';

import {
  ContainerBody,
  ContainerLogin,
  Form,
} from './styles';

const FirstAccess: React.FC = () => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [wasUserFound, setWasUserFound] = useState(false);

  function userFound(user: IUser) {
    setUser(user);
    setWasUserFound(true);
  }

  function back() {
    setWasUserFound(false);
  }

  return (
    <>
      <SiteHeaderMenu />
      <ContainerBody>
        <Banners />
        <ContainerLogin>
          <Form>
            <h1>Primeiro acesso</h1>
            <h5>Para o seu primeiro acesso por favor informe onde adquiriu o cartão e seu CPF.</h5>
            {!wasUserFound ? <FirstStep callback={userFound} /> : <SecondStep back={back} user={user} />}
          </Form>
        </ContainerLogin>
      </ContainerBody>
    </>
  );
}

export default FirstAccess;
