import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Form = styled.form`
   max-width: 25rem;
  width: 100%;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-bottom: 1rem;
  }

  span {
    font-size: 10px;
    color: ${COLORS.red};
  }

  div {
    margin-top: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 14px;
      color: ${COLORS.accentColor};
      text-decoration: underline;
      cursor: pointer;
    }

    &.actions {
      margin-top: 3rem;
    }
  }
`;
