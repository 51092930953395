import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getNameInitials, limitStr } from 'utils';

import api from 'service/hrAccountApi';
import { getUser, logout, isAuthenticated } from 'service/hrAccount';
import { getCompanyId, getCompany } from 'service/company';

import {
  ContainerNav,
  ContainerTitle,
  ContainerHrAccount,
  CirclePhoto,
  SideMenu,
  NotificationBox,
  Arrow,
  Alert
} from './styles';

import arrowLeft from 'assets/arrow-left.svg';
import arrowDown from 'assets/arrow-down.svg';
import iconProfile from 'assets/icon-profile.svg';
import iconKey from 'assets/icons/key.svg';
import exit from 'assets/exit.svg';
import buildings from 'assets/buildings.svg';
import notification from 'assets/notification.svg';

import Breadcrumb from 'components/Breadcrumb';
import NotificationMenu from './NotificationMenu';

interface IHeaderMenu {
  title?: string;
  backPath?: string;
}

let intervalState: any = null;

const HeaderMenu: React.FC<IHeaderMenu> = ({ title, backPath }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [notificationsNotSeenCount, setNotificationsNotSeenCount] = useState(0);
  const [hrAccount] = useState(formatHrAccount);

  useEffect(() => {
    clearInterval(intervalState);

    intervalState = setInterval(() => {
      const company = getCompany();
      if (isAuthenticated() && company) {
        api.get(`/hr-account/companies/${getCompanyId()}/notifications`)
          .then((response) => {
            const { notifications_not_seen } = response.data;
            setNotificationsNotSeenCount(notifications_not_seen || 0);
          });
      }
    }, 60000);
  }, []);

  function formatHrAccount() {
    const { name: fullName, email, imageUrl } = getUser();

    return {
      fullName,
      initials: getNameInitials(fullName),
      reducedName: limitStr(fullName, 12, false),
      email,
      imageUrl
    };
  }

  const history = useHistory();

  function handleLogout() {
    logout()
    history.push('/rh/login');
  }

  function openNotification() {
    setShowNotificationMenu(!showNotificationMenu);
    setNotificationsNotSeenCount(0);
  }

  return (
    <ContainerNav>
      <Breadcrumb />
      <ContainerTitle>
        <div>
          {backPath && <img src={arrowLeft} onClick={() => backPath && history.push(backPath)} alt="Seta para esquerda" />}
          <h2>{title}</h2>
        </div>
        <ContainerHrAccount>
          {
            !!getCompany() &&
            <div onClick={() => openNotification()}>
              {notificationsNotSeenCount > 0 && <Alert>{notificationsNotSeenCount}</Alert>}
              <NotificationBox onMouseLeave={() => setShowNotificationMenu(false)}>
                <img src={notification} alt="Notificações" />
                {showNotificationMenu && <NotificationMenu />}
              </NotificationBox>
            </div>
          }
          <div onClick={() => setShowMenu(!showMenu)} onMouseLeave={() => setShowMenu(false)}>
            <CirclePhoto>
              {
                !!hrAccount.imageUrl ?
                  <img src={hrAccount.imageUrl} alt="Imagem de perfil" />
                  :
                  hrAccount.initials.toUpperCase()
              }
            </CirclePhoto>
            <p>Olá, {hrAccount.reducedName}</p>
            <img src={arrowDown} alt="Seta para baixo" />
            {
              showMenu &&
              <SideMenu>
                <Arrow>
                  <span></span>
                </Arrow>
                <ul>
                  <li onClick={() => history.push('/rh/dados-pessoais')}>
                    <img src={iconKey} alt="Ícone de perfil" />
                    <p>Dados pessoais</p>
                  </li>
                  {
                    !!getCompany() &&
                    <li onClick={() => history.push('/rh/perfil-da-empresa')}>
                      <img src={iconProfile} alt="Ícone de perfil" />
                      <p>Perfil da empresa</p>
                    </li>
                  }
                  <li onClick={() => history.push('/rh/escolher-empresa')}>
                    <img src={buildings} alt="Ícone de trocar empresa" />
                    <p>Trocar empresa</p>
                  </li>
                  <li onClick={handleLogout}>
                    <img src={exit} alt="Ícone de sair" />
                    <p>Sair</p>
                  </li>
                </ul>
              </SideMenu>
            }
          </div>
        </ContainerHrAccount>
      </ContainerTitle>
    </ContainerNav>
  )
};

export default HeaderMenu;
