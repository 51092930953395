import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${COLORS.mediumGrey};
    cursor: pointer;
    margin-top: 0.75rem;
  }

  button, strong {
    margin-top: 1.3rem;
    margin-bottom: 1rem;
  }

  button {
    padding: 0rem 2.1rem;
    align-self: center;
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
`;
