import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface IContainer {
  type: 'success' | 'error' | 'warning' | 'inactive' | 'light-warning';
  fontSize?: number;
}

export const Container = styled.span<IContainer>`
  padding: 0.3125rem;
  border-radius: 0.75rem;
  font-size: 10px !important;
  ${(props) => props.fontSize ? css`font-size: ${props.fontSize}px !important;` : ''}
  ${(props) => props.type === 'success' ? css`color: ${COLORS.white};` : ''}
  ${(props) => props.type === 'success' ? css`background-color: ${COLORS.success};` : ''}
  ${(props) => props.type === 'error' ? css`color: ${COLORS.white};` : ''}
  ${(props) => props.type === 'error' ? css`background-color: ${COLORS.red};` : ''}
  ${(props) => props.type === 'warning' ? css`color: black;` : ''}
  ${(props) => props.type === 'warning' ? css`background-color: ${COLORS.yellow};` : ''}
  ${(props) => props.type === 'inactive' ? css`color: ${COLORS.white};` : ''}
  ${(props) => props.type === 'inactive' ? css`background-color: ${COLORS.lightGrey};` : ''}
  ${(props) => props.type === 'light-warning' ? css`color: ${COLORS.mediumOrange};` : ''}
  ${(props) => props.type === 'light-warning' ? css`background-color: rgba(229, 161, 75, 0.2);` : ''}
`;
