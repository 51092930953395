import styled, { css } from 'styled-components';
import { COLORS, SIZES } from 'styles/constants';

export const Container = styled.div`
  max-width: 90rem;
  width: 100%;
  padding: 1.5rem 3.75rem 1rem 3.75rem;
`;

export const Title = styled.div`
  display: flex;
  max-width: 78rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0.5rem 1rem 0rem;

  strong {
    margin-left: 0.8rem;
    font-weight: 600;
  }
`;

export const CardsContainer = styled.section`
  max-width: 78rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media(${SIZES.smallerThanDesktophd}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 18rem;
  margin-left: 0.8rem;
  height: 17rem;
  width: 18rem;
  padding: 0.2rem 0.6rem 0.6rem 0.6rem;
  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;

  .card-content {
    margin-top: 2rem;
  }
  
  h4 {
    margin-top: 1.2rem;
  }

  p {
    margin-top: 0.8rem;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  a {
    color: ${COLORS.accentColor};
    text-decoration: underline;
    cursor: pointer;
  }

  @media(${SIZES.smallerThanDesktophd}) {
    margin-top: 1rem;
  }
`;

interface IPhoneNumberColor {
  color: "orange" | "green" | "accent"
}

export const SmallCardsContainer = styled.section`
  max-width: 78rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media(${SIZES.smallerThanDesktophd}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SmallCard = styled.div<IPhoneNumberColor>`
  height: fit-content;
  width: 25.05rem;
  margin-left: 0.8rem;
  padding: 0.2rem 0.6rem 0.6rem 0.6rem;

  border: 0.0625rem solid ${COLORS.lightGrey};
  box-sizing: border-box;
  border-radius: 0.375rem;

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0.5rem;
    margin-left: 0.3rem;

    .info {
      text-align: left;
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1rem;
    }

    .phone-number {
      font-style: normal;
      font-weight: 550;
      font-size: 1.3rem;
      line-height: 0.625rem;
      text-align: left;
      margin-top: 0.8rem;
      ${(props) => props.color === 'orange' ? css`color: ${COLORS.mediumOrange};` : ''}
      ${(props) => props.color === 'green' ? css`color: ${COLORS.primaryGreen};` : ''}
      ${(props) => props.color === 'accent' ? css`color: ${COLORS.accentColor};` : ''}
    }

    .phone-description {
      font-style: normal;
      font-weight: 300;
      font-size: 0.85rem;
      line-height: 1.125rem;
      color: ${COLORS.mediumGrey};
      margin-top: 0.8rem;
    }
  }

  @media(${SIZES.smallerThanDesktophd}) {
    margin-top: 1rem;
  }
`;

export const AccordionBody = styled.div`
  padding-top: 1rem;

  .title-paragraph {
    margin-top: 0.5rem;
  }

  a {
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }

  p {
    color: ${COLORS.mediumGrey};
  }

  span {
    color: ${COLORS.defaultText};
  }
`;

export const Accordions = styled.div`
  max-width: 76.7rem;
  margin-left: 0.8rem;
`;


