import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContainerHeader = styled.div`
  width: 100%;
`;

export const ContainerBody = styled.div`
  overflow-y: auto;
  height: calc(100vh - 120px);
`;
