import React, { useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  NavigationButton,
  NavigationPage,
  ContainerNavigation
} from './styles';

import backPagination from 'assets/back-pagination.svg';
import nextPagePagination from 'assets/next-page-pagination.svg';
import nextToLastPagination from 'assets/next-to-last-pagination.svg';
import backToStartPagination from 'assets/back-to-start-pagination.svg';

interface IPagination {
  currentPage: number;
  numOfPages: number;
  totalFilteredCount: number;
  totalCount: number;
  perPage: number;
  lengthPage: number;
  onSelectPage?: Function;
}

interface IPage {
  id: string;
  number: number;
}

const Pagination: React.FC<IPagination> = ({ currentPage, numOfPages, onSelectPage, perPage, lengthPage, totalCount }) => {
  const [pages, setPages] = useState<IPage[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [onlyShowThisPages, setOnlyShowThisPages] = useState<number[]>([]);
  const [page, setPage] = useState<number>(currentPage);

  useEffect(() => {
    setPages(mountArrayPages());
    setOnlyShowThisPages(selectFirstPages());
    setTotalPages(numOfPages);
  }, [totalPages, numOfPages]);

  function selectPage(selectedPage: number) {
    if (selectedPage === page) return;

    setPage(selectedPage);
    if (!!onSelectPage) {
      onSelectPage(selectedPage);
    }
  }

  function mountArrayPages(): IPage[] {
    const pages: IPage[] = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push({ id: uuidv4(), number: i });
    }
    return pages;
  }

  function selectFirstPages(): number[] {
    const pages: number[] = [];

    if (totalPages > 5) {
      for (let i = 1; i <= 5; i++) {
        pages.push(i);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  }

  function selectNextPage() {
    const [lastPage] = [...pages].reverse();
    if (lastPage.number === page) return;

    const nextPage = page + 1;
    selectPage(nextPage);

    const [lastNumber] = [...onlyShowThisPages].reverse();
    if (lastNumber === page) {
      let newPages: number[] = [...onlyShowThisPages, nextPage];
      newPages.shift();
      setOnlyShowThisPages(newPages);
    }
  }

  function selectBackPage() {
    const [firstPage] = pages;
    if (firstPage.number === page) return;

    const backPage = page - 1;
    selectPage(backPage);

    const [firstNumber] = onlyShowThisPages;
    if (firstNumber === page) {
      let pages: number[] = [...onlyShowThisPages].reverse();
      pages.shift();
      pages = pages.reverse();
      let newPages: number[] = [backPage, ...pages];
      setOnlyShowThisPages(newPages);
    }
  }

  function selectLastPage() {
    const reversedPages = [...pages].reverse();

    const [lastPage] = reversedPages;
    if (lastPage.number === page) return;

    selectPage(lastPage.number);

    if (pages.length > 5) {
      const newPages: number[] = [];
      for (let i = 0; i <= 4; i++) {
        newPages.push(reversedPages[i].number);
      }
      setOnlyShowThisPages(newPages.reverse());
    }
  }

  function selectFistPage() {
    const [firstPage] = pages;
    if (firstPage.number === page) return;

    selectPage(firstPage.number);

    if (pages.length > 5) {
      const newPages: number[] = [];
      for (let i = 0; i <= 4; i++) {
        newPages.push(pages[i].number);
      }
      setOnlyShowThisPages(newPages);
    }
  }

  function getDisplayPages(): string {
    let initPage = 0;
    let finishPage = 0;

    initPage = (currentPage - 1) * perPage;
    finishPage = initPage + lengthPage;

    return `${initPage} - ${finishPage}`;
  }

  return (
    <Container>
      <ContainerNavigation>
        <NavigationButton onClick={selectFistPage}>
          <img src={backToStartPagination} alt="Voltar para primeira página." />
        </NavigationButton>
        <NavigationButton onClick={selectBackPage}>
          <img src={backPagination} alt="Voltár página." />
        </NavigationButton>

        {
          pages.map((p) => (
            <NavigationPage
              key={p.id}
              active={p.number === page}
              onClick={() => selectPage(p.number)}
              show={onlyShowThisPages.includes(p.number)}>
              {p.number}
            </NavigationPage>
          ))
        }

        <NavigationButton onClick={selectNextPage}>
          <img src={nextPagePagination} alt="Ir para próxima página." />
        </NavigationButton>
        <NavigationButton onClick={selectLastPage}>
          <img src={nextToLastPagination} alt="Ir para última página." />
        </NavigationButton>
      </ContainerNavigation>
      <p>Mostrando {getDisplayPages()} de {totalCount}</p>
    </Container>
  )
}

export default Pagination;
