import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BodyModal = styled.div`
  display: grid;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    color: ${COLORS.red};
    cursor: pointer;
    margin-top: 0.75rem;
  }

  button, strong {
    margin-top: 1rem;
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
`;
