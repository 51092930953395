import React from 'react';

import { SuccessModal, ErrorModal, ConfirmModal } from './styles';

interface IModal {
  showModal: boolean;
  message: string;
  onCancel: Function;
  onConfirm: Function;
  type: 'success' | 'confirmation' | 'error';
  icon?: string;
  textBtnConfirm?: string;
}

const Modal: React.FC<IModal> = (props) => {
  const { showModal, onCancel, onConfirm, message, icon, type, textBtnConfirm } = props;

  function renderModal() {
    switch (type) {
      case 'success':
        return (
          <SuccessModal>
            <div>
              <img src={icon} alt="Ícone." />
              <strong>{message}</strong>
              <button onClick={() => onConfirm()}>OK</button>
            </div>
          </SuccessModal>
        )
      case 'confirmation':
        return (
          <ConfirmModal>
            <div>
              <img src={icon} alt="Ícone." />
              <strong>{message}</strong>
              <button onClick={() => onConfirm()}>{textBtnConfirm || 'Excluir'}</button>
              <button onClick={() => onCancel()}>Cancelar</button>
            </div>
          </ConfirmModal>
        )
      case 'error':
        return (
          <ErrorModal>
            <div>
              <img src={icon} alt="Ícone." />
              <strong>{message}</strong>
              <button onClick={() => onConfirm()}>OK</button>
            </div>
          </ErrorModal>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {showModal && renderModal()}
    </>
  );
}

export default Modal;
