import styled from "styled-components";
import { COLORS } from "styles/constants";

export const PaymentCardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${COLORS.borderBase};
  padding: 2rem;
  border-radius: 6px;
`;
