import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const CirclePhoto = styled.div`
  height: 5rem;
  width: 5rem;
  margin: auto;
  border-radius: 50%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.borderBase};
  margin-bottom: 1rem;

  img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
  }
`;

export const Form = styled.div`
  width: 100%;
  max-width: 25rem;
  text-align: center;

  input[type="file"] {
    display: none;
  }

  input, select {
    margin-top: 1rem;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.3125rem;
    line-height: 1.9375rem;
    color: ${COLORS.offblack};
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3125rem;
    color: ${COLORS.offblack};
    margin-top: 2rem;
    text-align: start;
  }

  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    color: ${COLORS.offblack};
  }

  .message {
    text-align: start;
    color: ${COLORS.red};
  }

  .empty-contacts {
    padding: 2rem;
  }
`;

export const TextSelectFile = styled.div`
  display: flex;
  justify-content: center;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 0.875rem;
    color: ${COLORS.accentColor} !important;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    img {
      margin-right: 0.375rem;
      width: 0.625rem;
      height: 0.625rem;
    }
  }

  p + p {
    margin-left: 2rem;
  }
`;

export const AddMoreContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 1rem;

  img {
    margin-left: 0.5rem;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;

export const ContactData = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${COLORS.borderBase};

  a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  img {
    margin-left: 0.5rem;
  }

  p {
    text-align: start;
    color: ${COLORS.red};
    margin-top: 0.3rem;
  }
`;
