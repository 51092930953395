import styled from 'styled-components';
import { COLORS } from 'styles/constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContainerList = styled.div`
  width: 100%;
  max-width: 51.75rem;
  padding: 50px 10px 50px 10px;

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${COLORS.offblack};
    margin-top: 22px;
    text-align: center;
  }
`;

export const Companies = styled.div`
  padding-top: 20px;
  flex-wrap: wrap;
  display: flex;
`;

export const Company = styled.div`
  display: grid;
  align-items: center;
  background-color: black;
  padding: 1rem;
  width: 7.875rem;
  height: 8.25rem;
  background: ${COLORS.backgroundBase};
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  margin: 6px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 1rem;
    color: ${COLORS.mediumGrey};
    margin: 0;
  }

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${COLORS.offblack};
    word-break: break-all;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }
`;
