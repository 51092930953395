import React from 'react';

import { ContainerModal, BodyModal } from './styles';

interface IGenericModal {
  height?: string;
  width?: string;
}

const GenericModal: React.FC<IGenericModal> = ({ children, height, width }) => (
  <ContainerModal>
    <BodyModal height={height} width={width}>
      {children}
    </BodyModal>
  </ContainerModal>
);

export default GenericModal;
