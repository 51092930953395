import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

interface ITitle {
  status: string;
}

interface INotification {
  seen: boolean;
}

export const Menu = styled.nav`
  display: flex;
  position: absolute;
  width: 18rem;
  background-color: ${COLORS.defaultBg};
  top: 2.8rem;
  right: 6.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.375rem 0 rgb(0 0 0 / 10%);
  z-index: 10;
`;

export const NotificationTitle = styled.div`
  width: 100%;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.125rem;
  color: ${COLORS.offblack};
`;

export const NotificationsList = styled.ul`
  width: 100%;

  hr {
    border-color: rgba(255, 255, 255, 0.5);
    opacity: 0.3;
  }
`;

export const Li = styled.li<INotification>`
  list-style-type: none;
  padding: 0.375rem 0;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  opacity: ${(props) => props.seen ? '0.5' : ''};

  &:hover {
    background-color: rgba(244, 244, 244, 0.6);
  }

  p {
    color: ${COLORS.offblack};
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1rem;
    color: ${COLORS.offblack};
    margin-top: 0.1rem;
  }

  .date {
    font-size: 0.75rem;
    color: ${COLORS.mediumGrey};
    margin-top: 0.25rem;
  }
`;

export const Title = styled.div<ITitle>`
  display: flex;
  align-items: center;

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    color: ${COLORS.accentColor};
    ${(props) => props.status === 'success' && css`color: ${COLORS.success};`}
    ${(props) => props.status === 'warning' && css`color: ${COLORS.mediumOrange};`}
    ${(props) => props.status === 'error' && css`color: ${COLORS.red};`}
  }

  img {
    height: 1rem;
    margin-right: 0.3rem !important;
    margin-left: 0rem !important;
    width: 1rem;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  height: 1rem;
  width: 56%;
  top: -0.6875rem;
  background-color: transparent;

  span {
    position: absolute;
    right: 4.5rem;
    bottom: -0.5rem;
    height: 1rem;
    width: 1rem;
    background-color: ${COLORS.defaultBg};
    transform: rotate(45deg);
  }
`;

export const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;

  p {
    color: ${COLORS.offblack};
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 0.625rem;
    line-height: 1rem;
   }
`;
