import React from 'react';

import { BodyModal } from './styles';

import GenericModal from 'components/GenericModal';

import refreshing from 'assets/refreshing.svg';

interface IProcessingModal {
  messageProcess: string;
}

const ProcessingModal: React.FC<IProcessingModal> = ({ messageProcess }) => {
  return (
    <GenericModal height="15rem">
      <BodyModal>
        <img src={refreshing} alt="Refreshing" />
        <strong>{messageProcess}</strong>
        <p>A importação do arquivo pode demorar alguns minutos devido a quantidade de funcionários</p>
      </BodyModal>
    </GenericModal>
  );
};

export default ProcessingModal;
