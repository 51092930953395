import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'service/subscriberApi';
import { useConfirmationModal } from 'hooks/useConfirmationModal';
import { useItemsMenuSubscriber } from 'hooks/useItemsMenuSubscriber';

import addIcon from 'assets/add.svg';
import purpleTrashIcon from 'assets/purple-trash.svg';
import editIcon from 'assets/edit.svg';
import trashIcon from 'assets/trash.svg';

import PrimaryButton from 'components/PrimaryButton';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import Badge from 'components/Badge';
import Alert from 'components/Alert';

import { IDependant } from '..';

import { Container, ContainerAdd, CustomTable, ContainerTable } from './styles';

interface IDependantData extends IDependant {
  checked?: boolean;
}

interface IListArea {
  dependants: IDependantData[];
  listDependants: Function;
}

const ListArea: React.FC<IListArea> = ({ dependants: deps, listDependants }) => {
  const history = useHistory();
  const { confirmDelete } = useConfirmationModal();
  const { showDependantsAlert } = useItemsMenuSubscriber();

  const [dependants, setDependants] = useState<IDependantData[]>([]);

  useEffect(() => {
    setDependants(deps.map(dep => ({ ...dep, checked: false })));
  }, [deps]);

  function selectAllCheckboxes() {
    const activeDependants = dependants.filter(dep => dep.active);
    if (!activeDependants.length) return;

    if (areAllSelected()) {
      const deps = dependants.map((dependant) => ({ ...dependant, checked: false }));
      setDependants(deps);
    } else {
      const deps = dependants.map((dependant) => ({ ...dependant, checked: true }));
      setDependants(deps);
    }
  }

  function areAllSelected(): boolean {
    const activeDependants = dependants.filter(dep => dep.active);

    if (!activeDependants.length) {
      return false;
    } else {
      return !activeDependants.filter(dep => !dep.checked).length;
    }
  }

  function selectItem(id: number) {
    const deps = dependants.map((dependant) => {
      if (dependant.id === id) {
        return { ...dependant, checked: !dependant.checked };
      } else {
        return { ...dependant };
      }
    });

    setDependants(deps);
  }

  function openConfirmExclusionModal(id: number | null) {
    if (!!id) {
      const dep = dependants.find(dep => dep.id === id);
      const description = `Tem certeza que deseja inativar o(a) dependente ${dep?.name}?`;
      confirmDelete({
        description,
        textBtnConfirm: 'Inativar',
        onConfirm: async () => {
          await inactiveDependant(id);
          listDependants();
        }
      });
    } else {
      const dependantsSelected: IDependantData[] = dependants.filter(dep => dep.active && dep.checked);
      const ids = dependantsSelected.map((dep) => dep.id);
      let description = '';

      if (ids.length > 1) {
        description = `Tem certeza que deseja inativar os ${dependantsSelected.length} dependentes selecionados?`;
      } else {
        const [id] = ids;
        const dep = dependants.find(dep => dep.id === id);
        description = `Tem certeza que deseja inativar o(a) dependente ${dep?.name}?`;
      }

      confirmDelete({
        description,
        textBtnConfirm: 'Inativar',
        onConfirm: async () => {
          for (let i = 0; i < ids.length; i++) {
            await inactiveDependant(ids[i]);
          }
          listDependants();
        }
      });
    }
  }

  async function inactiveDependant(id: number) {
    try {
      await api.post(`users/dependants/${id}/inactive`);
      toast.success('Dependente inativado com sucesso!');
    } catch (_ex) {
      toast.error('Não foi possível inativar o dependente, por favor tente novamente!');
    }
  }

  function showNewDependantBtn() {
    return dependants.filter(dep => dep.active).length < 4;
  }

  function containerAlert() {
    if (!showDependantsAlert) return;

    return (
      <>
        <Alert title="Atualização cadastral" type="info">
          <p>É importante para nós que você mantenha seus dependentes atualizados. Você pode adicionar até 4 dependentes!</p>
        </Alert>
        <br />
      </>
    )
  }

  function containerAdd() {
    if (!showNewDependantBtn()) return;

    return (
      <ContainerAdd>
        <PrimaryButton
          type="button"
          iconUrl={addIcon}
          onClick={() => history.push('/assinantes/dependente/novo?step=personal-data')}>
          Novo dependente
        </PrimaryButton>
      </ContainerAdd>
    );
  }

  return (
    <Container>
      {containerAlert()}
      {containerAdd()}
      <ContainerTable>
        <CustomTable>
          <thead>
            <tr>
              <th scope="col">
                <Checkbox
                  checked={areAllSelected()}
                  onChange={selectAllCheckboxes}
                />
                {dependants.filter(u => u.checked).length > 0 && (
                  <img
                    src={purpleTrashIcon}
                    alt="Icone para exclusão em massa"
                    onClick={() => openConfirmExclusionModal(null)}
                  />
                )}
              </th>
              <th scope="col">NOME</th>
              <th scope="col">CPF</th>
              <th scope="col">RG</th>
              <th scope="col">STATUS</th>
              <th scope="col">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {
              dependants.map((dependant) => (
                <tr key={dependant.id}>
                  <td>
                    {
                      dependant.active &&
                      <Checkbox
                        checked={!!dependant?.checked}
                        onChange={(e: any) => selectItem(dependant.id)}
                      />
                    }
                  </td>
                  <td>{dependant.name}</td>
                  <td>{dependant.cpf || '-'}</td>
                  <td>{dependant.rg || '-'}</td>
                  <td>
                    {dependant.active && <Badge type={'success'}>ATIVO</Badge>}
                    {!dependant.active && <Badge type={'error'}>INATIVO</Badge>}
                  </td>
                  <td>
                    <div className="d-flex flex-row">
                      <Tooltip position="top center" text="editar">
                        <img
                          className='mr-3'
                          src={editIcon}
                          alt="Icone para edição"
                          height="16rem"
                          onClick={() => history.push(`/assinantes/dependentes/${dependant.id}`)}
                        />
                      </Tooltip>
                      {dependant.active && (
                        <Tooltip position="top center" text="excluir">
                          <img
                            src={trashIcon}
                            height="16rem"
                            onClick={() => openConfirmExclusionModal(dependant.id)}
                            alt="Icone para exclusão"
                          />
                        </Tooltip>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </CustomTable>
      </ContainerTable>
    </Container>
  );
};

export default ListArea;
