import React from "react";

import { BodyModal } from "./styles";

import GenericModal from "components/GenericModal";

import starIcon from "assets/star-orange.svg";
import coinModalIcon from "assets/icons/coin.svg";
import closeIcon from "assets/icons/close-accent-background.svg";

interface ICoinModal {
  close: Function;
}

const CoinModal: React.FC<ICoinModal> = ({close}) => {  
  return (
    <GenericModal height="auto" width="37rem">
      <BodyModal>
        <img onClick={() => close()} src={closeIcon} alt="Fechar"></img>
        <div className="header-content">
          <img src={coinModalIcon} alt="Sorteio pela loteria federal"></img>
          <h3>Sorteio pela loteria federal</h3>  
        </div>
        <p>O valor do sorteio de R$ 5.000,00 é um sorteio aberto e mensal,
           todo ultimo sábado do mês (começa a concorrer no mês subsequente ao pagamento da 1º mensalidade).</p>
        <div className="icon-and-description">
          <img src={starIcon}></img>
          <p>Concorre durante toda a vigência, é um sorteio aberto;</p>
        </div>
        <div className="icon-and-description">
          <img src={starIcon}></img>
          <p>Pode ser sorteado mais de uma vez;</p>
        </div>
        <div className="icon-and-description">
          <img src={starIcon}></img>
          <p>Número da sorte será enviado juntamente com o certificado e condições gerais do seguro;</p>
        </div>
        <p className="winner-information">
          O ganhador será comunicado por telefone ou poderá consultar o sorteio&nbsp;
          <a href="https://www.metlife.com.br/suporte-e-servicos/vida/resultados-de-sorteios/" target="_blank">clicando aqui.</a>
        </p>
      </BodyModal>      
    </GenericModal>
  )
}

export default CoinModal;
