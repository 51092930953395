import React from 'react';
import { useHistory } from 'react-router-dom';

import arrowCloseMenuRight from 'assets/arrow-close-menu-right.svg';
import smallLogo from 'assets/small-logo.svg';

import { IMenuItem } from 'components/SubscriberDashboard';
import Tooltip from 'components/Tooltip';
import { ISideMenu } from '..';

import {
  SmallCloseMenu,
  SmallMenuBox,
  ContainerSmallLogo,
  ItemSmallMenu,
  ItemSmallMenuHelp,
} from './styles';

interface ISmallMenu extends ISideMenu {
  toggleMenu?: Function;
}

const SmallMenu: React.FC<ISmallMenu> = ({ helpItem, menuItems, toggleMenu }) => {
  const history = useHistory();

  return (
    <SmallMenuBox>
      <ContainerSmallLogo>
        <img src={smallLogo} alt="Cartão Aliança" />
      </ContainerSmallLogo>

      {menuItems?.map((menuItem: IMenuItem) => (
        <React.Fragment key={menuItem.id}>
          <Tooltip position="right center" text={menuItem.name}>
            <ItemSmallMenu
              key={menuItem.id}
              isActive={menuItem.isActive}
              title={menuItem.name}
              onClick={() => history.push(menuItem.path)}
            >
              <div>
                <img src={menuItem.logo} alt={menuItem.name} />
              </div>
            </ItemSmallMenu>
          </Tooltip>
        </React.Fragment>
      ))}

      <Tooltip position="right center" text="FAQs">
        <ItemSmallMenuHelp
          isActive={helpItem.isActive}
          onClick={() => history.push(helpItem.path)}
        >
          <div>
            <img id="help" src={helpItem.logo} alt={helpItem.name} />
          </div>
        </ItemSmallMenuHelp>
      </Tooltip>

      <SmallCloseMenu
        src={arrowCloseMenuRight}
        alt="Fechar menu"
        onClick={() => toggleMenu && toggleMenu()}
      />
    </SmallMenuBox>
  )
}

export default SmallMenu;
