import React from 'react';

import { Loading } from './styles';

interface ISpinner {
  size: string;
}

const Spinner: React.FC<ISpinner> = ({size}) => {
  return <Loading size={size}/>;
};

export default Spinner;
