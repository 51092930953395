import styled, { css } from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div`
  border: 1px solid ${COLORS.borderBase};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

interface IAccordion {
  titleColor?: "accent" | "default";
}

export const Header = styled.div<IAccordion>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    font-size: 1.25rem;
    color: ${COLORS.accentColor};
    ${(props) => props.titleColor === 'accent' ? css`color: ${COLORS.accentColor};` : ''}
    ${(props) => props.titleColor === 'default' ? css`color: ${COLORS.defaultText};` : ''}    
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }
`;
