import React, { FormEvent, useState } from 'react';
import { useHistory } from "react-router-dom";

import { ContainerBody, ContainerLogin, Form } from './styles';

import api from 'service/hrAccountApi';

import SiteHeaderMenu from 'components/SiteHeaderMenu';
import Banners from 'components/Banners';
import TextInput from 'components/TextInput';
import PrimaryButton from 'components/PrimaryButton';

import { toast } from 'react-toastify';

const ResetPassword: React.FC = (props: any) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const token: string = new URLSearchParams(props.location.search).get("token") || '';

  const history = useHistory();

  async function submitForm(event: FormEvent) {
    event.preventDefault();
    setMessage('');

    if (!password || !passwordConfirmation) {
      setMessage('Por favor, preencha todas as senhas.');
      return;
    }

    if (password !== passwordConfirmation) {
      setMessage('Senhas não coincidem.');
      return;
    }

    try {
      const params = {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: token
      }

      await api.post('/hr-account/reset-password', params);

      history.push('/rh/login')
    } catch (ex: any) {
      if (ex.response && ex.response.status === 400) {
        setMessage(ex.response.data.error);
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  return (
    <>
      <SiteHeaderMenu />
      <ContainerBody>
        <Banners />
        <ContainerLogin>
          <Form onSubmit={submitForm}>
            <h1>Redefinição de senha</h1>
            <h5>Insira uma nova senha para redefini-la.</h5>

            <TextInput
              onChange={(value: string) => setPassword(value)}
              value={password}
              type="password"
              placeholder="Senha" />
            <TextInput
              onChange={(value: string) => setPasswordConfirmation(value)}
              value={passwordConfirmation}
              type="password"
              placeholder="Repita a senha" />

            <span>{message}</span>

            <PrimaryButton type="submit">Alterar</PrimaryButton>
          </Form>
        </ContainerLogin>
      </ContainerBody>
    </>
  )
};

export default ResetPassword;
