import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px Roboto, sans-serif;
  }

  html, body, #root {
    margin: 0 auto;
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  .Toastify__toast--success {
    background: rgba(7,188,12,.9);
  }

  .Toastify__toast--warning {
    background: rgba(241,196,15,.9);
  }

  .Toastify__toast--error {
    background: rgba(231,76,60,.9);
  }
`;
