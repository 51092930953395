import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import PrimaryButton from 'components/PrimaryButton';
import SelectInput, { IOption } from 'components/SelectInput';
import TextInput from 'components/TextInput';

import subscriberApi from 'service/subscriberApi';

import { BackLink } from './styles';
import { IUser } from '../SecondStep';

import { toast } from 'react-toastify';

interface IFirstStep {
  callback: Function;
}

const FirstStep: React.FC<IFirstStep> = ({ callback }) => {
  const [accessType, setAccessType] = useState('select');
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();
  const accessTypeOptions: IOption[] = [
    { label: 'Em uma unidade', value: 'local' },
    { label: 'Por uma empresa', value: 'company' },
  ];

  async function searchCpf(event: FormEvent) {
    event.preventDefault();

    try {
      setMessage('');

      if (accessType === 'select' || cpf === '') {
        setMessage('Preencha todos os campos');
        return;
      }

      setLoading(true);
      const path = `/users/find-user-by-cpf?cpf=${cpf}&access_type=${accessType}`;
      const response = await subscriberApi.get(path);
      setLoading(false);
      const { users } = response.data;
      if (!!users.length) {
        const [user] = users;
        callback({
          id: user.id,
          name: user.name,
          cpf: user.cpf,
          email: '',
          bithdate: user.birth_date,
          phone: '',
          password: '',
          passwordConfirmation: '',
        } as IUser);
      } else {
        setMessage('CPF incorreto ou não existente.');
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.data) {
        setMessage(err.response.data.error);
      } else {
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      }
    }
  }

  return (
    <>
      <SelectInput
        onChange={(value: string) => setAccessType(value)}
        options={accessTypeOptions}
        value={accessType}
        defaultValue="Selecione onde adquiriu o cartão" />
      <TextInput
        onChange={(value: string) => setCpf(value)}
        value={cpf}
        type="text"
        placeholder="CPF"
        typeMask="cpf" />
      <span>{message}</span>
      <PrimaryButton type="submit" onClick={searchCpf} loading={loading}>Procurar</PrimaryButton>
      <BackLink onClick={() => history.push('/assinantes/login')}>Voltar</BackLink>
    </>
  )
}

export default FirstStep;
