import React from 'react';
import { TooltipContainer } from './styles';
import "semantic-ui-css/semantic.min.css";

interface ITooltip {
  text: string;
  position: string;
}

const Tooltip: React.FC<ITooltip> = ({ children, text, position }) => {
  return (
    <div>
      <TooltipContainer trigger={children} position={position}>
        { text }
      </TooltipContainer>
    </div>
  );
}

export default Tooltip;
