import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  ContainerBox,
  ContainerList,
  CustomTable,
  HeaderActions,
  ChipActive,
  ChipCanceled,
  Chip,
  FilterBox,
  StatusFilterBox,
} from './styles';

import api from 'service/hrAccountApi';
import { getCompanyId } from 'service/company';

import { useBreadcrumb } from 'hooks/useBreadcrumb'

import Dashboard from 'components/HrAccountDashboard';
import SearchInput from 'components/SearchInput';
import PrimaryButton from 'components/PrimaryButton';
import Checkbox from 'components/Checkbox';
import Tabs, { ITabItems } from 'components/Tabs';
import Pagination from 'components/Pagination';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import ProcessingModal from './ProcessingModal';
import SuccessReportModal from './SuccessModal';

import editIcon from 'assets/edit.svg';
import trashIcon from 'assets/trash.svg';
import purpleTrashIcon from 'assets/purple-trash.svg';
import exportIcon from 'assets/export.svg';
import addIcon from 'assets/add.svg';
import arrowDown from 'assets/arrow-down-black.svg';
import redTrash from 'assets/red-trash.svg';
import successIcon from 'assets/success-icon.svg';
import errorIcon from 'assets/error-icon.svg';
import iconProfileActive from 'assets/icon-profile-active.svg';
import iconProfileInactive from 'assets/icon-profile-inactive.svg';
import uploadActive from 'assets/upload-active.svg';
import uploadInactive from 'assets/upload.svg';
interface IEmployee {
  id: number;
  name: string;
  cpf: string;
  dependantsCount: number;
  status: string;
  checked: boolean;
}

interface INotification {
  id: number;
  status: string;
  content: any;
}

const Employees: React.FC = () => {
  let intervalState: any = null;
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [allEmployeesChecked, setAllEmployeesChecked] = useState<boolean>(
    false,
  );
  const [orderByName, setOrderByName] = useState<string>('');
  const [orderByDate, setOrderByDate] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalFilteredCount, setTotalFilteredCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(5);
  const [debounceTimer, setDebounceTimer] = useState<any>(0);
  const [search, setSearch] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfimModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessReportModal, setShowSuccessReportModal] = useState(false);
  const [showStatusFilterBox, setShowStatusFilterBox] = useState<boolean>(
    false,
  );
  const [showFilterBox, setShowFilterBox] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification>();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [employeesReportUrl, setEmployeesReportUrl] = useState('');
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [tabItems] = useState<ITabItems[]>([{
    id: uuidv4(),
    title: 'Meus Funcionários',
    path: '/rh/funcionarios',
    isActive: true,
    icons: {
      active: iconProfileActive,
      inactive: iconProfileInactive
    }
  },
  {
    id: uuidv4(),
    title: 'Importar Base',
    path: '/rh/importar-funcionarios',
    isActive: false,
    icons: {
      active: uploadActive,
      inactive: uploadInactive
    }
  }]);

  const history = useHistory();

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Funcionários', path: '/rh/funcionarios' },
    ]);
    loadEmployeesByCompany();
  }, [orderByName, orderByDate, statusFilter, currentPage]);

  useEffect(() => {
    if (search !== null) onSearch();
  }, [search]);

  useEffect(() => {
    if (!notification || notification.status === 'success') return;

    intervalState = setInterval(startCheckingReportProcess, 3000);
  }, [notification]);

  function loadEmployeesByCompany(): void {
    setAllEmployeesChecked(false);
    setLoading(true);
    setMessage('Carregando...');

    let url = `/hr-account/companies/${getCompanyId()}/users?`;
    url += `search=${search}&`;
    url += `page=${currentPage}&`;
    url += `per_page=${perPage}&`;
    url += `order_by_name=${orderByName}&`;
    url += `order_by_date=${orderByDate}&`;
    url += `status=${statusFilter}`;

    api
      .get(url)
      .then(response => {
        setLoading(false);
        setMessage('');

        const {
          total_count,
          total_filtered_count,
          total_pages,
          per_page,
          users
        } = response.data;

        setTotalCount(total_count);
        setTotalFilteredCount(total_filtered_count);
        setTotalPages(total_pages);
        setPerPage(per_page);

        const employees: IEmployee[] = users || [];
        setEmployees(employees.map(employee => formatEmployee(employee)));

        if (!employees.length) {
          setMessage('Nenhum funcionário encontrado.');
        }
      })
      .catch(_error => {
        setLoading(false);
        setMessage(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }

  function formatEmployee(employee: any): IEmployee {
    return {
      id: employee.id,
      name: employee.name,
      cpf: employee.cpf,
      dependantsCount: employee.dependants_count,
      status: employee.subscription.status,
      checked: false,
    };
  }

  function handleCheckbox(event: any, employeeId: number): void {
    const checked = event ? event.target.checked : true;

    const checkedEmployees = employees.map(employee => {
      if (employee.id === employeeId) {
        return { ...employee, checked: checked };
      }

      return employee;
    });

    verifyAllCheckboxes(checkedEmployees);
    setEmployees(checkedEmployees);
  }

  function handleAllCheckboxes(event: any): void {
    const checked = event.target.checked;

    const checkedEmployees = employees.map(employee => {
      if (employee.status === 'active')
        return { ...employee, checked: checked };

      return { ...employee, checked: false };
    });

    setAllEmployeesChecked(checked);
    setEmployees(checkedEmployees);
  }

  function verifyAllCheckboxes(employees: IEmployee[]): void {
    const uncheckedEmployees = employees.filter(employee => !employee.checked);

    uncheckedEmployees.length === 0
      ? setAllEmployeesChecked(true)
      : setAllEmployeesChecked(false);
  }

  function onSearch() {
    clearTimeout(debounceTimer);

    setEmployees([]);
    setLoading(true);
    setMessage('Carregando...');
    setAllEmployeesChecked(false);

    const timeout = setTimeout(() => {
      setDebounceTimer(null);
      setCurrentPage(1);
      loadEmployeesByCompany();
    }, 1000);

    setDebounceTimer(timeout);
  }

  function getChipByStatus(status: string) {
    switch (status) {
      case 'active':
        return (
          <ChipActive>
            <span>Ativo</span>
          </ChipActive>
        );
      case 'canceled':
        return (
          <ChipCanceled>
            <span>Cancelado</span>
          </ChipCanceled>
        );
      default:
        return (
          <Chip>
            <span>Inativo</span>
          </Chip>
        );
    }
  }

  function handleSelectPage(page: number) {
    setCurrentPage(page);
  }

  function openConfirmModal(employeeId: number) {
    handleCheckbox(null, employeeId);

    setShowConfirmModal(true);
  }

  function clickCloseModal() {
    setShowConfirmModal(false);
    setShowSuccessModal(false);
    setShowErrorModal(false);
  }

  function clickConfirmModal() {
    handleExclusion();
    setShowConfirmModal(false);
  }

  function handleExclusion() {
    const checkedEmployeesIds = employees
      .filter(employee => employee.checked)
      .map(employee => employee.id);

    api
      .delete(
        `/hr-account/companies/${getCompanyId()}/users/${checkedEmployeesIds.toString()}`,
      )
      .then(response => {
        loadEmployeesByCompany();
        setShowSuccessModal(true);
      })
      .catch(_error => {
        setLoading(false);
        setShowErrorModal(true);
        setMessage(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }

  function getConfirmModalMessage() {
    const employeesCount = employees.filter(u => u.checked);

    if (employeesCount.length === 1) {
      return `Tem certeza que deseja excluir o funcionário(a) ${employeesCount[0].name}?`;
    } else {
      return `Tem certeza que deseja excluir os ${employeesCount.length} funcionários selecionados?`;
    }
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function handleEmployeeDetails(employeeId: number) {
    history.push(`/rh/funcionarios/${employeeId}`);
  }

  function handleStatusFilterBox() {
    setShowStatusFilterBox(!showStatusFilterBox);
  }

  function handleFilter() {
    setShowFilterBox(!showFilterBox);
  }

  function handleOrderByName() {
    setOrderByDate('');
    orderByName === 'ASC' ? setOrderByName('DESC') : setOrderByName('ASC');
  }

  function handleOrderByDate(order: string) {
    setOrderByName('');
    order === 'ASC' ? setOrderByDate('ASC') : setOrderByDate('DESC');
  }

  function handleStatusFilter(status: string) {
    if (status === 'active') return setStatusFilter('active');
    if (status === 'canceled') return setStatusFilter('canceled');

    return setStatusFilter('all');
  }

  function renderFilterBox(showFilterBox: boolean) {
    return (
      showFilterBox && (
        <FilterBox
          onMouseOver={() => setShowFilterBox(true)}
          onMouseLeave={() => setShowFilterBox(false)}
        >
          <span onClick={handleOrderByName}>Ordem alfabética</span>
          <span onClick={() => handleOrderByDate('DESC')}>Mais recentes</span>
          <span onClick={() => handleOrderByDate('ASC')}>Mais antigos</span>
        </FilterBox>
      )
    );
  }

  function renderStatusFilterBox(showStatusFilterBox: boolean) {
    return (
      showStatusFilterBox && (
        <StatusFilterBox
          onMouseOver={() => setShowStatusFilterBox(true)}
          onMouseLeave={() => setShowStatusFilterBox(false)}
        >
          <span onClick={() => handleStatusFilter('all')}>Todos</span>
          <span onClick={() => handleStatusFilter('active')}>Ativos</span>
          <span onClick={() => handleStatusFilter('canceled')}>Inativos</span>
        </StatusFilterBox>
      )
    );
  }

  function handleOnSearch(event: any) {
    setMessage('Nenhum funcionário encontrado na busca!');
    setSearch(event.target.value);
  }

  function handleUsersReportDownload() {
    setLoading(true);

    api
      .get(`/hr-account/companies/${getCompanyId()}/users-report`)
      .then(response => {
        setLoading(false);
        setShowProcessModal(true);
        setNotification(response.data.notification);
      })
      .catch(_error => {
        setLoading(false);
        setMessage(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }

  function startCheckingReportProcess() {
    api
      .get(
        `/hr-account/companies/${getCompanyId()}/notifications/${
          notification?.id
        }`,
      )
      .then(response => {
        const status = response.data.notification.status;

        if (status === 'success') {
          clearTimeout(intervalState);
          setShowProcessModal(false);
          setShowSuccessReportModal(true);
          setNotification(response.data.notification);
        } else if (status === 'error') {
          clearTimeout(intervalState);
          setShowProcessModal(false);
          setShowErrorModal(true);
        }
      })
      .catch(_error => {
        setLoading(false);
        setMessage(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }

  function handleCloseSuccessReportModal() {
    setShowSuccessReportModal(false);
    setEmployeesReportUrl(notification?.content?.file_url);
  }

  function renderModalByResult() {
    if (showProcessModal) {
      return <ProcessingModal />;
    } else if (showSuccessReportModal) {
      return <SuccessReportModal onClose={handleCloseSuccessReportModal} />;
    }
  }

  return (
    <>
      <Dashboard title="Funcionários" backPath="/rh/escolher-empresa">
        <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
        <Container>
          {!employees.length && !search && !statusFilter && !loading ? (
            <ContainerBox>
              <p>
                Nenhum funcionário encontrado. Clique no botão abaixo para
                cadastrar.
              </p>
              <PrimaryButton
                type="button"
                height="2.5rem"
                width="15rem"
                onClick={() => history.push(`/rh/funcionario/novo`)}
              >
                Cadastrar Funcionário
              </PrimaryButton>
            </ContainerBox>
          ) : (
            <ContainerList>
              <HeaderActions>
                <SearchInput
                  type="text"
                  placeholder="Buscar por nome ou cpf"
                  value={search || ''}
                  onChange={(event: any) => handleOnSearch(event)}
                />

                <PrimaryButton
                  type="button"
                  height="2.5rem"
                  width="13rem"
                  iconUrl={addIcon}
                  onClick={() => history.push(`/rh/funcionario/novo`)}
                >
                  Novo funcionário
                </PrimaryButton>
                <Tooltip position="top center" text="Exportar relatório">
                  <div>
                    <PrimaryButton
                      type="button"
                      height="2.5rem"
                      width="2.5rem"
                      onClick={handleUsersReportDownload}
                      iconUrl={exportIcon}
                    />
                  </div>
                </Tooltip>
                {employeesReportUrl && (
                  <iframe
                    title='relatório de funcionários'
                    style={{ display: 'none' }}
                    src={employeesReportUrl}
                  ></iframe>
                )}
              </HeaderActions>

              <CustomTable>
                <thead>
                  <tr>
                    <th scope="col">
                      {statusFilter !== 'canceled' && (
                        <>
                          <Checkbox
                            checked={allEmployeesChecked}
                            onChange={handleAllCheckboxes}
                          />
                          {employees.filter(u => u.checked).length > 0 && (
                            <Tooltip
                              position="top center"
                              text="excluir selecionados"
                            >
                              <img
                                src={purpleTrashIcon}
                                alt="Icone para exclusão em massa"
                                onClick={() => openConfirmModal(0)}
                              />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={handleFilter}
                      className="filter-box"
                    >
                      NOME
                      <img src={arrowDown} alt="Seta para baixo" />
                      {renderFilterBox(showFilterBox)}
                    </th>
                    <th scope="col">CPF</th>
                    <th className="th-dependants" scope="col">
                      DEPENDENTES
                    </th>
                    <th
                      scope="col"
                      onClick={handleStatusFilterBox}
                      className="filter-box"
                    >
                      STATUS
                      <img src={arrowDown} alt="Seta para baixo" />
                      {renderStatusFilterBox(showStatusFilterBox)}
                    </th>
                    <th scope="col">AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map(employee => (
                    <tr key={employee.cpf}>
                      <td>
                        {employee.status === 'active' && (
                          <Checkbox
                            checked={employee.checked}
                            onChange={(e: any) =>
                              handleCheckbox(e, employee.id)
                            }
                          />
                        )}
                      </td>
                      <td onClick={() => handleEmployeeDetails(employee.id)}>
                        {employee.name}
                      </td>
                      <td onClick={() => handleEmployeeDetails(employee.id)}>
                        {employee.cpf}
                      </td>
                      <td
                        className="td-dependants"
                        onClick={() => handleEmployeeDetails(employee.id)}
                      >
                        {employee.dependantsCount}
                      </td>
                      <td onClick={() => handleEmployeeDetails(employee.id)}>
                        {getChipByStatus(employee.status)}{' '}
                      </td>
                      <td>
                        <div className="d-flex flex-row">
                          <Tooltip position="top center" text="editar">
                            <img
                              className='mr-2'
                              onClick={() => handleEmployeeDetails(employee.id)}
                              height="16rem"
                              src={editIcon}
                              alt="Icone para edição"
                            />
                          </Tooltip>
                          {employee.status === 'active' && (
                            <Tooltip position="top center" text="excluir">
                              <img
                                onClick={() => openConfirmModal(employee.id)}
                                height="16rem"
                                src={trashIcon}
                                alt="Icone para exclusão"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustomTable>
              {(loading || !employees.length) && <p>{message}</p>}
              <Pagination
                currentPage={currentPage}
                numOfPages={totalPages}
                totalFilteredCount={totalFilteredCount}
                totalCount={totalCount}
                lengthPage={employees.length}
                perPage={perPage}
                onSelectPage={handleSelectPage}
              />
            </ContainerList>
          )}
        </Container>
      </Dashboard>
      <Modal
        type="confirmation"
        icon={redTrash}
        showModal={showConfimModal}
        onCancel={clickCloseModal}
        onConfirm={clickConfirmModal}
        message={getConfirmModalMessage()}
      />
      <Modal
        type="success"
        icon={successIcon}
        showModal={showSuccessModal}
        onCancel={() => {}}
        onConfirm={clickCloseModal}
        message={'Funcionário(s) excluído(s) com sucesso!'}
      />
      <Modal
        type="error"
        icon={errorIcon}
        showModal={showErrorModal}
        onCancel={() => {}}
        onConfirm={clickCloseModal}
        message={'Algo errado aconteceu :/'}
      />
      {renderModalByResult()}
    </>
  );
};

export default Employees;
