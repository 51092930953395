import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "service/subscriberApi";
import { getUser } from "service/subscriber";

import PrimaryButton from "components/PrimaryButton";
import SubscriberDashboard from "components/SubscriberDashboard";

import { BodyModal, Container } from "./styles";

import myHealthIcon from 'assets/my-health.svg';

import MyHealthQuiz from "./MyHealthQuiz";

export interface IMyHealthQuizData {
  weight: string;
  height: string;
  gender: string;
  chronicDiseases: string;
  familyChronicDiseases: string;
  chronicDiseasesDescription: string;
  familyChronicDiseasesDescription: string;
  useMedication: string;
  medicationDescription: string;
  cigarettesAmount: string;
  drinkAlcohol: string;
  physicalActivity: string;
  sleepQuality: string;
  foodQuality: string;
  healthQuality: string;
  mentalHealthQuality: string;
  lastAppointment: string;
}

const MyHealth: React.FC = () => {
  const [showQuiz, setshowQuiz] = useState(false);
  const [myHealthData, setMyHealthData] = useState<IMyHealthQuizData>({} as IMyHealthQuizData)

  useEffect(() => {
    loadUserHealthInfo();
  }, [])

  
  function loadUserHealthInfo(): void {
    api.get(`/users/${getUser().id}/get-user-health-info`)
    .then((response) => {
        if (response.status === 200 && response.data) {
          setMyHealthData(formatUserHealthInfo(response.data));
          setshowQuiz(true);
        }
        else if (response.status === 204) {
          setshowQuiz(false);
          setMyHealthData({} as IMyHealthQuizData);
        }
      })
      .catch(_error => {
        toast.error(
          'Serviço indisponível no momento, por favor tente mais tarde.',
        );
      });
  }
  
  function formatUserHealthInfo(data: any): IMyHealthQuizData {
    return {
      weight: data.weight,
      height: data.height,
      gender: data.gender,
      chronicDiseases: data.chronic_diseases,
      familyChronicDiseases: data.family_chronic_diseases,
      chronicDiseasesDescription: data.chronic_diseases_description,
      familyChronicDiseasesDescription: data.family_chronic_diseases_description,
      useMedication: data.use_medication.toString(),
      medicationDescription: data.medication_description,
      cigarettesAmount: data.cigarettes_amount,
      drinkAlcohol: data.drink_alcohol,
      physicalActivity: data.physical_activity,
      sleepQuality: data.sleep_quality,
      foodQuality: data.food_quality,
      healthQuality: data.health_quality,
      mentalHealthQuality: data.mental_health_quality,
      lastAppointment: data.last_appointment
    }
  }

  return (
    <SubscriberDashboard title="Minha Saúde" backPath="/assinantes/home">
      <Container>
        {!showQuiz && <BodyModal>
          <div className="header-content">
            <img src={myHealthIcon} alt="Minha Saúde"></img>
            <h3>Nós ainda não te conhecemos tão bem :(</h3>  
          </div>
          <p>Só faltam algumas perguntas pra gente conseguir entender melhor a Sua Saúde.
              Você pode nos contar depois, mas vamos precisar desses dados antes da sua primeira consulta. Melhor responder logo, né?</p>
          <PrimaryButton width="10rem" type="button" onClick={() => setshowQuiz(true)}>Começar quiz</PrimaryButton> 
        </BodyModal>}
        {showQuiz && <MyHealthQuiz myHealthData={myHealthData} close={() => setshowQuiz(false)}/>}
      </Container>
    </SubscriberDashboard>
  )
}

export default MyHealth;
