import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Form = styled.form`
  div {
    margin-top: 1rem;
    width: 100%;
  }

  .box-errors {
    margin: 0;
    color: ${COLORS.red};
    text-align: initial;
    font-size: 0.75rem;
  }
`;

export const BoxLogo = styled.div`
  display: flex;
  justify-content: center;

  .active {
    opacity: initial;
  }

  img {
    opacity: 0.4;
  }

  img + img {
    margin-left: 0.5rem;
  }
`;

export const FormGroup = styled.div`
  display: flex;

  div {
    margin-top: 0rem;
  }

  div + div {
    margin-left: 1rem;
  }
`;

export const ActionsBox = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  p {
    cursor: pointer;
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }

  button {
    max-width: 10rem;
  }
`;
