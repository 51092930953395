import React, { useEffect, useState, useContext } from "react";

import {
  Container,
  SuccessBox,
  ErrorBox,
  WarningBox
} from './styles';

import api from 'service/subscriberApi';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import Dashboard from 'components/SubscriberDashboard'

import successIcon from 'assets/small-success-icon.svg';
import errorIcon from 'assets/small-error-icon.svg';

import { toast } from "react-toastify";

interface INotification {
  id: number;
  title: string;
  status: string;
  content: object;
  event_type: string;
  description: string;
}

const Notification: React.FC = (props: any) => {
  const notificationId = props.match.params.notification_id;
  const [notification, setNotification] = useState<INotification>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Notificações', path: `/assinantes/notificacoes` },
      { title: `Notificação ${notificationId}`, path: `/assinantes/notificacoes/${notificationId}` }
    ]);

    loadNotification()
  }, [])

  function loadNotification() {
    setLoading(true);
    setMessage('Carregando...');

    let url = `/users/notifications/${notificationId}?`

    api.get(url)
      .then((response) => {
        if (response.data.notification) {
          setLoading(false);
          setMessage('');
          setNotification(response.data.notification);
        } else {
          setLoading(false);
          setMessage('Nenhuma notificação encontrada!');
        }
      }).catch((_error) => {
        setLoading(false);
        toast.error('Serviço indisponível no momento, por favor tente mais tarde.');
      });
  }

  function getNotificationBoxByStatus(notification: INotification) {
    if (notification.status === 'error') {
      return (
        <ErrorBox>
          <div>
            <img src={errorIcon} alt="icone de erro." />
            <span>{notification.description}</span>
          </div>
        </ErrorBox>
      )
    }
    else if (notification.status === 'success') {
      return (
        <SuccessBox>
          <div>
            <img src={successIcon} alt="icone de sucesso." />
            <span><span>{notification.description}</span></span>
          </div>
        </SuccessBox>
      )
    } else if (notification.status === 'warning') {
      return (
        <WarningBox>
          <div>
            <img src={successIcon} alt="icone de sucesso." />
            <span><span>{notification.description}</span></span>
          </div>
        </WarningBox>
      )
    }
  }

  return (
    <>
      <Dashboard title={`Notificação ${notificationId}`} backPath={`/assinantes/notificacoes`}>
        <Container>
          {notification && getNotificationBoxByStatus(notification)}
          {!loading && <p>{message}</p>}
        </Container>
      </Dashboard>
    </>
  )
};

export default Notification;
