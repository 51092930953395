import React, { useState } from 'react';

import { BoxTitle, Container, Header } from './styles';

import plus from 'assets/icons/plus.svg';
import dash from 'assets/icons/dash.svg';

interface IAccordion {
  title: string;
  logo?: string;
  titleColor?: "accent" | "default";
}

const Accordion: React.FC<IAccordion> = ({ title, logo, titleColor, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container onClick={() => setExpanded(!expanded)}>
      <Header titleColor={titleColor}>
        <BoxTitle>
          {logo && <img src={logo}/>}
          <h2>{title}</h2>
        </BoxTitle>
        {!expanded && <img src={plus} alt='ampliar'/>}
        {expanded && <img src={dash} alt='diminuir'/>}
      </Header>
      {expanded && children}
    </Container>
  );
};

export default Accordion;
