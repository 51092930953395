import React, { useState } from 'react';

import { InputBox, Input } from './styles';

import ActiveEyeIcon from 'assets/icons/active-eye.svg';
import InactiveEyeIcon from 'assets/icons/inactive-eye.svg';

interface ITextInput {
  type: string;
  placeholder: string;
  value: string;
  onChange?: Function;
  onBlur?: Function;
  maxLength?: number;
  typeMask?: 'cpf' | 'date' | 'phone' | 'zip_code' | 'credit_card_number' | 'only_numbers' | 'mmyyyy' | 'height' | 'weight'| null;
  disabled?: boolean;
  icon?: string;
  iconAlt?: string;
  iconPosition?: string;
}

const TextInput: React.FC<ITextInput> = (props) => {
  const { type, placeholder, onChange, onBlur, value, maxLength, typeMask, disabled = false, icon, iconAlt, iconPosition} = props;
  const [showPassword, setShowPassword] = useState(false)

  function cpfMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  function weightMask(value: string): string {
    if (value.length > 5) {
      return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2');
    } else {
      return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2');
    }
  }

  function heightMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d)/, '$1.$2');
  }

  function dateMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');
  }

  function phoneMask(value: string): string {
    value = value.replace(/[^0-9]+/g, "")
    if (value.length < 3) return value;

    value = value.replace(/^(\d{1})(\d)/, '($1$2) ')

    if (value.length === 14) {
      value = value.replace(/(\d{5})(\d{1,2})/, '$1-$2');
    } else if (value.length === 13) {
      value = value.replace(/(\d{4})(\d{1,2})/, '$1-$2');
    }

    return value
  }

  function zipCodeMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{3})/, '$1-$2')
  }

  function creditCardMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2');
  }

  function onlyNumbersMask(value: string): string {
    return value.replace(/\D/g, '');
  }

  function mmYYYYMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1')
  }

  function handleChange(event: any) {
    let value = event.target.value;

    if (typeMask && typeMask === 'cpf') {
      value = cpfMask(value);
    } else if (typeMask && typeMask === 'date') {
      value = dateMask(value);
    } else if (typeMask && typeMask === 'height') {
      value = heightMask(value);
    } else if (typeMask && typeMask === 'weight') {
      value = weightMask(value);
    } else if (typeMask && typeMask === 'phone') {
      value = phoneMask(value);
    } else if (typeMask && typeMask === 'zip_code') {
      value = zipCodeMask(value);
    } else if (typeMask && typeMask === 'credit_card_number') {
      value = creditCardMask(value);
    } else if (typeMask && typeMask === 'only_numbers') {
      value = onlyNumbersMask(value);
    } else if (typeMask && typeMask === 'mmyyyy') {
      value = mmYYYYMask(value);
    }

    onChange && onChange(value)
  }

  function showIcon(type: string) {
    if (!icon) return;

    if (type === 'password') {
      if (showPassword) {
        return <img src={ActiveEyeIcon} alt="Ícone para mostrar a senha" onClick={() => setShowPassword(!showPassword)} />
      } else {
        return <img src={InactiveEyeIcon} alt="Ícone para não mostrar a senha" onClick={() => setShowPassword(!showPassword)} />
      }
    } else {
      return <img src={icon} alt={iconAlt} onClick={() => setShowPassword(!showPassword)} />
    }
  }

  return (
    <InputBox disabled={disabled} iconPosition={iconPosition}>
      <Input
        type={type === 'password' && showPassword ? 'text' : type}
        placeholder={placeholder}
        value={value}
        onBlur={() => onBlur && onBlur(value)}
        onChange={handleChange}
        maxLength={maxLength}
        iconPosition={iconPosition}
        disabled={disabled === true} />
      <label>{placeholder}</label>
      {showIcon(type)}
    </InputBox>
  );
};

export default TextInput;
