import React from "react";

import { BodyModal } from "./styles";

import GenericModal from "components/GenericModal";

import tombModalIcon from "assets/icons/tomb.svg";
import closeIcon from "assets/icons/close-accent-background.svg";

interface ITombModal {
  close: Function;
}

const TombModal: React.FC<ITombModal> = ({close}) => {  
  return (
    <GenericModal height="auto" width="37rem">
      <BodyModal>
        <img onClick={() => close()} src={closeIcon} alt="Fechar"></img>
        <div className="header-content">
          <img src={tombModalIcon} alt="Assistência Funeral"></img>
          <h3>Assistência Funeral</h3>  
        </div>
        <p>Garante a disponibilização dos serviços de Assistência funeral, individual ou familiar limitado em até R$ 5.000,00.</p>
        <strong><p>”Familiar” se aplica ao cônjuge, filhos, entiados e tutelados até 21  anos, se universitário, até 24 anos.</p></strong>
      </BodyModal>      
    </GenericModal>
  )
}

export default TombModal;
