import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const BaseModal = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    height: 14rem;
    width: 21rem;
    background-color: ${COLORS.white};
    border-radius: 0.5rem;
    text-align: center;
    padding: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;

    img {
      margin-bottom: 1rem;
    }

    strong {
      font-style: normal;
      font-weight: 550;
      font-size: 1rem;
      line-height: 1.2rem;
    }

    button {
      height: 32px;
      background-color: transparent;
      border: 1px solid ${COLORS.red};
      border-radius: 0.5rem;
      width: 12rem;
      color: ${COLORS.red};
      font-size: 1rem;
      margin-top: 1.25rem;
    }

    button + button {
      background-color: transparent;
      border: 0px;
      border-radius: 0.5rem;
      width: 12rem;
      color: ${COLORS.mediumGrey};
      font-size: 1rem;
      margin-top: 0.2rem;
    }
  }
`;

export const ConfirmModal = styled(BaseModal)`
    div {
      button {
        border: 0.1rem solid ${COLORS.red};
        color: ${COLORS.red};
      }
    }
`;

export const ErrorModal = styled(BaseModal)`
    div {
      button {
        border: unset;
        color: ${COLORS.red};
      }
    }
`;

export const SuccessModal = styled(BaseModal)`
    div {
      button {
        border: unset;
        color: ${COLORS.success};
      }
    }
`;
