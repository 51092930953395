import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Form = styled.form`
  max-width: 31rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 28.125rem;
  min-height: 45rem;
  padding: 2rem;

  span {
    color: ${COLORS.red};
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }
`;

export const ActionsBox = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  p {
    cursor: pointer;
    color: ${COLORS.accentColor};
    text-decoration: underline;
  }

  button {
    max-width: 10rem;
  }
`;

export const ContainerLoading = styled.p`
  text-align: center;
`;

export const Switch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    align-items: center;
    margin-top: 0;
  }

  span {
    font-size: 1rem;
    color: ${COLORS.offblack};
  }

  img {
    cursor: pointer;
    margin-left: 0.5rem;
  }
`;

export const Separator = styled.hr`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: solid 0.01rem rgba(220, 220, 220, 0.6);
`;

export const DocumentInputs = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  .rg-input {
    width: 55%;
    margin-right: 0.5rem;
  }

  .issuing-agency-select {
    width: 45%;
  }
`