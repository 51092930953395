import React from 'react';
import { useHistory } from 'react-router-dom';

import PrimaryButton from 'components/PrimaryButton';

import newDependant from 'assets/new-dependant.svg';

import { ContainerEmptyArea } from './styles';

const EmptyArea: React.FC = () => {
  const history = useHistory();

  return (
    <ContainerEmptyArea>
      <div>
        <img src={newDependant} alt="Novo dependente" />
        <p>Você não possui dependentes. Clique no botão abaixo para cadastrar</p>
        <PrimaryButton type="button" onClick={() => history.push('/assinantes/dependente/novo?step=personal-data')}>
          Cadastrar dependente
        </PrimaryButton>
      </div>
    </ContainerEmptyArea>
  );
};

export default EmptyArea;
