import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'service/subscriberApi';

import SubscriberDashboard from 'components/SubscriberDashboard';
import Tabs, { ITabItems } from 'components/Tabs';
import UpdatePaymentMethodForm from './UpdatePaymentMethodForm';
import PaymentCard from './PaymentCard';

import { useBreadcrumb } from 'hooks/useBreadcrumb';

import { getSubscriberPersonalDataTabs, SubscriberPersonalDataTabsEnum } from 'utils';

import {
  Container,
  PaymentBox,
} from './styles';

export interface IPaymentMethod {
  flag: string;
  flagName: string;
  lastFourNumbers: string;
}

const PaymentData: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumb, defaultBreadcrumbs } = useBreadcrumb();
  const [tabItems] = useState<ITabItems[]>(getSubscriberPersonalDataTabs(SubscriberPersonalDataTabsEnum.PAYMENT_DATA));
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null);

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumbs,
      { title: 'Meio de pagamento', path: `/assinantes/dados-de-pagamento` }
    ]);
    getPaymentMethod();
  }, []);

  function getPaymentMethod() {
    setLoading(true);
    setMessage('');
    api.get(`/users/payment-methods`)
      .then((response) => {
        setLoading(false);
        const paymentMethod = response.data.payment_method;
        if (paymentMethod) {
          setPaymentMethod({
            flag: paymentMethod.payment_company.code,
            flagName: paymentMethod.payment_company.name,
            lastFourNumbers: paymentMethod.card_number_last_four
          });
        } else {
          setPaymentMethod(null);
          setMessage('Nenhum cartão de crédito adicionado.');
        }
      }).catch((_error) => {
        setLoading(false);
        setMessage('Não foi possível carregar as informações. Por favor tente novamente mais tarde!');
      });
  }

  function handleSelectTab(tabId: string) {
    const activeTab = tabItems.find(tabItem => tabItem.id === tabId);
    if (!activeTab) return;

    history.push(activeTab.path);
  }

  function closeForm() {
    setUpdating(false);
    getPaymentMethod();
  }

  function chooseForm() {
    if (loading) {
      return <p>Carregando...</p>
    } else if (updating) {
      return <UpdatePaymentMethodForm closeForm={() => closeForm()} />
    } else if (paymentMethod) {
      return <PaymentCard paymentMethod={paymentMethod} setUpdating={setUpdating} />
    } else {
      return <p>{message}</p>
    }
  }

  return (
    <SubscriberDashboard title="Dados Pessoais">
      <Tabs tabItems={tabItems} onSelectTab={handleSelectTab} />
      <Container>
        <PaymentBox>
          {chooseForm()}
        </PaymentBox>
      </Container>
    </SubscriberDashboard>
  )
}

export default PaymentData;

