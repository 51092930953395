import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import api from 'service/hrAccountApi';
import { searchCep } from 'service/searchCep';
import IState from 'service/IState';
import { getCompanyId } from 'service/company';

import PrimaryButton from 'components/PrimaryButton';
import TextInput from 'components/TextInput';
import SelectInput, { IOption } from 'components/SelectInput';

import { Form, ActionsBox, ContainerLoading } from './styles';
import { IEmployeeAddress } from '..';

interface IError {
  key: string;
  message: string;
}

interface IFormAddress {
  employeeId?: string;
  employeeAddress?: IEmployeeAddress
  loading: boolean
}

const FormAddress: React.FC<IFormAddress> = ({ employeeId, employeeAddress, loading }) => {
  const history = useHistory();
  const [searchedCep, setSearchedCep] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<IError[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [addressId, setAddressId] = useState<number | null>(null);
  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [states, setStates] = useState<IState[]>([]);

  useEffect(() => {
    const address = employeeAddress
    if (!!address) {
      setAddressId(address.id);
      setZipCode(address.zip_code);
      setStreet(address.street);
      setNumber(address?.number ? address?.number.toString() : "");
      setComplement(address.complement!);
      setDistrict(address.district);
      setCity(address.city);
      setState(address.state);
    }
  }, [employeeAddress]);

  useEffect(() => {
    api.get(`/states`)
      .then((response) => {
        setStates(response.data.states);
      })
  }, []);

  async function submitForm(event: FormEvent) {
    try {
      event.preventDefault();
      setSaving(true);
      setMessages([]);
      setErrors([]);
      const params = { zip_code: zipCode, street, number, complement, district, city, state }
      if (!!addressId) {
        await api.put(`/hr-account/companies/${getCompanyId()}/users/${employeeId}/address/${addressId}`, params);
      } else {
        await api.post(`/hr-account/companies/${getCompanyId()}/users/${employeeId}/address`, params);
      }
      setSaving(false);
      toast.success('Dados salvos com sucesso!');
      history.push('/rh/funcionarios/');
    } catch (ex: any) {
      handleResponseErrors(ex);
    }
  }

  function handleResponseErrors(ex: any) {
    setSaving(false);
    toast.error('Não foi possível atualizar o cadastro!');
    const response = ex.response;
    if (response && response.data) {
      const error: any = response.data.error;
      const newErrors: any = response.data.errors;

      if (error) {
        setMessages([...messages, response.data.error]);
      } else if (!!newErrors) {
        const newFieldErrors: IError[] = [];
        Object
          .keys(newErrors)
          .forEach((attr: any) => {
            const values: any = newErrors[attr];
            values.forEach((value: any) => {
              newFieldErrors.push({ key: attr, message: value });
            });
          })

        setErrors(newFieldErrors);
      }
    } else {
      setMessages([...messages, 'Serviço indisponível no momento, por favor tente mais tarde.']);
    }
  }

  function formatMessage({ message }: IError): string {
    return message?.trim().replace('.', '');
  }

  function handleCep(value: null) {
    searchCep(value || zipCode)
      .then((response) => {
        const { bairro, localidade, logradouro, uf, erro } = response.data;
        if (erro) {
          toast.error('CEP não foi encontrado');
        } else {
          setDistrict(bairro);
          setCity(localidade);
          setStreet(logradouro);
          setState(uf);
          setSearchedCep(true);
        }
      })
      .catch(() => {
        toast.error('CEP não foi encontrado');
      });
  }

  function onChangeCep(value: any) {
    const MAXLENGHT_CEP_VALUE = 9;
    setZipCode(value);
    if (value && value.length === MAXLENGHT_CEP_VALUE) {
      handleCep(value);
    }
  }

  function getCities(state: string): IOption[] {
    const stateFound = states.find((s) => s.shortName === state);
    const cities = stateFound?.cities || [];
    return cities.map((city) => ({ label: city, value: city }));
  }

  function getStates(): IOption[] {
    return states.map((s) => ({ label: s.fullName, value: s.shortName }));
  }

  if (loading) return <ContainerLoading>Carregando...</ContainerLoading>;

  return (
    <Form onSubmit={submitForm}>
      <TextInput
        onChange={onChangeCep}
        onBlur={handleCep}
        value={zipCode}
        type="text"
        placeholder="CEP *"
        typeMask="zip_code"
        maxLength={9} />
      <span>
        {
          errors
            .filter((error) => error.key === 'zip_code')
            .map(formatMessage)
            .join(', ')
        }
      </span>
      <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="blank">Não sei meu CEP</a>

      {
        searchedCep &&
        <>
          <TextInput
            onChange={(value: any) => setStreet(value)}
            value={street}
            type="text"
            placeholder="Rua *"
            maxLength={100} />
          <span>
            {
              errors
                .filter((error) => error.key === 'street')
                .map(formatMessage)
                .join(', ')
            }
          </span>

          <TextInput
            onChange={(value: any) => setNumber(value)}
            value={number}
            type="number"
            placeholder="Número"
            maxLength={4} />
          <span>
            {
              errors
                .filter((error) => error.key === 'number')
                .map(formatMessage)
                .join(', ')
            }
          </span>

          <TextInput
            onChange={(value: any) => setComplement(value)}
            value={complement}
            type="text"
            placeholder="Complemento"
            maxLength={255} />
          <span>
            {
              errors
                .filter((error) => error.key === 'complement')
                .map(formatMessage)
                .join(', ')
            }
          </span>

          <TextInput
            onChange={(value: any) => setDistrict(value)}
            value={district}
            type="text"
            placeholder="Bairro *"
            maxLength={100} />
          <span>
            {
              errors
                .filter((error) => error.key === 'district')
                .map(formatMessage)
                .join(', ')
            }
          </span>

          <SelectInput
            onChange={(value: string) => setState(value)}
            options={getStates()}
            value={state}
            defaultValue={state? state : "Selecione um estado..."} />
          <span>
            {
              errors
                .filter((error) => error.key === 'state')
                .map(formatMessage)
                .join(', ')
            }
          </span>

          <SelectInput
            onChange={(value: string) => setCity(value)}
            options={getCities(state)}
            value={city}
            defaultValue={city ? city : "Selecione uma cidade..." }/>
          <span>
            {
              errors
                .filter((error) => error.key === 'city')
                .map(formatMessage)
                .join(', ')
            }
          </span>
        </>
      }

      {messages.map(msg => formatMessage({ key: '', message: msg }))
        .join(', ')}

      <ActionsBox>
        <p onClick={() => history.push(`/rh/funcionarios/${employeeId}`)}>Cancelar</p>
        <PrimaryButton type="submit" loading={saving}>
          Salvar alteração
        </PrimaryButton>
      </ActionsBox>
    </Form>
  );
}

export default FormAddress;
